/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const SyncIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 -960 960 960'
      onClick={onClick}
      width={size}
      xmlns='http://www.w3.org/2000/svg'
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M236-477q0 60 21.5 103.5T319-298l25 16v-74q0-13 9.5-23t25.5-10q13 0 23 10t10 23v162q0 21-13 33.5T367-148H206q-15 0-24.5-10t-9.5-25q0-13 9.5-23t25.5-10h70l-6-6q-66-56-96.5-117T144-477q0-102 55.5-184T345-784q15-8 29.5 1.5T394-755q3 18-5.5 34T367-696q-62 32-96.5 91T236-477Zm489-7q0-44-23-90.5T644-657l-24-21v73q0 14-10 24t-24 10q-14 0-24.5-10.5T551-606v-161q0-20 13-32.5t34-12.5h160q14 0 24 9.5t10 23.5q0 14-10 24.5T758-744h-73l7 8q63 57 94 124t31 128q0 102-53.5 183.5T620-177q-16 9-31.5 0T569-205q-5-18 3-34t24-25q60-31 94.5-90.5T725-484Z' />
    </svg>
  )
}

export default SyncIcon
