import moment from 'moment'

export const dynamicStyles = (
  event,
  eventStartPlacement,
  eventEndPlacement,
  week
) => {
  /* Variables for event border radius */
  const eventStartTime = new Date(moment(event.startTime).format('YYYY-MM-DD'))
  const eventEndTime = new Date(moment(event.endTime).format('YYYY-MM-DD'))
  const firstDayOfWeek = new Date(week.length > 0 && week[0]?.fullDate)
  const lastDayOfWeek = new Date(week[week.length - 1]?.fullDate)

  return {
    gridColumnStart:
      eventStartTime < firstDayOfWeek
        ? 1
        : eventStartPlacement(week, event, moment),
    gridColumnEnd:
      eventEndTime > lastDayOfWeek ? 8 : eventEndPlacement(week, event, moment),

    borderTopRightRadius: `${eventEndTime > lastDayOfWeek ? 0 : 12}px`,
    borderBottomRightRadius: `${eventEndTime > lastDayOfWeek ? 0 : 12}px`,
    borderTopLeftRadius: `${eventStartTime < firstDayOfWeek ? 0 : 12}px`,
    borderBottomLeftRadius: `${eventStartTime < firstDayOfWeek ? 0 : 12}px`,
    marginRight: `${eventEndTime > lastDayOfWeek ? 0 : 2}px`,
    marginLeft: `${eventStartTime < firstDayOfWeek ? 0 : 2}px`,
  }
}
