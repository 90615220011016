/* eslint-disable no-unused-vars */
import axios from 'axios'
import {
  AccountCircleIcon,
  AddContactIcon,
  AddGroupIcon,
  AppearanceIcon,
  BlockedAccountIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ContactsIcon,
  DataIcon,
  EditIcon,
  EmailIcon,
  EventRequestIcon,
  LinkIcon,
  LockIcon,
  MoreVertIcon,
  NotificationsIcon,
  PhoneIcon,
  PreferenceIcon,
  SecurityIcon,
  SubscriptionIcon,
  VisibilityIcon,
} from 'components/Icons'
import { editUserAPI, setPersonalInformationIsOpen } from 'features'
import { useUser } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { toast } from 'react-toastify'
import uuid from 'react-uuid'

const PrivacyAndSecurity = () => {
  const dispatch = useDispatch()

  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )

  const info = [
    {
      icon: <LockIcon className={'mx-2'} />,
      title: 'Secure login',
      selected: 'Face ID',
    },
    {
      icon: <VisibilityIcon className={'mx-2'} />,
      title: 'Profile visibility',
      selected: 'My Contacts',
    },
  ]
  const privacy = [
    {
      icon: <EventRequestIcon className={'mx-2'} />,
      title: 'Event Requests',
      selected: 'Contact',
    },
    {
      icon: <AddContactIcon className={'mx-2'} />,
      title: 'Contact Requests',
      selected: 'Everyone',
    },

    {
      icon: <AddGroupIcon className={'mx-2'} />,
      title: 'Group Requests',
      selected: 'Nobody',
    },
    {
      icon: <BlockedAccountIcon className={'mx-2'} />,

      title: 'Blocked Accounts',
      selected: '3 blocked',
    },
  ]
  const data = [
    {
      icon: <DataIcon className={'mx-2'} />,
      title: 'Allow UNUM to collect data',
      selected: 'Allow',
    },
  ]

  return (
    <div className={`h-screen p-3 text-sm    ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='  pb-6 font-bold  flex justify-between items-center relative'>
        <ChevronLeftIcon
          size={30}
          className={'cursor-pointer'}
          onClick={() => {
            dispatch(setPersonalInformationIsOpen(false))
          }}
        />

        <p>Privacy and Security</p>

        <MoreVertIcon className={'cursor-pointer invisible'} />
      </div>
      {/* Content */}
      <div className='overflow-y-scroll h-full pb-14'>
        {/* Personal information */}
        <div className='gap-4 flex flex-col '>
          <div
            className={`  rounded-xl   ${
              nightMode
                ? 'bg-DMDarkBlueForeground'
                : 'bg-GrayMediumBGColor bg-opacity-20'
            }`}
          >
            {info?.map((item, index) => (
              <div
                key={uuid()}
                className='h-[44px] px-1   flex justify-start items-center'
              >
                <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                  {item.icon}
                  <div
                    className={`py-2 mr-2 flex w-full   justify-between items-center ${
                      index === info.length - 1
                        ? 'border-0'
                        : 'border-DivideLine border-opacity-20 border-b'
                    }`}
                  >
                    <p>{item.title}</p>
                    <div className='flex gap-3 text-gray-400  items-center'>
                      {' '}
                      <p
                        className={`${
                          nightMode
                            ? 'text-DM2ndLighterTextIcon '
                            : 'text-LM2ndLighterTextIcon'
                        }`}
                      >
                        {item.selected}
                      </p>
                      <ChevronRightIcon size={26} secondaryColor={true} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className={` rounded-xl ${
              nightMode
                ? 'bg-DMDarkBlueForeground'
                : 'bg-GrayMediumBGColor bg-opacity-20'
            }`}
          >
            {privacy?.map((item, index) => (
              <div
                key={uuid()}
                className='h-[44px] px-1   flex justify-start items-center'
              >
                <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                  {item.icon}
                  <div
                    className={`py-2 mr-2 flex w-full   justify-between items-center ${
                      index === privacy.length - 1
                        ? 'border-0'
                        : 'border-DivideLine border-opacity-20 border-b'
                    }`}
                  >
                    <p>{item.title}</p>
                    <div className='flex gap-3 text-gray-400 items-center '>
                      {' '}
                      <p
                        className={`${
                          nightMode
                            ? 'text-DM2ndLighterTextIcon '
                            : 'text-LM2ndLighterTextIcon'
                        }`}
                      >
                        {item.selected}
                      </p>
                      <ChevronRightIcon size={26} secondaryColor={true} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className={` rounded-xl ${
              nightMode
                ? 'bg-DMDarkBlueForeground'
                : 'bg-GrayMediumBGColor bg-opacity-20'
            }`}
          >
            {data?.map((item, index) => (
              <div
                key={uuid()}
                className='h-[44px] px-1   flex justify-start items-center'
              >
                <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                  {item.icon}
                  <div
                    className={`py-2 mr-2 flex w-full   justify-between items-center `}
                  >
                    <p>{item.title}</p>
                    <div className='flex gap-3 text-gray-400 items-center '>
                      {' '}
                      <p
                        className={`${
                          nightMode
                            ? 'text-DM2ndLighterTextIcon '
                            : 'text-LM2ndLighterTextIcon'
                        }`}
                      >
                        {item.selected}
                      </p>
                      <ChevronRightIcon size={26} secondaryColor={true} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyAndSecurity
