/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const LockIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 21 21'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M5.07458 19.2085C4.5567 19.2085 4.10686 19.0202 3.72504 18.6437C3.34324 18.2671 3.15234 17.8234 3.15234 17.3127V8.66683C3.15234 8.13089 3.34324 7.67745 3.72504 7.30654C4.10686 6.93562 4.5567 6.75016 5.07458 6.75016H6.19413V5.2085C6.19413 4.04232 6.60196 3.05285 7.41762 2.2401C8.2333 1.42736 9.21772 1.021 10.3709 1.021C11.5241 1.021 12.5104 1.42736 13.3299 2.2401C14.1493 3.05285 14.559 4.04232 14.559 5.2085V6.75016H15.6786C16.2023 6.75016 16.6571 6.93562 17.043 7.30654C17.429 7.67745 17.6219 8.13089 17.6219 8.66683V17.3127C17.6219 17.8234 17.429 18.2671 17.043 18.6437C16.6571 19.0202 16.2023 19.2085 15.6786 19.2085H5.07458ZM10.3818 14.5627C10.829 14.5627 11.2039 14.4126 11.5067 14.1124C11.8095 13.8121 11.9608 13.4512 11.9608 13.0297C11.9608 12.6211 11.8077 12.2502 11.5014 11.9168C11.1952 11.5835 10.8185 11.4168 10.3713 11.4168C9.9242 11.4168 9.54924 11.5831 9.24647 11.9155C8.9437 12.2479 8.79232 12.6205 8.79232 13.0331C8.79232 13.4514 8.94545 13.8109 9.25171 14.1116C9.55797 14.4123 9.93467 14.5627 10.3818 14.5627ZM8.11636 6.75016H12.6368V5.21331C12.6368 4.55038 12.4187 4.00537 11.9825 3.57829C11.5464 3.1512 11.0127 2.93766 10.3815 2.93766C9.7504 2.93766 9.21508 3.1512 8.77559 3.57829C8.3361 4.00537 8.11636 4.55038 8.11636 5.21331V6.75016Z' />
    </svg>
  )
}

export default LockIcon
