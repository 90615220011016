/* eslint-disable no-unused-vars */
import axios from 'axios'
import { LogoAnimation, Spinner } from 'components'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  EditIcon,
  MoreVertIcon,
} from 'components/Icons'
import { editUserAPI, setPersonalInformationIsOpen } from 'features'
import { useAvatar, useUser } from 'hooks'
import React, { useEffect, useState } from 'react'
import { set, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { toast } from 'react-toastify'
import uuid from 'react-uuid'

const PersonalInformation = () => {
  const dispatch = useDispatch()
  const { user } = useUser()
  const { editUserAPIStatus } = useSelector((state) => state.user)
  const [file, setFile] = useState(null)
  const { avatarOrEmail } = useAvatar()
  const imagePreview = (file) => {
    if (file) {
      return URL.createObjectURL(file)
    }
  }

  console.log('editUserAPIStatus', editUserAPIStatus)

  const {
    register,
    handleSubmit,
    //watch,
    //reset,
    //formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: user?.name,
      lastName: user?.lastName,
      nickNameId: user?.nickNameId,
    },
    //resolver: yupResolver(addEventSchema),
  })

  const onSubmit = async (values) => {
    const formData = new FormData()
    formData.append('avatar', file)
    formData.append('userId', user._id)
    formData.append('name', values.name)
    formData.append('lastName', values.lastName)
    formData.append('nickNameId', values.nickNameId)

    dispatch(editUserAPI(formData))
  }

  useEffect(() => {
    if (editUserAPIStatus === 'success') {
      toast.success('User information updated successfully')
    }
  }, [editUserAPIStatus])

  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )
  const userInfo = [
    {
      icon: 'account_circle',
      title: 'Name',
      value: user?.name,
      InputTag: 'name',
    },
    {
      icon: 'email',
      title: 'Last Name',
      value: user?.lastName,
      InputTag: 'lastName',
    },
    {
      icon: 'phone',
      title: 'UserID',
      value: user?.nickNameId,
      InputTag: 'nickNameId',
    },
  ]
  const socialNetworks = [
    { icon: 'account_circle', title: 'Facebook', selected: '@username' },
    { icon: 'email', title: 'Instagram', selected: '@username' },
    { icon: 'phone', title: 'X (Twitter)', selected: '@username' },
  ]
  return (
    <div className={`h-screen p-3 text-sm    ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='  pb-6 font-bold  flex justify-between items-center relative'>
        <ChevronLeftIcon
          size={30}
          className={'cursor-pointer'}
          onClick={() => {
            dispatch(setPersonalInformationIsOpen(false))
          }}
        />

        <p>Personal Information</p>

        <MoreVertIcon className={'cursor-pointer invisible'} />
      </div>
      {/* Content */}
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className='overflow-y-scroll h-full pb-14'
      >
        {/* User avatar */}
        <div className='w-full flex items-center justify-center flex-col gap-6 mb-8'>
          <div className='w-[6.25rem] h-[6.25rem]  relative flex  justify-center items-center rounded-full '>
            <label htmlFor='avatar' className='cursor-pointer'>
              {file && editUserAPIStatus !== 'pending' ? (
                <img
                  alt='user'
                  src={imagePreview(file)}
                  className='rounded-full aspect-square object-cover cursor-pointer'
                />
              ) : (
                <div
                  className={`w-[100px] z-30 h-[100px] object-cover flex items-center justify-center rounded-full ${
                    editUserAPIStatus === 'pending' ? 'bg-slate-400' : ''
                  }`}
                >
                  {editUserAPIStatus === 'pending' ? (
                    <LogoAnimation />
                  ) : (
                    avatarOrEmail
                  )}
                </div>
              )}
              <input
                {...register('avatar')}
                onChange={(event) => {
                  const target = event.currentTarget
                  if (target.files) {
                    setFile(target.files[0])
                  }
                }}
                type='file'
                id='avatar'
                name='avatar'
                className='hidden'
                accept='.png, .jpg, .jpeg'
              />
            </label>
          </div>
        </div>
        <div className='gap-4  flex flex-col '>
          <div
            className={`  rounded-xl   ${
              nightMode
                ? 'bg-DMDarkBlueForeground'
                : 'bg-GrayMediumBGColor bg-opacity-20'
            }`}
          >
            {user &&
              userInfo?.map((item, index) => (
                <div
                  key={uuid()}
                  className='h-[44px] px-3   flex justify-start items-center'
                >
                  <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                    <div
                      className={`py-3 flex w-full   justify-between items-center ${
                        index === userInfo.length - 1
                          ? 'border-0'
                          : 'border-DivideLine border-opacity-20 border-b'
                      }`}
                    >
                      <p>{item.title}</p>
                      <div className='flex gap-3 text-gray-400  items-center'>
                        {' '}
                        <input
                          defaultValue={item.value}
                          type='text'
                          {...register(item.InputTag)}
                          name={item.InputTag}
                          className={`bg-transparent outline-none text-right ${
                            nightMode
                              ? 'text-DM2ndLighterTextIcon '
                              : 'text-LM2ndLighterTextIcon'
                          }`}
                        />
                        <EditIcon secondaryColor={true} size={20} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div
            className={`h-[44px]  rounded-xl   ${
              nightMode
                ? 'bg-DMDarkBlueForeground'
                : 'bg-GrayMediumBGColor bg-opacity-20'
            }`}
          >
            <div className='h-[44px] px-3   flex justify-start items-center'>
              <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                <div className={` flex w-full   justify-between items-center `}>
                  <p>Bithrday</p>
                  <div className='flex gap-3  items-center'>
                    {' '}
                    <p
                      className={`${
                        nightMode
                          ? 'text-DM2ndLighterTextIcon '
                          : 'text-LM2ndLighterTextIcon'
                      }`}
                    >
                      July 7, 2002
                    </p>
                    <ChevronDownIcon
                      secondaryColor={true}
                      size={26}
                      className={'mr-[-5px]'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* BIO */}
          <p
            className={` text-xl ${
              nightMode
                ? 'text-DM2ndLighterTextIcon '
                : 'text-LM2ndLighterTextIcon'
            }`}
          >
            Bio:
          </p>
          <div
            className={` overflow-scroll  rounded-xl   ${
              nightMode
                ? 'bg-DMDarkBlueForeground'
                : 'bg-GrayMediumBGColor bg-opacity-20'
            }`}
          >
            <div className='h-[133px] px-3   flex justify-start items-center'>
              <div className='flex justify-between  items-center cursor-pointer w-full gap-1'>
                <div
                  className={`py-2 flex w-full   justify-between items-center `}
                >
                  <div className='flex    text-[13px] items-center'>
                    {' '}
                    <p
                      className={`${
                        nightMode
                          ? 'text-DM2ndLighterTextIcon '
                          : 'text-LM2ndLighterTextIcon'
                      }`}
                    >
                      What is a short bio? Short bios are concise, biographical
                      paragraphs that professionals use to introduce themselves.
                      You can often find short bios on social media profiles,
                      personal websites and company team directories.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Social Network */}
          <p
            className={` text-xl ${
              nightMode
                ? 'text-DM2ndLighterTextIcon '
                : 'text-LM2ndLighterTextIcon'
            }`}
          >
            Social Networks:
          </p>
          <div
            className={`  rounded-xl   ${
              nightMode
                ? 'bg-DMDarkBlueForeground'
                : 'bg-GrayMediumBGColor bg-opacity-20'
            }`}
          >
            {socialNetworks?.map((item, index) => (
              <div
                key={uuid()}
                className='h-[44px] px-3   flex justify-start items-center'
              >
                <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                  <div
                    className={`py-3 flex w-full   justify-between items-center ${
                      index === socialNetworks.length - 1
                        ? 'border-0'
                        : 'border-DivideLine border-opacity-20 border-b'
                    }`}
                  >
                    <p>{item.title}</p>
                    <div className='flex gap-3 text-gray-400  items-center'>
                      {' '}
                      <p
                        className={`${
                          nightMode
                            ? 'text-DM2ndLighterTextIcon '
                            : 'text-LM2ndLighterTextIcon'
                        }`}
                      >
                        {item.selected}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Submit Button */}

          <button
            disabled={editUserAPIStatus === 'pending'}
            type='submit'
            className={`w-full h-[44px] rounded-xl text-white font-semibold' ${
              nightMode ? ' ' : 'bg-opacity-60'
            } ${
              editUserAPIStatus === 'pending'
                ? 'bg-slate-400 cursor-not-allowed'
                : 'bg-BlueDarkColorMain'
            }`}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default PersonalInformation
