/* eslint-disable no-unused-vars */
import { usePerDayEvents } from 'hooks'
import { IoChevronBackOutline } from 'react-icons/io5'
import moment from 'moment'
import {
  AddNewEvent,
  EventDescription,
  InnerDrawer,
  LogoAnimation,
  SvgIcon,
} from 'components'
import { DailyEvents, LongEvents } from './Events'
import { formatData } from 'utils'
import { useSelector } from 'react-redux'
import {
  fetchPerDayEvent,
  setInnerEventDescIsOpen,
  setPerDayEventsIsOpen,
  setSingleEvent,
} from 'features'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ChevronLeftIcon, MoreVertIcon } from 'components/Icons'

const PerDayEvents = ({ isLeftBarOpened, defaultTextIconColor }) => {
  const dispatch = useDispatch()

  const { innerEventDescIsOpen, editingEventIsOpen } = useSelector(
    (state) => state.sideView
  )

  const [selectedElementIndex, setSelectedElementIndex] = useState(null)

  const {
    selectedDayOnCalendar,
    fetchedPerDayEvents,
    fetchedPerDayEventsStatus,
  } = useSelector((state) => state.event)

  const [perDayEvents, setPerDayEvents] = useState([])

  useEffect(() => {
    dispatch(fetchPerDayEvent(selectedDayOnCalendar))

    if (selectedDayOnCalendar) {
      setPerDayEvents([
        {
          day: selectedDayOnCalendar,
          events: fetchedPerDayEvents,
        },
      ])
    }
  }, [dispatch, selectedDayOnCalendar])

  const currentDate = new Date(selectedDayOnCalendar)
  const [nextDayOffset, setNextDayOffset] = useState(0)

  const addEvents = () => {
    const newNextDayOffset = nextDayOffset + 1
    setNextDayOffset(newNextDayOffset)
    const newNextDay = new Date(currentDate)
    newNextDay.setDate(newNextDay.getDate() + newNextDayOffset)

    setPerDayEvents((perDayEvents) => [
      ...perDayEvents,
      {
        day: moment(newNextDay).format('YYYY-MM-DD'),
        events: fetchedPerDayEvents,
      },
    ])
    dispatch(fetchPerDayEvent(moment(newNextDay).format('YYYY-MM-DD')))
  }

  return (
    <div className={`h-screen p-3 ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='pb-10 font-bold text-sm flex justify-between items-center relative'>
        <div
          onClick={() => {
            dispatch(setPerDayEventsIsOpen(false))
            dispatch(setInnerEventDescIsOpen(false))
          }}
        >
          <ChevronLeftIcon
            size={30}
            className={'cursor-pointer'}
            onClick={() => {
              dispatch(setPerDayEventsIsOpen(false))
              dispatch(setInnerEventDescIsOpen(false))
            }}
          />
        </div>
        <p>{moment(selectedDayOnCalendar).format('MMM DD.YYYY')}</p>

        <MoreVertIcon className={'cursor-pointer'} />
      </div>
      <div className='overflow-y-scroll h-full'>
        {/* Per day events list scroll version */}
        {/*  <InfiniteScroll
          dataLength={perDayEvents?.length}
          next={addEvents}
          hasMore={true}
          loader={<h4>Loading...</h4>}
          scrollableTarget='scrollableDiv'
        >
          {fetchedPerDayEventsStatus === 'pending' ? (
            <LogoAnimation />
          ) : (
            <div className=' overflow-y-scroll backdrop-blur-sm pb-20 '>
              {' '}
              {perDayEvents?.map((item) => (
                <>
                  <div className='flex pb-2 '>
                    <p> {moment(item?.day).format('MMM DD')}</p>
                    <span className=' pl-1 text-DM2ndLighterTextIcon'>
                      / Today
                    </span>
                  </div>
                  {item?.events?.map(
                    (event, index) =>
                      event.group.isVisible && (
                        <div
                          key={event._id}
                          onClick={() => {
                            setSelectedElementIndex(index)
                            dispatch(setInnerEventDescIsOpen(true))
                            dispatch(setSingleEvent(event))
                          }}
                          className={`overflow-hidden  mb-1 sm:mb-2 relative justify-end flex text-[0.6rem] sm:text-xs`}
                        >
                          {formatData(event.endTime) ===
                            selectedDayOnCalendar &&
                          formatData(event.startTime) ===
                            selectedDayOnCalendar ? (
                            <DailyEvents event={event} moment={moment} />
                          ) : (
                            <LongEvents
                              event={event}
                              moment={moment}
                              selectedDayOnCalendar={selectedDayOnCalendar}
                            />
                          )}
                        </div>
                      )
                  )}
                </>
              ))}
            </div>
          )}
        </InfiniteScroll> */}
        {/* Per day events list old version */}
        {fetchedPerDayEventsStatus === 'pending' ? (
          <LogoAnimation />
        ) : (
          <>
            {' '}
            <div className='flex pb-2 '>
              <p>{moment(selectedDayOnCalendar).format('MMM DD')}</p>
              <span className=' pl-1 text-DM2ndLighterTextIcon'>/ Today</span>
            </div>
            {fetchedPerDayEvents.length !== 0 ? (
              fetchedPerDayEvents.map(
                (event, index) =>
                  event.group.isVisible && (
                    <div
                      key={event._id}
                      onClick={() => {
                        setSelectedElementIndex(index)
                        dispatch(setInnerEventDescIsOpen(true))
                        dispatch(setSingleEvent(event))
                      }}
                      className={`overflow-hidden  mb-1 sm:mb-2 relative justify-end flex text-[0.6rem] sm:text-xs`}
                    >
                      {formatData(event.endTime) === selectedDayOnCalendar &&
                      formatData(event.startTime) === selectedDayOnCalendar ? (
                        <DailyEvents event={event} moment={moment} />
                      ) : (
                        <LongEvents
                          event={event}
                          moment={moment}
                          selectedDayOnCalendar={selectedDayOnCalendar}
                        />
                      )}
                    </div>
                  )
              )
            ) : (
              <p className='pt-24 text-center text-lg'>No events :(</p>
            )}
          </>
        )}
      </div>
      <InnerDrawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={innerEventDescIsOpen}
        position='left'
        className='bg-opacity-10'
      >
        {editingEventIsOpen ? (
          <AddNewEvent />
        ) : (
          <EventDescription
            singleEvent={fetchedPerDayEvents[selectedElementIndex]}
            defaultTextIconColor={defaultTextIconColor}
          />
        )}
      </InnerDrawer>
    </div>
  )
}

export default PerDayEvents
