/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const LogOutIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M13.2292 13.625C13.0486 13.4167 12.9549 13.1866 12.9479 12.9349C12.941 12.6832 13.0347 12.4618 13.2292 12.2708L14.5625 10.9583H8.52083C8.24618 10.9583 8.02127 10.8656 7.84608 10.68C7.67092 10.4945 7.58333 10.2688 7.58333 10.0029C7.58333 9.7232 7.67092 9.49653 7.84608 9.32292C8.02127 9.14931 8.24618 9.0625 8.52083 9.0625H14.5208L13.1458 7.66667C12.9653 7.49629 12.8785 7.2874 12.8854 7.03998C12.8924 6.79256 12.9945 6.57127 13.192 6.3761C13.3509 6.2087 13.5696 6.12847 13.848 6.13542C14.1264 6.14236 14.3646 6.22917 14.5625 6.39583L17.5 9.35417C17.5682 9.44204 17.6304 9.54626 17.6866 9.66683C17.7427 9.7874 17.7708 9.90765 17.7708 10.0276C17.7708 10.1475 17.7427 10.2663 17.6866 10.384C17.6304 10.5016 17.5682 10.5958 17.5 10.6667L14.5626 13.644C14.382 13.8258 14.1667 13.9097 13.9167 13.8958C13.6667 13.8819 13.4375 13.7917 13.2292 13.625ZM3.77083 17.9792C3.24167 17.9792 2.78993 17.7918 2.41563 17.4172C2.04132 17.0425 1.85417 16.5979 1.85417 16.0833V3.91667C1.85417 3.39635 2.04132 2.94683 2.41563 2.5681C2.78993 2.18937 3.24167 2 3.77083 2H9C9.28056 2 9.51042 2.09558 9.68958 2.28673C9.86875 2.47788 9.95833 2.70577 9.95833 2.9704C9.95833 3.24891 9.86875 3.47625 9.68958 3.65242C9.51042 3.82858 9.28056 3.91667 9 3.91667H3.77083V16.0833H9C9.28056 16.0833 9.51042 16.1714 9.68958 16.3474C9.86875 16.5235 9.95833 16.7513 9.95833 17.0311C9.95833 17.2969 9.86875 17.5214 9.68958 17.7045C9.51042 17.8876 9.28056 17.9792 9 17.9792H3.77083Z' />
    </svg>
  )
}

export default LogOutIcon
