import { GroupIcon } from 'components/Icons'
import { setGroupId } from 'features'
import React from 'react'

import { useDispatch } from 'react-redux'

const SingleGroup = ({ item, setShowGroupList }) => {
  const dispatch = useDispatch()
  return (
    <div
      onClick={() => {
        setShowGroupList(false)
        dispatch(setGroupId(item._id))
      }}
      className={`px-4 rounded-xl py-6  flex justify-between mb-3 cursor-pointer `}
      style={{ backgroundColor: item.color }}
    >
      <div className='flex '>
        <GroupIcon />
        <p className='text-gray-50 pl-2'>{item.name}</p>
      </div>
    </div>
  )
}

export default SingleGroup
