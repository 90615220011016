import { Toggle } from 'components'
import {
  fetchPerGroupEvents,
  setGroupDescriptionIsOpen,
  setSelectedGroup,
} from 'features'
import { motion, AnimatePresence } from 'framer-motion'
import { useGroups } from 'hooks'
import React, { useEffect, useState } from 'react'
import { IoPeople } from 'react-icons/io5'
import { toast } from 'react-toastify'
import { getColorWithOpacity } from 'utils'

const SearchGroup = ({ item }) => {
  const { groupVisibility, dispatch } = useGroups()
  const [showGroup, setShowGroup] = useState(false)

  useEffect(() => {
    setShowGroup(item.isVisible)
  }, [item.isVisible])

  const openGroupDescriptionHandler = (group) => {
    dispatch(setGroupDescriptionIsOpen(true))
    dispatch(fetchPerGroupEvents(group._id))
    dispatch(setSelectedGroup(group))
  }

  return (
    <div className='flex overflow-hidden cursor-pointer'>
      <div
        onClick={openGroupDescriptionHandler.bind(null, item)}
        className={`relative rounded-xl h-[3.75rem] overflow-hidden bg-gray-500 bg-opacity-70 hover:bg-opacity-90 items-center w-full !px-0  py-6  flex justify-between mb-3 `}
      >
        <div
          style={{
            backgroundColor: item.color,
          }}
          className='flex absolute left-0 justify-center items-center rounded-xl  !w-[3.75rem] h-[3.75rem]'
        >
          <IoPeople color='#fff' size={25} />
        </div>
        <AnimatePresence>
          <motion.div
            className='rounded-xl flex justify-between items-center h-[3.75rem]'
            initial={{
              width: '0%',
            }}
            animate={{
              width: showGroup ? '100%' : '0%',

              backgroundColor: showGroup
                ? getColorWithOpacity(item.color, 0.7)
                : 'rgba(107, 114, 128, 0.7)',
            }}
            transition={{
              duration: 0.3,
            }}
          >
            <p className='absolute left-[4.1875rem] text-black text-sm'>
              {item?.name}
            </p>
          </motion.div>
        </AnimatePresence>
      </div>
      <div className='absolute right-0 pt-[1.1rem] mr-6'>
        <Toggle
          enabled={showGroup}
          showGroup={setShowGroup}
          setShowGroup={setShowGroup}
          item={item}
          groupVisibility={groupVisibility}
          dispatch={dispatch}
          toast={toast}
          color={item.color}
        />
      </div>
    </div>
  )
}

export default SearchGroup
