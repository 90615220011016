/* eslint-disable no-unused-vars */
import { InnerDrawer, LogoAnimation, ReactSVGIcon, SvgIcon } from 'components'
import {
  AddCircleIcon,
  AddIcon,
  ChevronLeftIcon,
  SettingsIcon,
} from 'components/Icons'
import {
  fetchAllUsers,
  setColor,
  setGroupAddMemberIsOpen,
  setGroupDescriptionIsOpen,
  setGroupSettingsIsOpen,
  setGroupTitle,
} from 'features'

import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hexToRgba, userId } from 'utils'

const GroupDescription = ({ defaultTextIconColor }) => {
  const { fetchedPerGroupEvents, fetchedPerGroupEventsStatus } = useSelector(
    (state) => state.event
  )
  const { selectedGroup } = useSelector((state) => state.group)

  let myColorForSomeonesGroup = selectedGroup?.invitedUsers
    ?.filter((member) => member?.user === userId())
    .map((member) => member?.color)[0]

  const { groupSettingsIsOpen } = useSelector((state) => state.sideView)

  const { nightMode } = useSelector((state) => state.theme)

  const dispatch = useDispatch()

  const bgColor = hexToRgba(
    !myColorForSomeonesGroup ? selectedGroup?.color : myColorForSomeonesGroup,
    '0.3'
  )

  const groupDescBackArrowHandler = () => {
    dispatch(setGroupDescriptionIsOpen(false))
  }

  const groupMembers = selectedGroup?.invitedUsers?.filter(
    (member) => member.status === 'accepted'
  )

  const groupSettingsHandler = () => {
    dispatch(setGroupSettingsIsOpen(!groupSettingsIsOpen))
    dispatch(setGroupTitle(selectedGroup?.name))
    dispatch(setColor(selectedGroup?.color))
  }

  useEffect(() => {
    dispatch(setGroupTitle(selectedGroup?.name))
    dispatch(setColor(selectedGroup?.color))
  }, [dispatch, selectedGroup])

  return (
    <div
      style={{
        backgroundColor: bgColor,
      }}
      className={`h-screen w-full backdrop-blur-md p-3 text-xs  ${defaultTextIconColor}`}
    >
      {/* Header */}
      <div className='pb-6 font-bold text-sm flex justify-between items-center relative'>
        <ChevronLeftIcon
          size={30}
          className={'cursor-pointer'}
          onClick={groupDescBackArrowHandler}
        />

        <p>{fetchedPerGroupEvents?.group?.name}</p>

        <SettingsIcon
          onClick={groupSettingsHandler}
          className={'cursor-pointer'}
          size={20}
        />
      </div>
      {/* Content */}
      {fetchedPerGroupEventsStatus === 'success' ? (
        <div className=' flex  flex-col'>
          {/* events */}
          <div className='py-4 '>
            <div className='flex justify-between pb-4'>
              <p>Upcoming Events</p>
              <p
                style={{
                  color: !myColorForSomeonesGroup
                    ? selectedGroup?.color
                    : myColorForSomeonesGroup,
                }}
                className={` cursor-pointer`}
              >
                View All
              </p>
            </div>
            <div className='grid grid-cols-2 gap-3 max-h-[15rem] overflow-scroll  '>
              {fetchedPerGroupEvents?.events?.map((event) => (
                <div
                  key={event._id}
                  className={` w-full h-[4.5rem] cursor-pointer relative group `}
                >
                  {/* Event duration line */}
                  <span className='w-full grid grid-cols-6  absolute rounded-tl-xl rounded-tr-xl overflow-hidden '>
                    <div
                      style={{
                        opacity: 100,
                        backgroundColor: selectedGroup?.color,
                      }}
                      className='w-full h-2'
                    ></div>
                  </span>
                  {/* Background line */}
                  <span
                    style={{
                      backgroundColor: selectedGroup?.color,
                    }}
                    className='w-full h-2 top-0 absolute opacity-40  rounded-tl-xl rounded-tr-xl   '
                  ></span>
                  {/* Event background color */}
                  <div
                    style={{
                      backgroundColor: selectedGroup?.color,
                    }}
                    className='w-full h-[4.5rem] opacity-50 absolute rounded-xl group-hover:opacity-70'
                  ></div>
                  {/* Content */}
                  <div
                    className={`flex relative z-50 items-center  w-full h-full overflow-hidden`}
                  >
                    <ReactSVGIcon name={event?.iconId?.name} />

                    <div
                      className={` flex flex-col  justify-center px-1 my-3 ${
                        nightMode ? 'text-white ' : 'text-black'
                      }  `}
                    >
                      <p className=' text-left font-light '>{event.title}</p>
                      <p className=' text-left font-light '>
                        {event.description}
                      </p>
                      <div className='flex font-bold sm:font-semibold gap-1 truncate'>
                        <p>{moment(event.startTime).format(' HH:mm A')} </p> -
                        <p> {moment(event.endTime).format(' HH:mm A')} </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className='py-4 h-[20rem]'>
            <p className='pb-4'>Group Members</p>
            <div className='grid grid-cols-3 gap-3'>
              {groupMembers?.map((member) => (
                <div
                  key={member._id}
                  className='h-28 w-28 rounded-xl flex flex-col justify-evenly items-center bg-gray-500 bg-opacity-60'
                >
                  <div className='w-10 h-10  relative flex  justify-center  items-center rounded-full '>
                    <img
                      alt='Profile'
                      src='http://source.unsplash.com/100x100/?girl'
                      className='rounded-full'
                    />
                  </div>
                  <p className='truncate w-16'>{member.user}</p>
                  {/* <div className='flex gap-2 '>
                      {item.colorCodes.map((color) => (
                        <span
                          key={color}
                          style={{ backgroundColor: color }}
                          className='h-3 w-3 rounded-full'
                        ></span>
                      ))}
                    </div> */}
                </div>
              ))}
            </div>
            {/* Add Member Button */}
            <div
              onClick={() => {
                dispatch(setGroupAddMemberIsOpen(true))
                dispatch(fetchAllUsers())
              }}
              style={{
                backgroundColor: !myColorForSomeonesGroup
                  ? selectedGroup?.color
                  : myColorForSomeonesGroup,
              }}
              className='h-28 w-28 my-3 rounded-xl items-center flex flex-col justify-center cursor-pointer'
            >
              <AddCircleIcon size={30} />

              <p className='pt-2'>Add Member</p>
            </div>
          </div>
        </div>
      ) : (
        <LogoAnimation />
      )}
    </div>
  )
}

export default GroupDescription
