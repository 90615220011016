import {
  Calendar,
  Profile,
  SignUp,
  GetStarted,
  SignIn,
  ChooseNumber,
  MobileVerify,
  AddEmail,
  CreateProfile,
} from 'pages'
import { Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import { Helmet } from 'react-helmet'
import { ProtectedRoute, UnumButton } from 'components'
import Bard from 'pages/Bard/Bard'
import { EmailVerify } from 'pages/Auth'

function App() {
  localStorage.setItem('theme', 'light')

  return (
    <>
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
        <title>Unum</title>
      </Helmet>

      <Routes>
        <Route path='/' element={<GetStarted />} />
        <Route path='bard' element={<Bard />} />
        <Route path='/sign-in' element={<SignIn />} />
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/sign-mobile' element={<ChooseNumber />} />
        <Route path='/mobile-verify' element={<MobileVerify />} />
        <Route path='/add-email' element={<AddEmail />} />
        <Route path='/email-verify' element={<EmailVerify />} />
        <Route path='/create-profile' element={<CreateProfile />} />
        <Route element={<ProtectedRoute />}>
          <Route path='/calendar' element={<Calendar />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='/profile' element={<Profile />} />
        </Route>
      </Routes>
      <UnumButton />
      <ToastContainer autoClose={1500} />
    </>
  )
}

export default App
