import React from 'react'
import { useDispatch } from 'react-redux'
import { setIconId } from 'features/event/eventSlice'
import { ReactSVGIcon } from 'components'
import { useSelector } from 'react-redux'
import { ChevronLeftIcon } from 'components/Icons'

const IconList = ({ setShowIconList, icons }) => {
  const dispatch = useDispatch()
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )

  const entertainmentIcons = icons.filter(
    (icon) => icon.type === 'entertainment'
  )

  return (
    <div className={`${defaultTextIconColor} p-3`}>
      {/* Header */}
      <div className=' pb-6 font-bold text-sm flex justify-between items-center relative'>
        <ChevronLeftIcon
          onClick={() => {
            setShowIconList(false)
          }}
          size={30}
          className={'cursor-pointer'}
        />
        <p className={`text-sm  `}>Icons</p>

        <ChevronLeftIcon size={30} className={'cursor-pointer invisible'} />
      </div>
      {/* Icon List */}

      <h2
        className={`py-4 px-2 ${
          nightMode ? 'text-DM2ndLighterTextIcon' : 'text-LM2ndLighterTextIcon'
        }`}
      >
        Entertainment
      </h2>
      <div
        className={`w-full grid grid-cols-7 py-2 pl-2  rounded-xl items-center justify-center  ${
          nightMode ? 'bg-DMDarkBlueForeground' : 'bg-blue-100'
        } `}
      >
        {entertainmentIcons.map((item) => (
          <div
            onClick={() => {
              setShowIconList(false)
              dispatch(setIconId(item._id))
            }}
            key={item._id}
          >
            <ReactSVGIcon name={item.name} secondaryColor={true} size={24} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default IconList
