import {
  setAppearanceIsOpen,
  setConnectionsIsOpen,
  setGroupDescriptionIsOpen,
  setGroupsIsOpen,
  setHomeScreenIsOpen,
  setInnerEventDescIsOpen,
  setIsContactsOpen,
  setIsLeftBarOpened,
  setPerDayEventsIsOpen,
  setPersonalInformationIsOpen,
  setSearchIsOpen,
  setSingeEventDescIsOpen,
  setUserProfileIsOpen,
} from 'features'
import { useDispatch } from 'react-redux'

import moment from 'moment'
import { currentDate } from 'utils'
import { useSelector } from 'react-redux'

const useLeftBar = (isLeftBarOpened) => {
  const dispatch = useDispatch()
  const {
    groupsIsOpen,
    isContactsOpen,
    connectionsIsOpen,
    searchIsOpen,
    userProfileIsOpen,
  } = useSelector((state) => state.sideView)
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )

  const openGroupsHandler = () => {
    dispatch(setGroupsIsOpen(!groupsIsOpen))

    /* Close other drawers */
    dispatch(setPerDayEventsIsOpen(false))
    dispatch(setInnerEventDescIsOpen(false))
    dispatch(setSingeEventDescIsOpen(false))
    dispatch(setIsContactsOpen(false))
    dispatch(setConnectionsIsOpen(false))
    dispatch(setGroupDescriptionIsOpen(false))
    dispatch(setHomeScreenIsOpen(false))
    dispatch(setSearchIsOpen(false))
    dispatch(setUserProfileIsOpen(false))
  }

  const openContactsHandler = () => {
    dispatch(setIsContactsOpen(!isContactsOpen))
    /* Close other drawers */
    dispatch(setPerDayEventsIsOpen(false))
    dispatch(setInnerEventDescIsOpen(false))
    dispatch(setSingeEventDescIsOpen(false))
    dispatch(setGroupsIsOpen(false))
    dispatch(setConnectionsIsOpen(false))
    dispatch(setHomeScreenIsOpen(false))
    dispatch(setGroupDescriptionIsOpen(false))
    dispatch(setSearchIsOpen(false))
    dispatch(setUserProfileIsOpen(false))
  }
  const openConnectionsHandler = () => {
    dispatch(setConnectionsIsOpen(!connectionsIsOpen))
    /* Close other drawers */
    dispatch(setIsContactsOpen(false))
    dispatch(setPerDayEventsIsOpen(false))
    dispatch(setInnerEventDescIsOpen(false))
    dispatch(setSingeEventDescIsOpen(false))
    dispatch(setGroupsIsOpen(false))
    dispatch(setGroupDescriptionIsOpen(false))
    dispatch(setHomeScreenIsOpen(false))
    dispatch(setSearchIsOpen(false))
    dispatch(setUserProfileIsOpen(false))
  }
  const openSearchHandler = () => {
    dispatch(setSearchIsOpen(!searchIsOpen))
    /* Close other drawers */
    dispatch(setIsContactsOpen(false))
    dispatch(setPerDayEventsIsOpen(false))
    dispatch(setInnerEventDescIsOpen(false))
    dispatch(setSingeEventDescIsOpen(false))
    dispatch(setGroupsIsOpen(false))
    dispatch(setGroupDescriptionIsOpen(false))
    dispatch(setHomeScreenIsOpen(false))
    dispatch(setConnectionsIsOpen(false))
    dispatch(setUserProfileIsOpen(false))
  }
  const openUserProfileHandler = () => {
    dispatch(setUserProfileIsOpen(!userProfileIsOpen))
    /* Close other drawers */
    dispatch(setIsContactsOpen(false))
    dispatch(setPerDayEventsIsOpen(false))
    dispatch(setInnerEventDescIsOpen(false))
    dispatch(setSingeEventDescIsOpen(false))
    dispatch(setGroupsIsOpen(false))
    dispatch(setGroupDescriptionIsOpen(false))
    dispatch(setHomeScreenIsOpen(false))
    dispatch(setConnectionsIsOpen(false))
    dispatch(setSearchIsOpen(false))
    dispatch(setPersonalInformationIsOpen(false))
    dispatch(setAppearanceIsOpen(false))
  }

  const openLeftBarHandler = () => {
    dispatch(setIsLeftBarOpened(!isLeftBarOpened))
  }

  return {
    openGroupsHandler,
    dispatch,
    openLeftBarHandler,
    nightMode,
    currentDate,
    moment,
    isContactsOpen,
    openContactsHandler,
    openConnectionsHandler,
    defaultTextIconColor,
    openSearchHandler,
    openUserProfileHandler,
  }
}

export default useLeftBar
