import { ReactSVGIcon } from 'components'
import { useDarkSide } from 'hooks'
import useDailyEvent from 'hooks/useDailyEvent'
import moment from 'moment'
import React from 'react'

const DailyEvents = ({ event }) => {
  const { startPosition, endPosition } = useDailyEvent(event)
  const [colorTheme] = useDarkSide()

  return (
    <div className={` w-full h-[4.5rem] cursor-pointer group`}>
      {/* Event duration line */}
      <span className='w-full grid grid-cols-6  absolute rounded-tl-xl rounded-tr-xl overflow-hidden '>
        <div
          style={{
            opacity: 100,
            backgroundColor: event.group.color,
            gridColumnStart: startPosition,
            gridColumnEnd: endPosition,
          }}
          className='w-full h-2'
        ></div>
      </span>
      {/* Background line */}
      <span
        style={{
          backgroundColor: event.group.color,
        }}
        className='w-full h-2 top-0 absolute opacity-40  rounded-tl-xl rounded-tr-xl   '
      ></span>
      {/* Event background color */}
      <div
        style={{ backgroundColor: event.group.color }}
        className='w-full h-[4.5rem] opacity-50 absolute rounded-xl group-hover:opacity-70'
      ></div>
      {/* Content */}
      <div
        className={`flex relative z-50 items-center  w-full h-full overflow-hidden`}
      >
        <ReactSVGIcon name={event?.icon?.name} />

        <div
          className={` flex flex-col  justify-center px-1  ${
            colorTheme === 'light' ? 'text-black' : 'text-white'
          }  `}
        >
          <p className=' text-left font-light '>{event.title}</p>
          <p className=' text-left font-light '>{event.description}</p>
          <div className='flex font-bold sm:font-semibold gap-1 truncate'>
            <p>{moment(event.startTime).format(' HH:mm A')} </p> -
            <p> {moment(event.endTime).format(' HH:mm A')} </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DailyEvents
