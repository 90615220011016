import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { userId } from 'utils'

const useUser = () => {
  const [user, setUser] = useState(null)

  const { editUserAPIStatus } = useSelector((state) => state.user)

  useEffect(() => {
    const currentUserId = userId()

    const fetchUserInfo = async () => {
      const results = await axios(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user/${currentUserId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )

      setUser(results.data.user)
    }

    if (currentUserId !== null || currentUserId !== undefined) {
      fetchUserInfo()
    }
  }, [editUserAPIStatus])
  return { user }
}

export default useUser
