/* eslint-disable no-unused-vars */
import {
  AddNewGroup,
  CalendarEvents,
  Drawer,
  EventDescription,
  Groups,
  LogoAnimation,
  PerDayEvents,
} from 'components'
import { setGoToCurrentWeek, setGroupsIsOpen } from 'features'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Mousewheel, Virtual } from 'swiper/modules'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import {
  betweenDates,
  dynamicStyles,
  eventEndPlacement,
  eventStartPlacement,
  generateMonthData,
  generateWeekData,
  getEventDatesInRange,
  showEventsByDate,
} from 'utils'

import Week from './Week'

const WeekViewNew = ({
  sorted,
  generatedWeeks,
  fetchedEventStatus,
  handleHorizontalScroll,
  parentRef,
  handleAddYearAtEnd,
  handleAddYearAtBeginning,
  currentDayRef,
  openEventHandler,
  defaultTextIconColor,
  isLeftBarOpened,
  nightMode,
}) => {
  const dispatch = useDispatch()
  const swiperRef = useRef(null)
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
  const [currentYearForScrollBack, setCurrentYearForScrollBack] = useState(
    new Date().getFullYear()
  )
  const [currentMonthForScrollBack, setCurrentMonthForScrollBack] = useState(
    new Date().getMonth()
  )

  const [currentYearForScrollForward, setCurrentYearForScrollForward] =
    useState([])

  const scrollToSlide = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index)
    }
  }

  const [weekData, setWeekData] = useState(
    generateWeekData(currentYear, currentMonth)
  )

  /* Remove duplicate weeks */
  const [filteredWeeks, setFilteredWeeks] = useState()

  useEffect(() => {
    // Use a Set to keep track of seen "fullDate" values
    let seenFullDates = new Set()

    // Use the filter method to remove arrays with duplicate "fullDate" values
    let newArray = weekData.filter((innerArray) => {
      for (let obj of innerArray) {
        let fullDate = obj.fullDate
        if (seenFullDates.has(fullDate)) {
          return false // If duplicate is found, exclude the array
        }
        seenFullDates.add(fullDate)
      }
      return true // Include the array if no duplicates are found
    })

    setFilteredWeeks(newArray)
    // Clear the Set for future use if needed
    seenFullDates.clear()
  }, [weekData])

  const handleAddMonthAtTheEnd = () => {
    let nextYear = currentYear
    let nextMonth = currentMonth

    nextMonth++
    if (nextMonth > 11) {
      nextYear++
      nextMonth = 0
    }
    setWeekData((prevMonthData) => [
      ...prevMonthData,
      ...generateWeekData(nextYear, nextMonth),
    ])

    setCurrentYear(nextYear)
    setCurrentMonth(nextMonth)
  }

  const handleAddMonthAtTheBeginning = () => {
    let prevYear = currentYearForScrollBack
    let prevMonth = currentMonthForScrollBack

    prevMonth--
    if (prevMonth < 0) {
      prevYear--
      prevMonth = 11
    }
    setWeekData((prevMonthData) => [
      ...generateWeekData(prevYear, prevMonth),
      ...prevMonthData,
    ])

    setCurrentYearForScrollForward([...generateWeekData(prevYear, prevMonth)])

    setCurrentYearForScrollBack(prevYear)
    setCurrentMonthForScrollBack(prevMonth)
  }

  /* Detect OS */
  function detectOS() {
    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows'
    } else if (/Android/.test(userAgent)) {
      os = 'Android'
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux'
    }

    return os
  }

  const clientOS = detectOS()
  useLayoutEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(
        clientOS === 'Mac OS'
          ? currentYearForScrollForward.length === 5
            ? 5
            : 4
          : currentYearForScrollForward.length === 6
          ? 5
          : 4,
        0,
        false
      )
    }
  }, [
    clientOS,
    currentYearForScrollBack,
    currentMonthForScrollBack,
    currentYearForScrollForward,
  ])

  /* New logic for swipe */
  const goToSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(1)
      //swiper.slideNext()
    }
  }

  /* Get current week index */

  // Current date in the format 'YYYY-MM-DD'
  const currentDate = new Date().toISOString().split('T')[0]

  // Function to find the index of the parent array that includes the current date
  function findParentArrayIndex(data) {
    for (let i = 0; i < data?.length; i++) {
      const parentArray = data[i]
      const includesCurrentDate = parentArray.some(
        (obj) => obj?.fullDate === currentDate
      )

      if (includesCurrentDate) {
        return i
      }
    }

    // Return -1 if the current date is not found in any parent array
    return -1
  }

  // Call the function and get the index
  const currentWeekIndexNumber = findParentArrayIndex(filteredWeeks)

  /* Slide to current week on firs load */
  useEffect(() => {
    swiperRef.current.swiper.slideTo(currentWeekIndexNumber, 0, false)
  }, [currentWeekIndexNumber])

  // Slide to current week with arrow buttons
  const { goToCurrentWeek } = useSelector((state) => state.sideView)
  useEffect(() => {
    if (goToCurrentWeek) {
      swiperRef.current.swiper.slideTo(currentWeekIndexNumber)
    }
    dispatch(setGoToCurrentWeek(false))
  }, [currentWeekIndexNumber, dispatch, goToCurrentWeek])

  /* Get active slide index */
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const handleSlideChange = () => {
    // Update the active slide index on slide change
    if (swiperRef.current) {
      setActiveSlideIndex(swiperRef.current.swiper.activeIndex)
    }
  }

  return (
    <div className='h-screen w-full  animate-fadeIn '>
      {/*  <button
        className='bg-red-400 h-8 absolute z-[9999999999999999999]'
        onClick={goToSlide}
      >
        Back To Current month
      </button> */}
      <Swiper
        ref={swiperRef}
        spaceBetween={0}
        speed={700}
        touchRatio={0.01}
        centeredSlides={true}
        mousewheel={true}
        onReachEnd={() => handleAddMonthAtTheEnd()}
        onReachBeginning={() => handleAddMonthAtTheBeginning()}
        modules={[Mousewheel]}
        slidesPerView={1}
        onSlideChange={handleSlideChange}
        loading='lazy'
        className='h-full outer flex mx-4 overflow-y-hidden'
      >
        {filteredWeeks?.map((week) => {
          return (
            <SwiperSlide key={uuid()}>
              <div
                className={`grid  text-center w-[98.3%] mt-14 overflow-y-hidden rounded-xl mx-auto h-[92.3vh] divide-x divide-DivideLine grid-cols-7 pb-4 ${
                  nightMode ? 'divide-opacity-20' : ''
                } ${
                  nightMode
                    ? 'bg-DMDarkBlueForeground bg-opacity-50'
                    : 'bg-[#F2F6FB]'
                }`}
              >
                {week?.map((dayOfWeek) => (
                  <div key={uuid()}>
                    <Week
                      defaultTextIconColor={defaultTextIconColor}
                      nightMode={nightMode}
                      id={dayOfWeek?.fullDate}
                      dayOfWeek={dayOfWeek}
                      week={week}
                    />
                  </div>
                ))}
              </div>
              {/* Events */}
              <div className='h-full pr-12 pt-[4.2rem] ml-[-9px] '>
                <div
                  className={`relative overflow-hidden  w-[100%]  grid grid-cols-7 left-7 grid-flow-dense auto-rows-max gap-1 -top-[96vh] h-full  `}
                >
                  {sorted?.map((event) => {
                    const dates = betweenDates(event)
                    const eventsDatesRange = getEventDatesInRange(event)

                    for (let i = 0; i < week.length; i++) {
                      if (showEventsByDate(i, week, eventsDatesRange)) {
                        return (
                          <React.Fragment key={event._id}>
                            {event.group.isVisible && (
                              <>
                                <div
                                  style={{
                                    ...dynamicStyles(
                                      event,
                                      eventStartPlacement,
                                      eventEndPlacement,
                                      week
                                    ),
                                  }}
                                >
                                  <CalendarEvents
                                    openEventHandler={openEventHandler.bind(
                                      null,
                                      event
                                    )}
                                    event={event}
                                    week={week}
                                    index={i}
                                    dates={dates}
                                    isLeftBarOpened={isLeftBarOpened}
                                  />
                                </div>
                              </>
                            )}
                          </React.Fragment>
                        )
                      }
                    }
                    return ''
                  })}
                  {fetchedEventStatus === 'pending' && <LogoAnimation />}
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default WeekViewNew
