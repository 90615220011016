import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  innerEventDescIsOpen: false,
  rightSideViewIsOpen: false,
  isLeftBarOpened: false,
  singeEventDescIsOpen: false,
  singeEventDesc2IsOpen: false,
  perDayEventsIsOpen: false,
  groupsIsOpen: false,
  addNewGroupIsOpen: false,
  groupDescriptionIsOpen: false,
  groupAddMemberIsOpen: false,
  isContactsOpen: false,
  connectionsIsOpen: false,
  groupSettingsIsOpen: false,
  homeScreenIsOpen: false,
  editingEventIsOpen: false,
  searchIsOpen: false,
  reminderIsOpen: false,
  eventRepeatIsOpen: false,
  eventLocationIsOpen: false,
  participantsIsOpen: false,
  userProfileIsOpen: false,
  contactProfileIsOpen: false,
  appearanceIsOpen: false,
  personalInformationIsOpen: false,
  subscriptionIsOpen: false,
  privacyAndSecurityIsOpen: false,
  notificationIsOpen: false,
  preferenceIsOpen: false,
  currentTimeIndicator: false,
  goToCurrentWeek: false,
}

export const sideViewSlice = createSlice({
  name: 'sideView',
  initialState,
  reducers: {
    setRightSideViewIsOpen: (state, action) => {
      state.rightSideViewIsOpen = action.payload
    },
    setInnerEventDescIsOpen: (state, action) => {
      state.innerEventDescIsOpen = action.payload
    },
    setIsLeftBarOpened: (state, action) => {
      state.isLeftBarOpened = action.payload
    },
    setSingeEventDescIsOpen: (state, action) => {
      state.singeEventDescIsOpen = action.payload
    },
    setSingeEventDesc2IsOpen: (state, action) => {
      state.singeEventDesc2IsOpen = action.payload
    },
    setPerDayEventsIsOpen: (state, action) => {
      state.perDayEventsIsOpen = action.payload
    },
    setGroupsIsOpen: (state, action) => {
      state.groupsIsOpen = action.payload
    },
    setAddNewGroupIsOpen: (state, action) => {
      state.addNewGroupIsOpen = action.payload
    },
    setGroupDescriptionIsOpen: (state, action) => {
      state.groupDescriptionIsOpen = action.payload
    },
    setIsContactsOpen: (state, action) => {
      state.isContactsOpen = action.payload
    },
    setContactProfileIsOpen: (state, action) => {
      state.contactProfileIsOpen = action.payload
    },
    setConnectionsIsOpen: (state, action) => {
      state.connectionsIsOpen = action.payload
    },
    setGroupSettingsIsOpen: (state, action) => {
      state.groupSettingsIsOpen = action.payload
    },
    setGroupAddMemberIsOpen: (state, action) => {
      state.groupAddMemberIsOpen = action.payload
    },
    setHomeScreenIsOpen: (state, action) => {
      state.homeScreenIsOpen = action.payload
    },
    setEditingEventIsOpen: (state, action) => {
      state.editingEventIsOpen = action.payload
    },
    setSearchIsOpen: (state, action) => {
      state.searchIsOpen = action.payload
    },
    setReminderIsOpen: (state, action) => {
      state.reminderIsOpen = action.payload
    },
    setEventRepeatIsOpen: (state, action) => {
      state.eventRepeatIsOpen = action.payload
    },
    setEventLocationIsOpen: (state, action) => {
      state.eventLocationIsOpen = action.payload
    },
    setParticipantsIsOpen: (state, action) => {
      state.participantsIsOpen = action.payload
    },
    setUserProfileIsOpen: (state, action) => {
      state.userProfileIsOpen = action.payload
    },
    setAppearanceIsOpen: (state, action) => {
      state.appearanceIsOpen = action.payload
    },
    setPersonalInformationIsOpen: (state, action) => {
      state.personalInformationIsOpen = action.payload
    },
    setSubscriptionIsOpen: (state, action) => {
      state.subscriptionIsOpen = action.payload
    },
    setPrivacyAndSecurityIsOpen: (state, action) => {
      state.privacyAndSecurityIsOpen = action.payload
    },
    setNotificationIsOpen: (state, action) => {
      state.notificationIsOpen = action.payload
    },
    setPreferenceIsOpen: (state, action) => {
      state.preferenceIsOpen = action.payload
    },
    setCurrentTimeIndicator: (state, action) => {
      state.currentTimeIndicator = action.payload
    },
    setGoToCurrentWeek: (state, action) => {
      state.goToCurrentWeek = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setRightSideViewIsOpen,
  setInnerEventDescIsOpen,
  setIsLeftBarOpened,
  setSingeEventDescIsOpen,
  setSingeEventDesc2IsOpen,
  setPerDayEventsIsOpen,
  setGroupsIsOpen,
  setAddNewGroupIsOpen,
  setGroupDescriptionIsOpen,
  setIsContactsOpen,
  setContactProfileIsOpen,
  setConnectionsIsOpen,
  setGroupSettingsIsOpen,
  setHomeScreenIsOpen,
  setEditingEventIsOpen,
  setSearchIsOpen,
  setReminderIsOpen,
  setParticipantsIsOpen,
  setEventRepeatIsOpen,
  setEventLocationIsOpen,
  setGroupAddMemberIsOpen,
  setUserProfileIsOpen,
  setAppearanceIsOpen,
  setSubscriptionIsOpen,
  setPrivacyAndSecurityIsOpen,
  setNotificationIsOpen,
  setPreferenceIsOpen,
  setCurrentTimeIndicator,
  setGoToCurrentWeek,
  setPersonalInformationIsOpen,
} = sideViewSlice.actions

export default sideViewSlice.reducer
