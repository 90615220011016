import { ReactSVGIcon } from 'components'
import { useDarkSide } from 'hooks'
import useDailyEvent from 'hooks/useDailyEvent'

const TwoDayEventsM = ({ event }) => {
  const { startPosition, endPosition } = useDailyEvent(event)
  const [colorTheme] = useDarkSide()

  return (
    <div className={` w-full h-full group`}>
      {/* Event duration line */}
      <div className='flex w-full absolute'>
        <div className='w-full grid grid-cols-6   rounded-tl-md  overflow-hidden '>
          <div
            style={{
              opacity: 100,
              backgroundColor: event.group.color,
              gridColumnStart: startPosition,
              gridColumnEnd: 13,
            }}
            className='w-full  h-1.5'
          ></div>
        </div>
        <div className='w-full grid grid-cols-6   rounded-tr-md overflow-hidden '>
          <div
            style={{
              opacity: 100,
              backgroundColor: event.group.color,
              gridColumnStart: 1,
              gridColumnEnd: endPosition,
            }}
            className='w-full  h-1.5'
          ></div>
        </div>
      </div>
      {/* Background line */}
      <span
        style={{
          backgroundColor: event.group.color,
        }}
        className='w-full h-1.5 top-0 absolute opacity-40  rounded-tl-md rounded-tr-md   '
      ></span>
      {/* Event background color */}
      <div
        style={{ backgroundColor: event.group.color }}
        className='w-full h-full opacity-50 absolute rounded-md group-hover:opacity-70'
      ></div>
      {/* Content */}
      <div
        className={`flex relative z-50 items-center  w-full h-full overflow-hidden`}
      >
        <ReactSVGIcon name={event?.icon?.name} />

        <p
          className={`text-center sm:px-1 sm:pt-1 pt-2 truncate ${
            colorTheme === 'light' ? 'text-black' : 'text-white'
          } `}
        >
          {event.title}
        </p>
      </div>
    </div>
  )
}

export default TwoDayEventsM
