/* eslint-disable no-unused-vars */
import { Drawer } from 'components'
import { useDispatch } from 'react-redux'
import {
  contactAndFriendSearchRequest,
  contactSearchRequest,
  fetchGoogleContacts,
  setContactProfileIsOpen,
  setIsContactsOpen,
} from 'features'
import { useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { AddContact, InviteButton, PersonalCard, Search } from './components'
import { ContactProfile } from './components/ContactProfile'
import { useUser } from 'hooks'
import { useGoogleContacts } from './hooks'
import uuid from 'react-uuid'
import { useDebounce } from 'use-debounce'
import { AddIcon, ChevronLeftIcon } from 'components/Icons'

const Contacts = () => {
  const dispatch = useDispatch()
  const { authGoogle } = useGoogleContacts()

  const { fetchedUserInfo } = useSelector((state) => state.user)

  const [addContactView, setAddContactView] = useState(false)

  const addContactViewHandler = () => {
    setAddContactView(true)
  }
  const contactViewHandler = () => {
    setAddContactView(false)
  }

  const { defaultTextIconColor } = useSelector((state) => state.theme)

  const { isLeftBarOpened, contactProfileIsOpen } = useSelector(
    (state) => state.sideView
  )

  const { user } = useUser()

  /* Contact Scroll */

  //const [hasMore, setHasMore] = useState(true)
  const { contacts } = useSelector((state) => state.user)

  useEffect(() => {
    const data = { page: 1, limit: 10 }
    /* !!!!!!!!!! It makes API call loop in redux */

    dispatch(fetchGoogleContacts(data))
  }, [dispatch, user])

  const [page, setPage] = useState(1)

  const loadFunc = () => {
    /*    const response = await getQuoteHandler(page, 5)
    setQuotes([...quotes, ...response.data])

    if (response.data.length === 0 || response.data.length < 5) {
      setHasMore(false)
    }

    setPage(page + 1) */

    setPage(page + 1)

    const data = { page, limit: 10 }
    /* !!!!!!!!!! It makes API call loop in redux */
    dispatch(fetchGoogleContacts(data))
  }

  const specificDivRef = useRef(null)

  const handleScroll = () => {
    // Check if the user has scrolled down in the specific div
    const div = specificDivRef.current
    if (div.scrollTop + div.clientHeight >= div.scrollHeight) {
      // The user has scrolled to the bottom of the specific div
      // Add your logic here
      console.log('Scrolled to the bottom of the specific div')
      setPage(page + 1)

      const data = { page, limit: 10 }
      /* !!!!!!!!!! It makes API call loop in redux */
      dispatch(fetchGoogleContacts(data))
    }
  }
  useEffect(() => {
    const div = specificDivRef.current
    div.addEventListener('scroll', handleScroll)

    // Clean up the event listener when the component is unmounted
    return () => {
      div.removeEventListener('scroll', handleScroll)
    }
  }, [page])

  /* Contact Scroll END*/

  const [selectedUser, setSelectedUser] = useState({})

  const openContactProfileHandler = (user) => {
    dispatch(setContactProfileIsOpen(true))
    setSelectedUser(user)
  }

  /* Search */

  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [searchResults2, setSearchResults2] = useState([])
  const [debouncedValue] = useDebounce(searchTerm, 500)

  useEffect(() => {
    const searchHandler = async () => {
      if (debouncedValue.trim() !== '') {
        try {
          const data = await contactSearchRequest(debouncedValue)
          const data2 = await contactAndFriendSearchRequest(debouncedValue)
          setSearchResults(data)
          setSearchResults2(data2)
        } catch (error) {
          console.log(error)
        }
      }
    }
    searchHandler()
  }, [debouncedValue])

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
  }
  const firstInputRef = useRef(null)

  useEffect(() => {
    // Focus on the input element when the component mounts
    if (firstInputRef.current) {
      firstInputRef.current.focus()
    }
  }, [])

  const clearSearchInputHandler = () => {
    setSearchResults([])
    setSearchTerm('')
  }

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults([])
    }
  }, [searchTerm])

  return (
    <>
      <div className={` p-3 text-xs ${defaultTextIconColor}`}>
        {addContactView ? (
          <AddContact contactViewHandler={contactViewHandler} />
        ) : (
          <>
            <div className='pb-6 font-bold text-sm flex justify-between items-center relative '>
              <ChevronLeftIcon
                size={30}
                className={'cursor-pointer'}
                onClick={() => {
                  dispatch(setIsContactsOpen(false))
                }}
              />

              <p className={`text-sm  `}>Contacts</p>

              <AddIcon
                onClick={addContactViewHandler}
                className=' cursor-pointer '
              />
            </div>
            {/* Content */}
            <div className='w-full pb-2'>
              <Search
                handleSearch={handleSearch}
                searchTerm={searchTerm}
                firstInputRef={firstInputRef}
                clearSearchInputHandler={clearSearchInputHandler}
              />
              <InviteButton />
              {/* Sync Google contacts */}
              <div
                onClick={authGoogle}
                className={` px-3 h-[2.2rem] bg-BlueDarkColorMain bg-opacity-30 justify-center items-center  cursor-pointer  rounded-xl text-end  text-[13px]   text-BlueDarkColorMain font-semibold flex `}
              >
                <AddIcon
                  size={18}
                  className={'mr-1 !fill-BlueDarkColorMain mt-[1px]'}
                />
                <p className='truncate'>Sync Contacts </p>
              </div>

              <PersonalCard
                onClick={openContactProfileHandler.bind(null, fetchedUserInfo)}
                name={fetchedUserInfo?.email}
                img={fetchedUserInfo?.avatar}
              />
            </div>
            <div className='overflow-y-scroll h-[50vh] '>
              {!searchTerm ? (
                <>
                  {fetchedUserInfo?.friends?.map((friend) => {
                    return (
                      <PersonalCard
                        onClick={openContactProfileHandler.bind(null, friend)}
                        key={friend._id}
                        name={friend.email}
                        img={friend.avatar}
                      />
                    )
                  })}
                </>
              ) : (
                <>
                  {searchResults2.friends?.map((contact) => (
                    <PersonalCard
                      onClick={openContactProfileHandler.bind(null, contact)}
                      key={uuid()}
                      name={contact.email}
                      img={'http://source.unsplash.com/100x100/?boys'}
                    />
                  ))}
                </>
              )}
            </div>

            {/*  {!searchTerm && <p>Contacts</p>} */}

            <div
              ref={specificDivRef}
              className='w-full pb-[2rem] overflow-y-scroll h-[500px] invisible '
            >
              {searchTerm ? (
                <>
                  <p>Friends</p>
                  {searchResults2.friends?.map((contact) => (
                    <PersonalCard
                      onClick={openContactProfileHandler.bind(null, contact)}
                      key={uuid()}
                      name={contact.email}
                      img={'http://source.unsplash.com/100x100/?boys'}
                    />
                  ))}
                  <p>Contacts</p>
                  {searchResults2.contacts?.map((contact) => (
                    <PersonalCard
                      onClick={openContactProfileHandler.bind(null, contact)}
                      key={uuid()}
                      name={contact.name}
                      img={contact.photos}
                    />
                  ))}
                </>
              ) : (
                contacts?.map((contact) => (
                  <PersonalCard
                    onClick={openContactProfileHandler.bind(null, contact)}
                    key={uuid()}
                    name={contact.name}
                    img={contact.photos}
                  />
                ))
              )}
            </div>
          </>
        )}
      </div>
      <Drawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={contactProfileIsOpen}
        position='left'
        className=''
      >
        <ContactProfile user={selectedUser} />
      </Drawer>
    </>
  )
}

export default Contacts
