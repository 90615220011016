import axios from 'axios'
import { userId } from 'utils'

const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
}

const fetchGoogleContacts = async (data) => {
  const { page, limit } = data
  const results = await axios(
    `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/contacts/${userId()}?page=${page}&limit=${limit}`,
    config
  )

  return results.data
}

const fetchAllUsers = async () => {
  const results = await axios(
    `${process.env.REACT_APP_BASE_URL}/api/v1/users`,
    config
  )

  return results.data.users
}
const fetchUserInfo = async () => {
  const results = await axios(
    `${process.env.REACT_APP_BASE_URL}/api/v1/user/${userId()}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }
  )

  return results.data.user
}
const editUserAPI = async (formData) => {
  const results = await axios.patch(
    `${process.env.REACT_APP_BASE_URL}/api/v1/edit-user`,
    formData,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }
  )

  return results.data.user
}

const userService = {
  fetchGoogleContacts,
  fetchAllUsers,
  fetchUserInfo,
  editUserAPI,
}

export default userService
