/* eslint-disable no-unused-vars */
import { Header, Calendar, ErrorBoundary, LeftBar } from 'components'
import { useWeek, useDarkSide } from 'hooks'
import { useSelector } from 'react-redux'

const Home = () => {
  const { week } = useWeek()

  const { isLeftBarOpened } = useSelector((state) => state.sideView)
  const { nightMode } = useSelector((state) => state.theme)

  return (
    <ErrorBoundary>
      <div
        className={`min-h-screen ${
          nightMode ? 'bg-DMDarkBlueBackground' : 'bg-white'
        } `}
      >
        <LeftBar isLeftBarOpened={isLeftBarOpened} />
        <div className={`overflow-x-hidden w-full md:pl-[3.5rem] `}>
          <Header />
          <Calendar isLeftBarOpened={isLeftBarOpened} week={week} />
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default Home
