/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const PreferenceIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M10.4798 8.021V6.12516H13.1882V2.2085H15.084V6.12516H17.7923V8.021H10.4798ZM13.1882 17.7918V9.0835H15.084V17.7918H13.1882ZM4.91732 17.7918V14.0627H2.20898V12.1668H9.52148V14.0627H6.81315V17.7918H4.91732ZM4.91732 11.1043V2.2085H6.81315V11.1043H4.91732Z' />
    </svg>
  )
}

export default PreferenceIcon
