/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const SecurityIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M9.99571 18.6875C9.90972 18.6875 9.82296 18.691 9.73544 18.6979C9.64792 18.7049 9.57639 18.6944 9.52083 18.6667C7.57579 18.0297 5.9819 16.815 4.73915 15.0223C3.49638 13.2297 2.875 11.2639 2.875 9.125V5.1875C2.875 4.78814 2.98778 4.42108 3.21333 4.08631C3.4389 3.75156 3.74279 3.51446 4.125 3.375L9.35417 1.41667C9.5625 1.34722 9.77778 1.3125 10 1.3125C10.2222 1.3125 10.4444 1.34722 10.6667 1.41667L15.8958 3.375C16.2644 3.50807 16.5649 3.74297 16.7973 4.07969C17.0296 4.41642 17.1458 4.78569 17.1458 5.1875V9.125C17.1458 11.2639 16.5221 13.2299 15.2746 15.023C14.0271 16.816 12.434 18.0311 10.4954 18.6682C10.4233 18.695 10.3435 18.7049 10.256 18.6979C10.1685 18.691 10.0817 18.6875 9.99571 18.6875ZM9.99727 16.8542C11.388 16.3125 12.5553 15.4302 13.4992 14.2074C14.4431 12.9845 14.9989 11.582 15.1667 10H10V3.25L4.77083 5.18912V9.13215C4.77083 9.29735 4.77778 9.44352 4.79167 9.57065C4.80556 9.69778 4.81985 9.8409 4.83454 10H9.99727V16.8542Z' />
    </svg>
  )
}

export default SecurityIcon
