import axios from 'axios'
import { userId } from 'utils'

const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
}

const fetchConnections = async () => {
  const results = await axios(
    `${process.env.REACT_APP_BASE_URL}/api/v1/invites`,

    {
      params: { userId: userId() },
      ...config,
    }
  )
  return results.data
}

const sendInviteToMember = async (data) => {
  const { id, senderId, receiverId, type, objectArrayOfSelectedMembers } = data

  const dataForSubmit = {
    userIds: objectArrayOfSelectedMembers,
    // properties for contact invites
    senderId,
    receiverId,
  }

  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/invite-to/${type}/${id}`,
    dataForSubmit,
    config
  )
  return response.data
}

const responseToInvites = async (data) => {
  const { id, status, type } = data

  const results = await axios.put(
    `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/invite-response-to/${type}/${id}/${userId()}`,

    { response: status },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  return results.data
}
const cancelInvite = async (data) => {
  const { id, userId, type } = data

  const results = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/invite-cancel/${id}/${type}/${userId}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }
  )
  return results
}

export const searchRequest = async (debouncedValue, searchType) => {
  const res = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/search`,
    {
      type: searchType,
      searchTerm: debouncedValue,
      userId: userId(),
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  return res.data
}
export const userSearchRequest = async (debouncedValue) => {
  const results = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/user-search`,
    {
      params: { nickNameId: debouncedValue, userId: userId() },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }
  )
  return results.data
}
export const contactSearchRequest = async (debouncedValue) => {
  const results = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/contact-search`,
    {
      params: { name: debouncedValue, userId: userId() },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }
  )
  return results.data
}
export const contactAndFriendSearchRequest = async (debouncedValue) => {
  const results = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/search-friends`,
    {
      params: { nickNameId: debouncedValue, userId: userId() },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }
  )
  return results.data
}

const getNotifications = async () => {
  const results = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/get-notification/${userId()}`,

    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )

  return results.data
}
const fetchFriendList = async (data) => {
  const results = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/friends`,
    { friendRequestsArr: data },
    config
  )

  return results.data
}
const deleteNotification = async (data) => {
  const { id } = data
  const results = await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/v1/delete-notification`,

    {
      params: { typeId: id, userId: userId() },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }
  )

  return results.data
}

const userService = {
  fetchConnections,
  responseToInvites,
  sendInviteToMember,
  cancelInvite,
  getNotifications,
  deleteNotification,
  userSearchRequest,
  fetchFriendList,
}

export default userService
