import React from 'react'
import { setColor } from 'features'
import { useDispatch } from 'react-redux'

const ColorList = ({ item, setShowColors, className }) => {
  const dispatch = useDispatch()
  return (
    <span
      onClick={() => {
        dispatch(setColor(item.color))
        setShowColors(false)
      }}
      className={`rounded-xl ${className}   cursor-pointer `}
      style={{ backgroundColor: item.color }}
    ></span>
  )
}

export default ColorList
