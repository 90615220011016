import React from 'react'
import { useLocation } from 'react-router-dom'

const UnumSection = () => {
  const { pathname } = useLocation()

  return (
    <section className='w-full mt-10 md:mt-0 flex flex-col justify-center items-center'>
      <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
        <img
          className='mx-auto h-14 md:h-20 w-auto'
          src='/unum-icon.png'
          alt='unum-logo'
        />
      </div>

      <img
        className='mx-auto mt-4 hidden md:block md:mt-12 h-7 md:h-12 w-auto'
        src='/images/UNUM_LOGO.png'
        alt='unum'
      />

      {pathname === '/' ? (
        <h1 className='text-[32px]  leading-[40px] mt-12 text-white tracking-[-0.081px] md:w-[280px] mx-auto text-center'>
          Explore a world of productivity
        </h1>
      ) : (
        <h1 className='text-[32px]  md:block leading-[40px] mt-12 text-white tracking-[-0.081px] w-[280px] mx-auto text-center'>
          Explore a world of productivity
        </h1>
      )}
    </section>
  )
}

export default UnumSection
