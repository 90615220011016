/* eslint-disable no-unused-vars */
import axios from 'axios'
import { ChevronLeftIcon } from 'components/Icons'
import {
  cancelInvite,
  deleteNotification,
  fetchSingleEvent,
  resetSendInvitationStatus,
  sendInviteToMember,
  setCancelSocketData,
  setSocketData,
} from 'features'
import { useSocket } from 'hooks'

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import uuid from 'react-uuid'
import { userId } from 'utils'

const AddMemberToEvent = ({
  defaultTextIconColor,
  singleEvent,
  setShowContent,
}) => {
  const { fetchedUserInfo } = useSelector((state) => state.user)
  const { fetchedSingleEvent } = useSelector((state) => state.event)

  const { sendInvitationStatus } = useSelector((state) => state.connection)

  const dispatch = useDispatch()

  // /* Socket IO */
  const { socket } = useSocket()

  // connect to the socket server
  useEffect(() => {
    if (socket) {
      const handleGetInvite = (data) => {
        dispatch(setSocketData(data))
      }

      const handleCancelInvite = (data) => {
        dispatch(setCancelSocketData(data))
      }

      socket.on('cancelledInvite', handleCancelInvite)

      socket.on('getInvite', handleGetInvite)

      // Clean up the event listener when the component unmounts
      return () => {
        socket.off('getInvite', handleGetInvite)
        socket.off('cancelledInvite', handleCancelInvite)
      }
    }
  }, [dispatch, socket])

  useEffect(() => {
    if (sendInvitationStatus === 'rejected') {
      toast.error('Invitation has already sent')
      dispatch(resetSendInvitationStatus())

      return
    } else if (sendInvitationStatus === 'success') {
      //setShowContent('description')
      toast.success('Invitation sent')
      dispatch(resetSendInvitationStatus())
    }
  }, [dispatch, sendInvitationStatus, setShowContent])

  // add notification function

  const addNotification = async (forUser, typeId) => {
    const results = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add-notification`,

      {
        notificationFromUser: userId(),
        notificationType: 'event',
        isRead: false,
        notificationForUser: forUser,
        typeId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )

    return results.data
  }

  const inviteMemberToEventHandler = (user) => {
    let id = singleEvent._id

    const objectArrayOfSelectedMembers = [
      {
        id: user._id,
        email: user.email,
      },
    ]

    if (objectArrayOfSelectedMembers.length === 0) {
      return toast.warning('Please select at least one member')
    }

    let data = { id, objectArrayOfSelectedMembers, type: 'event' }
    dispatch(sendInviteToMember(data))

    if (socket) {
      setTimeout(() => {
        socket.emit('sendInvite', {
          senderId: userId(),
          receiverId: user._id,
        })
      }, 1000)
    }
    addNotification(user._id, singleEvent._id)
  }

  const cancelInviteHandler = (user) => {
    let data = {
      id: singleEvent._id,
      userId: user._id,
      type: 'event',
    }

    dispatch(cancelInvite(data))
    dispatch(deleteNotification(data))

    setTimeout(() => {
      socket.emit('cancelInvite', {
        senderId: userId(),
        receiverId: user._id,
      })
    }, 1000)
  }

  const backArrowHandler = () => {
    setShowContent('description')
  }

  return (
    <div
      className={` h-screen w-full backdrop-blur-md bg-DMDarkBlueBackground  p-3 text-xs ${defaultTextIconColor}`}
    >
      {/* Header */}
      <div className='pb-6 font-bold  text-sm flex justify-between items-center relative'>
        <ChevronLeftIcon
          size={30}
          className={'cursor-pointer'}
          onClick={backArrowHandler}
        />

        <p className='  absolute left-1/2 -translate-x-1/2 pr-5'>
          Invite Participants
        </p>
      </div>
      {/* Members list */}

      <div className='overflow-scroll pb-10 h-full'>
        {fetchedUserInfo?.friends
          ?.slice()
          .sort((a, b) => a.email.localeCompare(b.email))
          .map((user) => {
            let invitedUser = fetchedSingleEvent.invitedUsers.filter(
              (item) => item.user === user._id
            )
            return (
              <div
                key={user._id}
                className='relative rounded-xl h-[3.75rem] overflow-hidden bg-DMDarkBlueForeground items-center w-full !px-0  py-6  flex justify-between mb-3 '
              >
                <div className='flex items-center'>
                  <div className='flex  bg-DM2ndLighterTextIcon justify-center items-center rounded-xl  w-[3.75rem] h-[3.75rem]'>
                    <img
                      alt='test'
                      src={'http://source.unsplash.com/100x100/?girl'}
                      className='cursor-pointer rounded-xl'
                    />
                  </div>
                  <p className={`truncate pl-3 `}>{user.email}</p>
                </div>

                {invitedUser[0]?.status === 'pending' ? (
                  <span
                    onClick={cancelInviteHandler.bind(null, user)}
                    className='bg-DM2ndLighterTextIcon w-[4.688rem] bg-opacity-30 cursor-pointer h-[1.875rem] rounded-md justify-center flex items-center text-sm mr-4 text-red-600 '
                  >
                    Cancel
                  </span>
                ) : invitedUser[0]?.status === 'accepted' ? (
                  <span className='bg-DM2ndLighterTextIcon w-[4.688rem] bg-opacity-30  h-[1.875rem] rounded-md justify-center flex items-center text-sm mr-4 font-light'>
                    <p className='opacity-50'> Member</p>
                  </span>
                ) : (
                  <span
                    onClick={inviteMemberToEventHandler.bind(null, user)}
                    className='bg-DM2ndLighterTextIcon w-[4.688rem] bg-opacity-30 cursor-pointer h-[1.875rem] rounded-md justify-center flex items-center text-sm mr-4 '
                  >
                    Invite
                  </span>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default AddMemberToEvent
