import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import connectionsService from './connectionsService'

export const fetchConnections = createAsyncThunk(
  'connection/fetchConnections',
  async function (_, { rejectWithValue }) {
    try {
      return await connectionsService.fetchConnections()
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const fetchFriendList = createAsyncThunk(
  'connection/fetchFriendList',
  async function (data, { rejectWithValue }) {
    try {
      return await connectionsService.fetchFriendList(data)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const sendInviteToMember = createAsyncThunk(
  'connection/sendInviteToMember',
  async function (data, { rejectWithValue }) {
    try {
      return await connectionsService.sendInviteToMember(data)
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)

export const responseToInvites = createAsyncThunk(
  'connection/responseToInvites',
  async function (data, { rejectWithValue }) {
    try {
      return await connectionsService.responseToInvites(data)
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)
export const cancelInvite = createAsyncThunk(
  'connection/cancelInvite',
  async function (data, { rejectWithValue }) {
    try {
      return await connectionsService.cancelInvite(data)
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)
export const getNotifications = createAsyncThunk(
  'connection/getNotifications',
  async function (_, { rejectWithValue }) {
    try {
      return await connectionsService.getNotifications()
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)
export const deleteNotification = createAsyncThunk(
  'connection/deleteNotification',
  async function (data, { rejectWithValue }) {
    try {
      return await connectionsService.deleteNotification(data)
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)

const initialState = {
  fetchedConnections: [],
  fetchedConnectionsStatus: '',
  fetchedConnectionsError: null,
  fetchedFriendList: [],
  fetchedFriendListStatus: '',
  fetchedFriendListError: null,
  fetchedNotifications: [],
  fetchedNotificationsStatus: '',
  fetchedNotificationsError: null,
  inviteMemberStatus: '',
  inviteMemberError: null,
  sendInvitationStatus: '',
  sendInvitationError: null,
  responseToInvitesStatus: '',
  cancelInviteStatus: '',
  socketData: null,
  cancelSocketData: null,
}

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    resetResponseToInvitesStatus: (state, action) => {
      state.responseToInvitesStatus = ''
    },
    resetSendInvitationStatus: (state, action) => {
      state.sendInvitationStatus = ''
      state.sendInvitationError = ''
    },
    setSocketData: (state, action) => {
      state.socketData = action.payload
    },
    setCancelSocketData: (state, action) => {
      state.cancelSocketData = action.payload
    },
  },
  extraReducers: {
    [fetchConnections.fulfilled]: (state, action) => {
      state.fetchedConnections = action.payload
      state.fetchedConnectionsStatus = 'success'
      state.fetchedConnectionsError = null
    },
    [fetchConnections.pending]: (state, action) => {
      state.fetchedConnectionsStatus = 'pending'
    },
    [fetchConnections.rejected]: (state, action) => {
      state.fetchedConnectionsStatus = 'rejected'
      state.fetchedConnectionsError = action.payload.response.data.message
    },
    [fetchFriendList.fulfilled]: (state, action) => {
      state.fetchedFriendList = action.payload
      state.fetchedFriendListStatus = 'success'
      state.fetchedFriendListError = null
    },
    [fetchFriendList.pending]: (state, action) => {
      state.fetchedFriendListStatus = 'pending'
    },
    [fetchFriendList.rejected]: (state, action) => {
      state.fetchedFriendListStatus = 'rejected'
      state.fetchedFriendListError = action.payload.response.data.message
    },
    [sendInviteToMember.fulfilled]: (state, action) => {
      state.sendInvitationStatus = 'success'
      state.sendInvitationError = null
    },
    [sendInviteToMember.pending]: (state, action) => {
      state.sendInvitationStatus = 'pending'
    },
    [sendInviteToMember.rejected]: (state, action) => {
      state.sendInvitationStatus = 'rejected'
      state.sendInvitationError = action.payload
    },
    [responseToInvites.fulfilled]: (state, action) => {
      state.responseToInvitesStatus = 'success'
    },
    [responseToInvites.pending]: (state, action) => {
      state.responseToInvitesStatus = 'pending'
    },
    [responseToInvites.rejected]: (state, action) => {
      state.responseToInvitesStatus = 'rejected'
    },
    [cancelInvite.fulfilled]: (state, action) => {
      state.cancelInviteStatus = 'success'
    },
    [cancelInvite.pending]: (state, action) => {
      state.cancelInviteStatus = 'pending'
    },
    [cancelInvite.rejected]: (state, action) => {
      state.responseToInvitesStatus = 'rejected'
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.fetchedNotifications = action.payload

      state.fetchedNotificationsStatus = 'success'
    },
    [getNotifications.pending]: (state, action) => {
      state.fetchedNotificationsStatus = 'pending'
    },
    [getNotifications.rejected]: (state, action) => {
      state.fetchedNotificationsStatus = 'rejected'
      state.fetchedNotificationsError = action.payload
    },
  },
})

// Action creators are generated for each case reducer function

export const {
  resetResponseToInvitesStatus,
  resetSendInvitationStatus,
  setSocketData,
  setCancelSocketData,
} = connectionSlice.actions

export default connectionSlice.reducer
