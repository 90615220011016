/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const LocationIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 21 21'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M10.0762 12.4868C10.2823 12.4868 10.4549 12.4221 10.5937 12.2925C10.7326 12.163 10.8021 11.9962 10.8021 11.792V9.98682H12.5656C12.7816 9.98682 12.9583 9.92171 13.0958 9.7915C13.2333 9.66128 13.3021 9.496 13.3021 9.29567C13.3021 9.09532 13.2333 8.92432 13.0958 8.78265C12.9583 8.64098 12.7816 8.57015 12.5656 8.57015H10.8021V6.80663C10.8021 6.59064 10.733 6.4139 10.5948 6.2764C10.4566 6.1389 10.2814 6.07015 10.0693 6.07015C9.86302 6.07015 9.69401 6.1389 9.56223 6.2764C9.43046 6.4139 9.36458 6.59064 9.36458 6.80663V8.57015H7.58023C7.37015 8.57015 7.19835 8.63925 7.06483 8.77744C6.93133 8.91565 6.86458 9.09082 6.86458 9.30296C6.86458 9.50331 6.93133 9.66737 7.06483 9.79515C7.19835 9.92293 7.37015 9.98682 7.58023 9.98682H9.36458V11.792C9.36458 11.9962 9.43165 12.163 9.56579 12.2925C9.69993 12.4221 9.87007 12.4868 10.0762 12.4868ZM10.0729 17.091C11.8149 15.5025 13.1011 14.0621 13.9315 12.7699C14.7619 11.4777 15.1771 10.3342 15.1771 9.33923C15.1771 7.79073 14.6864 6.52543 13.705 5.54332C12.7237 4.56121 11.5143 4.07015 10.0768 4.07015C8.63939 4.07015 7.43215 4.56331 6.45512 5.54963C5.4781 6.53596 4.98958 7.79903 4.98958 9.33884C4.98958 10.3403 5.41195 11.4793 6.25669 12.7559C7.10142 14.0325 8.3735 15.4776 10.0729 17.091ZM10.0804 19.1535C9.93294 19.1535 9.7878 19.1295 9.64502 19.0815C9.50226 19.0335 9.37406 18.9672 9.26042 18.8826C7.17708 17.0222 5.62847 15.3082 4.61458 13.7405C3.60069 12.1728 3.09375 10.7063 3.09375 9.34098C3.09375 7.15526 3.79559 5.41439 5.19927 4.11836C6.60297 2.82233 8.22665 2.17432 10.0703 2.17432C11.914 2.17432 13.542 2.82233 14.9544 4.11836C16.3667 5.41439 17.0729 7.15526 17.0729 9.34098C17.0729 10.7034 16.5625 12.1691 15.5417 13.7383C14.5208 15.3075 12.9687 17.0222 10.8854 18.8826C10.7749 18.9672 10.6499 19.0335 10.5106 19.0815C10.3713 19.1295 10.2279 19.1535 10.0804 19.1535Z' />
    </svg>
  )
}

export default LocationIcon
