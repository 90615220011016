/* eslint-disable no-unused-vars */
import { AddIcon, CheckIcon, ChevronLeftIcon } from 'components/Icons'
import { setEventReminder, setReminderIsOpen } from 'features'
import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import uuid from 'react-uuid'

const Reminders = () => {
  const dispatch = useDispatch()
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )
  const { eventReminder } = useSelector((state) => state.event)

  const reminders = [
    '5 minutes  before',
    '10 minutes before',
    '30 minutes before',
    '1 hour before',
    '2 hour before',
    '1 day before',
    '2 day before',
    '1 week before',
  ]

  return (
    <div className={`h-screen p-3 ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='  pb-6 font-bold text-sm flex justify-between items-center relative'>
        <ChevronLeftIcon
          onClick={() => {
            dispatch(setReminderIsOpen(false))
          }}
          size={30}
          className={'cursor-pointer'}
        />
        <p className={`text-sm  `}>Reminders</p>

        <AddIcon size={30} className={'cursor-pointer'} />
      </div>

      {/* Content */}
      <div
        onClick={() => {
          dispatch(setEventReminder('None'))
          dispatch(setReminderIsOpen(false))
        }}
        className={`h-12 w-full rounded-xl group  flex justify-between items-center px-2 mb-4 cursor-pointer ${
          nightMode
            ? 'bg-DMDarkBlueForeground'
            : 'bg-GrayMediumBGColor bg-opacity-20'
        }`}
      >
        <p
          className={` group-hover:text-BlueDarkColorMain  ${
            eventReminder === 'None'
              ? ` ${nightMode ? 'text-white' : 'text-black'}`
              : ` ${
                  nightMode
                    ? 'text-DM2ndLighterTextIcon'
                    : 'text-DarkText text-opacity-50'
                }`
          }`}
        >
          {' '}
          None
        </p>

        <CheckIcon
          size={22}
          secondaryColor={true}
          className={`group-hover:!fill-BlueDarkColorMain mr-2 ${
            eventReminder === 'None' ? `!fill-BlueDarkColorMain` : ``
          }`}
        />
      </div>
      <div
        onClick={() => {
          dispatch(setEventReminder('During the event'))
          dispatch(setReminderIsOpen(false))
        }}
        className={`h-12 w-full rounded-xl group flex justify-between items-center px-2 mb-4 cursor-pointer ${
          nightMode
            ? 'bg-DMDarkBlueForeground'
            : 'bg-GrayMediumBGColor bg-opacity-20'
        }`}
      >
        <p
          className={` group-hover:text-BlueDarkColorMain ${
            eventReminder === 'During the event'
              ? `  ${nightMode ? 'text-white' : 'text-black'}`
              : ` ${
                  nightMode
                    ? 'text-DM2ndLighterTextIcon'
                    : 'text-DarkText text-opacity-50'
                }`
          }`}
        >
          {' '}
          During the event
        </p>

        <CheckIcon
          size={22}
          secondaryColor={true}
          className={`group-hover:!fill-BlueDarkColorMain mr-2 ${
            eventReminder === 'During the event'
              ? `!fill-BlueDarkColorMain`
              : ``
          }`}
        />
      </div>
      <div
        className={`w-full rounded-xl    divide-y-[1px] px-2 divide-DivideLine divide-opacity-20 ${
          nightMode
            ? 'bg-DMDarkBlueForeground'
            : 'bg-GrayMediumBGColor bg-opacity-20'
        }`}
      >
        {reminders.map((reminder) => (
          <div
            key={uuid()}
            onClick={() => {
              dispatch(setEventReminder(reminder))
              dispatch(setReminderIsOpen(false))
            }}
            className={`flex justify-between group items-center h-12   cursor-pointer ${
              eventReminder === reminder
                ? ` ${nightMode ? 'text-white' : 'text-black'}`
                : ` ${
                    nightMode
                      ? 'text-DM2ndLighterTextIcon'
                      : 'text-DarkText text-opacity-50'
                  }`
            }`}
          >
            <p className={`group-hover:text-BlueDarkColorMain `}> {reminder}</p>

            <CheckIcon
              size={22}
              secondaryColor={true}
              className={`group-hover:!fill-BlueDarkColorMain mr-2 ${
                eventReminder === reminder ? `!fill-BlueDarkColorMain` : ``
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Reminders
