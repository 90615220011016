import { ReactSVGIcon } from 'components'
import { useDarkSide } from 'hooks'
import useDailyEvent from 'hooks/useDailyEvent'
import React from 'react'

const OneDayEventsW = ({
  eventHours,
  event,
  moment,
  week,
  eventHeightLocal,
}) => {
  const { startPosition, endPosition } = useDailyEvent(event)
  const [colorTheme] = useDarkSide()

  return (
    <div className={` w-full h-full flex group`}>
      {/* Event duration line */}
      <span className='w-full grid grid-cols-6  absolute rounded-tl-md rounded-tr-md overflow-hidden '>
        <div
          style={{
            opacity: 100,
            backgroundColor: event.group.color,
            gridColumnStart: startPosition,
            gridColumnEnd: endPosition,
          }}
          className='w-full h-1.5'
        ></div>
      </span>
      {/* Background line */}
      <span
        style={{
          backgroundColor: event.group.color,
        }}
        className='w-full h-1.5 top-0 absolute opacity-40  rounded-tl-md rounded-tr-md   '
      ></span>
      {/* Event background color */}
      <div
        style={{ backgroundColor: event.group.color }}
        className='w-full h-full opacity-50 absolute rounded-md group-hover:opacity-70'
      ></div>
      {/* Content */}
      <div
        className={`flex relative z-50 items-center sm:justify-start justify-center  w-full h-full overflow-hidden`}
      >
        <ReactSVGIcon name={event?.icon?.name} />

        <div
          className={` sm:flex flex-col hidden truncate justify-center px-1 sm:pt-1 pt-1.5 ${
            colorTheme === 'light' ? 'text-black' : 'text-white'
          }  `}
        >
          <p className=' text-left font-light truncate '>{event.title}</p>
          <p className=' sm:font-semibold  truncate'>
            {moment(event.startTime).format(' HH:mm A')} -
            {moment(event.endTime).format(' HH:mm A')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default OneDayEventsW
