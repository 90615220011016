/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const PhoneIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M16.5051 17.8957C14.7656 17.8957 13.0356 17.4682 11.3152 16.6133C9.59476 15.7584 8.04962 14.6507 6.67977 13.2904C5.30992 11.93 4.19792 10.3818 3.34375 8.64567C2.48958 6.90956 2.0625 5.19088 2.0625 3.48963C2.0625 3.09366 2.20139 2.75331 2.47917 2.46859C2.75694 2.18386 3.09722 2.0415 3.5 2.0415H6.3125C6.74643 2.0415 7.10163 2.15262 7.37808 2.37484C7.65454 2.59706 7.83407 2.92345 7.91667 3.354L8.47917 5.56234C8.53472 5.95123 8.52232 6.28196 8.44196 6.55452C8.36161 6.82709 8.20735 7.05886 7.97917 7.24984L5.85417 9.20817C6.53472 10.3054 7.28518 11.2535 8.10554 12.0525C8.92592 12.8515 9.83518 13.5354 10.8333 14.104L12.8958 12.0415C13.1319 11.7915 13.397 11.6179 13.6911 11.5207C13.9852 11.4234 14.3104 11.4165 14.6667 11.4998L16.625 11.979C17.0538 12.0953 17.3798 12.2945 17.6029 12.5767C17.826 12.8588 17.9375 13.2082 17.9375 13.6248V16.4373C17.9375 16.854 17.7986 17.2012 17.5208 17.479C17.2431 17.7568 16.9045 17.8957 16.5051 17.8957ZM4.89583 7.5415L6.58333 5.95817L6.12479 3.93734H4C3.97222 4.41002 4.02758 4.93768 4.16606 5.52032C4.30456 6.10295 4.54782 6.77668 4.89583 7.5415ZM16.0208 15.979V13.8123L14.1875 13.3748L12.5833 15.0832C13.0694 15.3332 13.6146 15.5346 14.2188 15.6873C14.8229 15.8401 15.4236 15.9373 16.0208 15.979Z' />
    </svg>
  )
}

export default PhoneIcon
