/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { ReactSVGIcon } from 'components'
import { ChevronLeftIcon, GroupIcon, MoreVertIcon } from 'components/Icons'
import {
  responseToInvites,
  fetchConnections,
  setConnectionsIsOpen,
  setSocketData,
  setCancelSocketData,
  fetchUserInfo,
} from 'features'
import { useSocket } from 'hooks'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useDispatch } from 'react-redux'
import { userId } from 'utils'

const contacts = [
  {
    name: 'Aaron Dessner',
    imgUrl: 'http://source.unsplash.com/100x100/?girl',
  },
  {
    name: 'John Doe',
    imgUrl: 'http://source.unsplash.com/100x100/?girl',
  },
]

const Connections = () => {
  const dispatch = useDispatch()
  const { socketData, cancelSocketData } = useSelector(
    (state) => state.connection
  )

  const { defaultTextIconColor } = useSelector((state) => state.theme)
  const { fetchedConnections, responseToInvitesStatus } = useSelector(
    (state) => state.connection
  )
  const [showRequest, setShowRequest] = useState('')
  const { socket } = useSocket()

  // connect to the socket server

  useEffect(() => {
    if (socket) {
      const handleGetInvite = (data) => {
        dispatch(setSocketData(data))
      }

      const handleCancelInvite = (data) => {
        dispatch(setCancelSocketData(data))
      }

      socket.on('cancelledInvite', handleCancelInvite)

      socket.on('getInvite', handleGetInvite)

      // Clean up the event listener when the component unmounts
      return () => {
        socket.off('getInvite', handleGetInvite)
        socket.off('cancelledInvite', handleCancelInvite)
      }
    }
  }, [dispatch, socket])

  // const onContactClick = () => {
  //   if (showRequest === 'contacts') {
  //     setShowRequest('')
  //   } else {
  //     setShowRequest('contacts')
  //   }
  // }
  // const onGroupsClick = () => {
  //   if (showRequest === 'groups') {
  //     setShowRequest('')
  //   } else {
  //     setShowRequest('groups')
  //   }
  // }
  // const onEventsClick = () => {
  //   if (showRequest === 'events') {
  //     setShowRequest('')
  //   } else {
  //     setShowRequest('events')
  //   }
  // }

  const handleClick = () => {
    if (showRequest === 'contacts') {
      setShowRequest('')
    } else {
      setShowRequest('contacts')
    }

    if (showRequest === 'groups') {
      setShowRequest('')
    } else {
      setShowRequest('groups')
    }

    if (showRequest === 'events') {
      setShowRequest('')
    } else {
      setShowRequest('events')
    }
  }

  useEffect(() => {
    dispatch(fetchConnections())
    dispatch(fetchUserInfo())
  }, [dispatch, responseToInvitesStatus, socketData, cancelSocketData])

  const groupRequests = fetchedConnections?.groupInvites
  const eventRequests = fetchedConnections?.eventInvites
  const contactRequests = fetchedConnections?.contactRequest

  const acceptOrRejectGroupRequestsHandler = (id, status) => {
    let data = { id, status, type: 'group' }
    dispatch(responseToInvites(data))
  }
  const acceptOrRejectEventRequestsHandler = (id, status) => {
    let data = { id, status, type: 'event' }
    dispatch(responseToInvites(data))
  }
  const acceptOrRejectContactRequestsHandler = (id, status, receiverId) => {
    let data = { id, status, type: 'contact' }
    dispatch(responseToInvites(data))
    if (socket) {
      setTimeout(() => {
        socket.emit('sendInvite', {
          senderId: userId(),
          receiverId,
        })
      }, 1000)
    }
  }

  return (
    <div className={`h-screen p-3 ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='  pb-6 font-bold text-sm flex justify-between items-center relative'>
        <ChevronLeftIcon
          size={30}
          className={'cursor-pointer'}
          onClick={() => {
            dispatch(setConnectionsIsOpen(false))
          }}
        />

        <p className={`text-sm  `}>Connections</p>

        <MoreVertIcon className={'cursor-pointer'} />
      </div>
      {/* Content Buttons */}
      {/* <div className={`flex justify-between text-sm pb-7 `}>
        <div
          onClick={handleClick}
          className={`w-[7rem] h-[2.1875rem] cursor-pointer    flex justify-center items-center rounded-2xl ${
            showRequest === 'contacts'
              ? 'bg-BlueDarkColorMain'
              : 'bg-DMDarkBlueForeground'
          } `}
        >
          <p className={`${showRequest === 'contacts' ? '' : 'opacity-60'}`}>
            Contacts
          </p>
        </div>
        <div
          onClick={handleClick}
          className={`w-[7rem] h-[2.1875rem] cursor-pointer     flex justify-center items-center rounded-2xl ${
            showRequest === 'groups'
              ? 'bg-BlueDarkColorMain'
              : 'bg-DMDarkBlueForeground'
          } `}
        >
          <p className={`${showRequest === 'groups' ? '' : 'opacity-60'}`}>
            Groups
          </p>
        </div>
        <div
          onClick={handleClick}
          className={`w-[7rem] h-[2.1875rem] cursor-pointer     flex justify-center items-center rounded-2xl  ${
            showRequest === 'events'
              ? 'bg-BlueDarkColorMain'
              : 'bg-DMDarkBlueForeground'
          }`}
        >
          <p className={`${showRequest === 'events' ? '' : 'opacity-60'}`}>
            Events
          </p>
        </div>
      </div> */}
      {/* Content */}
      <div className='overflow-y-scroll h-full '>
        {/* Contacts */}
        {showRequest === 'contacts' || showRequest === '' ? (
          <>
            <h1 className='text-md py-3'>
              Contact requests
              <span className='text-gray-400'>
                {' '}
                / {contactRequests?.length} new{' '}
              </span>
            </h1>
            {contactRequests?.map((contact) => (
              <div
                key={contact._id}
                className={`relative rounded-2xl h-[3.75rem] overflow-hidden bg-DMDarkBlueForeground items-center w-full !px-0  py-6  flex justify-between mb-3 `}
              >
                <div className='flex items-center'>
                  <div
                    className={`flex  bg-[#778EAE] justify-center items-center rounded-2xl  w-[3.75rem] h-[3.75rem] `}
                  >
                    <img
                      src='http://source.unsplash.com/100x100/?boy'
                      className='cursor-pointer rounded-2xl'
                    />
                  </div>
                  <p className={`truncate w-40 pl-3 `}>
                    {contact?.user?.email}
                  </p>
                </div>

                <div className='pr-4 flex '>
                  <span
                    onClick={() =>
                      acceptOrRejectContactRequestsHandler(
                        contact?._id,
                        'declined',
                        contact?.user?._id
                      )
                    }
                    className='bg-[#778EAE] w-[3.75rem] cursor-pointer h-[1.875rem] rounded-lg text-center items-center justify-center flex text-sm mr-2 '
                  >
                    Reject
                  </span>
                  <span
                    onClick={() =>
                      acceptOrRejectContactRequestsHandler(
                        contact?._id,
                        'accepted',
                        contact?.user?._id
                      )
                    }
                    className='bg-[#6CE459] w-[3.75rem] cursor-pointer h-[1.875rem] rounded-lg justify-center flex items-center text-sm '
                  >
                    Confirm
                  </span>
                </div>
              </div>
            ))}
          </>
        ) : (
          <></>
        )}

        {/* Groups */}
        {showRequest === 'groups' || showRequest === '' ? (
          <>
            <h1 className='text-md py-3'>
              Group requests
              <span className='text-gray-400'>
                {' '}
                / {groupRequests?.length} new{' '}
              </span>
            </h1>
            {groupRequests?.map((group) => {
              let invitedUsers = group?.invitedUsers
              let groupMembers = invitedUsers?.filter(
                (member) => member.status === 'accepted'
              )

              return (
                <div
                  key={group._id}
                  className={`relative rounded-2xl h-[3.75rem] overflow-hidden   items-center w-full !px-0  py-6  flex justify-between mb-3 `}
                >
                  <span
                    style={{
                      backgroundColor: group.color,
                    }}
                    className='absolute h-full opacity-50 w-full'
                  ></span>
                  <div
                    style={{
                      backgroundColor: group.color,
                    }}
                    className={`flex absolute  justify-center items-center rounded-2xl  w-[3.75rem] h-[3.75rem] `}
                  >
                    <GroupIcon size={32} />
                  </div>

                  <div className='mr-6  absolute pl-20 '>
                    <div className={`font-bold absolute truncate w-[80%]`}>
                      {group.name}
                    </div>
                    <div className={`text-sm  pt-5`}>
                      Participants: {groupMembers?.length}{' '}
                    </div>
                  </div>

                  <div className='pr-4 flex right-0 absolute'>
                    <span
                      onClick={() =>
                        acceptOrRejectGroupRequestsHandler(
                          group._id,
                          'declined'
                        )
                      }
                      className=' w-[3.75rem] bg-slate-50 bg-opacity-20 cursor-pointer h-[1.875rem] rounded-md text-center items-center justify-center flex text-sm mr-2 '
                    >
                      Reject
                    </span>
                    <span
                      onClick={() =>
                        acceptOrRejectGroupRequestsHandler(
                          group._id,
                          'accepted'
                        )
                      }
                      style={{
                        backgroundColor: group.color,
                      }}
                      className=' w-[3.75rem]   cursor-pointer h-[1.875rem] rounded-md justify-center flex items-center text-sm '
                    >
                      Confirm
                    </span>
                  </div>
                </div>
              )
            })}
          </>
        ) : (
          <></>
        )}
        {/* Events */}
        {showRequest === 'events' || showRequest === '' ? (
          <div className='mb-44 '>
            <h1 className='text-md py-3'>
              Event requests
              <span className='text-gray-400'>
                {' '}
                / {eventRequests?.length} new{' '}
              </span>
            </h1>
            {eventRequests?.map((event) => (
              <div
                key={event._id}
                className={` w-full h-[4.5rem] text-sm  relative mb-3 `}
              >
                {/* Event duration line */}
                <span className='w-full grid grid-cols-6  absolute rounded-tl-md rounded-tr-md overflow-hidden '>
                  <div
                    style={{
                      opacity: 100,
                      backgroundColor: event?.group?.color,
                      gridColumnStart: 1,
                      gridColumnEnd: 2,
                    }}
                    className='w-full h-2'
                  ></div>
                </span>
                {/* Background line */}
                <span
                  style={{
                    backgroundColor: event?.group?.color,
                  }}
                  className='w-full h-2 top-0 absolute opacity-40  rounded-tl-md rounded-tr-md   '
                ></span>
                {/* Event background color */}
                <div
                  style={{ backgroundColor: event?.group?.color }}
                  className='w-full h-[4.5rem] opacity-50 absolute rounded-md'
                ></div>
                {/* Content */}
                <div
                  className={`flex relative z-50 items-center  w-full h-full overflow-hidden`}
                >
                  <ReactSVGIcon name={event?.icon?.name} />

                  <div
                    className={` flex flex-col  justify-center px-1 pt-1   `}
                  >
                    <p className=' text-left font-light truncate  w-44'>
                      {event?.title}
                    </p>
                    <p className=' text-left font-light truncate  w-44 '>
                      {event?.description}
                    </p>
                    <div className='flex font-bold sm:font-semibold gap-1 truncate'>
                      <p>{moment(event.startTime).format(' HH:mm A')} </p> -
                      <p> {moment(event.endTime).format(' HH:mm A')} </p>
                    </div>
                    {/*  <div className='flex font-bold sm:font-semibold gap-1 truncate'>
                      <p className=' text-left font-light truncate  w-32 '>
                        From: {event?.owner?.email}
                      </p>
                    </div> */}
                  </div>
                  <div className='pr-4 flex right-0 absolute'>
                    <span
                      onClick={() =>
                        acceptOrRejectEventRequestsHandler(
                          event._id,
                          'declined'
                        )
                      }
                      className=' w-[3.75rem] bg-slate-50 bg-opacity-20 cursor-pointer h-[1.875rem] rounded-md text-center items-center justify-center flex text-sm mr-2 '
                    >
                      Reject
                    </span>
                    <span
                      onClick={() =>
                        acceptOrRejectEventRequestsHandler(
                          event._id,
                          'accepted'
                        )
                      }
                      style={{
                        backgroundColor: event?.group?.color,
                      }}
                      className=' w-[3.75rem]   cursor-pointer h-[1.875rem] rounded-md justify-center flex items-center text-sm '
                    >
                      Confirm
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default Connections
