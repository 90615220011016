/* eslint-disable no-unused-vars */
import { Switch } from '@headlessui/react'

import { CheckIcon, ChevronLeftIcon } from 'components/Icons'
import { setEventRepeat, setEventRepeatIsOpen } from 'features'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import uuid from 'react-uuid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Repeat = () => {
  const dispatch = useDispatch()
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )
  const { eventRepeat } = useSelector((state) => state.event)

  const [showCustom, setShowCustom] = useState(false)
  const [showRepeatUntil, setShowRepeatUntil] = useState(false)
  const [selectedCustomPeriod, setSelectedCustomPeriod] = useState('Weekly')

  const [selectedWeekDay, setSelectedWeekDay] = useState('')

  const repeats = [
    { text: 'Never', value: 'never' },
    { text: 'Every Day', value: 'daily' },
    { text: 'Every Other Day', value: 'every_other_day' },
    { text: 'Every Week', value: 'weekly' },
    { text: 'Every Other Week', value: 'every_other_week' },
    { text: 'Every Month', value: 'monthly' },
    { text: 'Every Other Month', value: 'every_other_month' },
    { text: 'Every Year', value: 'yearly' },
  ]

  const buttons = ['Weekly', 'Monthly', 'Annual']
  const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

  return (
    <div className={`h-screen p-3 gap-3 flex flex-col ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='  pb-6 font-bold text-sm flex justify-between items-center relative'>
        <ChevronLeftIcon
          onClick={() => {
            dispatch(setEventRepeatIsOpen(false))
          }}
          size={30}
          className={'cursor-pointer'}
        />
        <p className={`text-sm  `}>Repeat Event</p>

        <ChevronLeftIcon size={30} className={'cursor-pointer invisible'} />
      </div>

      {/* Content*/}
      <div
        className={`w-full rounded-xl    divide-y-[1px] px-2 divide-DivideLine divide-opacity-20 ${
          nightMode
            ? 'bg-DMDarkBlueForeground'
            : 'bg-GrayMediumBGColor bg-opacity-20'
        }`}
      >
        {repeats.map((repeat) => (
          <div
            key={uuid()}
            onClick={() => {
              dispatch(setEventRepeat(repeat))
              dispatch(setEventRepeatIsOpen(false))
              setShowCustom(false)
            }}
            className={`flex justify-between group items-center h-12   cursor-pointer ${
              eventRepeat.text === repeat.text && !showCustom
                ? ` ${nightMode ? 'text-white' : 'text-black'}`
                : ` ${
                    nightMode
                      ? 'text-DM2ndLighterTextIcon'
                      : 'text-DarkText text-opacity-50'
                  }`
            }`}
          >
            <p className={`group-hover:text-BlueDarkColorMain `}>
              {' '}
              {repeat.text}
            </p>

            <CheckIcon
              size={22}
              secondaryColor={true}
              className={`group-hover:!fill-BlueDarkColorMain mr-2 ${
                eventRepeat.text === repeat.text && !showCustom
                  ? `!fill-BlueDarkColorMain`
                  : ``
              }`}
            />
          </div>
        ))}
      </div>
      {/* Custom Toggle */}
      <div
        className={`h-11 rounded-xl items-center px-2 flex justify-between ${
          nightMode
            ? 'bg-DMDarkBlueForeground'
            : 'bg-GrayMediumBGColor bg-opacity-20'
        }`}
      >
        <p
          className={`${
            showCustom
              ? ` ${nightMode ? 'text-white' : 'text-black'}`
              : ` ${
                  nightMode
                    ? 'text-DM2ndLighterTextIcon'
                    : 'text-DarkText text-opacity-50'
                }`
          }`}
        >
          Custom
        </p>
        <Switch
          checked={false}
          onChange={() => setShowCustom(!showCustom)}
          className={classNames(
            'relative inline-flex h-6 w-11 mr-1 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
          )}
          style={{
            backgroundColor: showCustom ? '#75CDE9' : '#778EAE',
          }}
        >
          <span className='sr-only'>Use setting</span>
          <span
            aria-hidden='true'
            className={classNames(
              showCustom ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      </div>

      {/* Custom Button */}
      {showCustom && (
        <div className='flex justify-between   gap-2'>
          {buttons.map((item) => (
            <div
              onClick={() => {
                setSelectedCustomPeriod(item)
              }}
              className={`w-full cursor-pointer h-9 rounded-xl text-center items-center justify-center flex ${
                selectedCustomPeriod === item
                  ? 'bg-BlueDarkColorMain'
                  : nightMode
                  ? 'bg-DMDarkBlueForeground'
                  : 'bg-GrayMediumBGColor bg-opacity-20'
              } `}
            >
              <p
                className={`${
                  selectedCustomPeriod === item
                    ? 'text-white'
                    : nightMode
                    ? 'text-DM2ndLighterTextIcon'
                    : 'text-DarkText text-opacity-50'
                }`}
              >
                {item}
              </p>
            </div>
          ))}
        </div>
      )}
      {/* Weekly Content */}
      {selectedCustomPeriod === 'Weekly' && showCustom && (
        <div
          className={`h-14 rounded-xl items-center px-7 flex justify-between ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          }`}
        >
          {weekDays?.map((day) => (
            <p
              onClick={() => setSelectedWeekDay(day)}
              className={` h-8 w-8 rounded-lg text-center flex justify-center items-center cursor-pointer hover:text-white hover:bg-BlueDarkColorMain ${
                selectedWeekDay === day
                  ? 'bg-BlueDarkColorMain text-white'
                  : nightMode
                  ? 'bg-DMDarkBlueForeground '
                  : 'bg-GrayMediumBGColor bg-opacity-20'
              }`}
            >
              {day}
            </p>
          ))}
        </div>
      )}
      {/* Monthly Content */}
      {selectedCustomPeriod === 'Monthly' && showCustom && (
        <div
          className={`h-36 rounded-xl items-center px-7 flex justify-between ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          } `}
        ></div>
      )}
      {/* Annual Content */}
      {selectedCustomPeriod === 'Annual' && showCustom && (
        <div
          className={`h-60 rounded-xl items-center px-7 flex justify-between ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          } `}
        ></div>
      )}

      {/*   Repeat Until Toggle */}
      <div
        className={`h-11 rounded-xl items-center px-2 flex justify-between ${
          nightMode
            ? 'bg-DMDarkBlueForeground'
            : 'bg-GrayMediumBGColor bg-opacity-20'
        }`}
      >
        <p
          className={`${
            showRepeatUntil
              ? ` ${nightMode ? 'text-white' : 'text-black'}`
              : ` ${
                  nightMode
                    ? 'text-DM2ndLighterTextIcon'
                    : 'text-DarkText text-opacity-50'
                }`
          }`}
        >
          Repeat Until
        </p>
        <Switch
          checked={false}
          onChange={() => setShowRepeatUntil(!showRepeatUntil)}
          className={classNames(
            'relative inline-flex h-6 w-11 mr-1 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
          )}
          style={{
            backgroundColor: showRepeatUntil ? '#75CDE9' : '#778EAE',
          }}
        >
          <span className='sr-only'>Use setting</span>
          <span
            aria-hidden='true'
            className={classNames(
              showRepeatUntil ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      </div>
    </div>
  )
}

export default Repeat
