import { useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import { userId } from 'utils'

const useSocket = () => {
  const [socket, setSocket] = useState(null)
  const [user, setUser] = useState('')

  useEffect(() => {
    setUser(userId())

    const socketConnection = io(process.env.REACT_APP_SOCKET_URL)

    socketConnection.on('connect', () => {
      console.log('Socket connected')
      socketConnection.emit('addUser', user)
    })

    socketConnection.on('getUsers', (users) => {
      console.log('Online users', users)
    })

    socketConnection.on('disconnect', () => {
      console.log('Socket disconnected')
      // You can handle reconnection logic here if needed
    })

    socketConnection.on('connect_error', (error) => {
      console.error('Socket connection error:', error)
      // You can handle connection errors here
    })

    setSocket(socketConnection)

    // Cleanup function to close the socket connection when the component unmounts
    return () => {
      socketConnection.close()
    }
  }, [user])

  return { socket }
}

export default useSocket
