import moment from 'moment'
import { useHeader } from 'hooks'
import {
  eventEndPlacement,
  eventHoursCounter,
  eventStartPlacement,
} from 'utils'

const useCalendarEvents = (event, week) => {
  const eventHours = eventHoursCounter(event.endTime, event.startTime)

  const eventEdgePosition =
    eventEndPlacement(week, event, moment) === 2 ||
    eventStartPlacement(week, event, moment) === 7
  const longEventSecondFromEdgePosition =
    (eventEndPlacement(week, event, moment) === 3 && eventHours >= 48) ||
    (eventStartPlacement(week, event, moment) === 6 && eventHours >= 48)

  const twoDaysEventEdgePosition =
    eventEndPlacement(week, event, moment) === 2 ||
    eventStartPlacement(week, event, moment) === 7

  const { eventHeightLocal } = useHeader()

  return {
    eventEndPlacement,
    eventStartPlacement,
    eventHours,
    eventEdgePosition,
    longEventSecondFromEdgePosition,
    twoDaysEventEdgePosition,
    eventHeightLocal,
  }
}

export default useCalendarEvents
