/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const ClearIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      viewBox='0 0 14 14'
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M6.97266 13.9453C10.7871 13.9453 13.9453 10.7803 13.9453 6.97266C13.9453 3.1582 10.7803 0 6.96582 0C3.1582 0 0 3.1582 0 6.97266C0 10.7803 3.16504 13.9453 6.97266 13.9453ZM4.67578 9.84375C4.36133 9.84375 4.11523 9.59082 4.11523 9.27637C4.11523 9.12598 4.16992 8.98242 4.2793 8.87988L6.17285 6.97949L4.2793 5.08594C4.16992 4.97656 4.11523 4.83984 4.11523 4.68945C4.11523 4.36816 4.36133 4.12891 4.67578 4.12891C4.83301 4.12891 4.95605 4.18359 5.06543 4.28613L6.97266 6.18652L8.89355 4.2793C9.00977 4.16309 9.13281 4.11523 9.2832 4.11523C9.59766 4.11523 9.85059 4.36133 9.85059 4.67578C9.85059 4.83301 9.80273 4.95605 9.67969 5.0791L7.7793 6.97949L9.67285 8.87305C9.78906 8.97559 9.84375 9.11914 9.84375 9.27637C9.84375 9.59082 9.59082 9.84375 9.26953 9.84375C9.1123 9.84375 8.96875 9.78906 8.86621 9.67969L6.97266 7.7793L5.08594 9.67969C4.97656 9.78906 4.83301 9.84375 4.67578 9.84375Z' />
    </svg>
  )
}

export default ClearIcon
