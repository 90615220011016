/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { LogoToText, SvgIcon } from 'components'
import useLeftBar from './useLeftBar'
import { useAvatar, useUser, useWindowDimensions } from 'hooks'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { formatData, generateOneMonthData, userId } from 'utils'
import {
  getNotifications,
  setInnerEventDescIsOpen,
  setSingleEvent,
  setUserProfileIsOpen,
} from 'features'
import { DailyEvents, LongEvents } from 'components/PerDayEvents/Events'
import { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import axios from 'axios'
import {
  CalendarMonthIcon,
  ChevronLeftIcon,
  ContactsIcon,
  GroupIcon,
  ArrowLeftIcon,
  SearchIcon,
  SyncAltIcon,
  ArrowRightIcon,
} from 'components/Icons'
import { motion } from 'framer-motion'

const LeftBar = ({ isLeftBarOpened }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }
  const {
    nightMode,
    openGroupsHandler,
    openLeftBarHandler,
    currentDate,
    moment,
    openConnectionsHandler,
    openContactsHandler,
    defaultTextIconColor,
    openSearchHandler,
    openUserProfileHandler,
  } = useLeftBar(isLeftBarOpened)

  const { user } = useUser()
  const { avatarOrEmail } = useAvatar()
  const dispatch = useDispatch()
  const { fetchedPerDayEvents } = useSelector((state) => state.event)
  const { fetchedNotifications } = useSelector((state) => state.connection)

  const { height, width } = useWindowDimensions()

  const filteredNotifications = fetchedNotifications?.filter(
    (notification) => notification.isRead === true
  )

  /* Calendar */
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [monthNumber, setMonthNumber] = useState(7)
  const [month, setMonth] = useState(
    generateOneMonthData(currentYear, monthNumber)
  )

  useEffect(() => {
    setMonth(generateOneMonthData(currentYear, monthNumber))
  }, [currentYear, monthNumber])

  const prevMonthHandler = () => {
    setMonthNumber(monthNumber - 1)
    if (monthNumber === 0) {
      setMonthNumber(11)
      setCurrentYear(currentYear - 1)
    }
  }
  const nextMonthHandler = () => {
    setMonthNumber(monthNumber + 1)
    if (monthNumber === 11) {
      setMonthNumber(0)
      setCurrentYear(currentYear + 1)
    }
  }

  /* Calendar  END */

  const { socketData, cancelSocketData } = useSelector(
    (state) => state.connection
  )

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  //get Notifications

  useEffect(() => {
    dispatch(getNotifications())
  }, [socketData, dispatch, cancelSocketData])

  const weekDays = ['SUN', 'MON', 'THU', 'WED', 'THU', 'FRI', 'SAT']

  return (
    <div
      className={`hidden md:flex md:min-h-screen z-50 fixed ${defaultTextIconColor} ${
        nightMode
          ? 'shadow-[0_10px_40px_-15px_rgba(0,0,0,1)]'
          : 'shadow-[0_10px_40px_-15px_rgba(128,128,128,1)]'
      } transition-all duration-500    flex-col items-center ${
        isLeftBarOpened ? 'w-[16.875rem] ' : 'w-[3.5em]'
      }  h-screen ${nightMode ? 'bg-DMDarkBlueForeground' : 'bg-lightDark'}`}
    >
      {/* Unum Logo */}
      <div
        onClick={openLeftBarHandler}
        className={` cursor-pointer transition-all bg-BlueDarkColorMain hover:bg-BlueLightColor   h-[3.4rem] flex justify-center items-center ${
          isLeftBarOpened
            ? ' w-[16.875rem] duration-500'
            : 'w-[3.5em] duration-500'
        } `}
      >
        {!isLeftBarOpened && (
          <div className='h-[3.75rem] items-center flex justify-center'>
            <img src='/images/unum.svg' alt='unum' />
          </div>
        )}
        {isLeftBarOpened && <LogoToText isLeftBarOpened={isLeftBarOpened} />}
      </div>

      {isLeftBarOpened ? (
        <div
          className={`flex  h-full w-[15rem]  flex-col  items-start justify-start `}
        >
          <div className='py-1  w-full'>
            <motion.div
              initial='hidden'
              animate='visible'
              exit='hidden'
              variants={containerVariants}
              transition={{ duration: 0.5 }}
            >
              {/* Calendar */}
              <div
                className={`animate-fadeInLeft ${
                  height < 931 ? 'pb-4' : 'pb-10'
                }`}
              >
                <div>
                  {/* Date and Arrows */}
                  <div className='flex w-full justify-center items-center '>
                    <ArrowLeftIcon
                      size={14}
                      onClick={prevMonthHandler}
                      className={'mr-[20px] cursor-pointer'}
                      secondaryColor={true}
                    />
                    <div className='flex pt-2 text-center truncate w-32'>
                      <h2 className='flex-auto text-sm font-semibold text center pb-5 pt-3'>
                        {month.name}{' '}
                        <span className='text-gray-400 '>/ {month.year}</span>
                      </h2>
                    </div>

                    <ArrowRightIcon
                      size={14}
                      onClick={nextMonthHandler}
                      className={'ml-[22px] cursor-pointer'}
                      secondaryColor={true}
                    />
                  </div>

                  {/* Week days */}
                  <div className='grid grid-cols-7  opacity-80 text-center items-center w-full my-2 text-[11px]'>
                    {weekDays.map((item) => (
                      <p>{item}</p>
                    ))}
                  </div>
                  {/* Calendar */}
                  <div className=' grid grid-cols-7 h-[10rem] text-sm'>
                    {month?.days.map((day, dayIdx) => (
                      <div
                        key={day.date}
                        className={classNames(
                          ' focus:z-10 relative sm:px-1.5  items-center justify-center flex',
                          day.isCurrentMonth ? 'text-gray-300' : 'text-gray-500'
                        )}
                      >
                        <span
                          type='button'
                          className={classNames(
                            'mx-auto flex  items-center justify-center  rounded-full relative'
                          )}
                        >
                          <time
                            dateTime={day.date}
                            className={classNames(
                              'mx-auto flex h-[1.95rem] w-[1.95rem] sm:h-5 sm:w-5 items-center   justify-center text-xs rounded-md ',
                              day.isToday && 'text-BlueDarkColorMain  '
                            )}
                          >
                            {day.date.split('-').pop().replace(/^0/, '')}
                          </time>
                          {false && (
                            <span className='absolute mt-8 rounded-xl w-4 h-1 bg-BlueDarkColorMain'></span>
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Date */}
              <div className='flex pb-3 animate-fadeInLeft'>
                <CalendarMonthIcon
                  size={30}
                  className={
                    'mb-3 mr-2 !fill-BlueDarkColorMain  hover:!fill-BlueLightColor '
                  }
                />

                <div className=''>
                  <p className='text-sm truncate mb-[-5px]'>
                    {moment(currentDate).format('dddd DD MMM').toUpperCase()}
                  </p>
                  <div className='flex gap-2'>
                    <p className='font-bold text-lg'>TODAY</p>{' '}
                    <span className='opacity-50 pt-1'>
                      {fetchedPerDayEvents?.length} event
                    </span>
                  </div>
                </div>
              </div>
              {/* Per day events list */}
              <div
                className={`h-40 mb-3 overflow-y-scroll animate-fadeInLeft ${
                  height < 931 ? 'hidden' : ''
                }`}
              >
                {fetchedPerDayEvents.length !== 0 ? (
                  fetchedPerDayEvents.map(
                    (event, index) =>
                      event.group.isVisible && (
                        <div
                          key={event._id}
                          onClick={() => {
                            /*  setSelectedElementIndex(index) */
                            dispatch(setInnerEventDescIsOpen(true))
                            dispatch(setSingleEvent(event))
                          }}
                          className={`overflow-hidden  mb-1 sm:mb-2 relative justify-end flex text-[0.6rem] sm:text-xs`}
                        >
                          {formatData(event.endTime) ===
                            formatData(currentDate) &&
                          formatData(event.startTime) ===
                            formatData(currentDate) ? (
                            <DailyEvents event={event} moment={moment} />
                          ) : (
                            <LongEvents
                              event={event}
                              moment={moment}
                              selectedDayOnCalendar={formatData(currentDate)}
                            />
                          )}
                        </div>
                      )
                  )
                ) : (
                  <p className='pt-24 text-center text-lg'>No events :(</p>
                )}
              </div>
            </motion.div>
          </div>

          {/* Buttons */}
          <div
            className={`flex flex-col items-center gap-6  w-full animate-fadeInLeft  `}
          >
            {/* Divider */}
            <span
              className={` h-[1px]   bg-opacity-30 w-[15rem]  ${
                nightMode ? 'bg-DivideLine' : 'bg-DM2ndLighterTextIcon'
              } `}
            ></span>
            <div className={`grid grid-cols-2 gap-3 `}>
              {/* Connection button */}
              <div
                onClick={openConnectionsHandler}
                className={`flex relative h-[5.5rem] group w-[7rem] rounded-xl gap-1  text-center  items-center justify-center flex-col cursor-pointer ${
                  nightMode
                    ? 'bg-DMLightBlueForeground2 hover:bg-BlueDarkColorMain'
                    : 'bg-LMDarkestBackground bg-opacity-30'
                }`}
              >
                {/* notification */}
                <span
                  className={`absolute bg-red-600 text-white px-[0.18rem] h-[0.85rem] right-0 rounded-xl text-[0.6rem]  text-center mr-[1.5rem] mt-[-2.3rem]  `}
                >
                  {fetchedNotifications.length}
                </span>

                <div className='items-center flex justify-center flex-col gap-1'>
                  <SyncAltIcon
                    className={`!fill-BlueDarkColorMain group-hover:!fill-BlueLightColor`}
                  />

                  <p className='text-xs'>Connections</p>
                </div>
              </div>
              {/* Contacts button */}
              <div
                onClick={openContactsHandler}
                className={`flex  h-[5.5rem] w-[7rem] group rounded-xl gap-1 text-center items-center justify-center flex-col cursor-pointer ${
                  nightMode
                    ? 'bg-DMLightBlueForeground2 hover:bg-BlueDarkColorMain'
                    : 'bg-LMDarkestBackground bg-opacity-30'
                } `}
              >
                <div className='items-center flex justify-center flex-col gap-1'>
                  <ContactsIcon
                    className={`!fill-BlueDarkColorMain group-hover:!fill-BlueLightColor`}
                  />
                  <p className='text-xs'>Contacts</p>
                </div>
              </div>
              {/* Groups button */}
              <div
                onClick={openGroupsHandler}
                className={`flex  h-[5.5rem] group w-[7rem] rounded-xl gap-1  text-center  items-center justify-center flex-col cursor-pointer ${
                  nightMode
                    ? 'bg-DMLightBlueForeground2 hover:bg-BlueDarkColorMain'
                    : 'bg-LMDarkestBackground bg-opacity-30'
                } `}
              >
                <div className='items-center flex justify-center flex-col gap-1'>
                  <GroupIcon
                    className={`!fill-BlueDarkColorMain group-hover:!fill-BlueLightColor`}
                  />
                  <p className='text-xs'>Groups</p>
                </div>
              </div>
              {/* Search button */}
              <div
                onClick={openSearchHandler}
                className={`flex  h-[5.5rem] group w-[7rem] rounded-xl gap-1  text-center  items-center justify-center flex-col cursor-pointer ${
                  nightMode
                    ? 'bg-DMLightBlueForeground2 hover:bg-BlueDarkColorMain'
                    : 'bg-LMDarkestBackground bg-opacity-30'
                }  `}
              >
                <div className='items-center flex justify-center flex-col gap-1'>
                  <SearchIcon
                    className={`!fill-BlueDarkColorMain group-hover:!fill-BlueLightColor`}
                  />
                  <p className='text-xs'>Search</p>
                </div>
              </div>
            </div>
            {/* Divider */}
            <span
              className={` h-[1px]   bg-opacity-30 w-[15rem] ${
                nightMode ? 'bg-DivideLine' : 'bg-DM2ndLighterTextIcon'
              } `}
            ></span>
          </div>
          {/* User Avatar */}
          <div
            onClick={openUserProfileHandler}
            className={`flex absolute bottom-0 animate-fadeInLeft  pb-5  cursor-pointer items-center w-full`}
          >
            <div className='w-10 h-10  relative flex justify-center items-center rounded-full '>
              <>
                {user?.avatar ? (
                  <img
                    alt='user'
                    src={`${process.env.REACT_APP_BASE_URL}/${user?.avatar}`}
                    className='rounded-full w-[40px] h-[40px] aspect-square object-cover  cursor-pointer'
                  />
                ) : (
                  <div className='w-[40px] flex items-center justify-center h-[40px] rounded-full bg-slate-400'>
                    <p className='text-lg'> {user?.email.slice(0, 1)}</p>
                  </div>
                )}
              </>
            </div>
            <div className={` text-sm pl-2 block`}>
              <p className={``}>{user?.email}</p>
              <p
                className={`opacity-30 hover:opacity-100 hover:text-BlueDarkColorMain`}
              >
                {user?.email}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`flex  h-full  flex-col animate-fadeInRight justify-between  `}
        >
          {/* Date */}
          <div className='items-center '>
            <div className={`pt-6 text-xs pl-3`}>
              <div
                className={`flex 
                 justify-center
             mr-2   uppercase`}
              >
                <p>{moment(currentDate).format('ddd')}</p>
              </div>
              <div className={`flex space-x-1  font-black pr-2`}>
                <p>{moment(currentDate).format('MMM')}</p>
                <p>{moment(currentDate).format('DD')}</p>
              </div>
            </div>
            <div className='flex flex-col gap-5   py-3 w-full'>
              <div
                className={`  items-center justify-center flex flex-col gap-2 mt-2  cursor-pointer ${
                  isLeftBarOpened ? 'py-2' : ''
                }`}
              >
                <CalendarMonthIcon
                  className={
                    '!fill-BlueDarkColorMain  hover:!fill-BlueLightColor  '
                  }
                />
                <div className='h-2 w-2 bg-BlueDarkColorMain rounded-full'></div>
              </div>
            </div>
          </div>
          {/* Buttons */}
          <div className='flex flex-col  items-center gap-8  w-full'>
            {/* Divider */}
            <span
              className={` h-[1px] w-[2.5rem]  bg-opacity-30  ${
                nightMode ? 'bg-DivideLine' : 'bg-DM2ndLighterTextIcon'
              } `}
            ></span>

            <div className={`flex flex-col gap-8 `}>
              {/* Connection button */}
              <div
                onClick={openConnectionsHandler}
                className={`flex relative  cursor-pointer `}
              >
                {/* Notification */}
                <span
                  className={`absolute bg-red-600 text-white px-[0.18rem] h-[0.85rem] right-0 rounded-xl text-[0.6rem]  text-center mr-[-0.7rem] mt-[-0.3rem] `}
                >
                  {fetchedNotifications.length}
                </span>

                <SyncAltIcon className={' hover:!fill-BlueLightColor '} />
              </div>
              {/* Contacts button */}
              <ContactsIcon
                onClick={openContactsHandler}
                className={`cursor-pointer  hover:!fill-BlueLightColor `}
              />

              {/* Groups button */}
              <GroupIcon
                onClick={openGroupsHandler}
                className={`cursor-pointer  hover:!fill-BlueLightColor `}
              />

              {/* Search button */}
              <SearchIcon
                onClick={openSearchHandler}
                className={`cursor-pointer  hover:!fill-BlueLightColor `}
              />
            </div>

            {/* Divider */}
            <span
              className={` h-[1px]   bg-opacity-30 w-[2.5rem]  ${
                nightMode ? 'bg-DivideLine' : 'bg-DM2ndLighterTextIcon'
              } `}
            ></span>

            {/* User Avatar */}
            <div
              onClick={openUserProfileHandler}
              className={`flex  pb-5  cursor-pointer items-center  `}
            >
              <div className='w-10 h-10  relative flex flex-shrink-0  justify-center items-center rounded-full '>
                <div className='w-[40px] h-[40px] overflow-hidden object-cover flex items-center justify-center rounded-full'>
                  {avatarOrEmail}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default LeftBar
