/* eslint-disable no-unused-vars */
import moment from 'moment/moment'
import { useCalendarEvents } from 'hooks'

import { dynamicStyles } from 'utils'
import {
  LongEventsM,
  MinimalEventsM,
  OneDayEventsM,
  TwoDayEventsM,
} from './MonthEvents'
import MinimalEventsW from './WeekEvents/MinimalEventsW'
import OneDayEventsW from './WeekEvents/OneDayEventsW'
import TwoDayEventsW from './WeekEvents/TwoDayEventsW'
import LongEventsW from './WeekEvents/LongEventsW'

const CalendarEvents = ({ event, week, openEventHandler }) => {
  const {
    eventEndPlacement,
    eventStartPlacement,
    eventHours,
    eventEdgePosition,
    longEventSecondFromEdgePosition,
    eventHeightLocal,
    switchView,
  } = useCalendarEvents(event, week)

  const monthView = localStorage.getItem('calendarView')
  const monthViewLocal = JSON.parse(monthView)

  return (
    <>
      <div
        onClick={openEventHandler}
        /*   style={{
          ...dynamicStyles(event, eventStartPlacement, eventEndPlacement, week),
        }} */
        className={`rounded-lg cursor-pointer sm:my-1 my-0.5  ${
          eventHeightLocal === 'min' && monthViewLocal === 'week'
            ? 'h-[3.125rem]'
            : eventHeightLocal === 'med' && monthViewLocal === 'week'
            ? 'h-[4rem]'
            : eventHeightLocal === 'max' && monthViewLocal === 'week'
            ? 'h-[4.5rem]'
            : 'h-[1.875rem]'
        } ${
          eventHeightLocal === 'min' && monthViewLocal === 'week'
            ? 'text-[0.6rem] sm:text-xs'
            : eventHeightLocal === 'med' && monthViewLocal === 'week'
            ? 'text-xs sm:text-sm'
            : eventHeightLocal === 'max' && monthViewLocal === 'week'
            ? 'text-sm sm:text-base'
            : 'text-[0.6rem] sm:text-xs'
        } relative w-full `}
      >
        {/* Event less than 2 hours */}
        {eventHours <= 2 &&
          (monthViewLocal === 'week' ? (
            <MinimalEventsW
              event={event}
              week={week}
              moment={moment}
              eventHours={eventHours}
              eventHeightLocal={eventHeightLocal}
              set
            />
          ) : (
            <MinimalEventsM
              event={event}
              week={week}
              moment={moment}
              eventHours={eventHours}
              eventHeightLocal={eventHeightLocal}
              set
            />
          ))}

        {/* One day event */}
        {eventHours > 2 &&
          eventHours < 24 &&
          (monthViewLocal === 'week' ? (
            <OneDayEventsW
              event={event}
              week={week}
              moment={moment}
              eventHours={eventHours}
              eventHeightLocal={eventHeightLocal}
            />
          ) : (
            <OneDayEventsM
              event={event}
              week={week}
              moment={moment}
              eventHours={eventHours}
              eventHeightLocal={eventHeightLocal}
            />
          ))}

        {/* Two day event */}
        {eventHours >= 24 &&
          eventHours < 48 &&
          eventEndPlacement(week, event, moment) !== 1 &&
          eventStartPlacement(week, event, moment) !== 8 &&
          (monthViewLocal === 'week' ? (
            <TwoDayEventsW
              event={event}
              week={week}
              moment={moment}
              eventHours={eventHours}
              eventEdgePosition={eventEdgePosition}
              eventHeightLocal={eventHeightLocal}
            />
          ) : (
            <TwoDayEventsM
              event={event}
              week={week}
              moment={moment}
              eventHours={eventHours}
              eventEdgePosition={eventEdgePosition}
              eventHeightLocal={eventHeightLocal}
            />
          ))}

        {/* Event more than 48 hours */}
        {eventHours >= 48 &&
          (monthViewLocal === 'week' ? (
            <LongEventsW
              event={event}
              week={week}
              moment={moment}
              eventEdgePosition={eventEdgePosition}
              longEventSecondFromEdgePosition={longEventSecondFromEdgePosition}
              eventHours={eventHours}
              eventHeightLocal={eventHeightLocal}
            />
          ) : (
            <LongEventsM
              event={event}
              week={week}
              moment={moment}
              eventEdgePosition={eventEdgePosition}
              longEventSecondFromEdgePosition={longEventSecondFromEdgePosition}
              eventHours={eventHours}
              eventHeightLocal={eventHeightLocal}
            />
          ))}
      </div>
    </>
  )
}

export default CalendarEvents
