/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const AddContactIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 21 21'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <g clip-path='url(#clip0_15151_4974)'>
        <path d='M16.7 11.4998C16.4747 11.4998 16.2916 11.4269 16.1508 11.2811C16.0099 11.1353 15.9395 10.9582 15.9395 10.7498V8.9165H14.0807C13.8553 8.9165 13.6723 8.84359 13.5315 8.69775C13.3906 8.55192 13.3202 8.37484 13.3202 8.1665C13.3202 7.94428 13.3906 7.76373 13.5315 7.62484C13.6723 7.48595 13.8553 7.4165 14.0807 7.4165H15.9395V5.58317C15.9395 5.36095 16.0099 5.18039 16.1508 5.0415C16.2916 4.90261 16.4747 4.83317 16.7 4.83317C16.9112 4.83317 17.0908 4.90261 17.2386 5.0415C17.3865 5.18039 17.4604 5.36095 17.4604 5.58317V7.4165H19.3193C19.5305 7.4165 19.7101 7.48595 19.8579 7.62484C20.0058 7.76373 20.0797 7.94428 20.0797 8.1665C20.0797 8.37484 20.0058 8.55192 19.8579 8.69775C19.7101 8.84359 19.5305 8.9165 19.3193 8.9165H17.4604V10.7498C17.4604 10.9582 17.3865 11.1353 17.2386 11.2811C17.0908 11.4269 16.9112 11.4998 16.7 11.4998ZM7.93373 10.2082C6.83531 10.2082 5.94108 9.86789 5.25104 9.18734C4.56101 8.50678 4.21599 7.63178 4.21599 6.56234C4.21599 5.49289 4.56101 4.61789 5.25104 3.93734C5.94108 3.25678 6.83531 2.9165 7.93373 2.9165C9.01807 2.9165 9.90525 3.25678 10.5953 3.93734C11.2853 4.61789 11.6303 5.49289 11.6303 6.56234C11.6303 7.63178 11.2853 8.50678 10.5953 9.18734C9.90525 9.86789 9.01807 10.2082 7.93373 10.2082ZM1.6178 17.9582C1.33616 17.9582 1.10028 17.8644 0.910167 17.6769C0.720056 17.4894 0.625 17.2568 0.625 16.979V15.4373C0.625 14.854 0.779906 14.3262 1.08972 13.854C1.39953 13.3818 1.822 13.0207 2.35713 12.7707C3.44147 12.2984 4.41667 11.9651 5.28273 11.7707C6.14879 11.5762 7.03246 11.479 7.93373 11.479C8.835 11.479 9.71866 11.5762 10.5847 11.7707C11.4508 11.9651 12.4119 12.2984 13.4681 12.7707C14.0032 13.0207 14.4292 13.3783 14.7461 13.8436C15.0629 14.3089 15.2213 14.8401 15.2213 15.4373V16.979C15.2213 17.2568 15.1263 17.4894 14.9362 17.6769C14.7461 17.8644 14.5102 17.9582 14.2285 17.9582H1.6178Z' />
      </g>
      <defs>
        <clipPath id='clip0_15151_4974'>
          <rect
            width='20.2786'
            height='20'
            fill='white'
            transform='translate(0.223633 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AddContactIcon
