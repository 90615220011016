/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const ChevronRightIcon = ({
  size = 24,
  className,
  onClick,
  secondaryColor,
}) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      viewBox='0 0 50 50'
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M17.1 35.5q-.65-.75-.675-1.7-.025-.95.675-1.65l8.2-8.2-8.25-8.25q-.65-.65-.625-1.675.025-1.025.675-1.675.75-.75 1.675-.725.925.025 1.625.725l9.95 9.95q.35.35.525.775.175.425.175.875t-.175.875q-.175.425-.525.775l-9.9 9.9q-.7.7-1.65.675-.95-.025-1.7-.675Z' />
    </svg>
  )
}

export default ChevronRightIcon
