/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const VisibilityIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 21 21'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <g clip-path='url(#clip0_15151_123722)'>
        <path d='M10.1536 13.6248C11.1398 13.6248 11.9766 13.2811 12.6641 12.5936C13.3516 11.9061 13.6953 11.0693 13.6953 10.0832C13.6953 9.09706 13.3516 8.26025 12.6641 7.57275C11.9766 6.88525 11.1398 6.5415 10.1536 6.5415C9.16754 6.5415 8.33073 6.88525 7.64323 7.57275C6.95573 8.26025 6.61198 9.09706 6.61198 10.0832C6.61198 11.0693 6.95573 11.9061 7.64323 12.5936C8.33073 13.2811 9.16754 13.6248 10.1536 13.6248ZM10.1536 12.0832C9.59809 12.0832 9.12587 11.8887 8.73698 11.4998C8.34809 11.1109 8.15365 10.6387 8.15365 10.0832C8.15365 9.52761 8.34809 9.05539 8.73698 8.6665C9.12587 8.27762 9.59809 8.08317 10.1536 8.08317C10.7092 8.08317 11.1814 8.27762 11.5703 8.6665C11.9592 9.05539 12.1536 9.52761 12.1536 10.0832C12.1536 10.6387 11.9592 11.1109 11.5703 11.4998C11.1814 11.8887 10.7092 12.0832 10.1536 12.0832ZM10.1536 16.8123C8.15365 16.8123 6.3342 16.2637 4.69531 15.1665C3.05642 14.0693 1.77865 12.6318 0.861979 10.854C0.806424 10.7568 0.764757 10.6353 0.736979 10.4894C0.709201 10.3436 0.695312 10.2082 0.695312 10.0832C0.695312 9.94428 0.709201 9.80539 0.736979 9.6665C0.764757 9.52761 0.806424 9.40262 0.861979 9.2915C1.77865 7.52762 3.05642 6.09706 4.69531 4.99984C6.3342 3.90262 8.15365 3.354 10.1536 3.354C12.1536 3.354 13.9731 3.90262 15.612 4.99984C17.2509 6.09706 18.5286 7.52762 19.4453 9.2915C19.5009 9.40262 19.5425 9.52761 19.5703 9.6665C19.5981 9.80539 19.612 9.94428 19.612 10.0832C19.612 10.2082 19.5981 10.3436 19.5703 10.4894C19.5425 10.6353 19.5009 10.7568 19.4453 10.854C18.5286 12.6318 17.2509 14.0693 15.612 15.1665C13.9731 16.2637 12.1536 16.8123 10.1536 16.8123Z' />
      </g>
      <defs>
        <clipPath id='clip0_15151_123722'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0.15332 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default VisibilityIcon
