import { AddIcon, ChevronLeftIcon, SearchIcon } from 'components/Icons'
import { setEventLocationIsOpen } from 'features'
import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

const Location = () => {
  const dispatch = useDispatch()
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )
  return (
    <div className={`h-screen p-3 ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='  pb-6 font-bold text-sm flex justify-between items-center relative'>
        <ChevronLeftIcon
          onClick={() => {
            dispatch(setEventLocationIsOpen(false))
          }}
          size={30}
          className={'cursor-pointer'}
        />
        <p className={`text-sm  `}>Event Location</p>

        <AddIcon size={30} />
      </div>

      {/* Search input */}
      <div
        className={` rounded-xl items-center flex overflow-hidden ${
          nightMode
            ? 'bg-DMDarkBlueForeground'
            : 'bg-LM2ndLighterTextIcon bg-opacity-10'
        }  `}
      >
        <SearchIcon className={'mx-2'} secondaryColor={true} />
        <input
          className={`outline-none h-10 w-full bg-opacity-0 bg-[#3BB9E0] ${
            nightMode
              ? 'placeholder-DM2ndLighterTextIcon '
              : 'placeholder-LM2ndLighterTextIcon'
          } `}
          placeholder='Search location...'
          name='search'
          type='text'
        />
      </div>
      {/* Search Result */}
      <div
        className={` rounded-xl items-center h-full mt-4 flex overflow-y-scroll ${
          nightMode
            ? 'bg-DMDarkBlueForeground'
            : 'bg-LM2ndLighterTextIcon bg-opacity-10'
        }  `}
      ></div>
    </div>
  )
}

export default Location
