/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const ChevronDownIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M17.7504 8.54988C17.5004 8.33321 17.2171 8.22071 16.9004 8.21238C16.5837 8.20404 16.3087 8.31654 16.0754 8.54988L11.9754 12.6499L7.8504 8.52488C7.63374 8.30821 7.35457 8.20404 7.0129 8.21238C6.67124 8.22071 6.39207 8.33321 6.1754 8.54988C5.9254 8.79988 5.80457 9.07904 5.8129 9.38738C5.82124 9.69571 5.94207 9.96654 6.1754 10.1999L11.1504 15.1749C11.2671 15.2915 11.3962 15.379 11.5379 15.4374C11.6796 15.4957 11.8254 15.5249 11.9754 15.5249C12.1254 15.5249 12.2712 15.4957 12.4129 15.4374C12.5546 15.379 12.6837 15.2915 12.8004 15.1749L17.7504 10.2249C17.9837 9.99154 18.0962 9.71654 18.0879 9.39988C18.0796 9.08321 17.9671 8.79988 17.7504 8.54988Z' />
    </svg>
  )
}

export default ChevronDownIcon
