/* eslint-disable no-unused-vars */
import { AuthLayout, Input } from 'components'
import { FormLayout, UnumSection } from '..'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { recoveryEmailSchema } from 'schema'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { sendCodeToEmailAPI, setRecoveryEmail } from 'features/auth/authSlice'
import { useSelector } from 'react-redux'

const AddEmail = () => {
  const { recoveryEmail, emailCodeAPIStatus } = useSelector(
    (state) => state.auth
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()
  /* Use Form */
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(recoveryEmailSchema),
  })

  const onSubmit = (data) => {
    console.log(data)
    dispatch(setRecoveryEmail(data.email))
    dispatch(sendCodeToEmailAPI(data.email))
  }

  useEffect(() => {
    if (emailCodeAPIStatus === 'success') {
      navigate('/email-verify')
    }
  }, [emailCodeAPIStatus, navigate])

  return (
    <AuthLayout>
      <div className='flex flex-col md:flex-row justify-between items-center'>
        <UnumSection />

        <form
          encType='multipart/form-data'
          onSubmit={handleSubmit(onSubmit)}
          className='space-y-3 md:bg-DMDarkBlueBackground h-screen flex flex-col items-center justify-center md:w-[526px] !overflow-y-hidden p-7'
        >
          <p className='text-white text-lg w-[200px] text-center mb-16'>
            Add a recovery email in case you lose access to you phone number
          </p>

          <Input
            defaultValue={recoveryEmail}
            name='email'
            placeholder='Email for account recovery'
            register={register}
            error={errors.email?.message}
            dirtyFields={dirtyFields.email}
          />

          <button
            type='submit'
            className='flex w-[330px] mb-7  h-[44px] justify-center items-center rounded-xl bg-BlueDarkColorMain px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-BlueDarkColorMainHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            Proceed
          </button>

          <Link
            className='underline text-DM2ndLighterTextIcon text-sm'
            to='/sign-in'
          >
            Skip for now
          </Link>
        </form>
      </div>
    </AuthLayout>
  )
}

export default AddEmail
