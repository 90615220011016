/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Toggle } from 'components'
import { useGroups } from 'hooks'
import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { IoPeople } from 'react-icons/io5'
import { toast } from 'react-toastify'
import { getColorWithOpacity, hideGroupHandler, userId } from 'utils'
import { motion, AnimatePresence } from 'framer-motion'
import {
  fetchPerGroupEvents,
  setGroupDescriptionIsOpen,
  setSelectedGroup,
} from 'features'

const Group = ({ item, index }) => {
  const { groupVisibility, dispatch, getItemStyle } = useGroups()
  const [showGroup, setShowGroup] = useState(false)

  let myColorForSomeonesGroup = item?.invitedUsers
    ?.filter((member) => member?.user === userId())
    .map((member) => member?.color)[0]

  useEffect(() => {
    setShowGroup(item.isVisible)
  }, [item.isVisible])

  const openGroupDescriptionHandler = (group) => {
    dispatch(setGroupDescriptionIsOpen(true))
    dispatch(fetchPerGroupEvents(group._id))
    dispatch(setSelectedGroup(group))
  }

  return (
    <Draggable key={item._id} draggableId={item._id.toString()} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className='flex overflow-hidden'
        >
          <div
            className={`relative rounded-xl h-[3.75rem] overflow-hidden bg-gray-500 bg-opacity-70 hover:bg-opacity-90 items-center w-full !px-0  py-6  flex justify-between mb-3`}
            style={getItemStyle(provided.draggableProps.style)}
            onClick={openGroupDescriptionHandler.bind(null, item)}
          >
            <div
              style={{
                backgroundColor: !myColorForSomeonesGroup
                  ? item.color
                  : myColorForSomeonesGroup,
              }}
              className='flex absolute left-0 justify-center items-center rounded-xl  !w-[3.75rem] h-[3.75rem]'
            >
              <IoPeople color='#fff' size={25} />
            </div>
            <AnimatePresence>
              <motion.div
                className='rounded-xl flex justify-between items-center h-[3.75rem]'
                initial={{
                  width: '0%',
                }}
                animate={{
                  width: showGroup ? '100%' : '0%',
                  backgroundColor: showGroup
                    ? getColorWithOpacity(
                        !myColorForSomeonesGroup
                          ? item?.color
                          : myColorForSomeonesGroup,
                        0.5
                      )
                    : 'rgba(107, 114, 128, 0.5)',
                }}
                transition={{
                  duration: 0.3,
                }}
              >
                <p className='absolute left-[4.1875rem] text-white text-sm'>
                  {item?.name}
                </p>
              </motion.div>
            </AnimatePresence>
          </div>
          <div className='absolute right-0 pt-[1.1rem] mr-6'>
            <Toggle
              enabled={showGroup}
              showGroup={setShowGroup}
              setShowGroup={setShowGroup}
              item={item}
              groupVisibility={groupVisibility}
              dispatch={dispatch}
              toast={toast}
              color={
                !myColorForSomeonesGroup ? item?.color : myColorForSomeonesGroup
              }
            />
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default React.memo(Group)
