/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const ArrowDownIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 11 7'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path
        d='M4.28332 6.54951L0.579156 2.69717C0.131933 2.23206 0.0298501 1.71134 0.272906 1.13501C0.515961 0.558674 0.938878 0.270508 1.54166 0.270508H8.94999C9.55277 0.270508 9.97568 0.558674 10.2187 1.13501C10.4618 1.71134 10.3597 2.23206 9.91249 2.69717L6.20832 6.54951C6.07221 6.69106 5.92152 6.79217 5.75624 6.85284C5.59096 6.91351 5.42082 6.94384 5.24582 6.94384C5.05138 6.94384 4.87638 6.91351 4.72082 6.85284C4.56527 6.79217 4.41943 6.69106 4.28332 6.54951Z'
        fill='#FAFAFA'
      />
    </svg>
  )
}

export default ArrowDownIcon
