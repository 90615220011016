/* eslint-disable no-unused-vars */

import {
  AccountCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EmailIcon,
  LinkIcon,
  MoreVertIcon,
  PhoneIcon,
  SubscriptionIcon,
} from 'components/Icons'
import { setPersonalInformationIsOpen, setSubscriptionIsOpen } from 'features'
import { useUser } from 'hooks'
import React from 'react'

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import uuid from 'react-uuid'

const Subscription = () => {
  const dispatch = useDispatch()
  const { user } = useUser()

  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )
  const userInfo = [
    {
      icon: 'account_circle',
      title: 'Name',
      value: user?.name,
      InputTag: 'name',
    },
    {
      icon: 'email',
      title: 'Last Name',
      value: user?.lastName,
      InputTag: 'lastName',
    },
    {
      icon: 'phone',
      title: 'UserID',
      value: user?.nickNameId,
      InputTag: 'nickNameId',
    },
  ]
  const socialNetworks = [
    { title: 'Subscription source', selected: 'App Store' },
    { title: 'Next billing date', selected: '12/05/2024' },
  ]

  const info = [
    {
      title: 'Personal',
      selected: 'Free',
    },
    {
      title: 'Personal Pro',
      selected: '$3/mo (30/yr)',
    },

    {
      title: 'Family (6 members)',
      selected: '$9/mo(60/yr)',
    },
    {
      title: 'Family Pro (12 members)',
      selected: '$12/mo (120/yr)',
    },
    {
      title: 'Business (24 members)',
      selected: '$18/mo (180/yr)',
    },
    {
      title: 'Business Pro (48 members)',
      selected: '$27/mo (270/yr)',
    },
  ]
  return (
    <div className={`h-screen p-3 text-sm    ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='  pb-6 font-bold  flex justify-between items-center relative'>
        <ChevronLeftIcon
          size={30}
          className={'cursor-pointer'}
          onClick={() => {
            dispatch(setSubscriptionIsOpen(false))
          }}
        />

        <p>Subscription</p>

        <MoreVertIcon className={'cursor-pointer invisible'} />
      </div>
      {/* Content */}
      <div className='gap-4  flex flex-col overflow-y-scroll h-full pb-14'>
        <div
          className={`  rounded-xl   ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          }`}
        >
          {info?.map((item, index) => (
            <div
              // onClick={onPrivacyClickHandler.bind(null, item.title)}
              key={uuid()}
              className='h-[44px] pl-4   flex justify-start items-center group hover:text-BlueDarkColorMain'
            >
              <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                <div
                  className={`py-2 mr-2 flex w-full   justify-between items-center border-DivideLine border-opacity-20 border-b `}
                >
                  <p>{item.title}</p>
                  <div className='flex gap-3  items-center'>
                    <p
                      className={`group-hover:text-BlueDarkColorMain ${
                        nightMode
                          ? 'text-DM2ndLighterTextIcon hover:text-BlueDarkColorMain'
                          : 'text-LM2ndLighterTextIcon'
                      }`}
                    >
                      {item.selected}
                    </p>
                    <CheckIcon
                      size={22}
                      secondaryColor={true}
                      className={`group-hover:!fill-BlueDarkColorMain mr-2`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
          <p
            className={`px-4 pb-8 pt-4 ${
              nightMode
                ? 'text-DM2ndLighterTextIcon '
                : 'text-LM2ndLighterTextIcon'
            }`}
          >
            Your current account status gives access to all the functions of
            UNUM, including chatting, creating notes, maintaining boards, etc.{' '}
          </p>
        </div>
        {/* Social Network */}
        <p
          className={` text-xl ${
            nightMode
              ? 'text-DM2ndLighterTextIcon '
              : 'text-LM2ndLighterTextIcon'
          }`}
        >
          My Subscription:
        </p>
        <div
          className={`  rounded-xl   ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          }`}
        >
          {socialNetworks?.map((item, index) => (
            <div
              key={uuid()}
              className='h-[44px] px-3   flex justify-start items-center'
            >
              <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                <div
                  className={`py-3 flex w-full   justify-between items-center ${
                    index === socialNetworks.length - 1
                      ? 'border-0'
                      : 'border-DivideLine border-opacity-20 border-b'
                  }`}
                >
                  <p>{item.title}</p>
                  <div className='flex gap-3 text-gray-400  items-center'>
                    {' '}
                    <p
                      className={`${
                        nightMode
                          ? 'text-DM2ndLighterTextIcon '
                          : 'text-LM2ndLighterTextIcon'
                      }`}
                    >
                      {item.selected}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Subscription
