/* eslint-disable no-unused-vars */
import moment from 'moment'
import { ReactSVGIcon, SvgIcon } from 'components'
import { GoogleMap } from '@react-google-maps/api'
import { useDispatch } from 'react-redux'
import {
  setSingleEvent,
  setInnerEventDescIsOpen,
  setSingeEventDescIsOpen,
  setSingeEventDesc2IsOpen,
  fetchAllUsers,
  setEventTitle,
  setRightSideViewIsOpen,
  setEventDescription,
  setGroupId,
  setIconId,
  setEventStartDate,
  setEventStartTime,
  setEventEndDate,
  setEventEndTime,
  setEditingEventIsOpen,
  fetchSingleEvent,
} from 'features'
import { useSelector } from 'react-redux'
import { hexToRgba } from 'utils'
import { useEffect, useState } from 'react'
import { AddMemberToEvent } from './components'
import {
  AddCircleIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  AttachmentIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  DescriptionIcon,
  LocationIcon,
  LocationOnIcon,
  SettingsIcon,
} from 'components/Icons'

const EventDescription = ({ singleEvent, defaultTextIconColor }) => {
  const { innerEventDescIsOpen } = useSelector((state) => state.sideView)

  const dispatch = useDispatch()
  const containerStyle = {
    width: '91%',
    height: '13.125rem',
    borderRadius: '20px',
    margin: '5px auto 5px auto',
    overflow: 'hidden',
  }

  const handleClick = () => {
    // when drawer open from per day events
    if (innerEventDescIsOpen) {
      dispatch(setInnerEventDescIsOpen(false))
    } else {
      // when drawer open from calendar (single event)
      dispatch(setSingeEventDescIsOpen(false))
      dispatch(setSingeEventDesc2IsOpen(false))
    }
    setTimeout(() => {
      dispatch(setSingleEvent(null))
    }, 400)
  }

  const center = {
    lat: singleEvent?.location?.lat ? singleEvent?.location?.lat : 0,
    lng: singleEvent?.location?.lng ? singleEvent?.location?.lng : 0,
  }
  const options = {
    disableDefaultUI: true, // removes default UI controls
  }

  const bgColor = hexToRgba(singleEvent?.group?.color, '0.3')

  const [showContent, setShowContent] = useState('')

  const addMemberHandler = () => {
    setShowContent('addMember')
    dispatch(fetchAllUsers())
  }
  const opeEditEventsHandler = () => {
    dispatch(setEventTitle(singleEvent?.title))
    dispatch(setEventDescription(singleEvent?.description))
    dispatch(setGroupId(singleEvent?.groupId))
    dispatch(setIconId(singleEvent?.iconId))
    dispatch(setEventStartDate(new Date(singleEvent?.startTime)))
    dispatch(setEventStartTime(new Date(singleEvent?.startTime)))
    dispatch(setEventEndDate(new Date(singleEvent?.endTime)))
    dispatch(setEventEndTime(new Date(singleEvent?.endTime)))

    dispatch(setRightSideViewIsOpen(false))
    dispatch(setEditingEventIsOpen(true))
  }

  /* Logic for adding member to event */
  const { sendInvitationStatus, cancelInviteStatus } = useSelector(
    (state) => state.connection
  )
  useEffect(() => {
    if (singleEvent?._id) {
      dispatch(fetchSingleEvent(singleEvent?._id))
    }
  }, [dispatch, singleEvent, sendInvitationStatus, cancelInviteStatus])

  return (
    <>
      {showContent === 'addMember' ? (
        <>
          <AddMemberToEvent
            defaultTextIconColor={defaultTextIconColor}
            setShowContent={setShowContent}
            singleEvent={singleEvent}
          />
        </>
      ) : (
        <div
          style={{
            backgroundColor: bgColor,
          }}
          className={`h-screen w-full backdrop-blur-md p-3 text-xs  ${defaultTextIconColor}`}
        >
          {/* Header */}
          <div className='pb-6 font-bold text-sm flex justify-between items-center relative'>
            <ChevronLeftIcon
              onClick={handleClick}
              size={30}
              className={'cursor-pointer'}
            />
            <p>Event Details</p>

            <SettingsIcon
              onClick={opeEditEventsHandler}
              size={20}
              className=' cursor-pointer '
            />
          </div>
          {/* Content */}
          <div className={`flex flex-col justify-between relative `}>
            <div className='flex  flex-col  items-center text-center'>
              <ReactSVGIcon size={100} name={singleEvent?.icon?.name} />

              <h1 className=' font-semibold  py-8 text-2xl'>
                {singleEvent?.title}
              </h1>
              {/* Start-End */}
              <div className=' w-full justify-between font-semibold pb-10 grid grid-cols-2 divide-x divide-DivideLine divide-opacity-20'>
                <div className='gap-3 flex flex-col'>
                  <p
                    style={{ color: singleEvent?.group?.color }}
                    className='font-bold text-sm'
                  >
                    Starts:
                  </p>
                  <p className='font-bold  text-xs'>
                    {moment(singleEvent?.startTime).format('dd, MMM DD, YYYY')}
                  </p>
                  <span>
                    {moment(singleEvent?.startTime).format('HH:mm A')}
                  </span>
                </div>
                <div className='gap-3 flex flex-col'>
                  <p
                    style={{ color: singleEvent?.group?.color }}
                    className='text-sm font-bold'
                  >
                    Ends:
                  </p>
                  <p className='font-bold  text-xs'>
                    {moment(singleEvent?.endTime).format('dd, MMM DD, YYYY')}
                  </p>
                  <span>{moment(singleEvent?.endTime).format('HH:mm A')}</span>
                </div>
              </div>

              <div className={` w-[90%] h-[6.25rem] relative  `}>
                {/* Event duration line */}
                <span className='w-full grid grid-cols-6  absolute rounded-tl-xl rounded-tr-xl overflow-hidden '>
                  <div
                    style={{
                      opacity: 100,
                      backgroundColor: singleEvent?.group?.color,
                      gridColumnStart: 1,
                      gridColumnEnd: 3,
                    }}
                    className='w-full h-[0.875rem]'
                  ></div>
                </span>
                {/* Background line */}
                <span
                  style={{
                    backgroundColor: singleEvent?.group?.color,
                  }}
                  className='w-full h-[0.875rem] top-0 absolute opacity-40  left-0 rounded-tl-xl rounded-tr-xl   '
                ></span>
                {/* Event background color */}
                <div
                  style={{ backgroundColor: singleEvent?.group?.color }}
                  className='w-full h-[6.25rem] opacity-50 absolute rounded-xl'
                ></div>
                {/* Content */}
                <div
                  className={`flex relative z-50 items-center text-center  flex-col w-full h-full  overflow-hidden `}
                >
                  <p className='text-center text-xl font-bold w-full  h-full pt-5'>
                    3 Days Ago
                  </p>
                  <div className='flex justify-between w-full pl-4 pb-3'>
                    <p className='text-sm'>View timeline event</p>

                    <ArrowDownIcon size={10} className={'mr-6'} />
                  </div>
                </div>
              </div>

              {/* Description */}
              <div className={` w-[90%] h-[8.875rem] relative my-3 text-sm `}>
                {/* event background color */}
                <div
                  style={{ backgroundColor: singleEvent?.group?.color }}
                  className='w-full h-[8.875rem] opacity-50 absolute rounded-xl'
                ></div>
                {/* content */}
                <div
                  className={`flex relative z-50 py-3 justify-between  flex-col w-full h-full  overflow-hidden `}
                >
                  <p className='text-left custom-truncate-text-with-2lines px-3  '>
                    {singleEvent?.description
                      ? singleEvent?.description
                      : 'Description'}
                  </p>
                  <p className='text-left truncate  px-3'>
                    {singleEvent?.address ? singleEvent?.address : 'Address'}
                  </p>
                  <div className='w-full flex justify-between pt-5 pb-1 '>
                    <div className='flex '>
                      <DescriptionIcon size={20} className={'mx-2'} />
                      <LocationOnIcon size={20} className={'mx-2'} />
                      <AttachmentIcon size={20} className={'mx-2'} />
                    </div>
                    <p className='pr-3 opacity-60'>last edit 6/21/2023</p>
                  </div>
                </div>
              </div>
              {/* Add Member Button */}
              <div
                onClick={addMemberHandler}
                style={{
                  backgroundColor: singleEvent?.group?.color,
                }}
                className='h-28 w-28 my-3 rounded-xl items-center flex flex-col justify-center cursor-pointer'
              >
                <AddCircleIcon size={30} />
                <p className='pt-2'>Add Member</p>
              </div>

              {/* Map */}
              {singleEvent?.location?.lat || singleEvent?.location?.lng ? (
                <GoogleMap
                  options={options}
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                >
                  {/* <Marker position={center} /> */}
                </GoogleMap>
              ) : null}

              <p className='text-center text-sm leading-5 text-gray-500'>
                {singleEvent?.location?.address}
              </p>
            </div>
          </div>
          {/* Footer - group name and members */}
          <div className='absolute bottom-0 flex w-full justify-between px-4 pb-8'>
            <div className='flex pt-1 gap-1 w-24'>
              <p className=' truncate'>{singleEvent?.group?.name}</p>
              <p>Group</p>
            </div>

            <div className='pr-8 flex gap-0.5'>
              <div className='w-6 h-6  relative flex  justify-center items-center rounded-full '>
                <img
                  alt='group member'
                  src='http://source.unsplash.com/100x100/?girl'
                  className='rounded-full cursor-pointer'
                />
              </div>
              <div className='w-6 h-6  relative flex  justify-center items-center rounded-full '>
                <img
                  alt='group member'
                  src='http://source.unsplash.com/100x100/?boy'
                  className='rounded-full cursor-pointer'
                />
              </div>
              <div className='w-6 h-6  relative flex  justify-center items-center rounded-full '>
                <img
                  alt='group member'
                  src='http://source.unsplash.com/100x100/?man'
                  className='rounded-full cursor-pointer'
                />
              </div>
              <div
                style={{ backgroundColor: singleEvent?.group?.color }}
                className='w-6 h-6 cursor-pointer relative flex  justify-center items-center opacity-80 rounded-full '
              >
                <p className='pr-0.5 '>+3</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default EventDescription
