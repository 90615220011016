/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const NotificationsSecondIcon = ({
  size = 24,
  className,
  onClick,
  secondaryColor,
}) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 21 21'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M3.53911 17.1325C3.25036 17.1325 3.01345 17.0383 2.82839 16.85C2.64332 16.6617 2.55078 16.4339 2.55078 16.1667C2.55078 15.88 2.64332 15.6498 2.82839 15.4762C3.01345 15.3026 3.24731 15.2158 3.52995 15.2158H4.48828V9.34082C4.48828 8.09147 4.85981 6.94928 5.60286 5.91424C6.34592 4.87918 7.34245 4.20598 8.59245 3.89463V3.40332C8.59245 2.99128 8.73554 2.64869 9.02172 2.37555C9.30791 2.1024 9.65543 1.96582 10.0643 1.96582C10.4731 1.96582 10.8231 2.1024 11.1142 2.37555C11.4052 2.64869 11.5508 2.99128 11.5508 3.40332V3.89463C12.8147 4.19209 13.8251 4.85789 14.582 5.89203C15.339 6.92615 15.7174 8.07575 15.7174 9.34082V15.2158H16.6549C16.9084 15.2158 17.1315 15.3038 17.3242 15.4799C17.5169 15.6559 17.6133 15.8838 17.6133 16.1636C17.6133 16.4433 17.5169 16.6747 17.3242 16.8578C17.1315 17.0409 16.9084 17.1325 16.6549 17.1325H3.53911ZM10.0829 19.6325C9.59657 19.6325 9.17101 19.455 8.80626 19.1001C8.4415 18.7451 8.25911 18.3184 8.25911 17.82H11.9049C11.9049 18.3339 11.7249 18.7644 11.3649 19.1117C11.0048 19.4589 10.5775 19.6325 10.0829 19.6325ZM6.38411 15.2158H13.8008V9.34082C13.8008 8.29829 13.4448 7.41961 12.7328 6.70476C12.0208 5.98991 11.1442 5.63249 10.1029 5.63249C9.06155 5.63249 8.18143 5.98991 7.46251 6.70476C6.74358 7.41961 6.38411 8.29829 6.38411 9.34082V15.2158Z' />
    </svg>
  )
}

export default NotificationsSecondIcon
