/* NOT USED COMPONENT */

import { ChevronLeftIcon } from 'components/Icons'
import { setGroupSettingsIsOpen } from 'features'
import React from 'react'
import { useDispatch } from 'react-redux'

const GroupSettings = ({ defaultTextIconColor }) => {
  const dispatch = useDispatch()
  const groupSettingsBackArrowHandler = () => {
    dispatch(setGroupSettingsIsOpen(false))
  }
  return (
    <div
      className={` h-screen w-full backdrop-blur-md p-3 text-xs ${defaultTextIconColor}`}
    >
      {/* Header */}
      <div className='pb-6 font-bold  text-sm flex justify-between items-center relative'>
        <ChevronLeftIcon
          size={30}
          onClick={groupSettingsBackArrowHandler}
          className=' cursor-pointer '
        />

        <p className='  absolute left-1/2 -translate-x-1/2 pr-5'>
          Group Setting
        </p>

        <p
          onClick={groupSettingsBackArrowHandler}
          className='text-green-300 cursor-pointer px-3'
        >
          Save
        </p>
      </div>
    </div>
  )
}

export default GroupSettings
