/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const GroupIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      onClick={onClick}
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M3.1 41.9q-1 0-1.675-.675T.75 39.55v-3.7q0-2.1 1.075-3.8 1.075-1.7 3.025-2.6 3.75-1.6 6.825-2.35T18 26.35q3.35 0 6.375.725t6.775 2.375q1.95.85 3.05 2.55 1.1 1.7 1.1 3.85v3.7q0 1-.675 1.675t-1.675.675Zm34.25 0q.6-.25 1.05-.9.45-.65.45-1.55v-3.5q0-3.25-1.575-5.475Q35.7 28.25 33 26.45q3.05.45 5.775 1.175 2.725.725 4.525 1.625 1.8.95 2.875 2.75t1.075 4.1v3.45q0 1-.675 1.675T44.9 41.9ZM18 23.3q-3.6 0-5.85-2.25-2.25-2.25-2.25-5.9 0-3.6 2.25-5.85Q14.4 7.05 18 7.05q3.6 0 5.875 2.25t2.275 5.85q0 3.65-2.275 5.9Q21.6 23.3 18 23.3Zm19.85-8.15q0 3.6-2.275 5.875T29.7 23.3q-.6 0-1.225-.1-.625-.1-1.325-.35 1.3-1.35 1.925-3.3.625-1.95.625-4.4 0-2.4-.625-4.275Q28.45 9 27.15 7.5q.6-.2 1.25-.325t1.3-.125q3.6 0 5.875 2.275t2.275 5.825Z' />
    </svg>
  )
}

export default GroupIcon
