import { ReactSVGIcon } from 'components'
import { ChevronRightIcon, IconsIcon } from 'components/Icons'
import React from 'react'

const TitleAndIcon = ({
  nightMode,
  register,
  errors,
  imageSrc,
  setShowIconList,
  eventTitle,
}) => {
  return (
    <div
      className={`mb-3  pt-2  text-sm rounded-xl flex ${
        nightMode ? 'bg-DMDarkBlueForeground' : 'bg-GrayMediumBG'
      }`}
    >
      <div className='w-full'>
        {/* Title */}
        <div className='pl-[3.1rem]'>
          <input
            placeholder='Event Title'
            id='title'
            name='title'
            defaultValue={eventTitle}
            {...register('title')}
            type='text'
            className={`form-control pb-2 block w-full  font-normal text-DM2ndLighterTextIcon  bg-clip-padding   rounded transition ease-in-out m-0 focus:text-DM2ndLighterTextIcon focus:border-blue-600 focus:outline-none ${
              nightMode
                ? 'bg-DMDarkBlueForeground placeholder-DM2ndLighterTextIcon '
                : 'bg-GrayMediumBG placeholder-LM2ndLighterTextIcon'
            } `}
          />

          {/* Divider */}
          <div className={`h-[1px]  w-full bg-DivideLine bg-opacity-20  `} />

          <div className={`h-2 w-52 ${errors.title ? 'visible' : 'invisible'}`}>
            {errors.title ? (
              <p className='text-red-400'>{errors.title?.message}</p>
            ) : (
              ''
            )}
          </div>
        </div>
        {/* Select Icon */}
        <div
          onClick={() => setShowIconList(true)}
          className='w-full pl-3 text-sm flex pb-2 items-center justify-between cursor-pointer'
        >
          <div className='flex'>
            <IconsIcon size={20} className={'mx-2'} />
            <p
              className={
                nightMode
                  ? 'text-DMMainLightTextIcon '
                  : 'text-LMMainDarkTextIcon'
              }
            >
              Icon
            </p>
          </div>

          <div className='flex justify-end   items-center '>
            <span className='mb-1'>
              <ReactSVGIcon name={imageSrc} secondaryColor={true} />
            </span>

            <ChevronRightIcon
              secondaryColor={true}
              size={28}
              className={' mx-2'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TitleAndIcon
