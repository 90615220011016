/* eslint-disable no-unused-vars */
import { Switch } from '@headlessui/react'
import {
  CalendarMonthIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  LockIcon,
  MoreVertIcon,
} from 'components/Icons'
import { setAppearanceIsOpen, setPreferenceIsOpen } from 'features'

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

const Preferences = () => {
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )

  const dispatch = useDispatch()

  const [time, setTime] = useState(false)

  const timeHandler = () => {
    setTime((prev) => !prev)
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className={`h-screen p-3 text-sm    ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='  pb-6 font-bold  flex justify-between items-center relative'>
        <ChevronLeftIcon
          size={30}
          className={'cursor-pointer'}
          onClick={() => {
            dispatch(setPreferenceIsOpen(false))
          }}
        />

        <p>Preferences</p>

        <MoreVertIcon className={'cursor-pointer invisible'} />
      </div>
      {/* Content */}
      <div className='overflow-y-scroll h-full pb-14'>
        {/* System setting */}
        <div
          className={`  rounded-xl  flex flex-col mb-4 px-3  divide-DivideLine divide-opacity-20 divide-y-[1px] ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          }`}
        >
          <div className='flex h-[44px] justify-between items-center  w-full '>
            <p>24h Time</p>{' '}
            <Switch
              checked={time}
              onChange={timeHandler}
              className='relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
              style={{
                backgroundColor: time
                  ? '#75CDE9'
                  : nightMode
                  ? '#778EAE'
                  : 'rgba(119, 142, 174, 0.2)',
              }}
            >
              <span
                className={classNames(
                  time ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
          </div>
          <div className='h-[44px]    flex justify-start items-center'>
            <div className='flex justify-between items-center cursor-pointer w-full gap-3 '>
              <CalendarMonthIcon />
              <div className={`  flex w-full   justify-between items-center `}>
                <p> Start week from</p>
                <div className='flex gap-3 text-gray-400 items-center '>
                  {' '}
                  <p
                    className={`${
                      nightMode
                        ? 'text-DM2ndLighterTextIcon '
                        : 'text-LM2ndLighterTextIcon'
                    }`}
                  >
                    Monday
                  </p>
                  <ChevronRightIcon
                    size={26}
                    secondaryColor={true}
                    className={'mr-[-10px]'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Preferences
