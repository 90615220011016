import { Switch } from '@headlessui/react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MoreVertIcon,
} from 'components/Icons'
import { setAppearanceIsOpen, setCurrentTimeIndicator } from 'features'
import { useDarkSide } from 'hooks'

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import LightThemeIcon from './LightThemeIcon'
import DarkThemeIcon from './DarkThemeIcon'

const Appearance = () => {
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )
  const { currentTimeIndicator } = useSelector((state) => state.sideView)
  const dispatch = useDispatch()
  const [colorTheme, setLightTheme, setDarkTheme] = useDarkSide()

  const [systemSettings, setSystemSettings] = useState(false)
  const [adaptive, setAdaptive] = useState(false)

  const setThemeLightHandler = () => {
    setLightTheme()
    setAdaptive(false)
    setSystemSettings(false)
  }
  const setThemeDarkHandler = () => {
    setDarkTheme()
    setAdaptive(false)
    setSystemSettings(false)
  }

  const systemSettingHandler = () => {
    setSystemSettings((prev) => !prev)
    setAdaptive(false)
  }
  const adaptiveHandler = () => {
    setAdaptive((prev) => !prev)
    setSystemSettings(false)
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className={`h-screen p-3 text-sm    ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='  pb-6 font-bold  flex justify-between items-center relative'>
        <ChevronLeftIcon
          size={30}
          className={'cursor-pointer'}
          onClick={() => {
            dispatch(setAppearanceIsOpen(false))
          }}
        />

        <p>Appearance</p>

        <MoreVertIcon className={'cursor-pointer invisible'} />
      </div>
      {/* Content */}
      <div className='overflow-y-scroll h-full pb-14'>
        {/* Theme button */}
        <div
          className={`${
            colorTheme === 'dark' ? 'text-white' : 'text-black'
          } px-2   cursor-pointer hidden`}
        >
          theme: {colorTheme}
        </div>

        {/* Theme buttons */}
        <div
          className={`h-[280px]  rounded-xl gap-3 flex justify-center items-center mb-4  ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          }`}
        >
          <div
            onClick={setThemeLightHandler}
            className='flex justify-center items-center flex-col'
          >
            <LightThemeIcon
              nightMode={nightMode}
              adaptive={adaptive}
              systemSettings={nightMode}
            />
            <p>Light Mode</p>
          </div>
          <div
            onClick={setThemeDarkHandler}
            className='flex justify-center items-center flex-col'
          >
            <DarkThemeIcon
              nightMode={nightMode}
              adaptive={adaptive}
              systemSettings={systemSettings}
            />

            <p>Dark Mode</p>
          </div>
        </div>
        {/* System setting */}
        <div
          className={`h-[180px]  rounded-xl gap-3 flex flex-col mb-4 px-3  divide-DivideLine divide-opacity-20 divide-y-[1px] ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          }`}
        >
          <div className='flex justify-between items-center  w-full pt-2'>
            <p>System Settings</p>{' '}
            <Switch
              checked={systemSettings}
              onChange={systemSettingHandler}
              className='relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
              style={{
                backgroundColor: systemSettings
                  ? '#75CDE9'
                  : nightMode
                  ? '#778EAE'
                  : 'rgba(119, 142, 174, 0.2)',
              }}
            >
              <span
                className={classNames(
                  systemSettings ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
          </div>
          <div className='flex justify-between items-center  w-full pt-2'>
            <p>Adaptive</p>{' '}
            <Switch
              checked={adaptive}
              onChange={adaptiveHandler}
              className='relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
              style={{
                backgroundColor: adaptive
                  ? '#75CDE9'
                  : nightMode
                  ? '#778EAE'
                  : 'rgba(119, 142, 174, 0.2)',
              }}
            >
              <span
                className={classNames(
                  adaptive ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
          </div>
          <div className='items-center flex justify-center h-full  '>
            {' '}
            <p
              className={`${
                nightMode
                  ? 'text-DM2ndLighterTextIcon '
                  : 'text-LM2ndLighterTextIcon'
              }`}
            >
              Adaptive settings will change based on the time for sunrise and
              sunset.
            </p>
          </div>
        </div>
        {/* Time Indicator */}
        <div
          className={`h-[136px]  rounded-xl gap-3 flex flex-col mb-4 px-3  divide-DivideLine divide-opacity-20 divide-y-[1px] ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          }`}
        >
          <div className='flex justify-between items-center w-full pt-2'>
            <p>Current Time Indicator</p>{' '}
            <Switch
              checked={currentTimeIndicator}
              onChange={() => {
                dispatch(setCurrentTimeIndicator(!currentTimeIndicator))
              }}
              className='relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
              style={{
                backgroundColor: currentTimeIndicator
                  ? '#75CDE9'
                  : nightMode
                  ? '#778EAE'
                  : 'rgba(119, 142, 174, 0.2)',
              }}
            >
              <span
                className={classNames(
                  currentTimeIndicator ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
          </div>

          <div className='items-center flex justify-center h-full   '>
            {' '}
            <p
              className={`${
                nightMode
                  ? 'text-DM2ndLighterTextIcon '
                  : 'text-LM2ndLighterTextIcon'
              }`}
            >
              Turn on and off the red line on the calendar that moves along with
              time to display the current time in correlation to events.
            </p>
          </div>
        </div>
        {/* App Icon*/}
        <div
          className={`h-[44px] cursor-pointer rounded-xl  flex justify-between items-center  px-3   ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          }`}
        >
          <p>App Icon</p>
          <div className='flex gap-5  items-center  '>
            {' '}
            <p
              className={`${
                nightMode
                  ? 'text-DM2ndLighterTextIcon '
                  : 'text-LM2ndLighterTextIcon'
              }`}
            >
              White - Blue{' '}
            </p>
            <ChevronRightIcon size={26} secondaryColor={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Appearance
