/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const LinkIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M14.3535 18.021C13.8587 18.021 13.4191 17.8639 13.0345 17.5496C12.65 17.2354 12.4507 16.8439 12.4369 16.3752V15.4168H10.9369C10.4261 15.4168 9.98242 15.2285 9.60585 14.852C9.22929 14.4754 9.04102 14.0318 9.04102 13.521V6.47933H7.58268V7.521C7.58268 7.98975 7.38737 8.38471 6.99675 8.70589C6.60612 9.02707 6.16949 9.18766 5.68685 9.18766H2.81185C2.30109 9.18766 1.85742 8.9992 1.48085 8.62229C1.10429 8.24539 0.916016 7.79495 0.916016 7.271V3.87516C0.916016 3.35866 1.10429 2.91009 1.48085 2.52945C1.85742 2.14882 2.30109 1.9585 2.81185 1.9585H5.68685C6.16949 1.9585 6.60612 2.11909 6.99675 2.44027C7.38737 2.76145 7.58268 3.15641 7.58268 3.62516V4.56266H12.4369V3.62516C12.4369 3.15641 12.6306 2.76145 13.018 2.44027C13.4054 2.11909 13.8506 1.9585 14.3535 1.9585H17.1869C17.7034 1.9585 18.1519 2.14882 18.5326 2.52945C18.9132 2.91009 19.1035 3.35866 19.1035 3.87516V7.271C19.1035 7.79495 18.9132 8.24539 18.5326 8.62229C18.1519 8.9992 17.7034 9.18766 17.1869 9.18766H14.3535C13.8506 9.18766 13.4054 9.02707 13.018 8.70589C12.6306 8.38471 12.4369 7.98975 12.4369 7.521V6.47933H10.9369V13.521H12.4369V12.4793C12.4369 12.0106 12.6287 11.6156 13.0124 11.2944C13.3961 10.9733 13.8431 10.8127 14.3535 10.8127H17.1869C17.7034 10.8127 18.1519 11.0009 18.5326 11.3775C18.9132 11.7541 19.1035 12.1977 19.1035 12.7085V16.1252C19.1035 16.6359 18.9132 17.0796 18.5326 17.4562C18.1519 17.8327 17.7034 18.021 17.1869 18.021H14.3535ZM14.3535 7.271H17.1869V3.87516H14.3535V7.271ZM14.3535 16.1252H17.1869V12.7085H14.3535V16.1252ZM2.81185 7.271H5.68685V3.87516H2.81185V7.271Z' />
    </svg>
  )
}

export default LinkIcon
