import { useLayoutEffect, useState } from 'react'

const useDailyEvent = (event) => {
  const startHour = new Date(event.startTime).getHours()
  const endHour = new Date(event.endTime).getHours()

  const [startPosition, setStartPosition] = useState(null)
  const [endPosition, setEndPosition] = useState(null)

  useLayoutEffect(() => {
    if (startHour < 4) {
      setStartPosition(1)
    } else if (startHour < 8) {
      setStartPosition(2)
    } else if (startHour < 12) {
      setStartPosition(3)
    } else if (startHour < 16) {
      setStartPosition(4)
    } else if (startHour < 20) {
      setStartPosition(5)
    } else if (startHour < 24) {
      setStartPosition(6)
    }
  }, [startHour, event])

  useLayoutEffect(() => {
    if (endHour < 4) {
      setEndPosition(2)
    } else if (endHour < 8) {
      setEndPosition(3)
    } else if (endHour <= 12) {
      setEndPosition(4)
    } else if (endHour < 16) {
      setEndPosition(5)
    } else if (endHour < 20) {
      setEndPosition(6)
    } else if (endHour < 24) {
      setEndPosition(7)
    }
  }, [endHour, event])
  return {
    startPosition,
    endPosition,
  }
}

export default useDailyEvent
