/* Making HTTP requests */

import axios from 'axios'
import { userId } from 'utils'

const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
}

const fetchGroups = async () => {
  const results = await axios(
    `${process.env.REACT_APP_BASE_URL}/api/v1/groups`,
    {
      params: { userId: userId() },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }
  )
  return results.data
}
const fetchSingleGroup = async (groupId) => {
  const results = await axios(
    `${process.env.REACT_APP_BASE_URL}/api/v1/get-group`,
    {
      params: { userId: userId(), groupId },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }
  )
  return results.data
}
const fetchColors = async () => {
  const results = await axios(
    `${process.env.REACT_APP_BASE_URL}/api/v1/colors`,
    config
  )
  return results.data
}

const createGroup = async (dataForSubmit) => {
  const results = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/add-group`,
    dataForSubmit,
    config
  )

  return results.data
}
const editGroup = async (dataForSubmit) => {
  const results = await axios.patch(
    `${process.env.REACT_APP_BASE_URL}/api/v1/edit-group`,
    dataForSubmit,
    config
  )

  return results.data
}

const hideShowAllGroups = async (showOrHide) => {
  const results = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/groups/hide-all/${showOrHide}`,
    config
  )
  return results
}

const groupVisibility = async (dataForSubmit) => {
  const { groupId, isGroupHide } = dataForSubmit

  const results = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/visible-group/${groupId}/${isGroupHide}`,
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }
  )
  return results
}

export const reOrderGroups = async (data) => {
  const results = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/groups/ordered`,
    data,
    config
  )
  return results
}

const groupService = {
  fetchGroups,
  fetchSingleGroup,
  fetchColors,
  createGroup,
  editGroup,
  groupVisibility,
  hideShowAllGroups,
}

export default groupService
