/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const SignUp = () => {
  const [register, setRegister] = useState()
  const [registerData, setRegisterData] = useState({
    email: '',
    password: '',
  })

  const registerHandler = async (e) => {
    e.preventDefault()
    const results = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/auth/register`,
      { email: registerData.email, password: registerData.password }
    )
    setRegister(results.data)
    console.log(register)
  }

  const textHandler = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value })
  }

  return (
    <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
        {/* <img
      className='mx-auto h-10 w-auto'
      src='https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600'
      alt='Your Company'
    /> */}
        <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
          Sign Up
        </h2>
      </div>

      <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
        <form onSubmit={registerHandler} className='space-y-6'>
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Email address
            </label>
            <div className='mt-2'>
              <input
                id='email'
                onChange={textHandler}
                name='email'
                value={registerData.email}
                type='email'
                autoComplete='email'
                required
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>

          <div>
            <div className='flex items-center justify-between'>
              <label
                htmlFor='password'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Password
              </label>
            </div>
            <div className='mt-2'>
              <input
                id='password'
                onChange={textHandler}
                name='password'
                type='password'
                value={registerData.password}
                autoComplete='current-password'
                required
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
          <div>
            <div className='flex items-center justify-between'>
              <label
                htmlFor='password'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Confirm Password
              </label>
            </div>
            <div className='mt-2'>
              <input
                id='password'
                name='confirmPassword'
                type='password'
                autoComplete='current-password'
                required
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>

          <div>
            <button
              type='submit'
              className='flex w-full justify-center rounded-md bg-BlueDarkColorMain px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-BlueDarkColorMainHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              Sign Up
            </button>
          </div>
        </form>

        <p className='mt-10 text-center text-sm text-gray-500'>
          Already have an account?{' '}
          <Link
            to={'/'}
            className='font-semibold leading-6 text-BlueDarkColorMain hover:text-BlueDarkColorMainHover'
          >
            Sign in
          </Link>
        </p>
      </div>
    </div>
  )
}

export default SignUp
