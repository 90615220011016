/* eslint-disable no-unused-vars */
import {
  fetchEvents,
  setConnectionsIsOpen,
  setGroupsIsOpen,
  setInnerEventDescIsOpen,
  setIsContactsOpen,
  setPerDayEventsIsOpen,
  setSingeEventDesc2IsOpen,
  setSingeEventDescIsOpen,
  setSingleEvent,
  setUserProfileIsOpen,
} from 'features'
import moment from 'moment'

import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useWeek from './useWeek'
import { currentDate, generateMonth } from 'utils'

const useCalendar = () => {
  const events = useSelector((state) => state.event.fetchedEvents)
  const fetchedEventStatus = useSelector(
    (state) => state.event.fetchedEventStatus
  )
  const showPerDayEventTemp = useSelector(
    (state) => state.event.showPerDayEventTemp
  )
  const { isLeftBarOpened, singeEventDescIsOpen, singeEventDesc2IsOpen } =
    useSelector((state) => state.sideView)

  const status = useSelector((state) => state.event.createEventStatus)
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )
  const date = useSelector((state) => state.event.date)
  const [generatedWeeks, setGeneratedWeeks] = useState([])
  const isVisibleGroup = useSelector((state) => state.group.isGroupVisible)
  const groupVisibilityStatus = useSelector(
    (state) => state.group.groupVisibilityStatus
  )
  const switchView = useSelector((state) => state.event.switchView)
  const { allGroupShowHideStatus, allGroupShowHideError, editGroupStatus } =
    useSelector((state) => state.group)
  const singleEvent = useSelector((state) => state.event.singleEvent)
  const editEventStatus = useSelector((state) => state.event.editEventStatus)

  const { responseToInvitesStatus } = useSelector((state) => state.connection)

  //const [weekNumber, setWeekNumber] = useState(0)
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [now, setNow] = useState(new Date())
  const parentRef = useRef(null)
  const currentDayRef = useRef(null)
  const { getFullWeeksStartAndEndInMonth } = useWeek()
  const dispatch = useDispatch()

  //spread fetched events
  let sorted = [...events]

  const calendarView = localStorage.getItem('calendarView')
  const calendarViewLocal = JSON.parse(calendarView)

  //render current month from start
  // useLayoutEffect(() => {
  //   if (calendarViewLocal === 'week') {
  //     setGeneratedWeeks(
  //       getFullWeeksStartAndEndInMonth(new Date().getFullYear())
  //     )
  //   } else {
  //     setGeneratedWeeks(generateMonth(new Date().getFullYear()))
  //   }
  // }, [getFullWeeksStartAndEndInMonth, calendarViewLocal])

  //sorting weeks
  generatedWeeks?.sort((a, b) => new Date(a?.fullDate) - new Date(b?.fullDate))

  //show month page with current date
  // useEffect(() => {
  //   const element = document?.getElementById(moment(currentDate).format('MMM'))
  //   element?.scrollIntoView({ inline: 'center', block: 'center' })
  // }, [generatedWeeks])

  //show week page with current date
  // useEffect(() => {
  //   const element = document?.getElementById(
  //     moment(new Date()).format('YYYY-MM-DD')
  //   )
  //   element?.scrollIntoView({ inline: 'center' })
  // }, [events, switchView, isLeftBarOpened, generatedWeeks])

  //Get events from backend
  useEffect(() => {
    dispatch(fetchEvents())
  }, [
    dispatch,
    status,
    editEventStatus,
    isVisibleGroup,
    allGroupShowHideStatus,
    responseToInvitesStatus,
    editGroupStatus,
  ])

  const goToCurrentDate = () => {
    const element = document?.getElementById(
      moment(new Date()).format('YYYY-MM-DD')
    )
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  //set position when scroll left
  // useEffect(() => {
  //   currentDayRef.current?.scrollIntoView({
  //     behavior: 'instant',
  //     inline: 'end',
  //   })
  // }, [])

  //Scroll week function
  // const handleHorizontalScroll = useCallback(() => {
  //   let timeoutId

  //   function getNoOfDays(start, last) {
  //     // gives no. of days
  //     if (!start || !last) return
  //     const date1 = new Date(start)
  //     const date2 = new Date(last)
  //     const oneDay = 1000 * 60 * 60 * 24
  //     const diffTime = date2.getTime() - date1.getTime()
  //     const diffDays = Math.round(diffTime / oneDay) + 1
  //     if (diffDays >= 0) return diffDays
  //   }

  //   let y = now.getFullYear(),
  //     m = now.getMonth()
  //   let firstDay = moment(new Date(y, m, 1)).format('YYYY-MM-DD')
  //   let lastDay = moment(new Date(y, m + 1, 0)).format('YYYY-MM-DD')
  //   let dayWidth = ((window.innerWidth - 60) / 7 + 1).toFixed(2)
  //   let scrollValue = +dayWidth * getNoOfDays(firstDay, lastDay)
  //   let e = document.getElementById('week-view-wrapper')
  //   let threshold = 40

  //   if (Math.ceil(e.scrollLeft) + e.clientWidth + threshold >= e.scrollWidth) {
  //     clearTimeout(timeoutId)
  //     setNow(new Date(now.getFullYear() + 1, now.getMonth()))

  //     setGeneratedWeeks((prev) => [
  //       ...prev,
  //       ...getFullWeeksStartAndEndInMonth(now.getFullYear() + 1),
  //     ])

  //     timeoutId = setTimeout(() => {
  //       const targetElement = e.getElementsByClassName(
  //         'your-generated-weeks-element-class'
  //       )[11] // Replace 'your-generated-weeks-element-class' with the appropriate class name or selector
  //       if (targetElement) {
  //         targetElement.scrollIntoView({
  //           behavior: 'smooth',
  //           block: 'end',
  //           inline: 'nearest',
  //         })
  //       }
  //     }, 50)
  //   } else if (e.scrollLeft <= 10) {
  //     clearTimeout(timeoutId)
  //     setNow(new Date(now.getFullYear() - 1, now.getMonth()))

  //     setGeneratedWeeks((prev) => [
  //       ...getFullWeeksStartAndEndInMonth(now.getFullYear() - 1),
  //       ...prev,
  //     ])

  //     timeoutId = setTimeout(() => {
  //       e.lastChild.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'end',
  //         inline: 'nearest',
  //       })
  //     }, 50)
  //   }
  // }, [getFullWeeksStartAndEndInMonth, now])

  // //Scroll Month function
  // const handleMonthHorizontalScroll = useCallback(() => {
  //   let timeoutId

  //   function getNoOfDays(start, last) {
  //     // gives no. of days
  //     if (!start || !last) return
  //     const date1 = new Date(start)
  //     const date2 = new Date(last)
  //     const oneDay = 1000 * 60 * 60 * 24
  //     const diffTime = date2.getTime() - date1.getTime()
  //     const diffDays = Math.round(diffTime / oneDay) + 1
  //     if (diffDays >= 0) return diffDays
  //   }

  //   let y = now.getFullYear(),
  //     m = now.getMonth()
  //   let firstDay = moment(new Date(y, m, 1)).format('YYYY-MM-DD')
  //   let lastDay = moment(new Date(y, m + 1, 0)).format('YYYY-MM-DD')
  //   let dayWidth = ((window.innerWidth - 60) / 7 + 1).toFixed(2)
  //   let scrollValue = +dayWidth * getNoOfDays(firstDay, lastDay)
  //   let e = document.getElementById('month-view-wrapper')
  //   let threshold = 40

  //   if (Math.ceil(e.scrollLeft) + e.clientWidth + threshold >= e.scrollWidth) {
  //     clearTimeout(timeoutId)
  //     setNow(new Date(now.getFullYear() + 1, now.getMonth()))

  //     setGeneratedWeeks([
  //       ...generatedWeeks,
  //       ...generateMonth(now.getFullYear() + 1),
  //     ])
  //   } else if (e.scrollLeft <= 10) {
  //     clearTimeout(timeoutId)
  //     setNow(new Date(now.getFullYear() - 1, now.getMonth()))

  //     setGeneratedWeeks([
  //       ...generateMonth(now.getFullYear() - 1),
  //       ...generatedWeeks,
  //     ])

  //     //test

  //     timeoutId = setTimeout(() => {
  //       // e.scrollTo(0, 0)
  //       e.lastChild.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'end',
  //         inline: 'nearest',
  //       })
  //     }, 0)
  //   }
  // }, [generatedWeeks, now])

  const openEventHandler = (event) => {
    if (singleEvent?._id === event?._id) {
      dispatch(setSingeEventDescIsOpen(false))
      dispatch(setSingeEventDesc2IsOpen(false))
      setTimeout(() => {
        dispatch(setSingleEvent(null))
      }, 400)
    } else if (singeEventDescIsOpen) {
      dispatch(setSingeEventDescIsOpen(false))
      setTimeout(() => {
        dispatch(setSingleEvent(event))
      })
      dispatch(setSingeEventDesc2IsOpen(true))
    } else if (singeEventDesc2IsOpen) {
      dispatch(setSingeEventDesc2IsOpen(false))

      setTimeout(() => {
        dispatch(setSingleEvent(event))
      })
      dispatch(setSingeEventDescIsOpen(true))
    } else {
      dispatch(setSingleEvent(event))
      dispatch(setSingeEventDescIsOpen(true))
    }

    /* Close other drawers */
    dispatch(setPerDayEventsIsOpen(false))
    dispatch(setInnerEventDescIsOpen(false))
    dispatch(setGroupsIsOpen(false))
    dispatch(setIsContactsOpen(false))
    dispatch(setConnectionsIsOpen(false))
    dispatch(setUserProfileIsOpen(false))
  }

  return {
    events,
    status,
    date,
    isVisibleGroup,
    groupVisibilityStatus,
    sorted,
    allGroupShowHideStatus,
    generatedWeeks,
    setGeneratedWeeks,
    goToCurrentDate,
    getFullWeeksStartAndEndInMonth,
    fetchedEventStatus,
    //handleHorizontalScroll,
    setMonth,
    parentRef,
    currentDayRef,
    showPerDayEventTemp,
    openEventHandler,
    singleEvent,
    calendarViewLocal,
    //handleMonthHorizontalScroll,
    nightMode,
    defaultTextIconColor,
  }
}

export default useCalendar
