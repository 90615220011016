import { ChevronRightIcon, GroupIcon } from 'components/Icons'
import React from 'react'

const GroupAndEventType = ({
  nightMode,
  selectedGroupId,
  selectedGroup,
  groups,
  setShowGroupList,
}) => {
  return (
    <div
      onClick={() => setShowGroupList(true)}
      className={`rounded-xl py-3 mb-3 pl-3 text-sm overflow-x-hidden cursor-pointer   ${
        nightMode ? 'bg-DMDarkBlueForeground' : 'bg-GrayMediumBG'
      }`}
    >
      <div className={`flex justify-between `}>
        <div className='flex items-center'>
          <GroupIcon className={'mx-2'} size={20} />
          <p
            className={
              nightMode
                ? 'text-DMMainLightTextIcon '
                : 'text-LMMainDarkTextIcon'
            }
          >
            Group
          </p>
        </div>
        <div className='flex items-center '>
          <p
            className={` pr-2 ${
              nightMode
                ? 'text-DM2ndLighterTextIcon '
                : 'text-LM2ndLighterTextIcon'
            }`}
          >
            {selectedGroupId ? selectedGroup[0]?.name : groups[0]?.name}
          </p>
          <ChevronRightIcon
            secondaryColor={true}
            size={28}
            className={' mx-2'}
          />
        </div>
      </div>
    </div>
  )
}

export default GroupAndEventType
