import React from 'react'
import SingleGroup from './SingleGroup'
import { useSelector } from 'react-redux'

import { ChevronLeftIcon } from 'components/Icons'

const GroupList = ({ groups, setShowGroupList }) => {
  const { defaultTextIconColor } = useSelector((state) => state.theme)

  return (
    <div className={`h-screen p-3  ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='  pb-2 font-bold text-sm flex justify-between items-center relative'>
        <ChevronLeftIcon
          onClick={() => {
            setShowGroupList(false)
          }}
          size={30}
          className={'cursor-pointer'}
        />
        <p className={`text-sm  `}> Select Group</p>

        <ChevronLeftIcon size={30} className={'cursor-pointer invisible'} />
      </div>

      {/* Content */}
      <div className=' pb-6'>
        {groups.map((item) => (
          <SingleGroup
            key={item._id}
            item={item}
            setShowGroupList={setShowGroupList}
          />
        ))}
      </div>
    </div>
  )
}

export default GroupList
