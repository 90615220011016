/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const LogOutIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M5.27075 18.0205C4.72908 18.0205 4.26381 17.8295 3.87492 17.4476C3.48603 17.0656 3.29159 16.6038 3.29159 16.0622V4.54134H3.06242C2.78464 4.54134 2.552 4.44759 2.3645 4.26009C2.177 4.07259 2.08325 3.83995 2.08325 3.56217C2.08325 3.2844 2.177 3.05176 2.3645 2.86426C2.552 2.67676 2.78464 2.58301 3.06242 2.58301H6.91659C6.91659 2.30523 7.01033 2.06912 7.19783 1.87467C7.38533 1.68023 7.61797 1.58301 7.89575 1.58301H12.1041C12.3819 1.58301 12.618 1.68023 12.8124 1.87467C13.0069 2.06912 13.1041 2.30523 13.1041 2.58301H16.9374C17.2152 2.58301 17.4478 2.67676 17.6353 2.86426C17.8228 3.05176 17.9166 3.2844 17.9166 3.56217C17.9166 3.83995 17.8228 4.07259 17.6353 4.26009C17.4478 4.44759 17.2152 4.54134 16.9374 4.54134H16.7083V16.0622C16.7083 16.6038 16.5138 17.0656 16.1249 17.4476C15.736 17.8295 15.2708 18.0205 14.7291 18.0205H5.27075ZM7.37492 13.6455C7.37492 13.8538 7.45478 14.0379 7.6145 14.1976C7.77422 14.3573 7.95825 14.4372 8.16659 14.4372C8.38881 14.4372 8.57978 14.3573 8.7395 14.1976C8.89922 14.0379 8.97908 13.8538 8.97908 13.6455V6.93717C8.97908 6.72884 8.89575 6.54134 8.72909 6.37467C8.56242 6.20801 8.37492 6.12467 8.16659 6.12467C7.94436 6.12467 7.75686 6.20801 7.60408 6.37467C7.45131 6.54134 7.37492 6.72884 7.37492 6.93717V13.6455ZM11.0208 13.6455C11.0208 13.8538 11.1041 14.0379 11.2708 14.1976C11.4374 14.3573 11.6249 14.4372 11.8333 14.4372C12.0555 14.4372 12.2464 14.3573 12.4062 14.1976C12.5659 14.0379 12.6458 13.8538 12.6458 13.6455V6.93717C12.6458 6.72884 12.5659 6.54134 12.4062 6.37467C12.2464 6.20801 12.0555 6.12467 11.8333 6.12467C11.611 6.12467 11.4201 6.20801 11.2603 6.37467C11.1006 6.54134 11.0208 6.72884 11.0208 6.93717V13.6455Z' />
    </svg>
  )
}

export default LogOutIcon
