import React from 'react'
import { useSelector } from 'react-redux'

function RightSideDrawer({
  children,
  isOpen,
  position,
  isLeftBarOpened,
  className,
}) {
  const { nightMode } = useSelector((state) => state.theme)
  return (
    <div
      className={`fixed top-0  w-[100%] sm:w-[24rem]  z-40 ${
        nightMode ? 'bg-DMDarkBlueBackground' : 'bg-white'
      }  transform h-full shadow-xl duration-500 ease-in-out transition-all ${className}  ${
        position === 'left'
          ? `left-0 ${isLeftBarOpened ? 'md:left-[12rem]' : 'md:left-[3.7rem]'}`
          : 'right-0'
      } ${
        isOpen
          ? ''
          : `${position === 'left' ? '-translate-x-full' : 'translate-x-full '}`
      }`}
    >
      <div className=''>{children}</div>
    </div>
  )
}

export default RightSideDrawer
