import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import authService from './authService'

export const sendCodeToEmailAPI = createAsyncThunk(
  'auth/emailCode',
  async function (email, { rejectWithValue }) {
    try {
      return await authService.sendCodeToEmailAPI(email)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const verifyEmailCodeAPI = createAsyncThunk(
  'auth/verifyEmailCode',
  async function (data, { rejectWithValue }) {
    try {
      return await authService.verifyEmailCodeAPI(data)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const initialState = {
  recoveryEmail: '',
  emailCodeAPIStatus: '',
  emailCodeAPIError: null,
  verifyEmailCodeAPIStatus: '',
  verifyEmailCodeAPIError: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRecoveryEmail: (state, action) => {
      state.recoveryEmail = action.payload
    },
  },
  extraReducers: {
    [sendCodeToEmailAPI.fulfilled]: (state, action) => {
      state.emailCodeAPIStatus = 'success'
      state.emailCodeAPIError = null
    },
    [sendCodeToEmailAPI.pending]: (state, action) => {
      state.emailCodeAPIStatus = 'pending'
    },
    [sendCodeToEmailAPI.rejected]: (state, action) => {
      state.emailCodeAPIStatus = 'rejected'
      state.emailCodeAPIError = action.payload.response.data.message
    },
    [verifyEmailCodeAPI.fulfilled]: (state, action) => {
      state.verifyEmailCodeAPIStatus = 'success'
      state.verifyEmailCodeAPIError = null
    },
    [verifyEmailCodeAPI.pending]: (state, action) => {
      state.verifyEmailCodeAPIStatus = 'pending'
    },
    [verifyEmailCodeAPI.rejected]: (state, action) => {
      state.verifyEmailCodeAPIStatus = 'rejected'
      state.verifyEmailCodeAPIError = action.payload.response.data.message
    },
  },
})

// Action creators are generated for each case reducer function

export const { setRecoveryEmail } = authSlice.actions

export default authSlice.reducer
