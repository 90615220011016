/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const GroupsIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      onClick={onClick}
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M1.75 36.6q-1 0-1.675-.675T-.6 34.25v-.9q0-2.25 2.25-3.6T7.5 28.4h1.15q-.45 1-.725 2.075Q7.65 31.55 7.65 32.8v3.8Zm12 0q-1 0-1.675-.675T11.4 34.25V32.8q0-3.55 3.475-5.725T24 24.9q5.65 0 9.125 2.175Q36.6 29.25 36.6 32.8v1.45q0 1-.675 1.675t-1.675.675Zm26.6 0v-3.8q0-1.2-.25-2.325-.25-1.125-.75-2.075h1.15q3.6 0 5.85 1.35 2.25 1.35 2.25 3.6v.9q0 1-.675 1.675t-1.675.675ZM7.5 27.05q-1.65 0-2.75-1.125t-1.1-2.775q0-1.6 1.125-2.725T7.5 19.3q1.65 0 2.775 1.125T11.4 23.2q0 1.6-1.125 2.725T7.5 27.05Zm33 0q-1.65 0-2.75-1.125t-1.1-2.775q0-1.6 1.125-2.725T40.5 19.3q1.65 0 2.775 1.125T44.4 23.2q0 1.6-1.125 2.725T40.5 27.05ZM24 23.45q-2.75 0-4.675-1.925Q17.4 19.6 17.4 16.85t1.925-4.675Q21.25 10.25 24 10.25t4.675 1.925Q30.6 14.1 30.6 16.85t-1.925 4.675Q26.75 23.45 24 23.45Z' />
    </svg>
  )
}

export default GroupsIcon
