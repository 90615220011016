import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import groupService from './groupService'

export const fetchGroups = createAsyncThunk(
  'groups/fetchGroups',
  async function (_, { rejectWithValue }) {
    try {
      return await groupService.fetchGroups()
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const fetchSingleGroup = createAsyncThunk(
  'groups/fetchSingleGroup',
  async function (groupId, { rejectWithValue }) {
    try {
      return await groupService.fetchSingleGroup(groupId)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const fetchColors = createAsyncThunk(
  'groups/fetchColors',
  async function (_, { rejectWithValue }) {
    try {
      return await groupService.fetchColors()
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const createGroup = createAsyncThunk(
  'groups/createGroup',
  async function (dataForSubmit, { rejectWithValue }) {
    try {
      return await groupService.createGroup(dataForSubmit)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const editGroup = createAsyncThunk(
  'groups/editGroup',
  async function (dataForSubmit, { rejectWithValue }) {
    try {
      return await groupService.editGroup(dataForSubmit)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const groupVisibility = createAsyncThunk(
  'groups/groupVisibility',
  async function (dataForSubmit, { rejectWithValue }) {
    try {
      return await groupService.groupVisibility(dataForSubmit)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const hideShowAllGroups = createAsyncThunk(
  'groups/hideShowAllGroups',
  async function (showOrHide, { rejectWithValue }) {
    try {
      return await groupService.hideShowAllGroups(showOrHide)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const initialState = {
  fetchedGroups: [],
  fetchedSingleGroup: {},
  fetchedColors: [],
  newGroup: [],
  color: '#6366f1',
  myColorForSomeonesGroup: '',
  groupTitle: '',
  createGroupStatus: '',
  createGroupError: null,
  editGroupStatus: '',
  editGroupError: null,
  fetchedSingleGroupStatus: '',
  fetchedSingleGroupError: null,
  groupVisibilityStatus: '',
  groupVisibilityError: null,
  isGroupVisible: '',
  allGroupShowHideStatus: '',
  allGroupShowHideError: null,
  selectedGroup: null,
}

export const groupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setColor: (state, action) => {
      state.color = action.payload
    },
    setMyColorForSomeonesGroup: (state, action) => {
      state.myColorForSomeonesGroup = action.payload
    },
    setGroupTitle: (state, action) => {
      state.groupTitle = action.payload
    },
    resetGroupAPICallStatus: (state, action) => {
      state.createGroupStatus = ''
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload
    },
  },
  extraReducers: {
    [fetchGroups.fulfilled]: (state, action) => {
      state.fetchedGroups = action.payload
    },

    [fetchColors.fulfilled]: (state, action) => {
      state.fetchedColors = action.payload
    },
    [groupVisibility.fulfilled]: (state, action) => {
      state.groupVisibilityStatus = 'success'
      state.isGroupVisible = action.payload
    },
    [groupVisibility.pending]: (state, action) => {
      state.groupVisibilityStatus = 'loading'
    },
    [groupVisibility.rejected]: (state, action) => {
      state.groupVisibilityStatus = 'rejected'
      state.groupVisibilityError = action.payload.response.data.message
    },
    [createGroup.rejected]: (state, action) => {
      state.createGroupStatus = 'rejected'
      state.createGroupError = action.payload.response.data.message
    },
    [createGroup.pending]: (state, action) => {
      state.createGroupStatus = 'pending'
    },
    [createGroup.fulfilled]: (state, action) => {
      state.newGroup = action.payload
      state.groupTitle = ''
      state.color = '#6366f1'
      state.createGroupStatus = 'success'
      state.createGroupError = null
    },
    [editGroup.rejected]: (state, action) => {
      state.editGroupStatus = 'rejected'
      state.editGroupError = action.payload.response.data.message
    },
    [editGroup.pending]: (state, action) => {
      state.editGroupStatus = 'pending'
    },
    [editGroup.fulfilled]: (state, action) => {
      state.editGroupStatus = 'success'
      state.editGroupError = null
    },

    [hideShowAllGroups.fulfilled]: (state, action) => {
      state.allGroupShowHideStatus = 'success'
    },
    [hideShowAllGroups.pending]: (state, action) => {
      state.allGroupShowHideStatus = 'loading'
    },
    [hideShowAllGroups.rejected]: (state, action) => {
      state.allGroupShowHideStatus = 'rejected'
      state.allGroupShowHideError = action.payload.response.data.message
    },
    [fetchSingleGroup.fulfilled]: (state, action) => {
      state.fetchedSingleGroup = action.payload
      state.fetchedSingleGroupStatus = 'success'
    },
    [fetchSingleGroup.pending]: (state, action) => {
      state.fetchedSingleGroupStatus = 'loading'
    },
    [hideShowAllGroups.rejected]: (state, action) => {
      state.fetchedSingleGroupStatus = 'rejected'
      state.fetchedSingleGroupError = action.payload.response.data.message
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setColor,
  setMyColorForSomeonesGroup,
  setGroupTitle,
  resetGroupAPICallStatus,
  setSelectedGroup,
} = groupSlice.actions

export default groupSlice.reducer
