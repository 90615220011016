/* NOT USED COMPONENT */
import { ReactSVGIcon } from 'components'
import {
  setSearchIsOpen,
  setSingeEventDescIsOpen,
  setSingleEvent,
} from 'features'
import moment from 'moment'
import React from 'react'
import { useDispatch } from 'react-redux'

const EventsRequest = ({ event }) => {
  const dispatch = useDispatch()
  const onClickHandler = () => {
    dispatch(setSingeEventDescIsOpen(true))
    dispatch(setSearchIsOpen(false))
    dispatch(setSingleEvent(event))
  }
  return (
    <div
      onClick={onClickHandler}
      key={event._id}
      className={` w-full cursor-pointer h-[4.5rem] text-sm  relative mb-3 group `}
    >
      {/* Event duration line */}
      <span className='w-full grid grid-cols-6  absolute rounded-tl-xl rounded-tr-xl overflow-hidden '>
        <div
          style={{
            opacity: 100,
            backgroundColor: event?.group?.color,
            gridColumnStart: 1,
            gridColumnEnd: 2,
          }}
          className='w-full h-2'
        ></div>
      </span>
      {/* Background line */}
      <span
        style={{
          backgroundColor: event?.group?.color,
        }}
        className='w-full h-2 top-0 absolute opacity-40  rounded-tl-xl rounded-tr-xl  '
      ></span>
      {/* Event background color */}
      <div
        style={{ backgroundColor: event?.group?.color }}
        className='w-full h-[4.5rem] opacity-50 absolute rounded-xl group-hover:opacity-70'
      ></div>
      {/* Content */}
      <div
        className={`flex relative z-50 items-center  w-full h-full overflow-hidden`}
      >
        <ReactSVGIcon name={event?.icon?.name} />

        <div className={` flex flex-col  justify-center px-1 pt-1   `}>
          <p className=' text-left font-light truncate  w-44'>{event?.title}</p>
          <p className=' text-left font-light truncate  w-44 '>
            {event?.description}
          </p>
          <div className='flex font-bold sm:font-semibold gap-1 truncate'>
            <p>{moment(event.startTime).format(' HH:mm A')} </p> -
            <p> {moment(event.endTime).format(' HH:mm A')} </p>
          </div>
        </div>
        <div className='pr-4 flex right-0 absolute'></div>
      </div>
    </div>
  )
}

export default EventsRequest
