import React from 'react'

const UnumLogoText = () => {
  return (
    <svg
      className='mt-2'
      width='70'
      height='17'
      viewBox='0 0 70 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M58.0525 7.31516V14.0906C58.0525 15.2731 57.0935 16.2331 55.9108 16.2331C54.7281 16.2331 53.7666 15.2731 53.7666 14.0906V2.14246C53.7666 1.85219 53.8256 1.57504 53.9311 1.32256C54.0339 1.06957 54.1908 0.832774 54.3939 0.627604C54.5996 0.422177 54.8361 0.267131 55.0907 0.162746C55.3426 0.0578475 55.6203 0 55.9108 0C56.1988 0 56.4764 0.0578475 56.731 0.162746C56.9829 0.267131 57.2195 0.422177 57.4252 0.627604L61.8834 5.08657L66.3415 0.627604C66.5472 0.422177 66.7863 0.267131 67.0383 0.162746C67.2902 0.0578475 67.5679 0 67.8584 0C68.149 0 68.4267 0.0578475 68.6787 0.162746C68.9307 0.267131 69.1671 0.422177 69.3728 0.627604C69.5785 0.832774 69.7328 1.06957 69.8382 1.32256C69.9436 1.57504 70.0001 1.85219 70.0001 2.14246V14.0906C70.0001 15.2731 69.0411 16.2331 67.8584 16.2331C66.6757 16.2331 65.7142 15.2731 65.7142 14.0906V7.31516L63.3978 9.63168C62.5622 10.4683 61.2046 10.4683 60.369 9.63168L58.0525 7.31516Z'
        fill='#3BB9E0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M47.7926 2.14254C47.7926 0.960032 48.7527 0 49.9351 0C51.1176 0 52.0776 0.960032 52.0776 2.14254V8.11661C52.0776 12.5964 48.4408 16.2332 43.9611 16.2332C39.4813 16.2332 35.8445 12.5964 35.8445 8.11661V2.14254C35.8445 0.960032 36.8045 0 37.987 0C39.1694 0 40.1295 0.960032 40.1295 2.14254V8.11661C40.1295 10.2312 41.8464 11.9482 43.9611 11.9482C46.0757 11.9482 47.7926 10.2312 47.7926 8.11661V2.14254Z'
        fill='#3BB9E0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9481 2.14254C11.9481 0.960032 12.9082 0 14.0906 0C15.2731 0 16.2331 0.960032 16.2331 2.14254V8.11661C16.2331 12.5964 12.5963 16.2332 8.11657 16.2332C3.63681 16.2332 0 12.5964 0 8.11661V2.14254C0 0.960032 0.960032 0 2.1425 0C3.32497 0 4.285 0.960032 4.285 2.14254V8.11661C4.285 10.2312 6.00195 11.9482 8.11657 11.9482C10.2312 11.9482 11.9481 10.2312 11.9481 8.11661V2.14254Z'
        fill='#3BB9E0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.2068 7.34215V14.0906C22.2068 15.2731 21.2477 16.2331 20.065 16.2331C18.8824 16.2331 17.9233 15.2731 17.9233 14.0906V2.14246C17.9233 1.85065 17.9825 1.57246 18.0879 1.31895C18.1933 1.06493 18.3476 0.827623 18.5558 0.621939C18.7615 0.418312 18.998 0.264822 19.2474 0.161209C19.4994 0.0573381 19.7771 0 20.065 0C20.3581 0 20.6357 0.0583727 20.8877 0.1643C21.1422 0.269713 21.3788 0.426033 21.5845 0.633002L29.8709 8.97939V2.14246C29.8709 0.960033 30.83 0 32.0127 0C33.1953 0 34.157 0.960033 34.157 2.14246V14.0906C34.157 15.2731 33.1953 16.2331 32.0127 16.2331C31.9947 16.2331 31.9767 16.2331 31.9587 16.2326C31.3956 16.2411 30.8325 16.0303 30.4057 15.6001L22.2068 7.34215Z'
        fill='#3BB9E0'
      />
    </svg>
  )
}

export default UnumLogoText
