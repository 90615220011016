/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-concat */
import {
  fetchIcons,
  setGroupId,
  setIconId,
  fetchGroups,
  setEventTitle,
  setRightSideViewIsOpen,
  setEventStartTime,
  setEventStartDate,
  setEventEndDate,
  setEventEndTime,
  editEvent,
  setEditingEventIsOpen,
  setReminderIsOpen,
  fetchSingleGroup,
  setParticipantsIsOpen,
  setEventRepeatIsOpen,
  setSingeEventDescIsOpen,
  setSingeEventDesc2IsOpen,
} from 'features'
import { forwardRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import { currentDate, userId } from 'utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addEventSchema } from 'schema'
import useDarkMode from './useDarkMode'

const useAddEvent = (createEvent) => {
  const [showGroupList, setShowGroupList] = useState(false)
  const groups = useSelector((state) => state.group.fetchedGroups)

  const {
    selectedGroupId,
    selectedIconId,
    eventTitle,
    eventDescription,
    createEventStatus,
    fetchedIcons,
    eventStartDate,
    eventStartTime,
    eventEndDate,
    eventEndTime,
    singleEvent,
    eventReminder,
    eventRepeat,
    participants,
  } = useSelector((state) => state.event)
  const {
    editingEventIsOpen,
    reminderIsOpen,
    participantsIsOpen,
    eventRepeatIsOpen,
    eventLocationIsOpen,
  } = useSelector((state) => state.sideView)
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )

  const dispatch = useDispatch()
  const [showIconList, setShowIconList] = useState(false)
  const selectedGroup = groups.filter((item) => item._id === selectedGroupId)

  const selectedIcon = fetchedIcons.filter(
    (item) => item._id === selectedIconId
  )
  const [enabled, setEnabled] = useState(false)
  const imageSrc = selectedIconId
    ? selectedIcon[0]?.name
    : fetchedIcons[0]?.name

  const [location, setLocation] = useState({})

  const onPlaceChanged = (place) => {
    setLocation({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      address: place.formatted_address,
    })
  }

  useEffect(() => {
    dispatch(fetchGroups())
  }, [dispatch, createEventStatus])

  useEffect(() => {
    if (createEventStatus === 'rejected') {
      toast.error('Event creation failed')
    }
  }, [createEventStatus])

  useEffect(() => {
    dispatch(fetchIcons())
  }, [dispatch, setShowIconList])

  // dispatch(setEventTitle(watch('title')))

  /* Overwrite event edn date with event start date */
  useEffect(() => {
    if (!editingEventIsOpen) {
      dispatch(setEventEndDate(eventStartDate))
    }
  }, [dispatch, editingEventIsOpen, eventStartDate])

  /* Set default group */
  useEffect(() => {
    if (!selectedGroupId) {
      dispatch(setGroupId(groups[0]?._id))
    }
  }, [dispatch, groups, selectedGroupId])

  /* Set default icon */

  useEffect(() => {
    if (!selectedIconId) {
      dispatch(setIconId(fetchedIcons[0]?._id))
    }
  }, [dispatch, fetchedIcons, selectedIconId])

  /* Custom Inputs */
  const StartDateExampleCustomInput = forwardRef(({ _, onClick }, ref) => (
    <span
      className={`${
        enabled ? 'cursor-not-allowed bg-gray-200' : ''
      } cursor-pointer ${
        nightMode
          ? 'bg-DM2ndLighterTextIcon '
          : 'bg-GrayMediumBGColor bg-opacity-20'
      } py-1 px-2 rounded-md ${defaultTextIconColor}`}
      onClick={onClick}
      ref={ref}
    >
      {eventStartDate.getDate().toString().padStart(2, '0') +
        ' ' +
        eventStartDate.toLocaleString('en-us', { month: 'short' }) +
        ' ' +
        eventStartDate.getFullYear()}
    </span>
  ))

  const StartTimeExampleCustomInput = forwardRef(({ _, onClick }, ref) => (
    <span
      className={`${
        enabled ? 'cursor-not-allowed bg-gray-200' : ''
      } cursor-pointer ${
        nightMode
          ? 'bg-DM2ndLighterTextIcon '
          : 'bg-GrayMediumBGColor bg-opacity-20'
      } py-1 px-2 rounded-md ${defaultTextIconColor}`}
      onClick={onClick}
      ref={ref}
    >
      {eventStartTime.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}
    </span>
  ))

  const EndDateExampleCustomInput = forwardRef(({ _, onClick }, ref) => (
    <span
      className={`${
        enabled ? 'cursor-not-allowed bg-gray-200' : ''
      } cursor-pointer ${
        nightMode
          ? 'bg-DM2ndLighterTextIcon '
          : 'bg-GrayMediumBGColor bg-opacity-20'
      } py-1 px-2 rounded-md ${defaultTextIconColor}`}
      onClick={onClick}
      ref={ref}
    >
      {eventEndDate.getDate().toString().padStart(2, '0') +
        ' ' +
        eventEndDate.toLocaleString('en-us', { month: 'short' }) +
        ' ' +
        eventEndDate.getFullYear()}
    </span>
  ))
  const EndTimeExampleCustomInput = forwardRef(({ _, onClick }, ref) => (
    <span
      className={`${
        enabled ? 'cursor-not-allowed bg-gray-200' : ''
      } cursor-pointer ${
        nightMode
          ? 'bg-DM2ndLighterTextIcon '
          : 'bg-GrayMediumBGColor bg-opacity-20'
      } py-1 px-2 rounded-md ${defaultTextIconColor}`}
      onClick={onClick}
      ref={ref}
    >
      {eventEndTime.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}
    </span>
  ))

  /* Use Form */
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(addEventSchema),
  })

  const onSubmit = (data) => {
    const oneDayData = {
      title: data.title,
      description: eventDescription,
      groupId: selectedGroupId,
      iconId: selectedIconId,
      startTime: moment(currentDate).format('MM/DD/YYYY') + ' ' + '00:01',
      endTime: moment(currentDate).format('MM/DD/YYYY') + ' ' + '23:59',
      location: location,
      userId: userId(),
      editType: 'single',
      pattern: eventRepeat.value,
    }
    const dataToSend = {
      title: data.title,
      description: eventDescription,
      groupId: selectedGroupId,
      iconId: selectedIconId,
      startTime:
        moment(eventStartDate).format('MM/DD/YYYY') +
        ' ' +
        eventStartTime.getHours() +
        ':' +
        eventStartTime.getMinutes(),
      endTime:
        moment(eventEndDate).format('MM/DD/YYYY') +
        ' ' +
        eventEndTime.getHours() +
        ':' +
        eventEndTime.getMinutes(),
      location: location,
      userId: userId(),
      pattern: eventRepeat.value,
      editType: 'single',
    }

    const updatedEventData = {
      eventId: singleEvent?._id,
      title: data.title,
      description: eventDescription,
      groupId: selectedGroupId,
      iconId: selectedIconId,
      startTime:
        moment(eventStartDate).format('MM/DD/YYYY') +
        ' ' +
        eventStartTime.getHours() +
        ':' +
        eventStartTime.getMinutes(),
      endTime:
        moment(eventEndDate).format('MM/DD/YYYY') +
        ' ' +
        eventEndTime.getHours() +
        ':' +
        eventEndTime.getMinutes(),
      location: location,
      userId: userId(),
      editType: 'single',
    }
    dispatch(setRightSideViewIsOpen(false))
    if (
      +moment(eventStartDate).format('DD') >
        +moment(eventEndDate).format('DD') &&
      moment(eventStartDate).format('MM/YYYY') ===
        moment(eventEndDate).format('MM/YYYY')
    ) {
      toast.error('End date must be greater than start date')
    } else {
      if (!enabled) {
        if (editingEventIsOpen) {
          dispatch(editEvent(updatedEventData))
          dispatch(setEditingEventIsOpen(false))
          toast.success('Event updated successfully')
          dispatch(setSingeEventDescIsOpen(false))
          dispatch(setSingeEventDesc2IsOpen(false))
        } else {
          dispatch(createEvent(dataToSend))
          toast.success('Event created successfully')
        }
      } else {
        dispatch(createEvent(oneDayData))
      }
    }

    dispatch(setEventStartDate(new Date()))
    dispatch(setEventStartTime(new Date()))
    dispatch(setEventStartDate(new Date()))
    dispatch(setEventEndTime(new Date()))

    reset()
  }

  const maxTime = new Date(
    eventStartTime.toLocaleString('en-us', { month: 'long' }) +
      eventStartTime.getDate() +
      ', ' +
      eventStartTime.getFullYear() +
      ' 23:45:00'
  )

  /* Repeat */
  const openRepeatHandler = () => {
    dispatch(setEventRepeatIsOpen(true))
  }

  /* Reminders */
  const openReminderHandler = () => {
    dispatch(setReminderIsOpen(true))
  }

  /* Participants */
  const { fetchedSingleGroup } = useSelector((state) => state.group)
  useEffect(() => {
    if (selectedGroupId) {
      dispatch(fetchSingleGroup(selectedGroupId))
    }
  }, [dispatch, selectedGroupId])

  const openParticipantsHandler = () => {
    dispatch(setParticipantsIsOpen(true))
  }

  return {
    groups,
    selectedGroup,
    selectedGroupId,
    dispatch,
    StartDateExampleCustomInput,
    EndDateExampleCustomInput,
    StartTimeExampleCustomInput,
    EndTimeExampleCustomInput,
    showGroupList,
    setShowGroupList,
    eventTitle,
    eventDescription,
    showIconList,
    setShowIconList,
    fetchedIcons,
    selectedIconId,
    selectedIcon,
    onSubmit,
    enabled,
    setEnabled,
    maxTime,
    register,
    handleSubmit,
    watch,
    errors,
    onPlaceChanged,
    imageSrc,

    eventStartDate,
    eventStartTime,
    eventEndDate,
    eventEndTime,
    reminderIsOpen,
    eventReminder,
    eventRepeat,
    defaultTextIconColor,
    nightMode,
    participants,
    participantsIsOpen,
    eventRepeatIsOpen,
    eventLocationIsOpen,
    openRepeatHandler,
    fetchedSingleGroup,
    openReminderHandler,
    openParticipantsHandler,
  }
}

export default useAddEvent
