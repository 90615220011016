import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import eventReducer from 'features/event/eventSlice'
import groupReducer from 'features/group/groupSlice'
import sideViewReducer from 'features/sideView/sideViewSlice'
import userReducer from 'features/user/userSlice'
import connectionsReducer from 'features/connections/connectionsSlice'
import themeReducer from 'features/theme/themeSlice'
import authReducer from 'features/auth/authSlice'

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
})

export const store = configureStore({
  reducer: {
    event: eventReducer,
    group: groupReducer,
    sideView: sideViewReducer,
    user: userReducer,
    connection: connectionsReducer,
    theme: themeReducer,
    auth: authReducer,
  },
  middleware: customizedMiddleware,
})
