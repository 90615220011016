import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import eventService from './eventService'

export const fetchIcons = createAsyncThunk(
  'events/fetchIcons',
  async function (_, { rejectWithValue }) {
    try {
      return await eventService.fetchIcons()
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const fetchEvents = createAsyncThunk(
  'events/fetchEvents',
  async function (_, { rejectWithValue }) {
    try {
      return await eventService.fetchEvents()
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const fetchPerDayEvent = createAsyncThunk(
  'events/fetchPerDayEvent',
  async function (data, { rejectWithValue }) {
    try {
      return await eventService.fetchPerDayEvent(data)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const fetchSingleEvent = createAsyncThunk(
  'events/fetchSingleEvent',
  async function (data, { rejectWithValue }) {
    try {
      return await eventService.fetchSingleEvent(data)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const fetchPerGroupEvents = createAsyncThunk(
  'events/fetchPerGroupEvents',
  async function (data, { rejectWithValue }) {
    try {
      return await eventService.fetchPerGroupEvents(data)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const createEvent = createAsyncThunk(
  'events/createEvent',
  async function (dataForSubmit, { rejectWithValue }) {
    try {
      return await eventService.createEvent(dataForSubmit)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const editEvent = createAsyncThunk(
  'events/editEvent',
  async function (dataForSubmit, { rejectWithValue }) {
    try {
      return await eventService.editEvent(dataForSubmit)
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)

const initialState = {
  date: '',
  fetchedEvents: [],
  fetchedEventStatus: '',
  fetchedEventError: null,
  fetchedSingleEvent: {},
  fetchedSingleEventStatus: '',
  fetchedSingleEventError: null,
  fetchedIcons: [],
  fetchedPerDayEvents: [],
  eventTitle: '',
  selectedGroupId: '',
  selectedIconId: '',
  eventDescription: '',
  eventStartDate: new Date(),
  eventStartTime: new Date(),
  eventEndDate: new Date(),
  eventEndTime: new Date(),
  eventReminder: 'None',
  eventRepeat: { text: 'Never', value: 'never' },
  createEventStatus: '',
  createEventError: null,
  editEventStatus: '',
  editEventError: null,

  switchView: '',
  itemId: 0,
  fetchedPerDayEventsStatus: '',
  fetchedPerDayEventsError: null,
  fetchedPerGroupEvents: {},
  fetchedPerGroupEventsStatus: '',
  fetchedPerGroupEventsError: null,
  startWeekDay: 1,
  weekView: [],
  monthView: 0,
  yearView: '',
  selectedDayOnCalendar: '',
  singleEvent: {},
}

export const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.date = action.payload
    },
    setWeekView: (state, action) => {
      state.weekView = action.payload
    },
    setMonthView: (state, action) => {
      state.monthView = action.payload
    },
    setYearView: (state, action) => {
      state.yearView = action.payload
    },
    setStartWeekDay: (state, action) => {
      state.startWeekDay = action.payload
    },
    setEventTitle: (state, action) => {
      state.eventTitle = action.payload
    },
    setGroupId: (state, action) => {
      state.selectedGroupId = action.payload
    },
    setIconId: (state, action) => {
      state.selectedIconId = action.payload
    },
    setEventDescription: (state, action) => {
      state.eventDescription = action.payload
    },
    setEventStartDate: (state, action) => {
      state.eventStartDate = action.payload
    },
    setEventStartTime: (state, action) => {
      state.eventStartTime = action.payload
    },
    setEventEndDate: (state, action) => {
      state.eventEndDate = action.payload
    },
    setEventEndTime: (state, action) => {
      state.eventEndTime = action.payload
    },
    setEventReminder: (state, action) => {
      state.eventReminder = action.payload
    },
    setEventRepeat: (state, action) => {
      state.eventRepeat = action.payload
    },
    resetEventInfo: (state, action) => {
      state.eventTitle = ''
      state.selectedGroupId = ''
      state.selectedIconId = ''
      state.eventDescription = ''
      state.eventStartDate = new Date()
      state.eventStartTime = new Date()
      state.eventEndDate = new Date()
      state.eventEndTime = new Date()
      state.eventRepeat = { text: 'Never', value: 'never' }
    },

    setItemId: (state, action) => {
      state.itemId = action.payload
    },
    setSwitchView: (state, action) => {
      state.switchView = action.payload
    },

    setSelectedDayOnCalendar: (state, action) => {
      state.selectedDayOnCalendar = action.payload
    },
    setSingleEvent: (state, action) => {
      state.singleEvent = action.payload
    },

    /* for rendering only */
    setEventHeight: (state, action) => {
      state.eventHeight = action.payload
    },
  },
  extraReducers: {
    [fetchIcons.fulfilled]: (state, action) => {
      state.fetchedIcons = action.payload
    },

    [fetchEvents.fulfilled]: (state, action) => {
      state.fetchedEventStatus = 'success'
      state.fetchedEvents = action.payload
    },

    [fetchEvents.pending]: (state, action) => {
      state.fetchedEventStatus = 'pending'
    },
    [fetchEvents.rejected]: (state, action) => {
      state.fetchedEventStatus = 'rejected'
      state.fetchedEventError = action.payload
    },
    [fetchSingleEvent.fulfilled]: (state, action) => {
      state.fetchedSingleEventStatus = 'success'
      state.fetchedSingleEvent = action.payload
    },

    [fetchSingleEvent.pending]: (state, action) => {
      state.fetchedSingleEventStatus = 'pending'
    },
    [fetchSingleEvent.rejected]: (state, action) => {
      state.fetchedSingleEventStatus = 'rejected'
      state.fetchedSingleEventError = action.payload
    },

    [createEvent.fulfilled]: (state, action) => {
      state.eventTitle = ''
      state.selectedGroupId = ''
      state.selectedIconId = ''
      state.eventDescription = ''
      state.createEventStatus = 'success'
    },
    [createEvent.pending]: (state, action) => {
      state.createEventStatus = 'pending'
    },
    [createEvent.rejected]: (state, action) => {
      state.createEventStatus = 'rejected'
      state.createEventError = action.payload
    },
    [editEvent.fulfilled]: (state, action) => {
      state.eventTitle = ''
      state.selectedGroupId = ''
      state.selectedIconId = ''
      state.eventDescription = ''
      state.editEventStatus = 'success'
    },
    [editEvent.pending]: (state, action) => {
      state.editEventStatus = 'pending'
    },
    [editEvent.rejected]: (state, action) => {
      state.editEventStatus = 'rejected'
      state.editEventError = action.payload
    },

    [fetchPerDayEvent.fulfilled]: (state, action) => {
      state.fetchedPerDayEventsStatus = 'success'
      state.fetchedPerDayEvents = action.payload
      state.fetchedPerDayEventsError = null
    },
    [fetchPerDayEvent.pending]: (state, action) => {
      state.fetchedPerDayEventsStatus = 'pending'
    },
    [fetchPerDayEvent.rejected]: (state, action) => {
      state.fetchedPerDayEventsStatus = 'rejected'
      state.fetchedPerDayEventsError = action.payload
    },
    [fetchPerGroupEvents.fulfilled]: (state, action) => {
      state.fetchedPerGroupEventsStatus = 'success'
      state.fetchedPerGroupEvents = action.payload.data
      state.fetchedPerGroupEventsError = null
    },
    [fetchPerGroupEvents.pending]: (state, action) => {
      state.fetchedPerGroupEventsStatus = 'pending'
    },
    [fetchPerGroupEvents.rejected]: (state, action) => {
      state.fetchedPerGroupEventsStatus = 'rejected'
      state.fetchedPerGroupEventsError = action.payload
      state.fetchedPerGroupEvents = {}
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setDate,
  setSwitchView,
  setEventTitle,
  setGroupId,
  setIconId,
  setEventDescription,
  setEventStartDate,
  setEventStartTime,
  setEventEndDate,
  setEventEndTime,
  setItemId,
  setWeekView,
  setSelectedDayOnCalendar,
  setStartWeekDay,
  setEventHeight,
  setSingleEvent,
  setMonthView,
  setYearView,
  resetEventInfo,
  setEventReminder,
  setEventRepeat,
} = eventSlice.actions

export default eventSlice.reducer
