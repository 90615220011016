import { AuthLayout } from 'components'
import { Link, useNavigate } from 'react-router-dom'
import { UnumSection } from '..'
import { useEffect } from 'react'

const GetStarted = () => {
  const navigate = useNavigate()
  useEffect(() => {
    let token = localStorage.getItem('token')
    console.log(token)
    if (token) {
      navigate('/calendar')
    }
  }, [navigate])

  return (
    <AuthLayout>
      <UnumSection />
      <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
        <Link to='/sign-mobile'>
          <button
            type='button'
            className='flex w-[200px] mt-[100px] md:mt-[20vh] mx-auto justify-center rounded-md bg-BlueDarkColorMain px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-BlueDarkColorMainHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            Get started
          </button>
        </Link>
        <img
          className='mx-auto mt-[100px] h-[22px] w-auto'
          src='/images/company-logo.png'
          alt='unum'
        />
        <div className='text-center mt-4 text-white'>
          <span>by</span>{' '}
          <Link className='underline' to='#'>
            Onyx Labs
          </Link>
        </div>
      </div>
    </AuthLayout>
  )
}

export default GetStarted
