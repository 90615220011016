/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const LocationOnIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 23 23'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path
        d='M11.2114 11.0896C11.6888 11.0896 12.0993 10.9196 12.4431 10.5796C12.7868 10.2397 12.9587 9.831 12.9587 9.3536C12.9587 8.8762 12.7887 8.46563 12.4488 8.12188C12.1088 7.77813 11.7001 7.60625 11.2227 7.60625C10.7453 7.60625 10.3348 7.77623 9.99102 8.11619C9.64727 8.45615 9.47539 8.86483 9.47539 9.34223C9.47539 9.81963 9.64537 10.2302 9.98533 10.574C10.3253 10.9177 10.734 11.0896 11.2114 11.0896ZM11.2228 20.3708C11.0509 20.3708 10.8848 20.3441 10.7243 20.2906C10.5639 20.2372 10.4226 20.1569 10.3004 20.05C8.00872 18.0036 6.30143 16.1139 5.17852 14.3811C4.0556 12.6483 3.49414 11.0317 3.49414 9.53125C3.49414 7.12081 4.27024 5.2005 5.82245 3.7703C7.37466 2.3401 9.17216 1.625 11.2149 1.625C13.2578 1.625 15.056 2.3401 16.6096 3.7703C18.1632 5.2005 18.94 7.12081 18.94 9.53125C18.94 11.0285 18.3785 12.6443 17.2556 14.3787C16.1327 16.1131 14.4254 18.0036 12.1337 20.05C12.0115 20.1569 11.8721 20.2372 11.7155 20.2906C11.5589 20.3441 11.3947 20.3708 11.2228 20.3708Z'
        fill='#FAFAFA'
      />
    </svg>
  )
}

export default LocationOnIcon
