/* Making HTTP requests */

import axios from 'axios'
import { userId } from 'utils'

const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
}

const fetchEvents = async () => {
  const results = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/events`,
    {
      params: { start: '08/01/2023', end: '09/30/2023', userId: userId() },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }
  )

  return results.data
}
const fetchSingleEvent = async (eventId) => {
  const results = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/event`,
    {
      params: { eventId },
      ...config,
    }
  )

  return results.data
}

const fetchIcons = async () => {
  const results = await axios(
    `${process.env.REACT_APP_BASE_URL}/api/v1/icons`,
    config
  )
  return results.data
}

const createEvent = async (dataForSubmit) => {
  const results = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/add-event`,
    dataForSubmit,
    config
  )

  return results.data
}
const editEvent = async (dataForSubmit) => {
  const results = await axios.patch(
    `${process.env.REACT_APP_BASE_URL}/api/v1/edit-event`,
    dataForSubmit,
    config
  )

  return results.data
}

const fetchPerDayEvent = async (date) => {
  const results = await axios(
    `${process.env.REACT_APP_BASE_URL}/api/v1/events/per-day`,

    {
      params: { date, userId: userId() },
      ...config,
    }
  )
  return results.data
}

const fetchPerGroupEvents = async (groupId) => {
  const results = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/events/group/${groupId}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }
  )
  return results
}

const eventService = {
  fetchEvents,
  fetchSingleEvent,
  createEvent,
  fetchIcons,
  fetchPerDayEvent,
  fetchPerGroupEvents,
  editEvent,
}

export default eventService
