import moment from 'moment'
import { useEventWeek, useMonthView } from 'hooks'
import { currentDate } from 'utils'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const MonthAppleView = ({
  item,
  monthCount,
  weekCount,
  item2,
  itemWeeks,
  numWeek,
  nightMode,
  defaultTextIconColor,
}) => {
  const { myRef } = useMonthView(monthCount, itemWeeks)
  const { oneDayHandler } = useEventWeek(itemWeeks, item)
  const { currentTimeIndicator } = useSelector((state) => state.sideView)

  /* Red line indicator */
  const [indicatorPosition, setIndicatorPosition] = useState(0)
  useEffect(() => {
    // Function to calculate and update the indicator position
    const updateIndicatorPosition = () => {
      const now = new Date()
      const hours = now.getHours()
      const minutes = now.getMinutes()

      const totalMinutesInDay = 24 * 60
      const currentTimeInMinutes = hours * 60 + minutes
      const percentageOfDayPassed =
        (currentTimeInMinutes / totalMinutesInDay) * 100

      setIndicatorPosition(percentageOfDayPassed)
    }

    // Initial update
    updateIndicatorPosition()

    // Set up an interval to update the indicator every 1 hour
    const intervalId = setInterval(() => {
      updateIndicatorPosition()
    }, 3600000) // 3600000 milliseconds = 1 hour

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  /* END Red line indicator */

  return (
    <div
      ref={myRef}
      onClick={oneDayHandler}
      className={`grid grid-flow-col snap-start h-[17rem] relative overflow-hidden my-[4px] ${defaultTextIconColor} ${
        item.isCurrentMonth === false ? 'opacity-50   ' : ''
      } } z-0 month-view-week last:rounded-tr-xl last:rounded-br-xl  ${
        nightMode
          ? 'bg-DMDarkBlueForeground bg-opacity-50 hover:bg-DMDarkBlueForeground'
          : 'hover:bg-[#e2e8ee] bg-[#F2F6FB]'
      }   overflow-x-scroll `}
    >
      <div
        className={`text-center rounded-lg overflow-hidden z-30 cursor-pointer  sm:py-5  ${
          item2[item2?.length - 1]?.fullDate === item.fullDate
            ? 'rounded-tr-xl rounded-br-xl'
            : ''
        } ${
          item2[0]?.fullDate === item.fullDate
            ? 'first:rounded-tl-xl  rounded-bl-xl'
            : ''
        }    ${
          moment(currentDate).format('YYYY-MM-DD') === item.fullDate
            ? nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-[#e2e8ee]'
            : ''
        } ${item.isCurrentMonth === false ? 'opacity-30   ' : ''}`}
      >
        {/* Timeline indicator */}
        {moment(currentDate).format('YYYY-MM-DD') === item.fullDate &&
          currentTimeIndicator && (
            <div
              style={{ left: `${indicatorPosition}%` }}
              className={`h-full absolute mt-8 sm:mt-3 flex justify-center  `}
            >
              <div
                className='w-0 h-0 
              border-l-[8px] border-l-transparent
              border-t-[8px] border-t-RedAccentColor
              border-r-[8px] border-r-transparent'
              ></div>

              {/* Vertical Line */}
              <div className='  absolute h-full w-px bg-RedAccentColor'></div>
            </div>
          )}

        <div
          className={`flex justify-center sm:border-b border-DivideLine  items-center absolute top-0 ${
            nightMode
              ? 'sm:bg-DMDarkBlueForeground border-opacity-20'
              : 'sm:bg-[#e2e8ee]'
          } w-full space-x-2  h-[2rem]`}
        >
          <div className='flex text-xs opacity-90'>
            <p className='pr-2 uppercase sm:block hidden'>{item.day}</p>
            <div>
              <p>
                {item.month} {item.date}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MonthAppleView
