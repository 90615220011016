import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { currentDate } from 'utils'
import { useEventWeek } from 'hooks'
import { useSelector } from 'react-redux'

const Week = ({ dayOfWeek, week, id, nightMode, defaultTextIconColor }) => {
  const { myRef, oneDayHandler } = useEventWeek(week, dayOfWeek)
  const { currentTimeIndicator } = useSelector((state) => state.sideView)

  /* Red line indicator */
  const [indicatorPosition, setIndicatorPosition] = useState(0)
  useEffect(() => {
    // Function to calculate and update the indicator position
    const updateIndicatorPosition = () => {
      const now = new Date()
      const hours = now.getHours()
      const minutes = now.getMinutes()

      const totalMinutesInDay = 24 * 60
      const currentTimeInMinutes = hours * 60 + minutes
      const percentageOfDayPassed =
        (currentTimeInMinutes / totalMinutesInDay) * 100

      setIndicatorPosition(percentageOfDayPassed)
    }

    // Initial update
    updateIndicatorPosition()

    // Set up an interval to update the indicator every 1 hour
    const intervalId = setInterval(() => {
      updateIndicatorPosition()
    }, 3600000) // 3600000 milliseconds = 1 hour

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  /* END Red line indicator */

  //bg-[#F6FAFF]
  return (
    <div
      ref={myRef}
      onClick={oneDayHandler}
      id={id}
      className={` cursor-pointer relative ${
        nightMode ? 'hover:bg-DMDarkBlueForeground' : 'hover:bg-[#e2e8ee]'
      }  ${
        week[week?.length - 1]?.fullDate === dayOfWeek.fullDate
          ? 'rounded-tr-xl rounded-br-xl'
          : ''
      } ${
        week[0]?.fullDate === dayOfWeek.fullDate
          ? 'first:rounded-tl-xl  rounded-bl-xl'
          : ''
      }  h-[93vh]  ${
        moment(currentDate).format('YYYY-MM-DD') === dayOfWeek.fullDate &&
        nightMode
          ? '!bg-DMDarkBlueForeground'
          : moment(currentDate).format('YYYY-MM-DD') === dayOfWeek.fullDate &&
            !nightMode
          ? 'bg-[#e2e8ee]'
          : ''
      } ${defaultTextIconColor}`}
    >
      {/* Timeline indicator */}
      {moment(currentDate).format('YYYY-MM-DD') === dayOfWeek.fullDate &&
        currentTimeIndicator && (
          <div
            style={{ left: `${indicatorPosition}%` }}
            className={`h-full absolute  pt-8 flex justify-center  `}
          >
            <div
              className='w-0 h-0 
              border-l-[8px] border-l-transparent
              border-t-[8px] border-t-RedAccentColor
              border-r-[8px] border-r-transparent'
            ></div>

            {/* Vertical Line */}
            <div className='  absolute h-full w-px bg-RedAccentColor'></div>
          </div>
        )}
      <div
        className={`h-[2rem] sm:border-b border-0 border-DivideLine ${
          nightMode ? ' border-opacity-20' : ''
        }`}
      >
        <div
          className={`flex justify-center items-center h-full  ${
            nightMode ? 'sm:bg-DMDarkBlueForeground' : 'sm:bg-[#e2e8ee]'
          }`}
        >
          <div className='flex text-xs opacity-90'>
            <p className=' pr-2 uppercase sm:block hidden'>{dayOfWeek.day}</p>
            <p c>
              {dayOfWeek.month} {dayOfWeek.date}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Week
