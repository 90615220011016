import React from 'react'
import { useSelector } from 'react-redux'

const InnerDrawer = ({
  children,
  isOpen,
  setIsOpen,
  position,
  isLeftBarOpened,
  className,
}) => {
  const { nightMode } = useSelector((state) => state.theme)
  return (
    <main
      className={`fixed overflow-hidden md:-z-10  bg-opacity-25 inset-0 transform ease-in-out ${
        isOpen
          ? 'transition-opacity opacity-100'
          : 'delay-500 opacity-0 translate-x-full'
      }`}
    >
      <section
        className={`w-[100%] sm:w-[24rem] ${
          position === 'left'
            ? `left-0 ${
                isLeftBarOpened ? 'md:left-[40.7rem]' : 'md:left-[27.5rem]'
              }`
            : 'right-0'
        } absolute ${
          nightMode ? 'bg-DMDarkBlueBackground' : 'bg-white'
        } ${className} h-full shadow-xl duration-500 ease-in-out transition-all ${
          isOpen
            ? ''
            : `${
                position === 'left' ? '-translate-x-full' : 'translate-x-full'
              }`
        }`}
      >
        <article className=''>{children}</article>
      </section>
      <section
        className=' w-screen h-full cursor-pointer '
        onClick={() => {
          setIsOpen(false)
        }}
      ></section>
    </main>
  )
}

export default InnerDrawer
