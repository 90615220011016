import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const PersonalCard = ({ name, img, onClick }) => {
  const [avatarOrEmail, setAvatarOrEmail] = useState(null)

  useEffect(() => {
    const checkAvatarAndSetState = async () => {
      if (img) {
        try {
          const avatarUrl = `${process.env.REACT_APP_BASE_URL}/${img}`
          const response = await axios.head(avatarUrl)

          if (response.status === 200) {
            setAvatarOrEmail(
              <img
                alt='user'
                src={avatarUrl}
                className='rounded-xl w-[60px] h-[3.75rem] aspect-square object-cover  cursor-pointer'
              />
            )
            return
          }
        } catch (error) {
          // Avatar does not exist on the server, fall through to render email slice
        }
      }

      setAvatarOrEmail(
        <div className=' flex items-center justify-center w-[60px] h-[3.75rem] rounded-xl bg-slate-400'>
          <p className='text-lg'>{name.slice(0, 2)}</p>
        </div>
      )
    }

    checkAvatarAndSetState()
  }, [img, name])

  const { nightMode } = useSelector((state) => state.theme)
  return (
    <div
      onClick={onClick}
      className={`h-[3.75rem] cursor-pointer my-3 relative w-full flex items-center rounded-xl ${
        nightMode ? 'bg-DMDarkBlueForeground' : ' bg-gray-300'
      }`}
    >
      {avatarOrEmail}

      <div className='pl-3'>
        <p className='text-[#FAFAFA] !leading-[14px] font-medium py-0 text-sm'>
          {name}
        </p>
        {/*  <p className='text-[#778EAE] !leading-[14px] font-medium py-0 text-[13px]'>
          Personal Card
        </p> */}
      </div>
      <div className='flex absolute right-3  items-center h-full justify-center space-x-1'>
        <div className='w-[15px] h-[15px] rounded-full bg-blue-500' />
        <div className='w-[15px] h-[15px] rounded-full bg-orange-500' />
        <div className='w-[15px] h-[15px] rounded-full bg-green-500' />
      </div>
    </div>
  )
}

export default PersonalCard
