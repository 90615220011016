/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react'
import { Search } from './components'
import { useSelector, useDispatch } from 'react-redux'
import {
  cancelInvite,
  deleteNotification,
  fetchUserInfo,
  sendInviteToFriendRequest,
  sendInviteToMember,
  setCancelSocketData,
  setSocketData,
  userSearchRequest,
} from 'features'
import { useDebounce } from 'use-debounce'
import { userId } from 'utils'
import uuid from 'react-uuid'
import { useSocket, useUser } from 'hooks'
import axios from 'axios'
import { ChevronLeftIcon } from 'components/Icons'

const AddContact = ({ contactViewHandler }) => {
  const dispatch = useDispatch()
  const { nightMode } = useSelector((state) => state.theme)

  // /* Socket IO */
  const { socket } = useSocket()

  // connect to the socket server
  useEffect(() => {
    if (socket) {
      const handleGetInvite = (data) => {
        dispatch(setSocketData(data))
      }

      const handleCancelInvite = (data) => {
        dispatch(setCancelSocketData(data))
      }

      socket.on('cancelledInvite', handleCancelInvite)

      socket.on('getInvite', handleGetInvite)

      // Clean up the event listener when the component unmounts
      return () => {
        socket.off('getInvite', handleGetInvite)
        socket.off('cancelledInvite', handleCancelInvite)
      }
    }
  }, [dispatch, socket])

  /* Search */

  const [searchTerm, setSearchTerm] = useState('')

  const [searchResults, setSearchResults] = useState([])
  const [debouncedValue] = useDebounce(searchTerm, 500)

  useEffect(() => {
    const searchHandler = async () => {
      if (debouncedValue.trim() !== '') {
        try {
          const data = await userSearchRequest(debouncedValue)
          setSearchResults(data.SearchResult)
        } catch (error) {
          console.log(error)
        }
      }
    }
    searchHandler()
  }, [debouncedValue])

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
  }
  const firstInputRef = useRef(null)

  useEffect(() => {
    // Focus on the input element when the component mounts
    if (firstInputRef.current) {
      firstInputRef.current.focus()
    }
  }, [])

  const clearSearchInputHandler = () => {
    setSearchResults([])
    setSearchTerm('')
  }

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults([])
    }
  }, [searchTerm])

  /* Invitations */
  const { sendInvitationStatus, cancelInviteStatus } = useSelector(
    (state) => state.connection
  )
  const { fetchedUserInfo } = useSelector((state) => state.user)
  const friendRequestsSent = fetchedUserInfo?.friendRequestsSent

  const addNotification = async (forUser, typeId) => {
    const results = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add-notification`,

      {
        notificationFromUser: userId(),
        notificationType: 'contact',
        isRead: false,
        notificationForUser: forUser,
        typeId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )

    return results.data
  }
  useEffect(() => {
    dispatch(fetchUserInfo())
  }, [dispatch, sendInvitationStatus, cancelInviteStatus])

  const inviteContactHandler = (user) => {
    let data = {
      id: 'test',
      receiverId: user._id,
      senderId: userId(),
      type: 'contact',
    }
    dispatch(sendInviteToMember(data))

    if (socket) {
      setTimeout(() => {
        socket.emit('sendInvite', {
          senderId: userId(),
          receiverId: user._id,
        })
      }, 1000)
    }
    addNotification(user._id, user._id)
  }

  const cancelInviteHandler = (user) => {
    let data = {
      id: user._id,
      userId: userId(),
      type: 'contact',
    }

    dispatch(cancelInvite(data))

    dispatch(deleteNotification(data))

    setTimeout(() => {
      socket.emit('cancelInvite', {
        senderId: userId(),
        receiverId: user._id,
      })
    }, 1000)
  }
  return (
    <>
      <div className='pb-6 font-bold text-sm flex justify-between items-center relative h-full overflow-y-scroll '>
        <ChevronLeftIcon
          size={30}
          className={'cursor-pointer'}
          onClick={contactViewHandler}
        />
        <p className={`text-sm  `}>Add Contact</p>

        <ChevronLeftIcon size={30} className={'cursor-pointer invisible'} />
      </div>
      {/* Content */}
      <Search
        handleSearch={handleSearch}
        searchTerm={searchTerm}
        firstInputRef={firstInputRef}
        clearSearchInputHandler={clearSearchInputHandler}
      />

      <div className=' '>
        {/* Unum Contacts */}
        <h1
          className={`text-lg py-3 ${
            nightMode
              ? 'text-DM2ndLighterTextIcon'
              : 'text-LMDarkestBackground '
          }`}
        >
          Your Contacts in UNUM:
        </h1>
        {searchResults?.map((user) => {
          let invitedUser = friendRequestsSent.filter(
            (item) => item.friend === user._id
          )
          return (
            <div
              key={user._id}
              className={`relative rounded-2xl h-[3.75rem] overflow-hidden items-center w-full !px-0  py-6  flex justify-between mb-3 ${
                nightMode
                  ? 'bg-DMDarkBlueForeground'
                  : 'bg-GrayMediumBGColor bg-opacity-20'
              } `}
            >
              <div className='flex items-center'>
                <div
                  className={`flex  bg-[#778EAE] justify-center items-center rounded-2xl  w-[3.75rem] h-[3.75rem] `}
                >
                  <img
                    src='http://source.unsplash.com/100x100/?boy'
                    alt='profile'
                    className='cursor-pointer rounded-2xl'
                  />
                </div>
                <div className='truncate pl-3'>
                  <p className={`truncate `}>{user?.email}</p>
                  <span
                    className={` ${
                      nightMode
                        ? 'text-DM2ndLighterTextIcon'
                        : 'text-DarkText text-opacity-50'
                    }`}
                  >
                    {user?.nickNameId}
                  </span>
                </div>
              </div>
              {invitedUser[0]?.status === 'pending' ? (
                <div
                  onClick={cancelInviteHandler.bind(null, user)}
                  className='pr-4 flex '
                >
                  <p className='bg-gray-400 text-white w-[4.313rem] cursor-pointer h-[1.875rem] rounded-lg justify-center flex items-center text-sm '>
                    Cancel
                  </p>
                </div>
              ) : invitedUser[0]?.status === 'accepted' ? (
                <div className='pr-4 flex '>
                  <p className='bg-gray-400 text-white w-[4.313rem]  h-[1.875rem] rounded-lg justify-center flex items-center text-sm '>
                    Friend
                  </p>
                </div>
              ) : (
                <div
                  onClick={inviteContactHandler.bind(null, user)}
                  className='pr-4 flex '
                >
                  <p className='bg-[#6CE459] text-white w-[4.313rem] cursor-pointer h-[1.875rem] rounded-lg justify-center flex items-center text-sm '>
                    Connect
                  </p>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default AddContact
