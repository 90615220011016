import { ReactSVGIcon } from 'components'
import { useDarkSide } from 'hooks'
import useDailyEvent from 'hooks/useDailyEvent'
import React, { useEffect, useState } from 'react'
import { formatData } from 'utils'

const LongEvents = ({ event, selectedDayOnCalendar, moment }) => {
  const { startPosition, endPosition } = useDailyEvent(event)
  const [colorTheme] = useDarkSide()

  const [borderRadiusSide, setBorderRadiusSide] = useState('')

  useEffect(() => {
    if (
      formatData(event.startTime) === selectedDayOnCalendar &&
      formatData(event.endTime) !== selectedDayOnCalendar
    ) {
      setBorderRadiusSide('left')
    } else if (
      formatData(event.endTime) === selectedDayOnCalendar &&
      formatData(event.startTime) !== selectedDayOnCalendar
    ) {
      setBorderRadiusSide('right')
    } else {
      setBorderRadiusSide('none')
    }
  }, [
    event.endTime,
    event.startTime,
    borderRadiusSide,
    selectedDayOnCalendar,
    setBorderRadiusSide,
  ])

  return (
    <div className={` w-full h-[4.5rem] cursor-pointer group `}>
      {/* Event duration line */}
      <span
        className={`w-full grid grid-cols-6  absolute   overflow-hidden ${
          borderRadiusSide === 'left'
            ? 'rounded-tl-xl'
            : borderRadiusSide === 'right'
            ? 'rounded-tr-xl '
            : ''
        } `}
      >
        <div
          style={{
            opacity: 100,
            backgroundColor: event.group.color,
            gridColumnStart:
              borderRadiusSide === 'none' || borderRadiusSide === 'right'
                ? 1
                : startPosition,
            gridColumnEnd:
              borderRadiusSide === 'none' || borderRadiusSide === 'left'
                ? 7
                : endPosition,
          }}
          className='w-full h-2'
        ></div>
      </span>
      {/* Background line */}
      <span
        style={{
          backgroundColor: event.group.color,
        }}
        className={`w-full h-2 top-0 absolute opacity-40  ${
          borderRadiusSide === 'left'
            ? 'rounded-tl-xl'
            : borderRadiusSide === 'right'
            ? 'rounded-tr-xl '
            : ''
        }`}
      ></span>
      {/* Event background color */}
      <div
        style={{ backgroundColor: event.group.color }}
        className={`w-full h-[4.5rem] opacity-50 absolute group-hover:opacity-70 ${
          borderRadiusSide === 'left'
            ? 'rounded-bl-xl rounded-tl-xl'
            : borderRadiusSide === 'right'
            ? 'rounded-br-xl rounded-tr-xl '
            : ''
        }`}
      ></div>
      {/* Content */}
      <div
        className={`flex relative z-50 items-center  w-full h-full overflow-hidden`}
      >
        <ReactSVGIcon name={event?.icon?.name} />

        <div
          className={` flex flex-col  justify-center px-1  ${
            colorTheme === 'light' ? 'text-black' : 'text-white'
          }  `}
        >
          <p className=' text-left font-light truncate'>{event.title}</p>
          <p className=' text-left font-light truncate'>{event.description}</p>
          <div className='flex font-bold sm:font-semibold gap-1 truncate'>
            <p>{moment(event.startTime).format('MMM DD HH:mm')} </p>-
            <p> {moment(event.endTime).format('MMM DD HH:mm')} </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LongEvents
