import { ReactSVGIcon } from 'components'
import { useDarkSide } from 'hooks'
import useDailyEvent from 'hooks/useDailyEvent'
import moment from 'moment'
import React, { useLayoutEffect, useState } from 'react'
import { betweenDates } from 'utils'

const LongEventsM = ({ event, week }) => {
  const { startPosition, endPosition } = useDailyEvent(event)
  const [colorTheme] = useDarkSide()

  const [firstDayOfWeek, setFirstDayOfWeek] = useState()
  const [lastDayOfWeek, setLastDayOfWeek] = useState()

  const days = betweenDates(event) - 1
  let testArrayOfDiv = []
  for (let i = 0; i < days; ++i) {
    testArrayOfDiv.push(
      <div key={i} className={`w-full grid grid-cols-6  overflow-hidden`}>
        <div
          style={{
            opacity: 100,
            backgroundColor: event.group.color,
            gridColumnStart: 1,
            gridColumnEnd: 7,
          }}
          className='w-full  h-1.5'
        ></div>
      </div>
    )
  }
  const eventStartTime = new Date(moment(event.startTime).format('YYYY-MM-DD'))
  const eventEndTime = new Date(moment(event.endTime).format('YYYY-MM-DD'))
  // const firstDayOfWeek = new Date(week[0][0]?.fullDate)
  // const lastDayOfWeek = new Date(week[2][6]?.fullDate)

  useLayoutEffect(() => {
    week.map((item) => {
      setFirstDayOfWeek(new Date(item[0]?.fullDate))
      setLastDayOfWeek(new Date(item[6]?.fullDate))
    })
  }, [])

  return (
    <div className={` w-full h-full group `}>
      {/* Event duration line */}
      <div className='flex w-full absolute'>
        <div
          className={`w-full grid grid-cols-6 rounded-tl-md overflow-hidden ${
            eventStartTime < firstDayOfWeek ? '' : 'rounded-tl-md '
          }  `}
        >
          <div
            style={{
              opacity: 100,
              backgroundColor: event.group.color,
              gridColumnStart:
                eventStartTime < firstDayOfWeek ? 1 : startPosition,
              gridColumnEnd: 7,
            }}
            className='w-full  h-1.5'
          ></div>
        </div>
        {testArrayOfDiv}
        <div
          className={`w-full grid grid-cols-6 rounded-tr-md   overflow-hidden ${
            eventEndTime > lastDayOfWeek ? '' : 'rounded-tr-md '
          }`}
        >
          <div
            style={{
              opacity: 100,
              backgroundColor: event.group.color,
              gridColumnStart: 1,
              gridColumnEnd: eventEndTime > lastDayOfWeek ? 7 : endPosition,
            }}
            className='w-full  h-1.5'
          ></div>
        </div>
      </div>
      {/* Background line */}
      <span
        style={{
          backgroundColor: event.group.color,
        }}
        className='w-full h-1.5 top-0 absolute opacity-40  rounded-tl-md rounded-tr-md   '
      ></span>
      {/* Event background color */}
      <div
        style={{ backgroundColor: event.group.color }}
        className={`w-full h-full opacity-50 absolute rounded-md group-hover:opacity-70 ${
          eventStartTime < firstDayOfWeek && eventEndTime > lastDayOfWeek
            ? ''
            : eventStartTime < firstDayOfWeek
            ? 'rounded-tr-md rounded-br-md'
            : eventEndTime > lastDayOfWeek
            ? 'rounded-tl-md rounded-bl-md'
            : 'rounded-md'
        } `}
      ></div>
      {/* Content */}
      <div
        className={`flex relative z-50 items-center  w-full h-full overflow-hidden`}
      >
        <ReactSVGIcon name={event?.icon?.name} />

        <p
          className={`text-center sm:px-1 sm:pt-1 pt-2 truncate ${
            colorTheme === 'light' ? 'text-black' : 'text-white'
          } `}
        >
          {event.title}
        </p>
      </div>
    </div>
  )
}

export default LongEventsM
