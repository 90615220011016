import {
  setConnectionsIsOpen,
  setGroupsIsOpen,
  setInnerEventDescIsOpen,
  setIsContactsOpen,
  setPerDayEventsIsOpen,
  setSelectedDayOnCalendar,
  setSingeEventDescIsOpen,
  setWeekView,
} from 'features'
import { useEffect, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'
import { useDispatch } from 'react-redux'

const useEventWeek = (itemWeeks, item) => {
  const dispatch = useDispatch()

  const myRef = useRef()
  const { inViewport } = useInViewport(myRef)

  const oneDayHandler = () => {
    dispatch(setSelectedDayOnCalendar(item?.fullDate))
    dispatch(setPerDayEventsIsOpen(true))
    /* Close other drawers */
    dispatch(setInnerEventDescIsOpen(false))
    dispatch(setSingeEventDescIsOpen(false))
    dispatch(setGroupsIsOpen(false))
    dispatch(setIsContactsOpen(false))
    dispatch(setConnectionsIsOpen(false))
  }
  useEffect(() => {
    if (inViewport) {
      let weekStartDay = itemWeeks[0].fullDate
      let weekEndDay = itemWeeks[itemWeeks?.length - 1]?.fullDate
      let arr = []
      arr.push(weekStartDay, weekEndDay)
      dispatch(setWeekView(arr))
    }
  }, [itemWeeks, inViewport, dispatch])
  return {
    myRef,
    oneDayHandler,
  }
}

export default useEventWeek
