import axios from 'axios'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MoreVertIcon,
} from 'components/Icons'
import { setContactProfileIsOpen } from 'features'

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

export const ContactProfile = ({ user }) => {
  const [avatarOrEmail, setAvatarOrEmail] = useState(null)
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )
  const dispatch = useDispatch()
  const backArrowHandler = () => {
    dispatch(setContactProfileIsOpen(false))
  }

  useEffect(() => {
    const checkAvatarAndSetState = async () => {
      if (user?.avatar) {
        try {
          const avatarUrl = `${process.env.REACT_APP_BASE_URL}/${user.avatar}`
          const response = await axios.head(avatarUrl)

          if (response.status === 200) {
            setAvatarOrEmail(
              <img
                alt='user'
                src={avatarUrl}
                className='rounded-full w-[100px] h-[100px] aspect-square object-cover  cursor-pointer'
              />
            )
            return
          }
        } catch (error) {
          // Avatar does not exist on the server, fall through to render email slice
        }
      }

      setAvatarOrEmail(
        <div className='w-[100px] flex items-center justify-center h-[100px] rounded-full bg-slate-400'>
          <p className='text-lg'>{user?.email.slice(0, 2)}</p>
        </div>
      )
    }

    checkAvatarAndSetState()
  }, [user])

  return (
    <div className={`h-screen p-3 text-xs ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='pb-6 font-bold text-sm flex justify-between items-center relative '>
        <div>
          <ChevronLeftIcon
            size={30}
            className={'cursor-pointer '}
            onClick={backArrowHandler}
          />
        </div>
        <p className={`text-sm  `}>Contact Profile</p>

        <MoreVertIcon className={'cursor-pointer'} />
      </div>
      {/* Content */}
      <div className='overflow-y-scroll h-full pb-14'>
        {/* User avatar */}
        <div className='w-full flex items-center justify-center flex-col gap-6 mb-8'>
          <div className='w-[6.25rem] h-[6.25rem]  relative flex  justify-center items-center rounded-full '>
            {avatarOrEmail}
          </div>
          <p className='text-lg'>{user?.email ? user?.email : user?.name}</p>
        </div>
        {/* User ID & Birthdate */}
        <div className='gap-4 flex flex-col '>
          <div
            className={`h-[88px] w-full rounded-xl flex flex-col justify-between  p-3  ${
              nightMode
                ? 'bg-DMDarkBlueForeground'
                : 'bg-GrayMediumBGColor bg-opacity-20'
            }`}
          >
            <div className='flex w-full justify-between   '>
              <p>User ID</p>
              <p
                className={`${
                  nightMode
                    ? 'text-DM2ndLighterTextIcon '
                    : 'text-LM2ndLighterTextIcon'
                }`}
              >
                jonnycash
              </p>
            </div>
            <span
              className={`w-full h-[1px] bg-DivideLine  ${
                nightMode ? 'opacity-20 ' : ''
              }`}
            ></span>
            <div className='flex w-full  justify-between '>
              <p>BirthDate</p>
              <p
                className={`${
                  nightMode
                    ? 'text-DM2ndLighterTextIcon '
                    : 'text-LM2ndLighterTextIcon'
                }`}
              >
                July 7, 2001{' '}
                <span className='text-BlueDarkColorMain'>(in 265 days)</span>
              </p>
            </div>
          </div>

          {/* Common Groups */}
          <div
            className={`h-[44px] rounded-xl items-center flex justify-center   text-sm overflow-x-hidden cursor-pointer p-3  ${
              nightMode
                ? 'bg-DMDarkBlueForeground'
                : 'bg-GrayMediumBGColor bg-opacity-20'
            }`}
          >
            <div className={` flex justify-between w-full h-full `}>
              <div className='flex items-center'>
                <p
                  className={
                    nightMode
                      ? 'text-DMMainLightTextIcon '
                      : 'text-LMMainDarkTextIcon'
                  }
                >
                  Common Groups
                </p>
              </div>
              <div className='flex items-center '>
                <span className='bg-red-400 h-3 w-3 rounded-full mx-0.5'></span>
                <span className='bg-green-400 h-3 w-3 rounded-full mx-0.5'></span>
                <span className='bg-blue-400 h-3 w-3 rounded-full mx-0.5'></span>
                <span className='bg-yellow-400 h-3 w-3 rounded-full mx-0.5'></span>

                <ChevronRightIcon className={'ml-3'} secondaryColor={true} />
              </div>
            </div>
          </div>
          {/* Bio */}
          <div
            className={`${
              nightMode
                ? 'text-DM2ndLighterTextIcon '
                : 'text-LM2ndLighterTextIcon'
            }`}
          >
            <p className='text-base pb-3'>Bio</p>
            <div
              className={`h-[88px] rounded-xl  text-sm overflow-hidden p-3 ${
                nightMode
                  ? 'bg-DMDarkBlueForeground  '
                  : 'bg-GrayMediumBGColor bg-opacity-20 '
              }`}
            >
              <div className='overflow-y-scroll h-full'>
                <p>
                  {' '}
                  What is a short bio? Short bios are concise, biographical
                  paragraphs that professionals use to introduce themselves. You
                  can often find short bios on social media profiles, personal
                  websites and company team directories. What is a short bio?
                  Short bios are concise, biographical paragraphs that
                  professionals use to introduce themselves. You can often find
                  short bios on social media profiles, personal websites and
                  company team directories.
                </p>
              </div>
            </div>
          </div>
          {/* Contact Info */}
          <div className={``}>
            <p
              className={`text-base pb-3 ${
                nightMode
                  ? 'text-DM2ndLighterTextIcon '
                  : 'text-LM2ndLighterTextIcon'
              }`}
            >
              Contact Info
            </p>
            <div
              className={`h-[88px] w-full rounded-xl flex flex-col justify-between  p-3  ${
                nightMode
                  ? 'bg-DMDarkBlueForeground'
                  : 'bg-GrayMediumBGColor bg-opacity-20'
              }`}
            >
              <div className='flex w-full justify-between   '>
                <p>Phone</p>
                <p className={`text-BlueDarkColorMain`}>
                  {user?.phone ? user?.phone : '+63 912 336 7678'}
                </p>
              </div>
              <span
                className={`w-full h-[1px] bg-DivideLine  ${
                  nightMode ? 'opacity-20 ' : ''
                }`}
              ></span>
              <div className='flex w-full  justify-between '>
                <p>Email</p>
                <p className={`text-BlueDarkColorMain`}>jonnysmith@gmail.com</p>
              </div>
            </div>
          </div>
          {/* Socials */}
          <div className={``}>
            <p
              className={`text-base pb-3 ${
                nightMode
                  ? 'text-DM2ndLighterTextIcon '
                  : 'text-LM2ndLighterTextIcon'
              }`}
            >
              Socials
            </p>
            <div
              className={`h-[132px] w-full rounded-xl flex flex-col justify-between  p-3  ${
                nightMode
                  ? 'bg-DMDarkBlueForeground'
                  : 'bg-GrayMediumBGColor bg-opacity-20'
              }`}
            >
              <div className='flex w-full justify-between   '>
                <p>Facebook</p>
                <p className={`text-BlueDarkColorMain`}>@jonnycash69</p>
              </div>
              <span
                className={`w-full h-[1px] bg-DivideLine  ${
                  nightMode ? 'opacity-20 ' : ''
                }`}
              ></span>
              <div className='flex w-full  justify-between '>
                <p>Instagram</p>
                <p className={`text-BlueDarkColorMain`}>@jonnycash69</p>
              </div>
              <span
                className={`w-full h-[1px] bg-DivideLine  ${
                  nightMode ? 'opacity-20 ' : ''
                }`}
              ></span>
              <div className='flex w-full  justify-between '>
                <p>X(Twitter)</p>
                <p className={`text-BlueDarkColorMain`}>@jonnycash69</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
