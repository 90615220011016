/* eslint-disable no-unused-vars */
import {
  Appearance,
  InnerDrawer,
  Notifications,
  PersonalInformation,
  Preferences,
  PrivacyAndSecurity,
  Subscription,
} from 'components'
import {
  AccountCircleIcon,
  AppearanceIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EmailIcon,
  LinkIcon,
  NotificationsIcon,
  PhoneIcon,
  PreferenceIcon,
  SecurityIcon,
  SubscriptionIcon,
} from 'components/Icons'
import {
  setAppearanceIsOpen,
  setNotificationIsOpen,
  setPerDayEventsIsOpen,
  setPersonalInformationIsOpen,
  setPreferenceIsOpen,
  setPrivacyAndSecurityIsOpen,
  setSubscriptionIsOpen,
  setUserProfileIsOpen,
} from 'features'
import { useAvatar, useUser } from 'hooks'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import uuid from 'react-uuid'
import { logOut } from 'utils'

const UserProfile = () => {
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )

  const {
    isLeftBarOpened,
    appearanceIsOpen,
    personalInformationIsOpen,
    subscriptionIsOpen,
    privacyAndSecurityIsOpen,
    preferenceIsOpen,
    notificationIsOpen,
  } = useSelector((state) => state.sideView)

  const dispatch = useDispatch()
  const { user } = useUser()
  console.log('🚀 ~ UserProfile ~ user:', user)
  const { avatarOrEmail } = useAvatar()

  const info = [
    {
      icon: <AccountCircleIcon className={'mx-2'} />,
      title: 'Personal Information',
      selected: '',
    },
    {
      icon: <EmailIcon className={'mx-2'} />,
      title: 'Email',
      selected: 'test@gmail.com',
    },
    {
      icon: <PhoneIcon className={'mx-2'} />,
      title: 'Phone',
      selected: '+995544785512',
    },
    {
      icon: <LinkIcon className={'mx-2'} />,
      title: 'Linked Accounts',
      selected: '2 connected',
    },
    {
      icon: <SubscriptionIcon className={'mx-2'} />,
      title: 'Subscription',
      selected: 'Family Plan',
    },
  ]
  const privacy = [
    {
      icon: <SecurityIcon className={'mx-2'} />,
      title: 'Privacy & Security',
      selected: '',
    },
    {
      icon: <NotificationsIcon className={'mx-2'} />,
      title: 'Notification',
      selected: 'Muted',
    },

    {
      icon: <PreferenceIcon className={'mx-2'} />,
      title: 'Preferences',
      selected: '',
    },
    {
      icon: <AppearanceIcon className={'mx-2'} />,

      title: 'Appearance',
      selected: '',
    },
  ]
  const manageAccount = [
    {
      icon: <AccountCircleIcon className={'mx-2'} />,
      title: 'Manage Account Status',
      selected: '',
    },
  ]

  const backArrowHandler = () => {
    dispatch(setUserProfileIsOpen(false))

    dispatch(setPersonalInformationIsOpen(false))
    dispatch(setAppearanceIsOpen(false))
    dispatch(setSubscriptionIsOpen(false))
    dispatch(setPrivacyAndSecurityIsOpen(false))
    dispatch(setPreferenceIsOpen(false))
    dispatch(setNotificationIsOpen(false))
  }

  const onInfoClickHandler = (title) => {
    if (title === 'Appearance') {
      dispatch(setAppearanceIsOpen(!appearanceIsOpen))
      dispatch(setSubscriptionIsOpen(false))
      dispatch(setPersonalInformationIsOpen(false))
      dispatch(setPrivacyAndSecurityIsOpen(false))
      dispatch(setPreferenceIsOpen(false))
    } else if (title === 'Privacy & Security') {
      dispatch(setPrivacyAndSecurityIsOpen(!privacyAndSecurityIsOpen))
      dispatch(setSubscriptionIsOpen(false))
      dispatch(setPersonalInformationIsOpen(false))
      dispatch(setAppearanceIsOpen(false))
      dispatch(setPreferenceIsOpen(false))
      dispatch(setNotificationIsOpen(false))
    } else if (title === 'Preferences') {
      dispatch(setPreferenceIsOpen(!preferenceIsOpen))
      dispatch(setSubscriptionIsOpen(false))
      dispatch(setPersonalInformationIsOpen(false))
      dispatch(setAppearanceIsOpen(false))
      dispatch(setPrivacyAndSecurityIsOpen(false))
      dispatch(setNotificationIsOpen(false))
    } else if (title === 'Notification') {
      dispatch(setNotificationIsOpen(!notificationIsOpen))
      dispatch(setSubscriptionIsOpen(false))
      dispatch(setPersonalInformationIsOpen(false))
      dispatch(setAppearanceIsOpen(false))
      dispatch(setPrivacyAndSecurityIsOpen(false))
      dispatch(setPreferenceIsOpen(false))
    }
  }
  const onPrivacyClickHandler = (title) => {
    if (title === 'Personal Information') {
      dispatch(setPersonalInformationIsOpen(!personalInformationIsOpen))
      dispatch(setAppearanceIsOpen(false))
      dispatch(setSubscriptionIsOpen(false))
      dispatch(setPrivacyAndSecurityIsOpen(false))
      dispatch(setPreferenceIsOpen(false))
      dispatch(setNotificationIsOpen(false))
    } else if (title === 'Subscription') {
      dispatch(setSubscriptionIsOpen(!subscriptionIsOpen))
      dispatch(setAppearanceIsOpen(false))
      dispatch(setPersonalInformationIsOpen(false))
      dispatch(setPrivacyAndSecurityIsOpen(false))
      dispatch(setPreferenceIsOpen(false))
      dispatch(setNotificationIsOpen(false))
    }
  }
  return (
    <>
      <div className={`h-screen w-full p-3 text-sm ${defaultTextIconColor}`}>
        {/* Header */}
        <div className='pb-6 font-bold text-sm flex justify-between items-center relative '>
          <div onClick={backArrowHandler}>
            <ChevronLeftIcon size={30} />
          </div>
          <p>User Profile</p>
          <ChevronLeftIcon size={30} className={'invisible'} />
        </div>
        {/* Content */}
        <div className='overflow-y-scroll h-full pb-14'>
          {/* User avatar */}
          <div className='w-full flex items-center justify-center flex-col gap-6 mb-8'>
            <div className='w-[6.25rem] h-[6.25rem]  relative flex  justify-center items-center rounded-full '>
              <>{avatarOrEmail}</>
            </div>
            <p className='text-lg'>
              {user?.name || user?.lastName
                ? `${user?.name + ' ' + user?.lastName}`
                : user?.email}
            </p>
          </div>

          {/* Personal information */}
          <div className='gap-4 flex flex-col '>
            <div
              className={`  rounded-xl   ${
                nightMode
                  ? 'bg-DMDarkBlueForeground'
                  : 'bg-GrayMediumBGColor bg-opacity-20'
              }`}
            >
              {info?.map((item, index) => (
                <div
                  onClick={onPrivacyClickHandler.bind(null, item.title)}
                  key={uuid()}
                  className='h-[44px] px-1   flex justify-start items-center'
                >
                  <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                    {item.icon}
                    <div
                      className={`py-2 mr-2 flex w-full   justify-between items-center ${
                        index === info.length - 1
                          ? 'border-0'
                          : 'border-DivideLine border-opacity-20 border-b'
                      }`}
                    >
                      <p>{item.title}</p>
                      <div className='flex gap-3 text-gray-400  items-center'>
                        {' '}
                        <p
                          className={`${
                            nightMode
                              ? 'text-DM2ndLighterTextIcon '
                              : 'text-LM2ndLighterTextIcon'
                          }`}
                        >
                          {item.selected}
                        </p>
                        <ChevronRightIcon size={26} secondaryColor={true} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={` rounded-xl ${
                nightMode
                  ? 'bg-DMDarkBlueForeground'
                  : 'bg-GrayMediumBGColor bg-opacity-20'
              }`}
            >
              {privacy?.map((item, index) => (
                <div
                  onClick={onInfoClickHandler.bind(null, item.title)}
                  key={uuid()}
                  className='h-[44px] px-1   flex justify-start items-center'
                >
                  <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                    {item.icon}
                    <div
                      className={`py-2 mr-2 flex w-full   justify-between items-center ${
                        index === privacy.length - 1
                          ? 'border-0'
                          : 'border-DivideLine border-opacity-20 border-b'
                      }`}
                    >
                      <p>{item.title}</p>
                      <div className='flex gap-3 text-gray-400 items-center '>
                        {' '}
                        <p
                          className={`${
                            nightMode
                              ? 'text-DM2ndLighterTextIcon '
                              : 'text-LM2ndLighterTextIcon'
                          }`}
                        >
                          {item.selected}
                        </p>
                        <ChevronRightIcon size={26} secondaryColor={true} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Manage Account Status Button */}
            <div
              className={` rounded-xl ${
                nightMode
                  ? 'bg-DMDarkBlueForeground'
                  : 'bg-GrayMediumBGColor bg-opacity-20'
              }`}
            >
              {manageAccount?.map((item, index) => (
                <div
                  key={uuid()}
                  className='h-[44px] px-1   flex justify-start items-center'
                >
                  <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                    {item.icon}
                    <div
                      className={`py-2 mr-2 flex w-full   justify-between items-center `}
                    >
                      <p>{item.title}</p>
                      <div className='flex gap-3 text-gray-400 items-center '>
                        {' '}
                        <p
                          className={`${
                            nightMode
                              ? 'text-DM2ndLighterTextIcon '
                              : 'text-LM2ndLighterTextIcon'
                          }`}
                        >
                          {item.selected}
                        </p>
                        <ChevronRightIcon size={26} secondaryColor={true} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Log out button */}

            {localStorage.getItem('token') ? (
              <div
                onClick={logOut}
                className={`h-[44px] cursor-pointer rounded-xl text-center text-red-400 items-center flex justify-center ${
                  nightMode
                    ? 'bg-DMDarkBlueForeground'
                    : 'bg-GrayMediumBGColor bg-opacity-20'
                }`}
              >
                <p>log out</p>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <InnerDrawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={appearanceIsOpen}
        position='left'
      >
        <Appearance />
      </InnerDrawer>
      <InnerDrawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={personalInformationIsOpen}
        position='left'
      >
        <PersonalInformation />
      </InnerDrawer>
      <InnerDrawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={subscriptionIsOpen}
        position='left'
      >
        <Subscription />
      </InnerDrawer>
      <InnerDrawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={privacyAndSecurityIsOpen}
        position='left'
      >
        <PrivacyAndSecurity />
      </InnerDrawer>
      <InnerDrawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={preferenceIsOpen}
        position='left'
      >
        <Preferences />
      </InnerDrawer>
      <InnerDrawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={notificationIsOpen}
        position='left'
      >
        <Notifications />
      </InnerDrawer>
    </>
  )
}

export default UserProfile
