/* eslint-disable no-unused-vars */
import { CalendarEvents, LogoAnimation } from 'components'

import React, { useEffect, useRef, useState } from 'react'

import uuid from 'react-uuid'
import {
  betweenDates,
  dynamicStyles,
  eventEndPlacement,
  eventStartPlacement,
  getEventDatesInRange,
  showEventsByDate,
  monthAbbreviations,
  fullMonths,
  generateYearWeeks,
  generateMonth2,
  generateMonths,
  generateMonthData,
} from 'utils'
import MonthWeek from './MonthWeek'
import { useSelector } from 'react-redux'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import MonthAppleView from './MonthAppleView'
import { set } from 'react-hook-form'

const MonthView = ({
  sorted,
  generatedWeeks,
  fetchedEventStatus,
  openEventHandler,
  isLeftBarOpened,
  nightMode,
  defaultTextIconColor,
}) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
  const [currentYearForScrollUp, setCurrentYearForScrollUp] = useState(
    new Date().getFullYear()
  )
  const [currentMonthForScrollUp, setCurrentMonthForScrollUp] = useState(
    new Date().getMonth()
  )

  const [isMouseOverDrawer, setMouseOverDrawer] = useState(false)

  const [monthData, setMonthData] = useState(
    generateMonthData(currentYear, currentMonth)
  )

  const handleMouseEnter = () => {
    setMouseOverDrawer(true)
    document.body.style.overflow = 'auto'
  }

  const handleMouseLeave = () => {
    setMouseOverDrawer(false)
    document.body.style.overflow = 'hidden'
  }

  /*  const handleScroll = (scrollDirection) => {
    let nextYear = currentYear
    let nextMonth = currentMonth

    if (scrollDirection === 'down') {
      nextMonth++
      if (nextMonth > 11) {
        nextYear++
        nextMonth = 0
      }
      setMonthData((prevMonthData) => [
   
        ...prevMonthData,
        ...generateMonthData(nextYear, nextMonth),
      ])
    } else if (scrollDirection === 'up') {
      nextMonth--
      if (nextMonth < 0) {
        nextYear--
        nextMonth = 11
      }
      setMonthData((prevMonthData) => [
        ...generateMonthData(nextYear, nextMonth),
        ...prevMonthData,
      ])
    }

    setCurrentYear(nextYear)
    setCurrentMonth(nextMonth)
  }
 */
  const addDataOnSCrollDown = () => {
    let nextYear = currentYear
    let nextMonth = currentMonth

    nextMonth++
    if (nextMonth > 11) {
      nextYear++
      nextMonth = 0
    }
    setMonthData((prevMonthData) => [
      ...prevMonthData,
      ...generateMonthData(nextYear, nextMonth),
    ])

    setCurrentYear(nextYear)
    setCurrentMonth(nextMonth)
  }
  const addDataOnScrollUp = () => {
    let nextYear = currentYearForScrollUp
    let nextMonth = currentMonthForScrollUp

    // Initialize an array to store data for 12 months
    let newDataFor12Months = []

    // Loop to generate data for 12 months
    for (let i = 0; i < 12; i++) {
      // Decrement month, and adjust year if necessary
      nextMonth--
      if (nextMonth < 0) {
        nextYear--
        nextMonth = 11
      }

      // Generate data for the current month and add it to the array
      newDataFor12Months = [
        ...generateMonthData(nextYear, nextMonth),
        ...newDataFor12Months,
      ]
    }

    // Update monthData with data for 12 months
    setMonthData((prevMonthData) => [...newDataFor12Months, ...prevMonthData])

    // Update currentYearForScrollUp and currentMonthForScrollUp
    setCurrentYearForScrollUp(nextYear)
    setCurrentMonthForScrollUp(nextMonth)
  }

  // Generate prev 12 mont on first load
  useEffect(() => {
    addDataOnScrollUp()
  }, [])

  // go to current month on first load
  /*   useEffect(() => {
    setTimeout(() => {
      let element = document?.getElementById(
        moment(new Date()).format('MMMM/Y')
      )
      if (element) {
        element.scrollIntoView()
      }
    }, 10)
  }, []) */

  // go to current month on first load with ref
  const lastDivRef = useRef(null)
  useEffect(() => {
    setTimeout(() => {
      if (lastDivRef.current) {
        lastDivRef.current.scrollIntoView()
      }
    }, 10)
  }, [])

  // get scroll
  const [scrollPosition, setScrollPosition] = useState('down')

  const scrollableRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      // Check if the scrollable element is at the top
      if (scrollableRef.current.scrollTop === 0) {
        setScrollPosition('up')
      } else {
        setScrollPosition('down')
      }
    }

    // Add the scroll event listener to the scrollable element
    const scrollableElement = scrollableRef.current
    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleScroll)
    }

    // Clean up the event listener
    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, []) // Empty dependency array to run only once on mount

  // add prev months on scroll up
  useEffect(() => {
    if (scrollPosition === 'up') {
      setTimeout(() => {
        addDataOnScrollUp()
        // scroll to second from top month
        let element = document?.getElementById(
          monthData[12].name + '/' + monthData[12].year
        )

        element?.scrollIntoView()
      }, 200)
    }
  }, [scrollPosition])

  return (
    <>
      {fetchedEventStatus === 'pending' && <LogoAnimation />}

      <InfiniteScroll
        dataLength={monthData.length}
        next={addDataOnSCrollDown}
        hasMore={true}
        scrollableTarget='scrollableDiv'
      >
        <div
          ref={scrollableRef}
          id='scrollableDiv'
          className='left-6 w-full px-4 overflow-y-scroll h-[calc(100vh_-_3.125rem)] !snap-mandatory !snap-y mt-[3.125rem]'
        >
          {monthData?.map((month, index) => {
            return (
              <>
                <p className={`text-lg pl-1 py-1 ${defaultTextIconColor}`}>
                  <span>{month.name}</span>
                  <span className=' text-DM2ndLighterTextIcon'>
                    {' '}
                    / {month.year}
                  </span>
                </p>

                {month.weeks.map((week, index2) => {
                  return (
                    <div
                      id={month.name + '/' + month.year}
                      key={uuid()}
                      ref={index === monthData.length - 1 ? lastDivRef : null}
                      className={`z-20 !grid relative !grid-cols-7 snap-start overflow-hidden !gap-[1px]`}
                    >
                      {/* EVENTS */}
                      <div className='absolute grid grid-cols-7 grid-flow-dense auto-rows-max z-30  bottom-0 w-full h-[85%] mb-1  '>
                        {sorted?.map((event) => {
                          const dates = betweenDates(event)
                          const eventsDatesRange = getEventDatesInRange(event)

                          for (let i = 0; i < month.weeks.length; i++) {
                            if (showEventsByDate(i, week, eventsDatesRange)) {
                              return (
                                <React.Fragment key={event._id}>
                                  {event.group.isVisible && (
                                    <div
                                      style={{
                                        ...dynamicStyles(
                                          event,
                                          eventStartPlacement,
                                          eventEndPlacement,
                                          week
                                        ),
                                      }}
                                    >
                                      <CalendarEvents
                                        openEventHandler={openEventHandler.bind(
                                          null,
                                          event
                                        )}
                                        event={event}
                                        week={month.weeks}
                                        index={i}
                                        dates={dates}
                                        isLeftBarOpened={isLeftBarOpened}
                                      />
                                    </div>
                                  )}
                                </React.Fragment>
                              )
                            }
                          }
                          return ''
                        })}
                      </div>
                      {/* EVENTS END */}
                      {week.map((day, index4) => (
                        <>
                          <MonthAppleView
                            nightMode={nightMode}
                            itemWeeks={month.weeks}
                            item2={month.weeks}
                            item={day}
                            monthCount={index4}
                            weekCount={index4}
                            numWeek={index2}
                            defaultTextIconColor={defaultTextIconColor}
                          />
                        </>
                      ))}
                    </div>
                  )
                })}
              </>
            )
          })}
        </div>
      </InfiniteScroll>
    </>
  )
}

export default MonthView
