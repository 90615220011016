/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const SyncAltIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      onClick={onClick}
      width={size}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M16.125 11.9755C15.9083 11.7755 15.8042 11.5172 15.8125 11.2005C15.8208 10.8838 15.925 10.6088 16.125 10.3755L18.175 8.3505H3.70001C3.36668 8.3505 3.09168 8.24217 2.87501 8.0255C2.65835 7.80883 2.55001 7.53383 2.55001 7.2005C2.55001 6.86717 2.65835 6.59633 2.87501 6.388C3.09168 6.17967 3.36668 6.0755 3.70001 6.0755H18.175L16.075 3.9505C15.8583 3.73383 15.7542 3.47133 15.7625 3.163C15.7708 2.85467 15.8833 2.58383 16.1 2.3505C16.3333 2.1505 16.6042 2.05467 16.9125 2.063C17.2208 2.07133 17.5 2.18383 17.75 2.4005L21.75 6.4005C21.8333 6.5005 21.9083 6.62133 21.975 6.763C22.0417 6.90467 22.075 7.0505 22.075 7.2005C22.075 7.36717 22.0417 7.52133 21.975 7.663C21.9083 7.80467 21.8333 7.91717 21.75 8.0005L17.7 12.0005C17.5 12.2338 17.2458 12.3505 16.9375 12.3505C16.6292 12.3505 16.3583 12.2255 16.125 11.9755ZM6.27501 21.6005L2.27501 17.6255C2.17501 17.5255 2.09585 17.4047 2.03751 17.263C1.97918 17.1213 1.95001 16.9672 1.95001 16.8005C1.95001 16.6505 1.97918 16.5047 2.03751 16.363C2.09585 16.2213 2.17501 16.1088 2.27501 16.0255L6.30001 11.9755C6.53335 11.7588 6.80418 11.6547 7.11251 11.663C7.42085 11.6713 7.69168 11.7838 7.92501 12.0005C8.12501 12.2172 8.22501 12.4838 8.22501 12.8005C8.22501 13.1172 8.10835 13.3838 7.87501 13.6005L5.82501 15.6755H20.325C20.6417 15.6755 20.9083 15.7838 21.125 16.0005C21.3417 16.2172 21.45 16.4838 21.45 16.8005C21.45 17.1505 21.3417 17.4297 21.125 17.638C20.9083 17.8463 20.6417 17.9505 20.325 17.9505H5.82501L7.92501 20.0255C8.15835 20.2755 8.26251 20.5463 8.23751 20.838C8.21251 21.1297 8.08335 21.3922 7.85001 21.6255C7.65001 21.8588 7.38751 21.9713 7.06251 21.963C6.73751 21.9547 6.47501 21.8338 6.27501 21.6005Z' />
    </svg>
  )
}

export default SyncAltIcon
