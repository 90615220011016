import React from 'react'
import { ReactSVGIcon } from 'components'
import useDailyEvent from 'hooks/useDailyEvent'
import { useDarkSide } from 'hooks'

const MinimalEventsM = ({ event }) => {
  const { startPosition, endPosition } = useDailyEvent(event)
  const [colorTheme] = useDarkSide()

  return (
    <div className={` w-full h-full group`}>
      {/* Event duration line */}
      <span className='w-full grid grid-cols-6  absolute rounded-tl-md rounded-tr-md overflow-hidden '>
        <div
          style={{
            opacity: 100,
            backgroundColor: event.group.color,
            gridColumnStart: startPosition,
            gridColumnEnd: endPosition,
          }}
          className={`lg:w-[50%] w-full ${
            endPosition >= 7 ? 'lg:ml-3 xl:ml-5' : ''
          }   h-1.5 items-center`}
        ></div>
      </span>
      {/* Background line */}
      <span
        style={{
          backgroundColor: event.group.color,
        }}
        className='w-full h-1.5 top-0 absolute opacity-40  rounded-tl-md rounded-tr-md   '
      ></span>
      {/* Event background color */}
      <div
        style={{ backgroundColor: event.group.color }}
        className='w-full h-full opacity-50 absolute rounded-md group-hover:opacity-70'
      ></div>
      {/* Content */}
      <div
        className={`flex relative z-50 items-center sm:justify-start justify-center w-full h-full overflow-hidden`}
      >
        <ReactSVGIcon name={event?.icon?.name} />

        <p
          className={`text-center sm:block hidden sm:px-1 sm:pt-1 pt-2  truncate ${
            colorTheme === 'light' ? 'text-black' : 'text-white'
          } `}
        >
          {event.title}
        </p>
      </div>
    </div>
  )
}

export default MinimalEventsM
