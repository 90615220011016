/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const EventRequestIcon = ({
  size = 24,
  className,
  onClick,
  secondaryColor,
}) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 21 21'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M9.3697 13.8958L12.7689 10.5433C12.9121 10.4033 13.0761 10.3368 13.2611 10.3437C13.4461 10.3507 13.6128 10.4236 13.7612 10.5625C13.9035 10.7213 13.9746 10.8936 13.9746 11.0792C13.9746 11.2649 13.9042 11.4191 13.7634 11.5417L10.0457 15.1875C9.85746 15.3819 9.63086 15.4792 9.36586 15.4792C9.10085 15.4792 8.88386 15.3819 8.71487 15.1875L6.83488 13.3958C6.69406 13.2521 6.62365 13.0877 6.62365 12.9026C6.62365 12.7175 6.69806 12.5521 6.8469 12.4062C6.98165 12.2743 7.1436 12.2083 7.33274 12.2083C7.52188 12.2083 7.68812 12.279 7.83147 12.4204L9.3697 13.8958ZM4.23669 19.2917C3.71318 19.2917 3.26193 19.0999 2.88293 18.7164C2.50394 18.3329 2.31445 17.8927 2.31445 17.3958V4.875C2.31445 4.35294 2.50394 3.90299 2.88293 3.52512C3.26193 3.14726 3.71318 2.95833 4.23669 2.95833H5.48298V2.5C5.48298 2.28646 5.56942 2.09765 5.74231 1.93358C5.9152 1.76953 6.10954 1.6875 6.32532 1.6875C6.5668 1.6875 6.76491 1.76953 6.91965 1.93358C7.07437 2.09765 7.15173 2.28646 7.15173 2.5V2.95833H13.5733V2.5C13.5733 2.28646 13.6548 2.09765 13.8178 1.93358C13.9809 1.76953 14.1766 1.6875 14.4051 1.6875C14.6395 1.6875 14.8376 1.76953 14.9994 1.93358C15.1611 2.09765 15.242 2.28646 15.242 2.5V2.95833H16.4883C17.0176 2.95833 17.4739 3.14726 17.857 3.52512C18.2401 3.90299 18.4317 4.35294 18.4317 4.875V17.3958C18.4317 17.8927 18.2401 18.3329 17.857 18.7164C17.4739 19.0999 17.0176 19.2917 16.4883 19.2917H4.23669ZM4.23669 17.3958H16.4883V8.66667H4.23669V17.3958ZM4.23669 7.47917H16.4883V4.875H4.23669V7.47917Z' />
    </svg>
  )
}

export default EventRequestIcon
