/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'
import { ReactSVG } from 'react-svg'

const ReactSVGIcon = ({ name, size = 22, className, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <ReactSVG
      className={`cursor-pointer pl-2 pr-1 pt-1  ${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
      src={`https://back.unum.today/icons/${name}.svg`}
      beforeInjection={(svg) => {
        // You can adjust the width and height values as needed.

        if (!svg.getAttribute('viewBox')) {
          console.warn('SVG is missing viewBox:', name)
          svg.setAttribute('viewBox', '0 0 50 50') // Set a default viewBox
        }
        svg.setAttribute('width', `${size}px`)
        svg.setAttribute('height', `${size}px`)
      }}
    />
  )
}

export default ReactSVGIcon
