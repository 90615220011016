/* eslint-disable no-unused-vars */
import { Switch } from '@headlessui/react'
import {
  CalendarMonthIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  GroupIcon,
  LockIcon,
  MoreVertIcon,
  MuteIcon,
  VisibilityIcon,
} from 'components/Icons'
import { setNotificationIsOpen } from 'features'

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'

const Notifications = () => {
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )

  const dispatch = useDispatch()

  const [mute, setMute] = useState(false)

  const muteHandler = () => {
    setMute((prev) => !prev)
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const [events, setEvents] = useState(false)
  const [timelineEvents, setTimelineEvents] = useState(false)
  const [reminders, setReminders] = useState(false)
  const [contactsRequest, setContactsRequests] = useState(false)
  const [groupRequest, setGroupRequests] = useState(false)
  const [eventRequest, setEventRequests] = useState(false)
  const data = [
    { title: 'Events', state: events },
    {
      title: 'Timeline Events',
      state: timelineEvents,
    },
    { title: 'Reminders', state: reminders },
  ]
  const connectionsData = [
    { title: 'New Contact Requests', state: contactsRequest },
    {
      title: 'New Group Requests',
      state: groupRequest,
    },
    { title: 'New Event Requests', state: eventRequest },
  ]

  const info = [
    {
      icon: <GroupIcon className={'mx-2'} />,
      title: 'New event',
      selected: 'From all Groups',
    },
    {
      icon: <GroupIcon className={'mx-2'} />,
      title: 'New participant',
      selected: 'From all Groups',
    },
  ]

  const changeStateHandler = (title) => {
    if (title === 'Events') {
      setEvents(!events)
    } else if (title === 'Timeline Events') {
      setTimelineEvents(!timelineEvents)
    } else if (title === 'Reminders') {
      setReminders(!reminders)
    }
  }

  const changeConnectionsStateHandler = (title) => {
    if (title === 'New Contact Requests') {
      setContactsRequests(!contactsRequest)
    } else if (title === 'New Group Requests') {
      setGroupRequests(!groupRequest)
    } else if (title === 'New Event Requests') {
      setEventRequests(!eventRequest)
    }
  }

  return (
    <div className={`h-screen p-3 text-sm    ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='  pb-6 font-bold  flex justify-between items-center relative'>
        <ChevronLeftIcon
          size={30}
          className={'cursor-pointer'}
          onClick={() => {
            dispatch(setNotificationIsOpen(false))
          }}
        />

        <p>Notifications</p>

        <MoreVertIcon className={'cursor-pointer invisible'} />
      </div>
      {/* Content */}

      <div className='overflow-y-scroll h-full pb-14 flex flex-col gap-4'>
        {/* Mute button */}
        <div
          className={`  rounded-xl  flex flex-col  px-3  divide-DivideLine divide-opacity-20 divide-y-[1px] ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          }`}
        >
          <div className='flex h-[44px] justify-between items-center  w-full '>
            <div className='flex gap-3 items-center'>
              {' '}
              <MuteIcon />
              <p>Mute all</p>{' '}
            </div>

            <Switch
              checked={mute}
              onChange={muteHandler}
              className='relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
              style={{
                backgroundColor: mute
                  ? '#75CDE9'
                  : nightMode
                  ? '#778EAE'
                  : 'rgba(119, 142, 174, 0.2)',
              }}
            >
              <span
                className={classNames(
                  mute ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
          </div>
        </div>
        {/* Event States */}
        <div
          className={`  rounded-xl  flex flex-col  px-3  divide-DivideLine divide-opacity-20 divide-y-[1px] ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          }`}
        >
          {data.map((item, index) => {
            return (
              <div className='flex h-[44px] justify-between items-center  w-full '>
                <p>{item.title}</p>{' '}
                <Switch
                  checked={item.state}
                  onChange={changeStateHandler.bind(null, item.title)}
                  className='relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
                  style={{
                    backgroundColor: item.state
                      ? '#75CDE9'
                      : nightMode
                      ? '#778EAE'
                      : 'rgba(119, 142, 174, 0.2)',
                  }}
                >
                  <span
                    className={classNames(
                      item.state ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </div>
            )
          })}
        </div>
        {/* Connections States */}
        <p
          className={` text-xl ${
            nightMode
              ? 'text-DM2ndLighterTextIcon '
              : 'text-LM2ndLighterTextIcon'
          }`}
        >
          Connections:
        </p>
        <div
          className={`  rounded-xl  flex flex-col  px-3  divide-DivideLine divide-opacity-20 divide-y-[1px] ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          }`}
        >
          {connectionsData.map((item, index) => {
            return (
              <div className='flex h-[44px] justify-between items-center  w-full '>
                <p>{item.title}</p>{' '}
                <Switch
                  checked={item.state}
                  onChange={changeConnectionsStateHandler.bind(
                    null,
                    item.title
                  )}
                  className='relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
                  style={{
                    backgroundColor: item.state
                      ? '#75CDE9'
                      : nightMode
                      ? '#778EAE'
                      : 'rgba(119, 142, 174, 0.2)',
                  }}
                >
                  <span
                    className={classNames(
                      item.state ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </div>
            )
          })}
        </div>
        {/* Groups */}
        <p
          className={` text-xl ${
            nightMode
              ? 'text-DM2ndLighterTextIcon '
              : 'text-LM2ndLighterTextIcon'
          }`}
        >
          Groups:
        </p>
        <div
          className={`  rounded-xl   ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-GrayMediumBGColor bg-opacity-20'
          }`}
        >
          {info?.map((item, index) => (
            <div
              key={uuid()}
              className='h-[44px] px-1   flex justify-start items-center'
            >
              <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                {item.icon}
                <div
                  className={`py-2 mr-2 flex w-full   justify-between items-center ${
                    index === info.length - 1
                      ? 'border-0'
                      : 'border-DivideLine border-opacity-20 border-b'
                  }`}
                >
                  <p>{item.title}</p>
                  <div className='flex gap-3 text-gray-400  items-center'>
                    {' '}
                    <p
                      className={`${
                        nightMode
                          ? 'text-DM2ndLighterTextIcon '
                          : 'text-LM2ndLighterTextIcon'
                      }`}
                    >
                      {item.selected}
                    </p>
                    <ChevronRightIcon size={26} secondaryColor={true} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Notifications
