import { useLayoutEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchGroups,
  resetGroupAPICallStatus,
  groupVisibility,
  hideShowAllGroups,
  reOrderGroups,
} from 'features'

const useGroups = (setShowGroupContent) => {
  const dispatch = useDispatch()
  const groups = useSelector((state) => state.group.fetchedGroups)
  const isVisibleGroup = useSelector((state) => state.group.isGroupVisible)
  const { allGroupShowHideStatus, allGroupShowHideError, editGroupStatus } =
    useSelector((state) => state.group)
  const { fetchedPerGroupEventsStatus } = useSelector((state) => state.event)
  const { responseToInvitesStatus } = useSelector((state) => state.connection)

  const { groupDescriptionIsOpen, isLeftBarOpened } = useSelector(
    (state) => state.sideView
  )

  // eslint-disable-next-line no-unused-vars
  const [reorderedGroups, setReorderedGroups] = useState([])
  const localGroups = JSON.parse(localStorage.getItem('localGroups')) || groups
  const allGroupsShowed = groups.every((element) => element.isVisible)

  useLayoutEffect(() => {
    setTimeout(() => {
      dispatch(fetchGroups())
      dispatch(resetGroupAPICallStatus())
    }, 300)
  }, [
    dispatch,
    setShowGroupContent,
    isVisibleGroup,
    allGroupShowHideStatus,
    responseToInvitesStatus,
    editGroupStatus,
  ])

  useLayoutEffect(() => {
    setReorderedGroups(groups)
  }, [groups])

  const onDragEnd = (result) => {
    const newItems = Array.from(reorderedGroups)
    const [removed] = newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, removed)
    setReorderedGroups(newItems)
    setTimeout(() => {
      reOrderGroups(newItems)
    }, 500)

    localStorage.setItem('localGroups', JSON.stringify(newItems))
  }

  const grid = 10

  const getItemStyle = (draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const getListStyle = () => ({
    padding: grid,
  })

  /* Show/Hide button click */
  const buttonHandleClick = (groupsStatus, showOrHide) => {
    if (groupsStatus) {
      dispatch(hideShowAllGroups(showOrHide))
    }
  }

  return {
    groups,
    isVisibleGroup,
    allGroupShowHideStatus,
    allGroupShowHideError,
    groupVisibility,
    hideShowAllGroups,
    dispatch,
    onDragEnd,
    getItemStyle,
    getListStyle,
    localGroups,
    allGroupsShowed,
    reorderedGroups,
    buttonHandleClick,
    groupDescriptionIsOpen,
    fetchedPerGroupEventsStatus,
    isLeftBarOpened,
  }
}

export default useGroups
