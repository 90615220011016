import React from 'react'
import { useSelector } from 'react-redux'

const AccountCircleIcon = ({
  size = 24,
  className,
  onClick,
  secondaryColor,
}) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 -960 960 960'
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M228-266q60-41 121.545-63.5Q411.091-352 480.48-352q69.39 0 131.874 23.268Q674.838-305.464 734-266q41-54 58.5-104.457Q810-420.914 810-480q0-140.247-94.826-235.123Q620.349-810 480.174-810 340-810 245-715.123 150-620.247 150-480q0 60 17.527 109.717T228-266Zm251.854-180q-59.011 0-99.433-40.646-40.421-40.645-40.421-99Q340-644 380.567-685t99.579-41q59.011 0 99.433 41.146Q620-643.709 620-585.354 620-527 579.433-486.5T479.854-446Zm.031 387Q393-59 317.077-91.554q-75.924-32.555-134.196-91.077-58.272-58.521-91.077-134.228Q59-392.566 59-480.849 59-565 92.21-641.913q33.211-76.914 90.965-135.237 57.754-58.324 133.545-91.587Q392.511-902 480.849-902q84.151 0 161.064 33.711 76.914 33.71 135 91.5Q835-719 868.5-641.965q33.5 77.034 33.5 162.08 0 86.885-33.263 162.92-33.263 76.036-91.587 133.79-58.323 57.754-135.271 90.965Q564.931-59 479.885-59Zm.115-91q54 0 100.5-13.5T679-216q-51.586-35.967-99.293-51.983Q532-284 480-284q-52 0-99 16t-98 52.303q51 38.304 97 52Q426-150 480-150Zm0-365q30.867 0 50.933-19.5Q551-554 551-585.5T530.933-637Q510.867-657 480-657t-50.933 20Q409-617 409-585.5t20.067 51Q449.133-515 480-515Zm0-71Zm1 369Z' />
    </svg>
  )
}

export default AccountCircleIcon
