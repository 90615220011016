import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchColors, resetGroupAPICallStatus } from 'features'
import { toast } from 'react-toastify'

const useAddNewGroup = () => {
  const [showColors, setShowColors] = useState(false)
  const colors = useSelector((state) => state.group.fetchedColors)
  const selectedColor = useSelector((state) => state.group.color)
  const groupTitle = useSelector((state) => state.group.groupTitle)
  const groupStatus = useSelector((state) => state.group.createGroupStatus)
  const groupError = useSelector((state) => state.group.createGroupError)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchColors())
  }, [dispatch])
  useEffect(() => {
    dispatch(resetGroupAPICallStatus())
  }, [dispatch, groupTitle, selectedColor])

  useEffect(() => {
    if (groupStatus === 'success') {
      toast.success('Group added successfully')
    }
    if (groupStatus === 'rejected') {
      toast.error(groupError)
    }
  }, [groupStatus, groupError])
  return {
    dispatch,
    groupError,
    groupStatus,
    groupTitle,
    selectedColor,
    colors,
    showColors,
    setShowColors,
  }
}

export default useAddNewGroup
