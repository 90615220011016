/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const SearchIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 -960 960 960'
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M373.438-315q-115.311 0-193.875-78.703Q101-472.406 101-585.203T179.703-776.5q78.703-78.5 191.5-78.5T562.5-776.356Q641-697.712 641-584.85q0 45.85-13 83.35-13 37.5-38 71.5l237 235q13 13.556 13 33.256 0 19.7-13 33.244-14.333 13.5-33.244 13.5-18.912 0-32.756-14L526.472-364Q496-341.077 457.541-328.038 419.082-315 373.438-315Zm-1.997-91q75.985 0 127.272-51.542Q550-509.083 550-584.588q0-75.505-51.346-127.459Q447.309-764 371.529-764q-76.612 0-128.071 51.953Q192-660.093 192-584.588t51.311 127.046Q294.623-406 371.441-406Z' />
    </svg>
  )
}

export default SearchIcon
