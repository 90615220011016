/* eslint-disable no-unused-vars */
import { AddButton, AddNewGroup, Group, InnerDrawer } from 'components'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useGroups } from 'hooks'
import {
  setAddNewGroupIsOpen,
  setGroupAddMemberIsOpen,
  setGroupDescriptionIsOpen,
  setGroupSettingsIsOpen,
  setGroupsIsOpen,
  setRightSideViewIsOpen,
} from 'features'
import { useSelector } from 'react-redux'
import GroupDescription from './GroupDescription'
import { useState } from 'react'
import { AddMember, GroupSettings } from './components'
import { ChevronLeftIcon } from 'components/Icons'

const Groups = ({ setShowGroupContent, defaultTextIconColor }) => {
  const {
    dispatch,
    onDragEnd,
    reorderedGroups,
    allGroupsShowed,
    buttonHandleClick,
    groupDescriptionIsOpen,
    fetchedPerGroupEventsStatus,
    isLeftBarOpened,
  } = useGroups(setShowGroupContent)

  const { groupSettingsIsOpen, groupAddMemberIsOpen } = useSelector(
    (state) => state.sideView
  )
  const { nightMode } = useSelector((state) => state.theme)

  const groupsBackArrowHandler = () => {
    /* Close right drawer */
    dispatch(setRightSideViewIsOpen(false))
    /* Close left drawer */
    dispatch(setGroupsIsOpen(false))
    dispatch(setGroupDescriptionIsOpen(false))
    dispatch(setGroupSettingsIsOpen(false))
    dispatch(setGroupAddMemberIsOpen(false))
  }

  return (
    <>
      <div className={`p-3 ${defaultTextIconColor}`}>
        {/* Header */}
        <div className='pb-6 font-bold text-sm flex justify-between items-center relative'>
          <ChevronLeftIcon
            size={30}
            className={'cursor-pointer'}
            onClick={groupsBackArrowHandler}
          />

          <p className=' absolute left-1/2 -translate-x-1/2 pr-5'>Groups</p>

          <div
            onClick={() => {
              dispatch(setAddNewGroupIsOpen(true))
            }}
          >
            <AddButton text={'New Group'} />
          </div>
        </div>
        {/* Show/Hide Buttons */}
        <div className='flex justify-between text-sm pb-7 w-full'>
          <div
            onClick={buttonHandleClick.bind(
              null,
              allGroupsShowed ? false : true,
              'show'
            )}
            className={`w-[6.7rem] h-[2.1875rem] text-DMMainLightTextIcon flex cursor-pointer justify-center items-center rounded-xl ${
              allGroupsShowed
                ? 'bg-BlueDarkColorMain hover:bg-BlueLightColor '
                : nightMode
                ? 'bg-DMDarkBlueForeground hover:bg-DMLightBlueForeground2'
                : 'bg-GrayMediumBGColor bg-opacity-20  text-LMMainDarkTextIcon2 hover:bg-opacity-30'
            } `}
          >
            View All
          </div>
          <div
            onClick={buttonHandleClick.bind(
              null,
              !allGroupsShowed ? false : true,
              'hide'
            )}
            className={`w-[6.7rem] h-[2.1875rem] text-DMMainLightTextIcon flex cursor-pointer justify-center items-center rounded-xl ${
              !allGroupsShowed
                ? 'bg-BlueDarkColorMain hover:bg-BlueLightColor'
                : nightMode
                ? 'bg-DMDarkBlueForeground hover:bg-DMLightBlueForeground2'
                : 'bg-GrayMediumBGColor bg-opacity-20  text-LMMainDarkTextIcon2 hover:bg-opacity-30'
            } `}
          >
            Hide All
          </div>
          <div
            className={`w-[6.7rem] cursor-pointer h-[2.1875rem]  text-DMMainLightTextIcon  flex justify-center items-center rounded-xl ${
              nightMode
                ? 'bg-DMDarkBlueForeground  hover:bg-DMLightBlueForeground2'
                : 'bg-GrayMediumBGColor bg-opacity-20  text-LMMainDarkTextIcon2 hover:bg-opacity-30'
            }  `}
          >
            <span className='font-bold pb-1 pr-0.5 text-xl'>+</span> Add Combo
          </div>
        </div>
        {/* Group List */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className=' w-full h-screen pb-16'
              >
                {reorderedGroups?.map((item, index) => (
                  <Group key={item._id} item={item} index={index} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <InnerDrawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={groupDescriptionIsOpen}
        position='left'
        className='bg-opacity-10'
      >
        <GroupDescription
          groupDescriptionIsOpen={groupDescriptionIsOpen}
          defaultTextIconColor={defaultTextIconColor}
        />
      </InnerDrawer>
      <InnerDrawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={groupAddMemberIsOpen}
        position='left'
        className=''
      >
        <AddMember defaultTextIconColor={defaultTextIconColor} />
      </InnerDrawer>
      <InnerDrawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={groupSettingsIsOpen}
        position='left'
        className=''
      >
        <AddNewGroup />
      </InnerDrawer>
    </>
  )
}

export default Groups
