/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const EmailIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M3.12435 17.1248C2.60803 17.1248 2.16297 16.9331 1.78918 16.5496C1.4154 16.1661 1.22852 15.7259 1.22852 15.229V4.77067C1.22852 4.26806 1.4154 3.82296 1.78918 3.43538C2.16297 3.0478 2.60803 2.854 3.12435 2.854H16.8743C17.3964 2.854 17.8464 3.0478 18.2242 3.43538C18.6021 3.82296 18.791 4.26806 18.791 4.77067V15.229C18.791 15.7259 18.6021 16.1661 18.2242 16.5496C17.8464 16.9331 17.3964 17.1248 16.8743 17.1248H3.12435ZM16.8743 6.33317L10.5222 10.6017C10.4089 10.6727 10.3129 10.7186 10.2343 10.7394C10.1557 10.7603 10.0761 10.7707 9.99539 10.7707C9.9147 10.7707 9.83637 10.7603 9.76041 10.7394C9.68445 10.7186 9.5898 10.6727 9.47645 10.6017L3.12435 6.33317V15.229H16.8743V6.33317ZM9.99935 9.31234L16.7702 4.77067H3.24935L9.99935 9.31234ZM3.12435 6.5415V5.43717V5.46802V4.77067V5.45817V5.43917V6.5415Z' />
    </svg>
  )
}

export default EmailIcon
