import React from 'react'
import { useLottie } from 'lottie-react'
import groovyWalkAnimation from '../../assets/animations/logo-to-dot.json'

const LogoAnimation = () => {
  const options = {
    animationData: groovyWalkAnimation,
    loop: true,
  }

  const { View } = useLottie(options)

  return (
    <div className='absolute h-full w-full opacity-70  z-50 flex justify-center items-center'>
      <div className='w-[10rem]'> {View}</div>
    </div>
  )
}

export default LogoAnimation
