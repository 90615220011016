/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const MuteIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 21 20'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M10.4975 18.771C9.2922 18.771 8.15345 18.5452 7.0812 18.0935C6.00895 17.6418 5.07477 17.0145 4.27866 16.2114C3.48255 15.4084 2.85866 14.4697 2.40699 13.3956C1.95533 12.3214 1.72949 11.1757 1.72949 9.9585C1.72949 8.78294 1.95744 7.65566 2.41333 6.57664C2.86923 5.49764 3.4939 4.55906 4.28733 3.76089C5.08077 2.96271 6.01727 2.33777 7.09683 1.88606C8.17638 1.43435 9.31105 1.2085 10.5008 1.2085C11.6906 1.2085 12.8244 1.43396 13.9022 1.88489C14.98 2.33584 15.915 2.95973 16.7071 3.75656C17.4993 4.55341 18.1281 5.49239 18.5937 6.5735C19.0592 7.65461 19.292 8.78294 19.292 9.9585C19.292 11.1757 19.0634 12.3225 18.6061 13.3989C18.1488 14.4752 17.5223 15.4124 16.7265 16.2104C15.9307 17.0084 14.9949 17.6347 13.919 18.0892C12.8432 18.5437 11.7027 18.771 10.4975 18.771ZM10.5003 16.8752C12.4222 16.8752 14.0485 16.2063 15.3792 14.8687C16.71 13.531 17.3753 11.8943 17.3753 9.9585C17.3753 9.19786 17.2573 8.46256 17.0212 7.7526C16.785 7.04264 16.4378 6.396 15.9795 5.81266L6.33366 15.4377C6.90708 15.9368 7.55159 16.3012 8.2672 16.5308C8.98281 16.7604 9.72719 16.8752 10.5003 16.8752ZM5.04199 14.1252L14.6462 4.50016C14.0464 4.03591 13.3988 3.69068 12.7032 3.46448C12.0077 3.23827 11.2734 3.12516 10.5003 3.12516C8.57845 3.12516 6.95215 3.78964 5.62141 5.11858C4.29069 6.44754 3.62533 8.06084 3.62533 9.9585C3.62533 10.7365 3.75579 11.4789 4.01672 12.1859C4.27765 12.8929 4.61941 13.5393 5.04199 14.1252Z' />
    </svg>
  )
}

export default MuteIcon
