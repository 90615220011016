import { AuthLayout } from 'components'
import { FormLayout, UnumSection } from '..'
import PinInput from 'react-pin-input'
import { Link } from 'react-router-dom'

const MobileVerify = () => {
  return (
    <AuthLayout>
      <div className='flex flex-col md:flex-row justify-between items-center'>
        <UnumSection />
        <FormLayout>
          <p className='text-white w-[170px] text-center text-lg'>
            We sent you an sms verification code
          </p>

          <div className='flex w-[279px] justify-between items-center'>
            <p className='text-white text-sm'>Sent to: +1 234 567 8900 </p>
            <p className='text-sm text-BlueDarkColorMain cursor-pointer'>
              Change
            </p>
          </div>

          <PinInput
            length={5}
            initialValue=''
            secret
            secretDelay={100}
            onChange={(value, index) => {}}
            type='numeric'
            inputMode='number'
            style={{ padding: '10px' }}
            //inputStyle={{ borderColor: 'red' }}
            inputFocusStyle={{ borderColor: 'blue' }}
            onComplete={(value, index) => {}}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
          <Link to='/add-email'>
            <button
              type='button'
              className='flex w-[330px] h-[53px] justify-center items-center rounded-xl bg-BlueDarkColorMain px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-BlueDarkColorMainHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              Proceed
            </button>
          </Link>
          <div className='w-[240px] flex justify-between items-center text-center text-white text-sm'>
            <p>Did not received a code ? </p>
            <p className='text-NegativeRed'>Resend</p>
          </div>
        </FormLayout>
      </div>
    </AuthLayout>
  )
}

export default MobileVerify
