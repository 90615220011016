/* eslint-disable no-unused-vars */
import { Search, SvgIcon, UnumLogoText } from 'components'
import {
  ContactsIcon,
  GroupIcon,
  SearchIcon,
  SyncAltIcon,
} from 'components/Icons'
import useLeftBar from 'components/LeftBar/useLeftBar'
import { DailyEvents, LongEvents } from 'components/PerDayEvents/Events'
import {
  fetchPerDayEvent,
  setInnerEventDescIsOpen,
  setSearchIsOpen,
  setSingleEvent,
} from 'features'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import {
  currentDate,
  formatData,
  generateMonthData,
  generateYearData,
  userId,
} from 'utils'

const HomeScreen = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [yearData, setYearData] = useState(generateYearData(currentYear))
  const { nightMode, defaultTextIconColor } = useSelector(
    (state) => state.theme
  )

  const dispatch = useDispatch()

  const { searchIsOpen, homeScreenIsOpen } = useSelector(
    (state) => state.sideView
  )
  const { fetchedPerDayEvents } = useSelector((state) => state.event)

  const month = yearData[7]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const { openGroupsHandler, openConnectionsHandler, openContactsHandler } =
    useLeftBar()
  const weekDays = ['SUN', 'MON', 'THU', 'WED', 'THU', 'FRI', 'SAT']

  useEffect(() => {
    const date = moment(new Date()).format('YYYY-MM-DD')
    dispatch(fetchPerDayEvent(date))
  }, [dispatch, homeScreenIsOpen])

  return (
    <>
      {!searchIsOpen ? (
        <div
          className={`px-4 pt-8 overflow-y-scroll min-h-screen ${defaultTextIconColor}`}
        >
          {/* Header */}
          <div className='flex justify-between'>
            <UnumLogoText />
            <div className='w-8 h-8  relative flex  justify-center  items-center rounded-full '>
              <img
                alt='user'
                src='http://source.unsplash.com/100x100/?girl'
                className='rounded-full cursor-pointer'
              />
            </div>
          </div>
          {/* Calendar */}
          <div className='grid grid-cols-1   pt-8 pb-4  gap-4 '>
            <div
              key={uuid()}
              className={`rounded-xl p-2 pb-8 hover:bg-gray-600 cursor-pointer z-30 ${
                nightMode ? 'bg-DMDarkBlueForeground ' : 'bg-LightForeground  '
              }`}
            >
              <div className='flex items-center'>
                <h2 className='flex-auto text-sm font-semibold pl-4 pb-5 pt-3'>
                  {month.name}{' '}
                  <span className='text-gray-400'>/ {month.year}</span>
                </h2>
              </div>
              <div className='grid grid-cols-7 opacity-80 items-center w-full ml-4 my-2 text-xs'>
                {weekDays.map((item) => (
                  <p key={uuid()}>{item}</p>
                ))}
              </div>

              <div className=' grid grid-cols-7 h-[10rem] text-sm'>
                {month?.days.map((day, dayIdx) => (
                  <div
                    key={day.date}
                    className={classNames(
                      ' focus:z-10 relative sm:p-1  items-center justify-center flex',
                      day.isCurrentMonth ? 'text-gray-300' : 'text-gray-500'
                    )}
                  >
                    <button
                      type='button'
                      className={classNames(
                        'mx-auto flex  items-center justify-center  rounded-full relative'
                      )}
                    >
                      <time
                        dateTime={day.date}
                        className={classNames(
                          'mx-auto flex h-[1.95rem] w-[1.95rem] sm:h-5 sm:w-5 items-center   justify-center text-xs rounded-md ',
                          day.isToday && 'text-BlueDarkColorMain  '
                        )}
                      >
                        {day.date.split('-').pop().replace(/^0/, '')}
                      </time>
                      {false && (
                        <span className='absolute mt-8 rounded-xl w-4 h-1 bg-BlueDarkColorMain'></span>
                      )}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='divide-y divide-DivideLine divide-opacity-20'>
            {/* Events */}
            <div className='py-3'>
              <p>{moment(currentDate).format('dddd DD MMM').toUpperCase()}</p>
              <div className='flex gap-2'>
                <p className='font-bold text-lg'>TODAY</p>{' '}
                <span className='opacity-50 pt-1'>
                  {fetchedPerDayEvents?.length} event
                </span>
              </div>
              {/* Per day events list */}
              <div className='w-full  overflow-scroll gap-2 flex  '>
                {fetchedPerDayEvents.length !== 0 ? (
                  fetchedPerDayEvents.map(
                    (event, index) =>
                      event.group.isVisible && (
                        <div
                          key={event._id}
                          onClick={() => {
                            //setSelectedElementIndex(index)
                            dispatch(setInnerEventDescIsOpen(true))
                            dispatch(setSingleEvent(event))
                          }}
                          className={` relative text-xs`}
                        >
                          {formatData(event.endTime) ===
                            moment(new Date()).format('YYYY-MM-DD') &&
                          formatData(event.startTime) ===
                            moment(new Date()).format('YYYY-MM-DD') ? (
                            <DailyEvents event={event} moment={moment} />
                          ) : (
                            <LongEvents
                              event={event}
                              moment={moment}
                              selectedDayOnCalendar={moment(new Date()).format(
                                'YYYY-MM-DD'
                              )}
                            />
                          )}
                        </div>
                      )
                  )
                ) : (
                  <p className='py-3 text-center text-lg'>No events :(</p>
                )}
              </div>
            </div>

            {/* Buttons And Search*/}
            <div className='py-3'>
              <div className='flex justify-between w-full gap-3'>
                <div
                  onClick={openGroupsHandler}
                  className={`h-24 w-28 rounded-xl gap-1 bg-DMDarkBlueForeground text-center flex items-center justify-center flex-col cursor-pointer ${
                    nightMode
                      ? 'bg-DMDarkBlueForeground '
                      : 'bg-LightForeground '
                  } `}
                >
                  <GroupIcon className={`!fill-BlueDarkColorMain `} />

                  <p>Groups</p>
                </div>
                <div
                  onClick={openContactsHandler}
                  className={`h-24 w-28 rounded-xl gap-1 bg-DMDarkBlueForeground text-center flex items-center justify-center flex-col cursor-pointer ${
                    nightMode
                      ? 'bg-DMDarkBlueForeground '
                      : 'bg-LightForeground '
                  }  `}
                >
                  <ContactsIcon className={`!fill-BlueDarkColorMain `} />
                  <p>Contacts</p>
                </div>
                <div
                  onClick={openConnectionsHandler}
                  className={`h-24 w-28 rounded-xl gap-1 bg-DMDarkBlueForeground text-center flex items-center justify-center flex-col cursor-pointer ${
                    nightMode
                      ? 'bg-DMDarkBlueForeground '
                      : 'bg-LightForeground '
                  }  `}
                >
                  <SyncAltIcon className={`!fill-BlueDarkColorMain `} />
                  <p>Connections</p>
                </div>
              </div>
              {/* search */}
              <div
                onClick={() => dispatch(setSearchIsOpen(true))}
                className={`w-full h-10 rounded-xl my-3 items-center flex ${
                  nightMode ? 'bg-DMDarkBlueForeground ' : 'bg-LightForeground '
                }`}
              >
                <SearchIcon className={'m-2'} />
                <input
                  className={`w-full  outline-none  text-DM2ndLighterTextIcon  ${
                    nightMode
                      ? 'bg-DMDarkBlueForeground '
                      : 'bg-LightForeground '
                  }`}
                  placeholder='Search UNUM'
                  name='search'
                  type='text'
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Search />
      )}
    </>
  )
}

export default HomeScreen
