import axios from 'axios'
import { useEffect, useState } from 'react'
import useUser from './useUser'

const useAvatar = () => {
  const [avatarOrEmail, setAvatarOrEmail] = useState(null)
  const { user } = useUser()

  useEffect(() => {
    const checkAvatarAndSetState = async () => {
      if (user?.avatar) {
        try {
          const avatarUrl = `${process.env.REACT_APP_BASE_URL}/${user.avatar}`
          const response = await axios.head(avatarUrl)

          if (response.status === 200) {
            setAvatarOrEmail(
              <img
                alt='user'
                src={avatarUrl}
                className='rounded-full  aspect-square object-cover  cursor-pointer'
              />
            )
            return
          }
        } catch (error) {
          // Avatar does not exist on the server, fall through to render email slice
        }
      }

      setAvatarOrEmail(
        <div className='w-[100px] flex items-center justify-center h-[100px] rounded-full bg-slate-400'>
          <p className='text-lg'>{user?.email.slice(0, 2)}</p>
        </div>
      )
    }

    checkAvatarAndSetState()
  }, [user])

  return {
    avatarOrEmail,
  }
}

export default useAvatar
