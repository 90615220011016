/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const ContactsIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 50 50'
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M7.55 47.15q-.65 0-1.075-.425-.425-.425-.425-1.075 0-.65.425-1.075.425-.425 1.075-.425h32.9q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075-.425.425-1.075.425Zm0-43.3q-.65 0-1.075-.425Q6.05 3 6.05 2.35q0-.65.425-1.075Q6.9.85 7.55.85h32.9q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075-.425.425-1.075.425ZM24 26.35q2.65 0 4.425-1.75t1.775-4.4q0-2.65-1.775-4.425T24 14q-2.65 0-4.425 1.775T17.8 20.2q0 2.65 1.775 4.4Q21.35 26.35 24 26.35ZM7.15 41.15q-1.95 0-3.325-1.375Q2.45 38.4 2.45 36.45v-24.9q0-2.05 1.375-3.375Q5.2 6.85 7.15 6.85h33.7q1.95 0 3.325 1.375Q45.55 9.6 45.55 11.55v24.9q0 1.95-1.375 3.325Q42.8 41.15 40.85 41.15Zm3.3-4.15h27.1q-2.2-3.25-5.975-5.05-3.775-1.8-7.575-1.8-3.85 0-7.55 1.8-3.7 1.8-6 5.05Z' />
    </svg>
  )
}

export default ContactsIcon
