/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const CheckIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M7.875 14.9377C7.75 14.9377 7.62847 14.9134 7.51042 14.8648C7.39236 14.8162 7.29861 14.7433 7.22917 14.646L3.45833 10.896C3.27778 10.7155 3.1875 10.4863 3.1875 10.2085C3.1875 9.93075 3.27778 9.70159 3.45833 9.52103C3.63889 9.34047 3.86458 9.2502 4.13542 9.2502C4.40625 9.2502 4.63889 9.34047 4.83333 9.52103L7.875 12.5835L15.1667 5.29186C15.3611 5.11131 15.5903 5.01756 15.8542 5.01061C16.1181 5.00367 16.3403 5.09742 16.5208 5.29186C16.7153 5.48631 16.8125 5.71895 16.8125 5.98978C16.8125 6.26061 16.7153 6.48631 16.5208 6.66686L8.54167 14.646C8.45833 14.7433 8.35764 14.8162 8.23958 14.8648C8.12153 14.9134 8 14.9377 7.875 14.9377Z' />
    </svg>
  )
}

export default CheckIcon
