import { Navigate, Outlet } from 'react-router-dom'
import { hasJWT } from 'utils'
const ProtectedRoute = ({ children }) => {
  if (!hasJWT()) {
    return <Navigate to={'/calendar'} replace />
  }

  return children ? children : <Outlet />
}

export default ProtectedRoute
