/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const DescriptionIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 23 23'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path
        d='M4.5487 20.0047C3.9563 20.0047 3.44917 19.7938 3.02731 19.3719C2.60546 18.95 2.39453 18.4429 2.39453 17.8505V4.51302C2.39453 3.91432 2.60546 3.40179 3.02731 2.97545C3.44917 2.54911 3.9563 2.33594 4.5487 2.33594H13.1424C13.4358 2.33594 13.7154 2.39705 13.9812 2.51927C14.247 2.64149 14.4869 2.80191 14.7008 3.00052L19.3987 7.69844C19.5973 7.91233 19.7577 8.15219 19.8799 8.41802C20.0022 8.68385 20.0633 8.96344 20.0633 9.25677V17.8505C20.0633 18.4429 19.8501 18.95 19.4238 19.3719C18.9974 19.7938 18.4849 20.0047 17.8862 20.0047H4.5487ZM7.1799 15.3755H15.2825C15.4867 15.3755 15.6585 15.3 15.7979 15.1489C15.9373 14.9979 16.007 14.8185 16.007 14.6108C16.007 14.4032 15.9368 14.2304 15.7962 14.0926C15.6556 13.9548 15.4815 13.8859 15.2737 13.8859H7.15331C6.9485 13.8859 6.77639 13.9562 6.63698 14.0967C6.49757 14.2371 6.42786 14.4112 6.42786 14.6189C6.42786 14.8265 6.50301 15.0046 6.6533 15.1529C6.8036 15.3013 6.97913 15.3755 7.1799 15.3755ZM7.1788 12.0068H15.2607C15.4642 12.0068 15.6394 11.9312 15.7865 11.7802C15.9335 11.6291 16.007 11.4512 16.007 11.2465C16.007 11.0418 15.9319 10.8652 15.7816 10.7169C15.6313 10.5685 15.4561 10.4943 15.2561 10.4943H7.17419C6.97074 10.4943 6.79549 10.5698 6.64844 10.7209C6.50139 10.8719 6.42786 11.0498 6.42786 11.2545C6.42786 11.4593 6.50301 11.6358 6.6533 11.7842C6.8036 11.9326 6.97877 12.0068 7.1788 12.0068ZM7.18411 8.6151H12.0407C12.2519 8.6151 12.4311 8.53957 12.5781 8.3885C12.7252 8.23744 12.7987 8.05955 12.7987 7.85484C12.7987 7.65012 12.7235 7.47357 12.5732 7.32519C12.423 7.1768 12.246 7.1026 12.0424 7.1026H7.1859C6.97464 7.1026 6.79549 7.17814 6.64844 7.3292C6.50139 7.48027 6.42786 7.65816 6.42786 7.86286C6.42786 8.06759 6.50301 8.24414 6.6533 8.39251C6.8036 8.54091 6.98054 8.6151 7.18411 8.6151Z'
        fill='#FAFAFA'
      />
    </svg>
  )
}

export default DescriptionIcon
