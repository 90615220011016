import { SearchIcon } from 'components/Icons'
import { useSelector } from 'react-redux'

const InviteButton = () => {
  const { nightMode } = useSelector((state) => state.theme)

  return (
    <div className='w-full flex items-center justify-center relative my-4 h-[65px] bg-BlueDarkColorMain hover:bg-BlueLightColor cursor-pointer rounded-xl'>
      <img
        src='images/unum-logo.png'
        className='absolute left-5'
        alt='unum-logo'
      />
      <div>
        <p
          className={` !leading-[14px] font-medium py-0 text-sm ${
            nightMode ? 'text-DMMainLightTextIcon' : 'text-LMMainDarkTextIcon2'
          }`}
        >
          Invite People on UNUM
        </p>
        <a
          className='text-[#1980A0] text-sm font-medium !leading-[14px]'
          href='https://unum.today'
        >
          https://invite.unum.today
        </a>
      </div>

      <SearchIcon className='absolute right-3' size={20} />
    </div>
  )
}

export default InviteButton
