/* eslint-disable no-unused-vars */
import React from 'react'
import { IoCheckmarkCircle } from 'react-icons/io5'

import {
  createGroup,
  setAddNewGroupIsOpen,
  setGroupTitle,
  setGroupSettingsIsOpen,
  editGroup,
  setGroupDescriptionIsOpen,
} from 'features'
import { addGroupHandler, userId } from 'utils'
import useAddNewGroup from 'hooks/useAddNewGroup'
import ColorList from './ColorList'
import { useSelector } from 'react-redux'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClearIcon,
  DeleteIcon,
  GroupIcon,
  GroupsIcon,
  LogOutIcon,
  PaletteIcon,
  SyncIcon,
  WavingHandIcon,
} from 'components/Icons'

const AddNewGroup = () => {
  const {
    dispatch,
    groupError,
    groupStatus,
    groupTitle,
    selectedColor,
    colors,
    showColors,
    setShowColors,
  } = useAddNewGroup()

  const { nightMode, defaultTextIconColor } = useSelector(
    (state) => state.theme
  )
  const { groupSettingsIsOpen } = useSelector((state) => state.sideView)
  const { selectedGroup } = useSelector((state) => state.group)

  const defaultColors = colors.filter((item) => item.type === 'default')
  const vividColors = colors.filter((item) => item.type === 'vivid')
  const paleColors = colors.filter((item) => item.type === 'pale')

  const addGroupHandler = () => {
    if (groupTitle !== '' && selectedColor !== '') {
      if (groupSettingsIsOpen) {
        dispatch(
          editGroup({
            name: groupTitle,
            color: selectedColor,
            userId: userId(),
            groupId: selectedGroup?._id,
          })
        )
      } else {
        dispatch(
          createGroup({
            name: groupTitle,
            color: selectedColor,
            userId: userId(),
          })
        )
      }

      dispatch(setAddNewGroupIsOpen(false))
      dispatch(setGroupSettingsIsOpen(false))
      dispatch(setGroupDescriptionIsOpen(false))
    } else {
      alert('Fill all the fields')
    }
  }

  const backArrowHandler = () => {
    dispatch(setAddNewGroupIsOpen(false))
    dispatch(setGroupSettingsIsOpen(false))
  }
  return (
    <div className={`h-screen p-3 text-xs ${defaultTextIconColor}`}>
      {!showColors ? (
        <>
          {/* New Group */}
          {/* Header */}
          <div className='pb-6 font-bold text-sm flex justify-between items-center relative '>
            <ChevronLeftIcon
              size={30}
              onClick={backArrowHandler}
              className=' cursor-pointer '
            />

            <p className={`text-sm  `}>
              {' '}
              {groupSettingsIsOpen ? 'Edit Group' : 'New Group'}
            </p>

            <button onClick={addGroupHandler}>
              {groupTitle !== '' && selectedColor !== '' ? (
                <IoCheckmarkCircle color='#40EF22' size={25} cursor='pointer' />
              ) : (
                <IoCheckmarkCircle color='red' size={25} cursor='pointer' />
              )}
            </button>
          </div>
          {/* Content */}
          <div className='flex flex-col gap-4'>
            {/* Form */}
            <form
              className={`w-full h-[220px] mt-4  rounded-xl  p-2 divide-y-[1px]  divide-DivideLine  ${
                nightMode
                  ? 'bg-DMDarkBlueForeground divide-opacity-20'
                  : 'bg-GrayMediumBGColor bg-opacity-20'
              }`}
            >
              {/* group title input */}
              <div>
                <label
                  className={` text-sm mb-10 ${
                    nightMode
                      ? 'text-DM2ndLighterTextIcon '
                      : 'text-LM2ndLighterTextIcon'
                  }`}
                  htmlFor='group-title'
                >
                  Group Title
                </label>
                <input
                  value={groupTitle}
                  onChange={(evt) => dispatch(setGroupTitle(evt.target.value))}
                  id='calendar-title'
                  type='text'
                  className={`form-control block w-full pt-10 text-base font-normal  bg-clip-padding   rounded transition ease-in-out m-0  focus:border-blue-600 focus:outline-none ${
                    nightMode
                      ? 'bg-DMDarkBlueForeground'
                      : 'bg-GrayMediumBGColor bg-opacity-20'
                  }`}
                />
              </div>
              {/* Selects */}
              <div>
                {/* Color picker */}
                <div
                  onClick={() => {
                    setShowColors(true)
                  }}
                  className='flex w-full justify-between cursor-pointer bg-red '
                >
                  <div className='flex  justify-center items-center'>
                    <PaletteIcon size={18} className={'mx-2'} />
                  </div>
                  <div
                    className={`flex items-center justify-between w-full border-b-[1px] py-1.5 border-DivideLine ${
                      nightMode ? 'border-opacity-20 ' : ''
                    } `}
                  >
                    <p>Accent Color</p>
                    <div className='flex items-center gap-2'>
                      <span
                        style={{
                          backgroundColor: selectedColor,
                        }}
                        className=' rounded-full h-4 w-4 '
                      ></span>

                      <ChevronRightIcon
                        className={'pt-0.5'}
                        secondaryColor={true}
                      />
                    </div>
                  </div>
                </div>
                {/* Participants*/}
                <div
                  className={`flex w-full justify-between cursor-pointer bg-red `}
                >
                  <div className='flex  justify-center items-center'>
                    <GroupsIcon size={18} className={'mx-2'} />
                  </div>
                  <div
                    className={`flex items-center justify-between w-full border-b-[1px] py-1.5 border-DivideLine ${
                      nightMode ? 'border-opacity-20 ' : ''
                    } `}
                  >
                    <p>Participants</p>
                    <div className='flex items-center gap-2'>
                      <p
                        className={`${
                          nightMode
                            ? 'text-DM2ndLighterTextIcon '
                            : 'text-LM2ndLighterTextIcon'
                        }`}
                      >
                        {groupSettingsIsOpen
                          ? selectedGroup?.invitedUsers.length
                          : 4}{' '}
                        Person
                      </p>

                      <ChevronRightIcon
                        className={'pt-1'}
                        secondaryColor={true}
                      />
                    </div>
                  </div>
                </div>
                {/* default rank */}
                <div
                  className={`flex w-full justify-between cursor-pointer bg-red `}
                >
                  <div className='flex  justify-center items-center'>
                    <GroupIcon size={18} className={'mx-2'} />
                  </div>
                  <div
                    className={`flex items-center justify-between w-full  py-1.5 ${
                      nightMode ? 'border-opacity-20 ' : ''
                    } `}
                  >
                    <p>Default rank</p>
                    <div className='flex items-center gap-2'>
                      <p
                        className={`${
                          nightMode
                            ? 'text-DM2ndLighterTextIcon '
                            : 'text-LM2ndLighterTextIcon'
                        }`}
                      >
                        Member
                      </p>

                      <ChevronRightIcon
                        className={'pt-1'}
                        secondaryColor={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* Sync with */}
            <div
              className={`${
                nightMode
                  ? 'text-DM2ndLighterTextIcon '
                  : 'text-LM2ndLighterTextIcon'
              }`}
            >
              <p className={`text-base pb-3 `}>Sync with:</p>

              <div
                className={`h-[132px] w-full rounded-xl flex flex-col justify-between  p-3  ${
                  nightMode
                    ? 'bg-DMDarkBlueForeground '
                    : 'bg-GrayMediumBGColor bg-opacity-20 '
                }`}
              >
                <div className='flex w-full justify-between cursor-pointer  '>
                  <p className='text-white'>superboy@gmail.com</p>

                  <SyncIcon size={20} className={'fill-BlueDarkColorMain'} />
                </div>
                <span
                  className={`w-full h-[1px] bg-DivideLine  ${
                    nightMode ? 'opacity-20 ' : ''
                  }`}
                ></span>
                <div className='flex w-full  justify-between cursor-pointer '>
                  <p>annashikhman@yahoo.com </p>

                  <SyncIcon size={20} secondaryColor={true} />
                </div>
                <span
                  className={`w-full h-[1px] bg-DivideLine  ${
                    nightMode ? 'opacity-20 ' : ''
                  }`}
                ></span>
                <div className='flex w-full  justify-between cursor-pointer  '>
                  <p>dshikhman@gmail.com </p>

                  <SyncIcon size={20} secondaryColor={true} />
                </div>
              </div>
            </div>
            {/* Leave & Pass Ownership buttons */}
            {groupSettingsIsOpen && (
              <>
                <div
                  className={`h-[88px] rounded-xl flex flex-col justify-between py-1 overflow-hidden ${
                    nightMode
                      ? 'bg-DMDarkBlueForeground'
                      : 'bg-GrayMediumBGColor bg-opacity-20'
                  }`}
                >
                  <div className=' px-1 text-NegativeRed  flex justify-start items-center '>
                    <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                      <LogOutIcon className={'!fill-NegativeRed mx-2'} />
                      <div
                        className={`py-2 mr-2 flex w-full   justify-between items-center `}
                      >
                        <p className='text-sm'>Pass Ownership</p>
                      </div>
                    </div>
                  </div>
                  {/* divider */}
                  <span
                    className={`w-[85%] h-[1px] ml-[2.8rem]  bg-DivideLine   ${
                      nightMode ? 'opacity-20 ' : ''
                    }`}
                  ></span>
                  <div className=' px-1 text-NegativeRed  flex justify-start items-center'>
                    <div className='flex justify-between items-center cursor-pointer w-full gap-1'>
                      <WavingHandIcon className={'mx-2 !fill-NegativeRed'} />
                      <div
                        className={`py-2 mr-2 flex w-full   justify-between items-center `}
                      >
                        <p className='text-sm'>Leave Group</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Delete Group Button */}
                <div
                  className={`h-[44px] rounded-xl items-center justify-center  flex text-sm overflow-x-hidden cursor-pointer ${
                    nightMode
                      ? 'bg-DMDarkBlueForeground'
                      : 'bg-GrayMediumBGColor bg-opacity-20'
                  }`}
                >
                  <div className={` flex justify-between w-full `}>
                    <div className='flex items-center ml-1'>
                      <DeleteIcon
                        className={'mx-2 !fill-NegativeRed'}
                        size={20}
                      />

                      <p className={`text-NegativeRed pl-1`}>
                        Delete this group
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {/* Color List */}
          <div className='flex justify-between '>
            <ChevronLeftIcon
              onClick={() => {
                setShowColors(false)
              }}
              className={'cursor-pointer'}
              size={30}
            />

            <p
              className={`text-sm  absolute left-1/2 -translate-x-1/2 pr-5 pt-2 ${
                nightMode
                  ? ' text-DMMainLightTextIcon'
                  : 'text-LMMainDarkTextIcon '
              }`}
            >
              Accent Color
            </p>
            <button
              onClick={() => {
                dispatch(setAddNewGroupIsOpen(false))
                dispatch(setGroupSettingsIsOpen(false))
              }}
            ></button>
            <button className='invisible'>
              <IoCheckmarkCircle color='#40EF22' size={25} cursor='pointer' />
            </button>
          </div>
          <div
            className={`w-full h-[75%] mt-4  rounded-xl px-6 pb-4 mb-4  overflow-scroll ${
              nightMode ? ' bg-DMDarkBlueForeground' : 'bg-grayLightBG '
            }`}
          >
            {/* Color - Grid */}
            <p className=' text-base py-3'>Default colors </p>
            <div className='grid grid-cols-5 gap-3  '>
              {defaultColors.map((item) => (
                <ColorList
                  className={'h-12 w-12'}
                  key={item._id}
                  item={item}
                  setShowColors={setShowColors}
                />
              ))}
            </div>
            <p className=' text-base py-3'>Vivid colors </p>
            <div className='grid grid-cols-5 gap-3  '>
              {vividColors.map((item) => (
                <ColorList
                  className={'h-12 w-12'}
                  key={item._id}
                  item={item}
                  setShowColors={setShowColors}
                />
              ))}
            </div>
            <p className=' text-base py-3'>Pale colors </p>
            <div className='grid grid-cols-6 gap-3  '>
              {paleColors.map((item) => (
                <ColorList
                  className={'h-10 w-10'}
                  key={item._id}
                  item={item}
                  setShowColors={setShowColors}
                />
              ))}
            </div>
          </div>
          <p className='text-xs '>
            NOTE: You can choose any color for each of your groups. It will not
            change colors for other participants. <br /> <br /> Each color can
            only be chosen once for one group.
          </p>
        </>
      )}
    </div>
  )
}

export default AddNewGroup
