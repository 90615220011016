import { ClearIcon, SearchIcon } from 'components/Icons'
import { useSelector } from 'react-redux'

const Search = ({
  handleSearch,
  searchTerm,
  firstInputRef,
  clearSearchInputHandler,
}) => {
  const { nightMode } = useSelector((state) => state.theme)
  return (
    <div
      className={` rounded-xl items-center flex group overflow-hidden ${
        nightMode
          ? 'bg-DMDarkBlueForeground'
          : 'bg-GrayMediumBGColor bg-opacity-20'
      }  `}
    >
      <SearchIcon
        color={'#778EAE'}
        size={28}
        className={`mx-2 fill-DM2ndLighterTextIcon  ${
          nightMode
            ? 'group-hover:!fill-DMMainLightTextIcon'
            : 'group-hover:!fill-LMMainDarkTextIcon'
        }`}
      />
      <input
        onChange={handleSearch}
        value={searchTerm}
        ref={firstInputRef}
        className={`w-full h-10  placeholder-DM2ndLighterTextIcon  outline-none bg-opacity-0   text-DM2ndLighterTextIcon text-sm ${
          nightMode
            ? 'bg-DMDarkBlueBackground group-hover:placeholder-DMMainLightTextIcon '
            : ' bg-GrayMediumBGColor bg-opacity-20 group-hover:placeholder-LMMainDarkTextIcon'
        }`}
        placeholder='Search contacts...'
        name='search'
        type='text'
      />

      <ClearIcon
        onClick={clearSearchInputHandler}
        className={'mx-3 cursor-pointer fill-DM2ndLighterTextIcon'}
        size={18}
      />
    </div>
  )
}

export default Search
