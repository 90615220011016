import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import userService from './userService'

export const fetchGoogleContacts = createAsyncThunk(
  'user/fetchGoogleContacts',
  async function (data, { rejectWithValue }) {
    try {
      return await userService.fetchGoogleContacts(data)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const fetchAllUsers = createAsyncThunk(
  'user/fetchAllUsers',
  async function (_, { rejectWithValue }) {
    try {
      return await userService.fetchAllUsers()
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const fetchUserInfo = createAsyncThunk(
  'user/fetchUserInfo',
  async function (_, { rejectWithValue }) {
    try {
      return await userService.fetchUserInfo()
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const editUserAPI = createAsyncThunk(
  'user/editUserAPI',
  async function (formData, { rejectWithValue }) {
    try {
      return await userService.editUserAPI(formData)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const initialState = {
  contacts: [],
  fetchedUsers: [],
  fetchedUsersStatus: '',
  fetchedUsersError: null,
  fetchedUserInfo: {},
  fetchedUserInfoStatus: '',
  fetchedUserInfoError: null,
  editUserAPIStatus: '',
  editUserAPIError: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGoogleContacts.fulfilled]: (state, action) => {
      state.contacts = [...state.contacts, ...action.payload.contacts]
    },
    [fetchAllUsers.fulfilled]: (state, action) => {
      state.fetchedUsers = action.payload
      state.fetchedUsersStatus = 'success'
    },
    [fetchAllUsers.pending]: (state, action) => {
      state.fetchedUsersStatus = 'pending'
    },
    [fetchAllUsers.rejected]: (state, action) => {
      state.fetchedUsersStatus = 'rejected'
      state.fetchedUsersError = action.payload.response.data.message
    },
    [fetchUserInfo.fulfilled]: (state, action) => {
      state.fetchedUserInfo = action.payload
      state.fetchedUserInfoStatus = 'success'
    },
    [fetchUserInfo.pending]: (state, action) => {
      state.fetchedUserInfoStatus = 'pending'
    },
    [fetchUserInfo.rejected]: (state, action) => {
      state.fetchedUserInfoStatus = 'rejected'
      state.fetchedUserInfoStatusError = action.payload.response.data.message
    },
    [editUserAPI.fulfilled]: (state, action) => {
      state.editUserAPIStatus = 'success'
    },
    [editUserAPI.pending]: (state, action) => {
      state.editUserAPIStatus = 'pending'
    },
    [editUserAPI.rejected]: (state, action) => {
      state.editUserAPIStatus = 'rejected'
      state.editUserAPIError = action.payload.response.data.message
    },
  },
})

// Action creators are generated for each case reducer function

export const {} = userSlice.actions

export default userSlice.reducer
