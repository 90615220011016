import { setTheme } from 'features'
import { useSelector, useDispatch } from 'react-redux'

export default function useDarkSide() {
  const { theme } = useSelector((state) => state.theme)
  const dispatch = useDispatch()

  const setDarkTheme = () => {
    localStorage.setItem('theme', 'light')
    dispatch(setTheme('light'))
  }

  const setLightTheme = () => {
    localStorage.setItem('theme', 'dark')
    dispatch(setTheme('dark'))
  }

  const colorTheme = theme === 'dark' ? 'light' : 'dark'

  const defaultTextIconColor =
    colorTheme === 'light'
      ? 'text-LMMainDarkTextIcon'
      : 'text-DMMainLightTextIcon'

  return [colorTheme, setLightTheme, setDarkTheme, defaultTextIconColor]
}
