import { AuthLayout } from 'components'
import { FormLayout, UnumSection } from '..'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const ChooseNumber = () => {
  const [phoneNumber, setPhoneNumber] = useState()

  const numberHandler = (phone) => {
    setPhoneNumber(phone)
  }

  return (
    <AuthLayout>
      <div className='flex min-h-screen w-full flex-col absolute bottom-0 md:flex-row justify-between items-center'>
        <UnumSection />
        <FormLayout>
          <PhoneInput
            inputClass='!rounded-2xl !bg-GrayMediumBG !bg-opacity-30 !placeholder-white !w-[330px] !h-[54px]  !border-0'
            buttonClass='!rounded-tl-2xl !rounded-bl-2xl !border-0 !bg-GrayMediumBG !bg-opacity-5'
            dropdownClass='!rounded-2xl !bg-DM2ndLighterTextIcon  !w-[330px]'
            country={'us'}
            value={phoneNumber}
            onChange={(phone) => numberHandler(phone)}
          />
          <Link to='/mobile-verify'>
            <button
              type='button'
              className='flex w-[328px] h-[53px] justify-center items-center rounded-xl bg-BlueDarkColorMain px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-BlueDarkColorMainHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              Proceed
            </button>
          </Link>
          <div className='w-[280px]  text-center text-white text-sm'>
            <p>Already have an account</p>
            <p className='mb-5'>but lost access to phone number?</p>
            <Link className='underline text-BlueLightColor' to='/sign-in'>
              Log in with email
            </Link>
          </div>
        </FormLayout>
      </div>
    </AuthLayout>
  )
}

export default ChooseNumber
