import moment from 'moment'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

const useWeek = () => {
  //const selectedDay = useSelector((state) => state.event.date)
  const startWeekDay = useSelector((state) => state.event.startWeekDay)
  let week = []

  const getFullWeeksStartAndEndInMonth = useCallback(
    (year) => {
      const weeksByMonth = []
      for (let month = 0; month < 12; month++) {
        const weeks = []
        let firstDate = new Date(year, month, 1)
        let lastDate = new Date(year, month + 1, 0)
        let numDays = lastDate.getDate()

        let start = 1
        let end
        if (firstDate.getDay() === startWeekDay) {
          end = 7
        } else if (firstDate.getDay() === 0) {
          let preMonthEndDay = new Date(year, month, 0)
          start = preMonthEndDay.getDate() - 6 + 1
          end = 1
        } else {
          let preMonthEndDay = new Date(year, month, 0)
          start = preMonthEndDay.getDate() + 1 - firstDate.getDay() + 1
          end = 7 - firstDate.getDay() + 1
          weeks.push({
            start: start,
            end: end,
          })
          start = end + 1
          end = end + 7
        }

        // Create an object to keep track of which dates have already been added
        const addedDates = {}

        while (start <= numDays) {
          // Check if date has already been added
          const date = new Date(year, month, start)
          const formattedDate = moment(date).format('YYYY-MM-DD')
          if (!addedDates[formattedDate]) {
            // Date hasn't been added, so add it to the array and the addedDates object
            addedDates[formattedDate] = true
            weeks.push({
              start: start,
              end: end,
            })
          }

          start = end + 1
          end = end + 7
          end = start === 1 && end === 8 ? 1 : end
          if (end > numDays && start <= numDays) {
            end = end - numDays
            if (!addedDates[formattedDate]) {
              // Date hasn't been added, so add it to the array and the addedDates object
              addedDates[formattedDate] = true
              weeks.push({
                start: start,
                end: end,
              })
            }
            break
          }
        }

        const weeksFormatted = weeks.map(({ start, end }, index) => {
          const sub = +(start > end && index === 0)
          return Array.from({ length: 7 }, (_, index) => {
            const date = new Date(year, month - sub, start + index)
            return {
              date: date.getDate(),
              month: date.toLocaleString('en', { month: 'short' }),
              day: date.toLocaleString('en', { weekday: 'short' }),
              fullDate: moment(date).format('YYYY-MM-DD'),
            }
          })
        })

        weeksByMonth.push(weeksFormatted)
      }

      return weeksByMonth
    },
    [startWeekDay]
  )

  return { week, getFullWeeksStartAndEndInMonth }
}

export default useWeek
