/* eslint-disable no-unused-vars */
import {
  setGroupsIsOpen,
  setInnerEventDescIsOpen,
  setIsContactsOpen,
  setPerDayEventsIsOpen,
  setSelectedDayOnCalendar,
  setSingeEventDescIsOpen,
  setSwitchView,
} from 'features'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { viewButtonsArray } from './data'
import { useSelector } from 'react-redux'

const ViewButtonsList = ({ isLeftBarOpened }) => {
  const dispatch = useDispatch()
  const { switchView } = useSelector((state) => state.event)
  const { nightMode } = useSelector((state) => state.theme)

  const {
    singeEventDescIsOpen,
    singeEventDesc2IsOpen,
    perDayEventsIsOpen,
    groupsIsOpen,
    isContactsOpen,
    connectionsIsOpen,
    searchIsOpen,
    userProfileIsOpen,
  } = useSelector((state) => state.sideView)

  const calendarViewLocal = JSON.parse(localStorage.getItem('calendarView'))

  useEffect(() => {
    if (!calendarViewLocal) {
      dispatch(setSwitchView('year'))
      localStorage.setItem('calendarView', JSON.stringify('year'))
    }
  }, [calendarViewLocal, dispatch, switchView])

  useEffect(() => {
    if (!perDayEventsIsOpen) {
      dispatch(setSwitchView(calendarViewLocal))
    }
  }, [perDayEventsIsOpen])

  const viewButtonsHandler = (value) => {
    if (value === 'day') {
      dispatch(setSelectedDayOnCalendar(new Date()))
      dispatch(setPerDayEventsIsOpen(true))
      /* Close other drawers */
      dispatch(setInnerEventDescIsOpen(false))
      dispatch(setSingeEventDescIsOpen(false))
      dispatch(setGroupsIsOpen(false))
      dispatch(setIsContactsOpen(false))
      dispatch(setSwitchView(value))
      return
    }
    localStorage.setItem('calendarView', JSON.stringify(value))
    dispatch(setSwitchView(value))
    dispatch(setPerDayEventsIsOpen(false))
  }

  return (
    <div
      className={`flex transition-all duration-500 ${
        (isLeftBarOpened && searchIsOpen) ||
        (isLeftBarOpened && singeEventDescIsOpen) ||
        (isLeftBarOpened && singeEventDesc2IsOpen) ||
        (isLeftBarOpened && groupsIsOpen) ||
        (isLeftBarOpened && perDayEventsIsOpen) ||
        (isLeftBarOpened && isContactsOpen) ||
        (isLeftBarOpened && connectionsIsOpen) ||
        (isLeftBarOpened && userProfileIsOpen)
          ? 'sm:pl-[37rem]'
          : singeEventDescIsOpen ||
            singeEventDesc2IsOpen ||
            perDayEventsIsOpen ||
            groupsIsOpen ||
            isContactsOpen ||
            connectionsIsOpen ||
            searchIsOpen ||
            userProfileIsOpen
          ? 'sm:pl-[24rem]'
          : isLeftBarOpened
          ? 'sm:pl-[13.2rem]'
          : ''
      }`}
    >
      {viewButtonsArray.map((item) => (
        <div
          onClick={viewButtonsHandler.bind(null, item.value)}
          key={item.value}
          className={`w-[4.25rem] mx-1 h-[2rem]  cursor-pointer flex justify-center  items-center rounded-xl text-DMMainLightTextIcon ${
            switchView === item.value
              ? 'bg-BlueDarkColorMain hover:bg-BlueLightColor  '
              : nightMode
              ? 'bg-DMDarkBlueForeground  hover:bg-DMLightBlueForeground2'
              : 'bg-GrayMediumBGColor bg-opacity-20  text-LMMainDarkTextIcon2 hover:bg-opacity-30'
          }  `}
        >
          <p>{item.title}</p>
        </div>
      ))}
    </div>
  )
}

export default ViewButtonsList
