/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useEffect, useRef, useState } from 'react'
import {
  generateYearData,
  generateYearCalendarData,
  getEventDatesInRange,
} from 'utils'
import uuid from 'react-uuid'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector, useDispatch } from 'react-redux'
import {
  setConnectionsIsOpen,
  setGroupsIsOpen,
  setInnerEventDescIsOpen,
  setIsContactsOpen,
  setPerDayEventsIsOpen,
  setSelectedDayOnCalendar,
  setSingeEventDescIsOpen,
  setYearView,
} from 'features'
import moment from 'moment'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function YearView({ events }) {
  const dispatch = useDispatch()
  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [currentYearForScrollUp, setCurrentYearForScrollUp] = useState(
    new Date().getFullYear()
  )

  const [yearData, setYearData] = useState(
    generateYearCalendarData(currentYear)
  )

  // get scroll
  const [scrollPosition, setScrollPosition] = useState('down')

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset === 0) {
        setScrollPosition('up')
      } else {
        setScrollPosition('down')
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const addDataOnSCrollUp = () => {
    // Create a new array excluding the last object
    const updatedYearData = yearData.slice(0, -1)

    setYearData([
      ...generateYearCalendarData(currentYearForScrollUp - 1),
      ...updatedYearData,
    ])

    setCurrentYearForScrollUp(currentYearForScrollUp - 1)
    setCurrentYear(currentYear - 1)
  }

  const addYearOnScrollDown = () => {
    // scrolled to bottom, go to next year

    setYearData([...yearData, ...generateYearCalendarData(currentYear + 1)])
    setCurrentYear(currentYear + 1)
  }

  // add one year on first load
  useEffect(() => {
    setYearData([
      ...generateYearCalendarData(currentYearForScrollUp - 1),
      ...yearData,
    ])

    setCurrentYearForScrollUp(currentYearForScrollUp - 1)
  }, [])

  //  scroll to current year on firs load
  const lastDivRef = useRef(null)
  useEffect(() => {
    setTimeout(() => {
      if (lastDivRef.current) {
        lastDivRef.current.scrollIntoView()
      }
    }, 10)
  }, [])

  // current year view logic
  useEffect(() => {
    function handleScroll() {
      const scrollTop = window.scrollY
      const scrollBottom = scrollTop + window.innerHeight

      const visible = []
      yearData.forEach((year, index) => {
        const yearElement = document.getElementById(year.year)
        if (yearElement) {
          const top = yearElement.offsetTop
          const bottom = top + yearElement.clientHeight
          if (bottom > scrollTop && top < scrollBottom) {
            visible.push(year.year)
          }
        }
      })
      dispatch(setYearView(visible))
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [yearData])

  // add prev month on scroll up
  useEffect(() => {
    if (scrollPosition === 'up') {
      setTimeout(() => {
        addDataOnSCrollUp()

        let element = document?.getElementById(yearData[1]?.year)

        element?.scrollIntoView()
      }, 200)
    }
  }, [scrollPosition])

  const oneDayHandler = (date) => {
    dispatch(setSelectedDayOnCalendar(date))
    dispatch(setPerDayEventsIsOpen(true))
    /* Close other drawers */
    dispatch(setInnerEventDescIsOpen(false))
    dispatch(setSingeEventDescIsOpen(false))
    dispatch(setGroupsIsOpen(false))
    dispatch(setIsContactsOpen(false))
    dispatch(setConnectionsIsOpen(false))
  }

  /* Disable scroll on mouse over */
  const [isMouseOverDrawer, setMouseOverDrawer] = useState(false)
  const handleMouseEnter = () => {
    setMouseOverDrawer(true)
    document.body.style.overflow = 'auto'
  }

  const handleMouseLeave = () => {
    setMouseOverDrawer(false)
    document.body.style.overflow = 'hidden'
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className='animate-fadeIn relative'
    >
      <InfiniteScroll
        dataLength={yearData?.length}
        next={addYearOnScrollDown}
        hasMore={true}
        loader={<h4 className='text-white'>Loading...</h4>}
      >
        {yearData.map((year, index) => (
          <div
            ref={index === yearData.length - 1 ? lastDivRef : null}
            id={year.year}
            className='h-full  sm:h-screen  w-full '
          >
            <p className={`text-lg absolute pl-4 pt-6 ${defaultTextIconColor}`}>
              <span className=' text-DM2ndLighterTextIcon'>{year.year}</span>
            </p>
            <div className='grid grid-cols-1 sm:grid-cols-4 h-full pt-14 pb-4 px-4 gap-4 '>
              {year?.months?.map((month) => (
                <div
                  key={uuid()}
                  className={`rounded-md p-2 pb-8 z-30 ${
                    nightMode
                      ? 'bg-DMDarkBlueForeground text-white'
                      : 'bg-gray-300 text-black'
                  }`}
                >
                  <div className='flex items-center'>
                    <h2 className='flex-auto text-sm font-semibold pl-6'>
                      {month.name}{' '}
                      <span className='text-gray-400'>/ {month.year}</span>
                    </h2>
                  </div>

                  <div className=' grid grid-cols-7 min-h-full text-sm'>
                    {month?.days.map((day, dayIdx) => {
                      let dayIncludesEvents = false
                      events.map((event) => {
                        const eventsDatesRange = getEventDatesInRange(event)
                        if (eventsDatesRange.includes(day.date)) {
                          dayIncludesEvents = true
                        }
                      })

                      return (
                        <div
                          key={day.date}
                          onClick={oneDayHandler.bind(null, day.date)}
                          className={classNames(
                            ' focus:z-10 relative items-center justify-center flex',
                            day.isCurrentMonth
                              ? 'text-gray-300'
                              : 'text-gray-500'
                          )}
                        >
                          <button
                            type='button'
                            className={classNames(
                              'mx-auto flex  items-center justify-center  rounded-full relative'
                            )}
                          >
                            <time
                              dateTime={day.date}
                              className={classNames(
                                'mx-auto flex h-[1.95rem] w-[1.95rem] sm:h-5 sm:w-5 items-center   justify-center text-xs rounded-md ',
                                day.isToday &&
                                  'bg-BlueDarkColorMain  font-bold text-black'
                              )}
                            >
                              {day.date.split('-').pop().replace(/^0/, '')}
                            </time>
                            {dayIncludesEvents && (
                              <span className='absolute mt-8 rounded-xl w-4 h-1 bg-BlueDarkColorMain'></span>
                            )}
                          </button>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  )
}
