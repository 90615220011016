/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

const Bard = () => {
  const [googleToken, setGoogleToken] = useState('')
  const [googleData, setGoogleData] = useState({
    events: [],
    contacts: [],
  })

  const [googleEmail, setGoogleEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState()

  const location = useLocation()

  //Grab code after user Logged in with Google
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const authorizationCode = urlParams.get('code')
    setCode(authorizationCode)
  }, [location.search])

  //Set Google accessToken to localStorage
  useEffect(() => {
    setGoogleToken(localStorage.getItem('accessToken'))
  }, [googleToken])

  //Grab email from Google Token for Send to BackEnd
  useEffect(() => {
    const getGoogleEmail = async () => {
      try {
        if (googleToken) {
          const response = await axios(
            'https://www.googleapis.com/userinfo/v2/me',
            {
              headers: {
                Authorization: `Bearer ${googleToken}`,
              },
            }
          )
          setGoogleEmail(response.data.email)
        }
      } catch (error) {}
    }
    getGoogleEmail()
  }, [googleToken])

  //Clear Google token from localStorage
  const googleLogout = () => {
    setGoogleToken(localStorage.removeItem('accessToken'))
  }

  // After getting code from Google Auth send it to backend to receive accessToken
  useEffect(() => {
    const getEvents = async () => {
      try {
        if (code !== '' || code !== undefined || code !== null) {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/v1/google/bard/get-token`,
            {
              params: {
                code: code,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${googleToken}`,
              },
            }
          )

          if (response.status === 200) {
            setGoogleToken(
              localStorage.setItem('accessTokenBard', response.data.accessToken)
            )
            localStorage.setItem('refreshTokenBard', response.data.refreshToken)
          }
        }
      } catch (error) {}
    }
    if (code !== undefined || code !== null) {
      getEvents()
    }
  }, [code, googleToken])

  // After Google accessToken received begin to sync data

  //This function popup google auth page and open auth link in browser
  const authGoogle = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/google/bard/auth`
      )
      window.location.href = res.data
    } catch (error) {}
  }

  return (
    <div className='bg-black h-screen text-white'>
      <p onClick={authGoogle}>Sign in with Google 🚀 </p>

      <div className='py-10'>
        <p className='text-green-300'>Code:</p> {code}
      </div>
      <div>
        <p className='text-green-300'>GoogleToken:</p>{' '}
        {localStorage.getItem('accessTokenBard')}
      </div>
    </div>
  )
}

export default Bard
