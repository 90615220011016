/* eslint-disable no-unused-vars */
import { useCalendar } from 'hooks'
import React from 'react'
import {
  AddNewEvent,
  AddNewGroup,
  Connections,
  Contacts,
  Drawer,
  EventDescription,
  Groups,
  HomeScreen,
  MonthView,
  PerDayEvents,
  Search,
  UserProfile,
  WeekView,
  YearView,
} from 'components'
import { IoCloseSharp } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import WeekViewNew from './WeekViewNew'
import MonthViewNew from './MonthViewNew'
import MonthViewNew2 from './MonthViewNew2'
import MonthApple from './MonthApple'

const Calendar = ({ isLeftBarOpened }) => {
  const {
    sorted,
    generatedWeeks,
    fetchedEventStatus,
    handleHorizontalScroll,
    parentRef,
    currentDayRef,
    nightMode,
    defaultTextIconColor,
    openEventHandler,
    singleEvent,
    calendarViewLocal,
  } = useCalendar()

  const {
    singeEventDescIsOpen,
    singeEventDesc2IsOpen,
    perDayEventsIsOpen,
    homeScreenIsOpen,
    editingEventIsOpen,
    searchIsOpen,
    userProfileIsOpen,
    appearanceIsOpen,
  } = useSelector((state) => state.sideView)

  const { groupsIsOpen, isContactsOpen, connectionsIsOpen, addNewGroupIsOpen } =
    useSelector((state) => state.sideView)

  return (
    <>
      {calendarViewLocal === 'week' ? (
        /*  <WeekView
          nightMode={nightMode}
          defaultTextIconColor={defaultTextIconColor}
          singleEvent={singleEvent}
          openEventHandler={openEventHandler}
          sorted={sorted}
          parentRef={parentRef}
          currentDayRef={currentDayRef}
          handleHorizontalScroll={handleHorizontalScroll}
          generatedWeeks={generatedWeeks}
          fetchedEventStatus={fetchedEventStatus}
          isLeftBarOpened={isLeftBarOpened}
          swiperRef={swiperRef}
          handleAddYearAtEnd={handleAddYearAtEnd}
          handleAddYearAtBeginning={handleAddYearAtBeginning}
        /> */
        <WeekViewNew
          nightMode={nightMode}
          defaultTextIconColor={defaultTextIconColor}
          singleEvent={singleEvent}
          openEventHandler={openEventHandler}
          sorted={sorted}
          parentRef={parentRef}
          currentDayRef={currentDayRef}
          handleHorizontalScroll={handleHorizontalScroll}
          generatedWeeks={generatedWeeks}
          fetchedEventStatus={fetchedEventStatus}
          isLeftBarOpened={isLeftBarOpened}
        />
      ) : calendarViewLocal === 'month' ? (
        <MonthApple
          nightMode={nightMode}
          defaultTextIconColor={defaultTextIconColor}
          singleEvent={singleEvent}
          openEventHandler={openEventHandler}
          sorted={sorted}
          parentRef={parentRef}
          currentDayRef={currentDayRef}
          handleHorizontalScroll={handleHorizontalScroll}
          generatedWeeks={generatedWeeks}
          fetchedEventStatus={fetchedEventStatus}
          isLeftBarOpened={isLeftBarOpened}
          icon={<IoCloseSharp size={22} />}
        />
      ) : calendarViewLocal === 'year' ? (
        <YearView events={sorted} />
      ) : (
        <></>
      )}
      {/* Events in one day */}
      <Drawer
        // className='bg-opacity-80'
        isOpen={perDayEventsIsOpen}
        isLeftBarOpened={isLeftBarOpened}
        position='left'
      >
        <PerDayEvents
          isLeftBarOpened={isLeftBarOpened}
          defaultTextIconColor={defaultTextIconColor}
        />
      </Drawer>
      {/* Event Description */}
      <Drawer
        className='bg-opacity-10'
        isLeftBarOpened={isLeftBarOpened}
        isOpen={singeEventDescIsOpen}
        position='left'
      >
        {editingEventIsOpen ? (
          <AddNewEvent />
        ) : (
          <EventDescription
            singleEvent={singleEvent}
            defaultTextIconColor={defaultTextIconColor}
          />
        )}
      </Drawer>
      <Drawer
        className='bg-opacity-10'
        isLeftBarOpened={isLeftBarOpened}
        isOpen={singeEventDesc2IsOpen}
        position='left'
      >
        {editingEventIsOpen ? (
          <AddNewEvent />
        ) : (
          <EventDescription
            singleEvent={singleEvent}
            defaultTextIconColor={defaultTextIconColor}
          />
        )}
      </Drawer>

      {/* Groups */}
      <Drawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={groupsIsOpen}
        position='left'
      >
        {groupsIsOpen && !addNewGroupIsOpen ? (
          <Groups defaultTextIconColor={defaultTextIconColor} />
        ) : addNewGroupIsOpen && groupsIsOpen ? (
          <AddNewGroup />
        ) : (
          <></>
        )}
      </Drawer>
      {/* Contacts */}
      <Drawer
        isLeftBarOpened={isLeftBarOpened}
        // className='bg-opacity-80'
        isOpen={isContactsOpen}
        position='left'
      >
        <Contacts />
      </Drawer>
      <Drawer
        isLeftBarOpened={isLeftBarOpened}
        // className='bg-opacity-80'
        isOpen={connectionsIsOpen}
        position='left'
      >
        <Connections />
      </Drawer>
      <Drawer
        isLeftBarOpened={isLeftBarOpened}
        // className='bg-opacity-80'
        isOpen={searchIsOpen}
        position='left'
      >
        <Search />
      </Drawer>
      <Drawer isOpen={homeScreenIsOpen} position='left'>
        <HomeScreen />
      </Drawer>
      <Drawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={userProfileIsOpen}
        position='left'
      >
        <UserProfile />
      </Drawer>
    </>
  )
}

export default Calendar
