import moment from 'moment'
import jwtDecode from 'jwt-decode'
import axios from 'axios'

export function addDays(date, days) {
  var result = new Date(date)
  result.setDate(date.getDate() + days)
  return result
}

export const userId = () => {
  let token = localStorage.getItem('token')
  const decodedToken = jwtDecode(token)

  return decodedToken._id
}

export const eventStartPlacement = (week, event, moment) => {
  let start = 0
  for (let i = 0; i < week.length; i++) {
    if (week[i].fullDate === moment(event.startTime).format('YYYY-MM-DD')) {
      start = i + 1
    }
  }
  return start
}

export const eventEndPlacement = (week, event, moment) => {
  let end = 0
  const filteredWeek = week.filter((day) => day.fullDate !== null)
  for (let i = 0; i < filteredWeek.length; i++) {
    if (week[i].date === +moment(event.endTime).format('DD')) {
      end = i + 2
    }
  }
  return end
}

export const dateForward = (date, setStartDate) => {
  const dateCopy = new Date(date.getTime())
  dateCopy.setDate(dateCopy.getDate() + 1)
  setStartDate(dateCopy)
}

export const dateBackward = (date, setStartDate) => {
  const dateCopy = new Date(date.getTime())
  dateCopy.setDate(dateCopy.getDate() - 1)
  setStartDate(dateCopy)
}

export const showEventsByDate = (i, week, eventsDatesRange) =>
  eventsDatesRange.includes(week[i].fullDate)

export const betweenDates = (item) => {
  let timeDifference =
    new Date(item.endTime).getTime() - new Date(item.startTime).getTime()
  let dayMilliSeconds = 1000 * 60 * 60 * 24
  let totalDays = Math.abs(timeDifference / dayMilliSeconds) // it returns negative value if start date < end date
  totalDays = Math.floor(totalDays)
  return totalDays
}

export const getEventDatesInRange = (item) => {
  const startTime = new Date(moment(item.startTime).format('YYYY-MM-DD'))
  const endTime = new Date(moment(item.endTime).format('YYYY-MM-DD'))

  const date = new Date(startTime.getTime())

  const dates = []

  while (date <= endTime) {
    dates.push(moment(date).format('YYYY-MM-DD'))
    date.setDate(date.getDate() + 1)
  }

  return dates
}

export const eventHoursCounter = (date_1, date_2) => {
  let difference = new Date(date_1).getTime() - new Date(date_2).getTime()
  let evenTotalHours = difference / (1000 * 3600)
  return evenTotalHours
}

export const formatData = (data) => {
  return moment(data).format('YYYY-MM-DD')
}

export function hexToRgb(hex) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `${r}, ${g}, ${b}`
}

export function hexToRgba(hex, opacity) {
  const hexCode = hex ? hex?.replace('#', '') : ''
  const r = parseInt(hexCode.substring(0, 2), 16)
  const g = parseInt(hexCode.substring(2, 4), 16)
  const b = parseInt(hexCode.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export function getColorWithOpacity(color, opacity) {
  return `rgba(${hexToRgb(color)}, ${opacity || '1'})`
}

export const generateMonth = (year, startWeekDay) => {
  const weeksByMonth = []
  for (let month = 0; month < 12; month++) {
    const weeks = []
    let firstDate = new Date(year, month, 1)
    let lastDate = new Date(year, month + 1, 0)
    let numDays = lastDate.getDate()

    let start = 1
    let end
    if (firstDate.getDay() === startWeekDay) {
      end = 7
    } else if (firstDate.getDay() === 0) {
      let preMonthEndDay = new Date(year, month, 0)
      start = preMonthEndDay.getDate() - 6 + 1
      end = 1
    } else {
      let preMonthEndDay = new Date(year, month, 0)
      start = preMonthEndDay.getDate() + 1 - firstDate.getDay() + 1
      end = 7 - firstDate.getDay() + 1
      weeks.push({
        start: start,
        end: end,
      })
      start = end + 1
      end = end + 7
    }

    const dates = new Set()

    while (start <= numDays) {
      const date = new Date(year, month, start)
      const fullDate = moment(date).format('YYYY-MM-DD')

      if (!dates.has(fullDate)) {
        dates.add(fullDate)
        weeks.push({
          start: start,
          end: end,
        })
      }

      start = end + 1
      end = end + 7
      end = start === 1 && end === 8 ? 1 : end
      if (end > numDays && start <= numDays) {
        end = end - numDays
        const date = new Date(year, month, start)
        const fullDate = moment(date).format('YYYY-MM-DD')
        if (!dates.has(fullDate)) {
          dates.add(fullDate)
          weeks.push({
            start: start,
            end: end,
          })
        }
        break
      }
    }

    if (weeks.length === 6) {
      weeks.pop()
    }

    const weeksFormatted = weeks.map(({ start, end }, index) => {
      const sub = +(start > end && index === 0)
      return Array.from({ length: 7 }, (_, index) => {
        const date = new Date(year, month - sub, start + index)
        return {
          date: date.getDate(),
          month: date.toLocaleString('en', { month: 'short' }),
          day: date.toLocaleString('en', { weekday: 'short' }),
          fullDate: moment(date).format('YYYY-MM-DD'),
        }
      })
    })

    weeksByMonth.push(weeksFormatted)
  }

  return weeksByMonth
}

export function hasJWT() {
  let result = false
  let decodedToken
  let token = localStorage.getItem('token')
  if (token) {
    decodedToken = jwtDecode(token)
    let currentDate = new Date()
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      result = false
      return result
    } else {
      result = true
      return result
    }
  }
}

export function userIdFromToken() {
  let decodedToken
  let token = localStorage.getItem('token')
  if (token) {
    decodedToken = jwtDecode(token)
  }
  return decodedToken._id
}

export const logOut = () => {
  localStorage.removeItem('token')
  window.location.href = '/'
}

export const checkAccessToken = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${accessToken}`
    )
    return response.data
  } catch (error) {
    console.error(error)
    return null
  }
}
export function generateYearData(year) {
  const months = []

  for (let i = 0; i < 12; i++) {
    const month = {
      year: year,
      name: '',
      days: [],
    }

    const date = new Date(year, i, 1)
    month.name = date.toLocaleString('default', { month: 'long' })

    // find out the number of days in the month
    const numDays = new Date(year, i + 1, 0).getDate()

    // find out which day of the week the first day of the month is
    const firstDayOfWeek = date.getDay()

    // add blank entries for the days before the first day of the month
    const prevMonth = i === 0 ? 11 : i - 1
    const prevMonthYear = i === 0 ? year - 1 : year
    const prevMonthNumDays = new Date(prevMonthYear, prevMonth + 1, 0).getDate()
    const numDaysBefore = firstDayOfWeek
    for (
      let j = prevMonthNumDays - numDaysBefore + 1;
      j <= prevMonthNumDays;
      j++
    ) {
      const day = {
        date: `${prevMonthYear}-${prevMonth + 1}-${j}`,
        isCurrentMonth: false,
        isToday: isToday(new Date(prevMonthYear, prevMonth, j), false),
      }
      month.days.push(day)
    }

    // add the days of the current month
    for (let j = 1; j <= numDays; j++) {
      const day = {
        date: `${year}-${i + 1}-${j}`,
        isCurrentMonth: true,
        isToday: isToday(new Date(year, i, j), true),
      }
      month.days.push(day)
    }

    // add blank entries for the days after the last day of the month
    const nextMonth = i === 11 ? 0 : i + 1
    const nextMonthYear = i === 11 ? year + 1 : year
    const numDaysAfter = 42 - month.days.length
    for (let j = 1; j <= numDaysAfter; j++) {
      const day = {
        date: `${nextMonthYear}-${nextMonth + 1}-${j}`,
        isCurrentMonth: false,
        isToday: isToday(new Date(nextMonthYear, nextMonth, j), false),
      }
      month.days.push(day)
    }

    months.push(month)
  }

  return months
}
export function generateYearCalendarData(year) {
  const yearArray = []
  const yearData = {
    months: [],
    year,
  }

  for (let i = 0; i < 12; i++) {
    const month = {
      year: year,
      name: '',
      days: [],
    }

    const date = new Date(year, i, 1)
    month.name = date.toLocaleString('default', { month: 'long' })

    // find out the number of days in the month
    const numDays = new Date(year, i + 1, 0).getDate()

    // find out which day of the week the first day of the month is
    const firstDayOfWeek = date.getDay()

    // add blank entries for the days before the first day of the month
    const prevMonth = i === 0 ? 11 : i - 1
    const prevMonthYear = i === 0 ? year - 1 : year
    const prevMonthNumDays = new Date(prevMonthYear, prevMonth + 1, 0).getDate()
    const numDaysBefore = firstDayOfWeek
    for (
      let j = prevMonthNumDays - numDaysBefore + 1;
      j <= prevMonthNumDays;
      j++
    ) {
      const day = {
        date: `${prevMonthYear}-${(prevMonth + 1)
          .toString()
          .padStart(2, '0')}-${j.toString().padStart(2, '0')}`,
        isCurrentMonth: false,
        isToday: isToday(new Date(prevMonthYear, prevMonth, j), false),
      }
      month.days.push(day)
    }

    // add the days of the current month
    for (let j = 1; j <= numDays; j++) {
      const day = {
        date: `${year}-${(i + 1).toString().padStart(2, '0')}-${j
          .toString()
          .padStart(2, '0')}`,
        isCurrentMonth: true,
        isToday: isToday(new Date(year, i, j), true),
      }
      month.days.push(day)
    }

    // add blank entries for the days after the last day of the month
    const nextMonth = i === 11 ? 0 : i + 1
    const nextMonthYear = i === 11 ? year + 1 : year
    const numDaysAfter = 42 - month.days.length
    for (let j = 1; j <= numDaysAfter; j++) {
      const day = {
        date: `${nextMonthYear}-${(nextMonth + 1)
          .toString()
          .padStart(2, '0')}-${j.toString().padStart(2, '0')}`,
        isCurrentMonth: false,
        isToday: isToday(new Date(nextMonthYear, nextMonth, j), false),
      }

      month.days.push(day)
    }

    yearData.months.push(month)
  }
  yearArray.push(yearData)

  return yearArray
}

export function generateOneMonthData(year, monthIndex) {
  const month = {
    year: year,
    name: '',
    days: [],
  }

  const date = new Date(year, monthIndex, 1)
  month.name = date.toLocaleString('default', { month: 'long' })

  // find out the number of days in the month
  const numDays = new Date(year, monthIndex + 1, 0).getDate()

  // add blank entries for the days before the first day of the month
  const firstDayOfWeek = date.getDay()
  const prevMonth = monthIndex === 0 ? 11 : monthIndex - 1
  const prevMonthYear = monthIndex === 0 ? year - 1 : year
  const prevMonthNumDays = new Date(prevMonthYear, prevMonth + 1, 0).getDate()
  const numDaysBefore = firstDayOfWeek
  for (
    let j = prevMonthNumDays - numDaysBefore + 1;
    j <= prevMonthNumDays;
    j++
  ) {
    const day = {
      date: `${prevMonthYear}-${prevMonth + 1}-${j}`,
      isCurrentMonth: false,
      isToday: isToday(new Date(prevMonthYear, prevMonth, j), false),
    }
    month.days.push(day)
  }

  // add the days of the current month
  for (let j = 1; j <= numDays; j++) {
    const day = {
      date: `${year}-${monthIndex + 1}-${j}`,
      isCurrentMonth: true,
      isToday: isToday(new Date(year, monthIndex, j), true),
    }
    month.days.push(day)
  }

  // add blank entries for the days after the last day of the month
  const nextMonth = monthIndex === 11 ? 0 : monthIndex + 1
  const nextMonthYear = monthIndex === 11 ? year + 1 : year
  const numDaysAfter = 42 - month.days.length
  for (let j = 1; j <= numDaysAfter; j++) {
    const day = {
      date: `${nextMonthYear}-${nextMonth + 1}-${j}`,
      isCurrentMonth: false,
      isToday: isToday(new Date(nextMonthYear, nextMonth, j), false),
    }
    month.days.push(day)
  }

  return month
}
export function generateMonthData(year, monthIndex) {
  const month = {
    year: year,
    name: '',
    weeks: [],
  }

  const date = new Date(year, monthIndex, 1)
  month.name = date.toLocaleString('default', { month: 'long' })

  // find out the number of days in the month
  const numDays = new Date(year, monthIndex + 1, 0).getDate()

  // find out which day of the week the first day of the month is
  const firstDayOfWeek = date.getDay()

  let currentWeek = []

  // add blank entries for the days before the first day of the month
  const prevMonthIndex = monthIndex === 0 ? 11 : monthIndex - 1
  const prevMonthYear = monthIndex === 0 ? year - 1 : year
  const prevMonthNumDays = new Date(
    prevMonthYear,
    prevMonthIndex + 1,
    0
  ).getDate()
  const numDaysBefore = firstDayOfWeek

  for (
    let j = prevMonthNumDays - numDaysBefore + 1;
    j <= prevMonthNumDays;
    j++
  ) {
    const day = {
      date: j,
      month: getMonthAbbreviation(prevMonthIndex),
      day: getDayOfWeek(j, prevMonthIndex, prevMonthYear),
      fullDate: getFullDate(prevMonthYear, prevMonthIndex + 1, j),
      isCurrentMonth: false,
      isToday: false,
    }
    currentWeek.push(day)
  }

  // add the days of the current month
  for (let j = 1; j <= numDays; j++) {
    const day = {
      date: j,
      month: getMonthAbbreviation(monthIndex),
      day: getDayOfWeek(j, monthIndex, year),
      fullDate: getFullDate(year, monthIndex + 1, j),
      isCurrentMonth: true,
      isToday: isToday(new Date(year, monthIndex, j), true),
    }
    currentWeek.push(day)

    // check if the week is complete
    if (currentWeek.length === 7) {
      month.weeks.push(currentWeek)
      currentWeek = []
    }
  }

  // add blank entries for the remaining days if needed
  const numDaysAfter = 42 - month.weeks.flat().length

  for (let j = 1; j <= numDaysAfter; j++) {
    const nextMonthIndex = monthIndex === 11 ? 0 : monthIndex + 1
    const nextMonthYear = monthIndex === 11 ? year + 1 : year
    const day = {
      date: j,
      month: getMonthAbbreviation(nextMonthIndex),
      day: getDayOfWeek(j, nextMonthIndex, nextMonthYear),
      fullDate: getFullDate(nextMonthYear, nextMonthIndex + 1, j),
      isCurrentMonth: false,
      isToday: false,
    }
    currentWeek.push(day)

    // check if the week is complete
    if (currentWeek?.length === 7) {
      // Check if any day in the week has isCurrentMonth: true
      const hasCurrentMonthDay = currentWeek?.some((day) => day?.isCurrentMonth)

      // Only add the week if it contains days from the current month
      if (hasCurrentMonthDay) {
        month.weeks.push(currentWeek)
      }

      currentWeek = []
    }
  }

  return [month]
}

export function generateWeekData(year, monthIndex) {
  const weeks = []

  const date = new Date(year, monthIndex, 1)

  // find out the number of days in the month
  const numDays = new Date(year, monthIndex + 1, 0).getDate()

  // find out which day of the week the first day of the month is
  const firstDayOfWeek = date.getDay()

  let currentWeek = []

  // add blank entries for the days before the first day of the month
  const prevMonthIndex = monthIndex === 0 ? 11 : monthIndex - 1
  const prevMonthYear = monthIndex === 0 ? year - 1 : year
  const prevMonthNumDays = new Date(
    prevMonthYear,
    prevMonthIndex + 1,
    0
  ).getDate()
  const numDaysBefore = firstDayOfWeek

  for (
    let j = prevMonthNumDays - numDaysBefore + 1;
    j <= prevMonthNumDays;
    j++
  ) {
    const day = {
      date: j,
      month: getMonthAbbreviation(prevMonthIndex),
      day: getDayOfWeek(j, prevMonthIndex, prevMonthYear),
      fullDate: getFullDate(prevMonthYear, prevMonthIndex + 1, j),
      isCurrentMonth: false,
      isToday: false,
    }
    currentWeek.push(day)
  }

  // add the days of the current month
  for (let j = 1; j <= numDays; j++) {
    const day = {
      date: j,
      month: getMonthAbbreviation(monthIndex),
      day: getDayOfWeek(j, monthIndex, year),
      fullDate: getFullDate(year, monthIndex + 1, j),
      isCurrentMonth: true,
      isToday: isToday(new Date(year, monthIndex, j), true),
    }
    currentWeek.push(day)

    // check if the week is complete
    if (currentWeek.length === 7) {
      weeks.push(currentWeek)
      currentWeek = []
    }
  }

  // add blank entries for the remaining days if needed
  const numDaysAfter = 42 - weeks.flat().length

  for (let j = 1; j <= numDaysAfter; j++) {
    const nextMonthIndex = monthIndex === 11 ? 0 : monthIndex + 1
    const nextMonthYear = monthIndex === 11 ? year + 1 : year
    const day = {
      date: j,
      month: getMonthAbbreviation(nextMonthIndex),
      day: getDayOfWeek(j, nextMonthIndex, nextMonthYear),
      fullDate: getFullDate(nextMonthYear, nextMonthIndex + 1, j),
      isCurrentMonth: false,
      isToday: false,
    }
    currentWeek.push(day)

    // check if the week is complete
    if (currentWeek?.length === 7) {
      // Check if any day in the week has isCurrentMonth: true
      const hasCurrentMonthDay = currentWeek?.some((day) => day?.isCurrentMonth)

      // Only add the week if it contains days from the current month
      if (hasCurrentMonthDay) {
        weeks.push(currentWeek)
      }

      currentWeek = []
    }
  }

  return weeks
}

// helper function to check if a date is today
function isToday(date, isCurrentMonth) {
  const today = new Date()
  return (
    isCurrentMonth &&
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

// helper function to get the abbreviation of the month
function getMonthAbbreviation(monthIndex) {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return monthNames[monthIndex]
}

// helper function to get the day of the week abbreviation
function getDayOfWeek(day, monthIndex, year) {
  const date = new Date(year, monthIndex, day)
  const dayOfWeekIndex = date.getDay()
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return daysOfWeek[dayOfWeekIndex]
}

// helper function to get the full date string in the format: 'YYYY-MM-DD'
function getFullDate(year, month, day) {
  return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(
    2,
    '0'
  )}`
}

export const renderUserAvatarOrEmail = async (user) => {
  if (user?.avatar) {
    try {
      const avatarUrl = `${process.env.REACT_APP_BASE_URL}/${user.avatar}`
      const response = await axios.head(avatarUrl)

      if (response.status === 200) {
        return (
          <img
            alt='user'
            src={avatarUrl}
            className='rounded-full w-[100px] h-[100px] aspect-square object-cover  cursor-pointer'
          />
        )
      }
    } catch (error) {}
  }

  return (
    <div className='w-[100px] flex items-center justify-center h-[100px] rounded-full bg-slate-400'>
      <p className='text-lg'>{user?.email.slice(0, 1)}</p>
    </div>
  )
}
