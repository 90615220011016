/* eslint-disable no-unused-vars */
import { AuthLayout } from 'components'
import { FormLayout, UnumSection } from '..'
import PinInput from 'react-pin-input'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { verifyEmailCodeAPI } from 'features/auth/authSlice'
import { useState } from 'react'

const EmailVerify = () => {
  const dispatch = useDispatch()
  const { recoveryEmail } = useSelector((state) => state.auth)

  const [pin, setPin] = useState('')

  const onSubmit = () => {
    const data = {
      email: recoveryEmail,
      code: pin,
    }

    dispatch(verifyEmailCodeAPI(data))
  }
  return (
    <AuthLayout>
      <div className='flex flex-col md:flex-row justify-between items-center'>
        <UnumSection />
        <FormLayout>
          <p className='text-white w-[170px] pb-10 text-center text-lg'>
            We sent you a verification code
          </p>

          <div className='flex w-[279px] justify-between  items-center'>
            <div className='text-white text-sm flex gap-2 pt-1 '>
              To:
              <p className='truncate w-48'>{recoveryEmail}</p>{' '}
            </div>
            <Link
              className='text-sm underline text-BlueDarkColorMain cursor-pointer'
              to='/add-email'
            >
              Change
            </Link>
          </div>

          <PinInput
            length={5}
            initialValue=''
            secret
            secretDelay={100}
            onChange={(value, index) => {
              setPin(value)
            }}
            type='numeric'
            inputMode='number'
            style={{ padding: '10px' }}
            //inputStyle={{ borderColor: 'red' }}
            inputFocusStyle={{ borderColor: 'blue' }}
            onComplete={(value, index) => {}}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />

          <button
            onClick={onSubmit}
            type='button'
            className='flex w-[330px] h-[53px] justify-center items-center rounded-xl bg-BlueDarkColorMain px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-BlueDarkColorMainHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            Proceed
          </button>

          <div className='w-[240px] flex justify-between items-center text-center text-white text-sm'>
            <p>Did not received a code ? </p>
            <p className='text-NegativeRed underline'>Resend</p>
          </div>
        </FormLayout>
      </div>
    </AuthLayout>
  )
}

export default EmailVerify
