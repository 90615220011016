/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const ArrowLeftIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      viewBox='0 0 11 10'
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M6.68369 9.53435C7.41564 9.94598 8.19334 10.0448 9.01679 9.83078C9.84023 9.61679 10.252 9.20925 10.252 8.60817V1.45133C10.252 0.868959 9.84023 0.470777 9.01679 0.256782C8.19334 0.0427695 7.41564 0.141581 6.68369 0.553216L0.873831 4.14567C0.721341 4.26093 0.576476 4.40039 0.439235 4.56405C0.301994 4.72773 0.233374 4.88839 0.233374 5.04603C0.233374 5.20367 0.301994 5.36432 0.439235 5.52801C0.576476 5.69167 0.721341 5.82028 0.873831 5.91383L6.68369 9.53435Z' />
    </svg>
  )
}

export default ArrowLeftIcon
