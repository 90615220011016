/* eslint-disable no-unused-vars */
import { IoCheckmarkCircle } from 'react-icons/io5'

import { useAddEvent } from 'hooks'
import DatePicker from 'react-datepicker'
import { GroupList, IconList, RightSideDrawer, Toggle } from 'components'
import Autocomplete from 'react-google-autocomplete'
import {
  createEvent,
  setEditingEventIsOpen,
  setEventDescription,
  setEventEndDate,
  setEventEndTime,
  setEventStartDate,
  setEventStartTime,
  setRightSideViewIsOpen,
} from 'features'
import {
  EventDescAndLocation,
  GroupAndEventType,
  Location,
  Participants,
  Reminders,
  Repeat,
  TitleAndIcon,
} from './components'
import { useSelector } from 'react-redux'
import { ReactSVG } from 'react-svg'
import { Switch } from '@headlessui/react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  EventRepeatIcon,
  GroupsIcon,
  NotificationsIcon,
  NotificationsSecondIcon,
} from 'components/Icons'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const arr = ['address']

const AddNewEvent = () => {
  const {
    StartDateExampleCustomInput,
    EndDateExampleCustomInput,
    StartTimeExampleCustomInput,
    EndTimeExampleCustomInput,
    showGroupList,
    setShowGroupList,
    groups,
    selectedGroup,
    selectedGroupId,
    dispatch,
    eventDescription,
    showIconList,
    setShowIconList,
    fetchedIcons,
    onPlaceChanged,
    imageSrc,
    onSubmit,
    enabled,
    setEnabled,
    maxTime,
    register,
    errors,
    watch,
    handleSubmit,
    eventTitle,
    eventStartTime,
    eventStartDate,
    eventEndTime,
    eventEndDate,
    reminderIsOpen,
    eventReminder,
    eventRepeat,
    defaultTextIconColor,
    nightMode,
    participants,
    participantsIsOpen,
    eventRepeatIsOpen,
    openRepeatHandler,
    openReminderHandler,
    openParticipantsHandler,
    fetchedSingleGroup,
    eventLocationIsOpen,
  } = useAddEvent(createEvent)

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const { editingEventIsOpen } = useSelector((state) => state.sideView)
  const { fetchedUserInfo } = useSelector((state) => state.user)

  const image = fetchedIcons[24]?.svg

  return (
    <div
      className={` ${
        nightMode ? 'bg-DMDarkBlueBackground' : ''
      } p-3 h-screen ${defaultTextIconColor}`}
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <>
          {/* Header */}
          <div className='  pb-6 font-bold text-sm flex justify-between items-center relative'>
            <ChevronLeftIcon
              size={30}
              onClick={() => {
                dispatch(setRightSideViewIsOpen(false))
                dispatch(setEditingEventIsOpen(false))
              }}
              className={'cursor-pointer'}
            />

            <p className={`text-sm  `}>
              {' '}
              {editingEventIsOpen ? 'Edit event' : 'New Event'}
            </p>

            <div>
              {watch('title') ? (
                <button type='submit '>
                  <span className='block sm:hidden'>
                    <IoCheckmarkCircle
                      color='#40EF22'
                      size={25}
                      cursor='pointer'
                    />
                  </span>
                  <p className='text-sm text-[#40EF22] pt-2 pr-2 sm:block hidden cursor-pointer'>
                    Done
                  </p>
                </button>
              ) : (
                <div>
                  <span className='block sm:hidden'>
                    <IoCheckmarkCircle color='#F3F4F6' size={25} />
                  </span>
                  <p className='text-sm font-normal pt-2 pr-2 text-gray-400 sm:block hidden '>
                    Done
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* Content */}
          <div className='w-full  rounded-xl'>
            <TitleAndIcon
              nightMode={nightMode}
              register={register}
              errors={errors}
              setShowIconList={setShowIconList}
              imageSrc={imageSrc}
              eventTitle={eventTitle}
            />

            <GroupAndEventType
              nightMode={nightMode}
              selectedGroupId={selectedGroupId}
              selectedGroup={selectedGroup}
              groups={groups}
              setShowGroupList={setShowGroupList}
            />

            <EventDescAndLocation
              eventLocationIsOpen={eventLocationIsOpen}
              nightMode={nightMode}
              eventDescription={eventDescription}
              dispatch={dispatch}
              setEventDescription={setEventDescription}
            />
            {/* Event Starts/Ends */}
            <div
              className={`rounded-xl pt-3 mb-3 pl-3 text-sm overflow-x-hidden ${
                nightMode ? 'bg-DMDarkBlueForeground' : 'bg-GrayMediumBG'
              }`}
            >
              {/* all day toggle */}
              <div className={` flex justify-between pr-3 pb-2`}>
                <div className='flex items-center'>
                  <ClockIcon className={'mx-2'} />
                  <p
                    className={
                      nightMode
                        ? 'text-DMMainLightTextIcon '
                        : 'text-LMMainDarkTextIcon'
                    }
                  >
                    All-Day
                  </p>
                </div>
                {/*        <Toggle enabled={enabled} setEnabled={setEnabled} /> */}
                <Switch
                  checked={enabled}
                  onChange={() => {
                    setEnabled((prev) => !prev)
                  }}
                  className='relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
                  style={{
                    backgroundColor: enabled ? '#75CDE9' : '#778EAE',
                  }}
                >
                  <span className='sr-only'>Use setting</span>
                  <span
                    className={classNames(
                      enabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </div>
              {/* date picker */}
              <div className='px-3 flex flex-col gap-2 pb-3 '>
                <div className='py-3 text-sm font-normal  text-gray-500 flex justify-between w-full'>
                  <p
                    className={`${enabled ? 'cursor-not-allowed' : ''} ${
                      nightMode
                        ? 'text-DMMainLightTextIcon '
                        : 'text-LMMainDarkTextIcon'
                    }`}
                  >
                    Start
                  </p>
                  <div className='ml-[3rem] sm:ml-[7rem]'>
                    <DatePicker
                      selected={eventStartDate ? eventStartDate : new Date()}
                      onChange={(date) => dispatch(setEventStartDate(date))}
                      disabled={enabled}
                      customInput={<StartDateExampleCustomInput />}
                    />
                  </div>
                  <div>
                    <DatePicker
                      selected={eventStartTime ? eventStartTime : new Date()}
                      onChange={(date) => dispatch(setEventStartTime(date))}
                      showTimeSelect
                      disabled={enabled}
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption='Time'
                      dateFormat='hh:mm aa'
                      customInput={<StartTimeExampleCustomInput />}
                    />
                  </div>
                </div>

                <div className='py-3 text-sm font-normal  text-gray-500  flex justify-between'>
                  <p
                    className={`${enabled ? ' cursor-not-allowed' : ''} ${
                      nightMode
                        ? 'text-DMMainLightTextIcon '
                        : 'text-LMMainDarkTextIcon'
                    }`}
                  >
                    Ends
                  </p>
                  <div className='ml-[3rem] sm:ml-[7rem]'>
                    <DatePicker
                      disabled={enabled}
                      selected={eventEndDate}
                      minDate={eventStartDate}
                      onChange={(date) => dispatch(setEventEndDate(date))}
                      customInput={<EndDateExampleCustomInput />}
                    />
                  </div>
                  <div>
                    <DatePicker
                      disabled={enabled}
                      selected={eventEndTime}
                      minTime={eventStartTime}
                      maxTime={maxTime}
                      onChange={(date) => dispatch(setEventEndTime(date))}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption='Time'
                      dateFormat='hh:mm aa'
                      customInput={<EndTimeExampleCustomInput />}
                    />
                  </div>
                </div>
              </div>

              {/* repeat */}
              <div
                onClick={openRepeatHandler}
                className={` flex pb-3 justify-between cursor-pointer `}
              >
                <div className='flex items-center'>
                  <EventRepeatIcon className={'mx-2'} />
                  <p
                    className={
                      nightMode
                        ? 'text-DMMainLightTextIcon '
                        : 'text-LMMainDarkTextIcon'
                    }
                  >
                    Repeat
                  </p>
                </div>
                <div className='flex items-center '>
                  <p
                    className={` pr-2 ${
                      nightMode
                        ? 'text-DM2ndLighterTextIcon '
                        : 'text-LM2ndLighterTextIcon'
                    }`}
                  >
                    {eventRepeat.text}
                  </p>

                  <ChevronRightIcon
                    secondaryColor={true}
                    size={28}
                    className={' mx-2'}
                  />
                </div>
              </div>
            </div>
            {/* Participants */}
            <div
              onClick={openParticipantsHandler}
              className={`rounded-xl py-3 mb-3 pl-3 text-sm overflow-x-hidden cursor-pointer ${
                nightMode ? 'bg-DMDarkBlueForeground' : 'bg-GrayMediumBG'
              }`}
            >
              <div className={` flex justify-between `}>
                <div className='flex items-center'>
                  <GroupsIcon className={'mx-2'} />
                  <p
                    className={
                      nightMode
                        ? 'text-DMMainLightTextIcon '
                        : 'text-LMMainDarkTextIcon'
                    }
                  >
                    Participants
                  </p>
                </div>
                <div className='flex items-center '>
                  <p
                    className={` pr-2 ${
                      nightMode
                        ? 'text-DM2ndLighterTextIcon '
                        : 'text-LM2ndLighterTextIcon'
                    }`}
                  >
                    {fetchedUserInfo?.friends?.length}
                  </p>

                  <ChevronRightIcon
                    secondaryColor={true}
                    size={28}
                    className={' mx-2'}
                  />
                </div>
              </div>
            </div>
            {/* Reminder */}
            <div
              onClick={openReminderHandler}
              className={`rounded-xl py-3 mb-3 pl-3 text-sm overflow-x-hidden cursor-pointer ${
                nightMode ? 'bg-DMDarkBlueForeground' : 'bg-GrayMediumBG'
              }`}
            >
              <div className={` flex justify-between `}>
                <div className='flex items-center'>
                  <NotificationsSecondIcon className={'mx-2'} />
                  <p
                    className={
                      nightMode
                        ? 'text-DMMainLightTextIcon '
                        : 'text-LMMainDarkTextIcon'
                    }
                  >
                    Reminder
                  </p>
                </div>
                <div className='flex items-center '>
                  <p
                    className={` pr-2 ${
                      nightMode
                        ? 'text-DM2ndLighterTextIcon '
                        : 'text-LM2ndLighterTextIcon'
                    }`}
                  >
                    {eventReminder}
                  </p>

                  <ChevronRightIcon
                    secondaryColor={true}
                    size={28}
                    className={' mx-2'}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </form>

      <RightSideDrawer isOpen={showGroupList}>
        <GroupList groups={groups} setShowGroupList={setShowGroupList} />
      </RightSideDrawer>
      <RightSideDrawer isOpen={showIconList}>
        <IconList setShowIconList={setShowIconList} icons={fetchedIcons} />{' '}
      </RightSideDrawer>
      <RightSideDrawer isOpen={reminderIsOpen}>
        <Reminders />
      </RightSideDrawer>
      <RightSideDrawer isOpen={eventRepeatIsOpen}>
        <Repeat />
      </RightSideDrawer>
      <RightSideDrawer isOpen={participantsIsOpen}>
        <Participants participants={fetchedUserInfo?.friends} />
      </RightSideDrawer>
      <RightSideDrawer isOpen={eventLocationIsOpen}>
        <Location />
      </RightSideDrawer>
    </div>
  )
}

export default AddNewEvent
