/* eslint-disable no-unused-vars */

import { ChevronRightIcon, LocationIcon } from 'components/Icons'
import { setEventLocationIsOpen } from 'features'
import React from 'react'
import Autocomplete from 'react-google-autocomplete'

const EventDesAndLocation = ({
  nightMode,
  eventDescription,
  dispatch,
  setEventDescription,
  eventLocationIsOpen,
  onPlaceChanged,
}) => {
  const arr = ['address']
  return (
    <div
      className={`rounded-xl py-3 mb-3 pl-3 text-sm overflow-x-hidden ${
        nightMode ? 'bg-DMDarkBlueForeground' : 'bg-GrayMediumBG'
      }`}
    >
      <div className='flex justify-between  '>
        <div className='sm:w-10 w-8'></div>
        <div className='w-full'>
          <label
            className={`text-sm font-normal  mb-5 ${
              nightMode
                ? ' text-DM2ndLighterTextIcon '
                : ' text-LM2ndLighterTextIcon'
            }`}
            htmlFor='event-description'
          >
            Event Description / URL
          </label>
          <input
            value={eventDescription}
            onChange={(evt) => dispatch(setEventDescription(evt.target.value))}
            id='event-description'
            type='text'
            className={`form-control pt-6 block w-full text-base font-normal text-DM2ndLighterTextIcon  bg-clip-padding   rounded transition ease-in-out m-0 focus:text-DM2ndLighterTextIcon focus:border-blue-600 focus:outline-none ${
              nightMode
                ? 'bg-DMDarkBlueForeground placeholder-DM2ndLighterTextIcon '
                : 'bg-GrayMediumBG placeholder-LM2ndLighterTextIcon'
            } `}
          />
        </div>
      </div>
      {/* divider */}
      <div
        className={`h-[1px]  w-full sm:ml-[2.4rem] ml-[1.9rem] bg-DivideLine bg-opacity-20    `}
      />
      {/* location */}
      <div
        onClick={() => dispatch(setEventLocationIsOpen(true))}
        className={`pt-2 flex justify-between cursor-pointer `}
      >
        <div className='flex items-center'>
          <LocationIcon className={'mx-2'} />
          <p
            className={
              nightMode
                ? 'text-DMMainLightTextIcon '
                : 'text-LMMainDarkTextIcon'
            }
          >
            Location
          </p>
        </div>
        <div className='flex items-center '>
          <ChevronRightIcon
            secondaryColor={true}
            size={28}
            className={' mx-2'}
          />
        </div>
      </div>
      {/* <div>
        <label
          className='text-sm font-normal text-gray-400 mb-5'
          htmlFor='location'
        >
          Location
        </label>
        <Autocomplete
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          id='location'
          placeholder=''
          options={{
            types: arr,
          }}
          className='form-control mb-1 block w-full text-base font-normal text-gray-700 bg-blue-100 bg-clip-padding   rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-blue-100 focus:border-blue-600 focus:outline-none'
          onPlaceSelected={(place) => onPlaceChanged(place)}
        />
      </div> */}
    </div>
  )
}

export default EventDesAndLocation
