import axios from 'axios'

const registerHandler = async ({ email, password }) => {
  const results = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/register`,
    { email, password }
  )
  return results.data
}

const loginHandler = async ({ email, password }) => {
  const results = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/login`,
    { email, password }
  )
  return results.data
}
const sendCodeToEmailAPI = async (email) => {
  const results = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/email-code`,
    { email }
  )
  return results.data
}
const verifyEmailCodeAPI = async (data) => {
  console.log('🚀 ~ verifyEmailCodeAPI ~ data:', data)

  const results = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/verify-email`,
    data
  )
  return results.data
}

const authService = {
  registerHandler,
  loginHandler,
  sendCodeToEmailAPI,
  verifyEmailCodeAPI,
}

export default authService
