/* eslint-disable no-unused-vars */
import React, {
  useState,
  forwardRef,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { currentDate, formatData } from 'utils'
import {
  setRightSideViewIsOpen,
  setEventHeight,
  setSwitchView,
  setEditingEventIsOpen,
  resetEventInfo,
  setGoToCurrentWeek,
} from 'features'
import useDarkSide from './useDarkMode'
import useLeftBar from 'components/LeftBar/useLeftBar'

const useHeader = () => {
  const [startDate, setStartDate] = useState(new Date())

  const [weekStatus, setWeekStatus] = useState('')
  const { weekView, yearView } = useSelector((state) => state.event)
  const { isLeftBarOpened } = useSelector((state) => state.sideView)
  let currentMonth = useSelector((state) => state.event.monthView)
  const { rightSideViewIsOpen } = useSelector((state) => state.sideView)
  const { nightMode } = useSelector((state) => state.theme)
  const [colorTheme, toggleTheme, defaultTextIconColor] = useDarkSide()
  const dispatch = useDispatch()
  const { openGroupsHandler } = useLeftBar()

  const switchView = useCallback(
    (e) => {
      dispatch(setSwitchView(e.target.value))
    },
    [dispatch]
  )

  const newEventHandler = () => {
    /* Reset event inputs */
    dispatch(resetEventInfo())
    /* Close drawers */
    dispatch(setRightSideViewIsOpen(true))
    dispatch(setEditingEventIsOpen(false))
  }

  const arrowLeft = () => {
    let el = document.querySelector('.outer')
    let distance = 200
    el?.scrollBy({
      left: -distance,
      behavior: 'smooth',
    })
  }

  const arrowRight = () => {
    let el = document.querySelector('.outer')
    let distance = 200
    el?.scrollBy({
      left: distance,
      behavior: 'smooth',
    })
  }

  /*   const filteredMonthWeeks = currentMonth?.itemWeeks
    ?.map((item) => item.filter((item2) => item2.fullDate !== null))
    .filter((arr) => arr.length !== 0) */

  // show current year for 3 second on month view
  const calendarView = localStorage.getItem('calendarView')
  const calendarViewLocal = JSON.parse(calendarView)
  const [displayedYear, setDisplayedYear] = useState('')

  useEffect(() => {
    setDisplayedYear(new Date().getFullYear())
    setTimeout(() => {
      setDisplayedYear(null)
    }, 3000)
  }, [calendarViewLocal])

  const ExampleCustomInput = forwardRef(({ _, onClick }, ref) => (
    <div
      className='cursor-pointer flex justify-center w-[9rem] '
      onClick={onClick}
      ref={ref}
    >
      {monthViewLocal === 'day' ? (
        <p
          className={`px-5 w-40 ${
            colorTheme === 'dark'
              ? 'text-DMMainLightTextIcon'
              : 'text-LMMainDarkTextIcon2'
          } md:w-[9rem]`}
        >
          {moment(currentDate).format('MMM D')},{' '}
          {moment(currentDate).format('YYYY')}
        </p>
      ) : monthViewLocal === 'week' ? (
        <p
          className={`px-2 w-40 ${
            colorTheme === 'dark'
              ? 'text-DMMainLightTextIcon'
              : 'text-LMMainDarkTextIcon2'
          } md:w-[9rem]`}
        >
          {moment(weekView[0]).format('MMM D')}-
          {moment(weekView[1]).format('D')},{' '}
          {moment(weekView[1]).format('YYYY')}
        </p>
      ) : monthViewLocal === 'month' ? (
        <p
          className={`mr-3.5 ${
            colorTheme === 'dark'
              ? 'text-DMMainLightTextIcon'
              : 'text-LMMainDarkTextIcon2'
          }`}
        >
          {currentMonth && (
            <>
              {moment(currentMonth.itemWeeks[3][0]?.fullDate).format('MMMM') +
                ', '}

              {displayedYear
                ? displayedYear
                : moment(currentMonth.itemWeeks[3][6]?.fullDate).format('YYYY')}
            </>
          )}
        </p>
      ) : (
        <p
          className={`mr-3.5 ${
            colorTheme === 'dark'
              ? 'text-DMMainLightTextIcon'
              : 'text-LMMainDarkTextIcon2'
          }`}
        >
          {yearView[yearView.length - 1]}
        </p>
      )}
    </div>
  ))

  const monthTitle = currentMonth ? (
    <>
      <span>
        {moment(currentMonth.itemWeeks[3][0]?.fullDate).format('MMMM')}
      </span>
      <span className=' text-DM2ndLighterTextIcon'>
        {' '}
        / {moment(currentMonth.itemWeeks[3][6]?.fullDate).format('YYYY')}
      </span>
    </>
  ) : (
    ''
  )

  const goToCurrentDate = () => {
    /*  const element = document?.getElementById(
      moment(new Date()).format('YYYY-MM-DD')
    )
    element?.scrollIntoView({ behavior: 'smooth', inline: 'center' }) */
    dispatch(setGoToCurrentWeek(true))
  }

  const goToCurrentMonth = () => {
    let element = document?.getElementById(moment(currentDate).format('MMMM/Y'))
    element?.scrollIntoView({ behavior: 'smooth', inline: 'center' })
  }

  // useEffect(() => {
  //   dispatch(setDate(startDate))

  //   let arr = []
  //   for (let i = 0; i < 7; i++) {
  //     let date = new Date(startDate)
  //     date.setDate(date.getDate() + i)
  //     arr.push({
  //       day: date.toLocaleString('en-us', { weekday: 'short' }),
  //       data:
  //         date.toLocaleString('en-us', { month: 'short' }) +
  //         ' ' +
  //         date.getDate(),
  //     })
  //   }
  // }, [dispatch, startDate])

  /* Logic for week status: less/current/more */

  useLayoutEffect(() => {
    const firstDayOfWeek = new Date(weekView[0])
    const lastDayOfWeek = new Date(weekView[1])
    const currentDate = new Date()
    if (
      firstDayOfWeek < currentDate &&
      lastDayOfWeek < currentDate &&
      weekView[1] !== formatData(currentDate)
    ) {
      const week = setTimeout(() => {
        setWeekStatus('less')
      }, 600)
      return () => clearTimeout(week)
    } else if (
      lastDayOfWeek > currentDate &&
      firstDayOfWeek > currentDate &&
      weekView[0] !== formatData(currentDate)
    ) {
      const week = setTimeout(() => {
        setWeekStatus('more')
      }, 600)
      return () => clearTimeout(week)
    } else {
      const week = setTimeout(() => {
        setWeekStatus('current')
      }, 600)
      return () => clearTimeout(week)
    }
  }, [setWeekStatus, weekView])

  /* Event size changer */
  useEffect(() => {
    const eventHeight = localStorage.getItem('eventHeight')
    if (!eventHeight) {
      localStorage.setItem('eventHeight', JSON.stringify('min'))
    }
    const calendarView = localStorage.getItem('calendarView')

    if (!calendarView) {
      localStorage.setItem('calendarView', JSON.stringify('week'))
    }
  }, [])

  const eventHeightHandler = (size) => {
    dispatch(setEventHeight(size))
    localStorage.setItem('eventHeight', JSON.stringify(size))
  }

  const eventHeightUnparsed = localStorage.getItem('eventHeight')
  const eventHeightLocal = JSON.parse(eventHeightUnparsed)

  const monthView = localStorage.getItem('calendarView')
  const monthViewLocal = JSON.parse(monthView)

  return {
    startDate,
    setStartDate,
    rightSideViewIsOpen,
    setRightSideViewIsOpen,
    ExampleCustomInput,
    goToCurrentDate,
    switchView,
    weekView,
    dispatch,
    eventHeightHandler,
    eventHeightLocal,
    weekStatus,
    monthViewLocal,
    goToCurrentMonth,
    colorTheme,
    toggleTheme,
    arrowLeft,
    arrowRight,
    monthTitle,
    defaultTextIconColor,
    openGroupsHandler,
    isLeftBarOpened,
    newEventHandler,
    nightMode,
  }
}

export default useHeader
