import * as yup from 'yup'

const recoveryEmailSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required.')
    .email('Enter a valid email address.'),
})

export default recoveryEmailSchema
