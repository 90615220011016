/* eslint-disable no-unused-vars */

import { AddIcon } from 'components/Icons'

import React from 'react'

const AddButton = ({ text, icon }) => {
  return (
    <>
      <div
        className={` px-3 h-[2.2rem] bg-BlueDarkColorMain bg-opacity-30 justify-center items-center  cursor-pointer  rounded-xl text-end  text-[13px]   text-BlueDarkColorMain font-semibold sm:flex hidden`}
      >
        <AddIcon
          size={18}
          className={'mr-1 !fill-BlueDarkColorMain mt-[1px]'}
        />
        <p className='truncate'>{text} </p>
      </div>

      <AddIcon
        size={30}
        className={'mr-1 !fill-BlueDarkColorMain mt-[1px] block sm:hidden '}
      />
    </>
  )
}

export default AddButton
