/* eslint-disable no-unused-vars */
import { useHeader } from 'hooks'
import { MdOutlineToday } from 'react-icons/md'
import DatePicker from 'react-datepicker'
import { AddButton, AddNewEvent, RightSideDrawer, SvgIcon } from 'components'
import React, { useEffect, useRef } from 'react'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'

import { motion, AnimatePresence, distance } from 'framer-motion'

import { ViewButtonsList } from '.'

import { setEditingEventIsOpen, setIsLeftBarOpened } from 'features'
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons'

const Header = () => {
  const {
    startDate,
    setStartDate,
    rightSideViewIsOpen,
    setRightSideViewIsOpen,
    ExampleCustomInput,
    goToCurrentDate,
    eventHeightHandler,
    eventHeightLocal,
    weekStatus,
    monthTitle,
    monthViewLocal,
    goToCurrentMonth,
    colorTheme,
    toggleTheme,
    defaultTextIconColor,
    dispatch,
    arrowLeft,
    arrowRight,
    openGroupsHandler,
    isLeftBarOpened,
    newEventHandler,
    nightMode,
  } = useHeader()

  /* Socket IO */

  return (
    <div className='relative'>
      {/* Scroll arrows with frame motion */}
      <div className=' items-center absolute  justify-between flex h-[95vh] w-full'>
        <AnimatePresence
          initial={false}
          exitBeforeEnter={true}
          onExitComplete={() => null}
        >
          {weekStatus === 'more' && (
            <motion.div
              initial={{
                x: -100,
              }}
              animate={{ x: 0 }}
              exit={{ x: -100 }}
              transition={{ duration: 0.5 }}
              onClick={goToCurrentDate}
              className='flex  left-0  z-40 items-center  absolute  overflow-hidden'
            >
              <p
                className={`cursor-pointer pl-5 h-16 w-16 items-center flex justify-center ${
                  colorTheme === 'dark' ? 'bg-DMDarkBlueBackground' : 'bg-white'
                } rounded-br-full rounded-tr-full ml-[-20px]`}
              >
                <BsArrowLeft size={30} color={'gray'} />
              </p>
            </motion.div>
          )}

          {weekStatus === 'less' && (
            <motion.div
              initial={{
                x: 100,
              }}
              animate={{ x: 0 }}
              exit={{ x: 100 }}
              transition={{ duration: 0.5 }}
              onClick={goToCurrentDate}
              className='flex  right-0  z-40 items-center  absolute  overflow-hidden'
            >
              <p
                className={`cursor-pointer pr-5 h-16 w-16 items-center flex justify-center ${
                  colorTheme === 'dark' ? 'bg-DMDarkBlueBackground' : 'bg-white'
                } rounded-bl-full rounded-tl-full  mr-[-20px]`}
              >
                <BsArrowRight size={30} color={'gray'} />
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {/* Scroll arrows with frame motion END */}

      <div
        className={`flex fixed top-0 left-0 z-40 transition-all justify-start w-full px-2 md:px-3 ${
          colorTheme === 'dark' ? 'bg-DMDarkBlueBackground' : 'bg-white'
        }  items-center h-[3.125rem]`}
      >
        {/* Month Title */}
        {/* <p
          className={`w-[17rem] text-left text-lg  ${
            isLeftBarOpened ? 'pl-[17.2rem]' : 'pl-16'
          }  sm:block hidden truncate  ${defaultTextIconColor} ${
            monthViewLocal !== 'month' ? 'invisible !hidden' : 'visible'
          } `}
        >
          {monthTitle}
        </p> */}
        {/* Left side */}
        <div className='flex w-full justify-start sm:pl-14  items-center z-[30]'>
          {/* Current date button */}
          {/*  {monthViewLocal === 'week' ? (
            <button className='px-2 hidden md:block' onClick={goToCurrentDate}>
              <MdOutlineToday size={25} color='#6B7280' />
            </button>
          ) : (
            <button className='px-2 hidden md:block' onClick={goToCurrentMonth}>
              <MdOutlineToday size={25} color='#6B7280' />
            </button>
          )}
 */}
          {/* View Buttons for navigation */}
          <ViewButtonsList isLeftBarOpened={isLeftBarOpened} />

          {/* Theme button */}
          {/* <div
            className={`${
              colorTheme === 'dark' ? 'text-white' : 'text-black'
            } px-2 cursor-pointer sm:block hidden`}
            onClick={toggleTheme}
          >
            {colorTheme}
          </div> */}

          {/* Event size functionality*/}
          {/* <div className=' gap-1 pr-2 flex  font-semibold text-gray-400 '>
            <RiFontSize
              size={15}
              className={`${
                eventHeightLocal === 'min' && colorTheme === 'dark'
                  ? 'text-DarkText'
                  : eventHeightLocal === 'min' && colorTheme === 'light'
                  ? 'text-black'
                  : ''
              } cursor-pointer  mt-2  hover:text-black`}
              onClick={eventHeightHandler.bind(this, 'min')}
            />
            <RiFontSize
              size={20}
              className={`${
                eventHeightLocal === 'med' && colorTheme === 'dark'
                  ? 'text-DarkText'
                  : eventHeightLocal === 'med' && colorTheme === 'light'
                  ? 'text-black'
                  : ''
              } cursor-pointer mt-1 hover:text-black`}
              onClick={eventHeightHandler.bind(this, 'med')}
            />
            <RiFontSize
              size={24}
              className={`${
                eventHeightLocal === 'max' && colorTheme === 'dark'
                  ? 'text-DarkText'
                  : eventHeightLocal === 'max' && colorTheme === 'light'
                  ? 'text-black'
                  : ''
              } cursor-pointer hover:text-black`}
              onClick={eventHeightHandler.bind(this, 'max')}
            />
          </div> */}
        </div>

        {/* Right side  */}
        <div className='flex'>
          {/* Slide arrows and DatePicker */}
          <div
            className={`justify-center px-1 cursor-pointer py-1 rounded-xl mx-1 sm:mx-4 sm:flex hidden ${
              nightMode
                ? 'bg-DMDarkBlueForeground  hover:bg-DMLightBlueForeground2'
                : 'bg-GrayMediumBGColor bg-opacity-20   hover:bg-opacity-30'
            } `}
          >
            <button onClick={arrowLeft} type='button'>
              <ChevronLeftIcon />
            </button>
            <div className='mr-[-1rem] px-2'>
              <DatePicker
                selected={startDate}
                calendarStartDay={1}
                onChange={(date) => setStartDate(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>

            <button onClick={arrowRight} type='button'>
              <ChevronRightIcon />
            </button>
          </div>
          {/* Add new event button */}
          <div onClick={newEventHandler} className='z-40'>
            <AddButton text={'New Event'} />
          </div>
        </div>
      </div>

      {/* Drawer - New Event */}
      <RightSideDrawer
        className='bg-opacity-80 backdrop-blur-sm'
        isOpen={rightSideViewIsOpen}
        position='right'
        closeOnCLickOutside={true}
      >
        <AddNewEvent />
      </RightSideDrawer>
    </div>
  )
}

export default Header
