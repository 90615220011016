/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const IconsIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 21 21'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <g clip-path='url(#clip0_10501_64714)'>
        <path
          d='M6.38411 10.3825C6.02035 10.3825 5.74191 10.2193 5.54878 9.8929C5.35567 9.56651 5.36328 9.23665 5.57161 8.90332L9.27995 2.92415C9.46149 2.6186 9.7291 2.46582 10.0828 2.46582C10.4364 2.46582 10.7036 2.6186 10.8841 2.92415L14.6341 8.90332C14.8147 9.23665 14.8136 9.56651 14.631 9.8929C14.4484 10.2193 14.1716 10.3825 13.8008 10.3825H6.38411ZM15.1942 20.0283C14.0659 20.0283 13.1037 19.6309 12.3075 18.836C11.5114 18.0412 11.1133 17.073 11.1133 15.9315C11.1133 14.8016 11.5105 13.8339 12.3051 13.0283C13.0996 12.2228 14.063 11.82 15.1954 11.82C16.3393 11.82 17.3061 12.2219 18.0956 13.0259C18.8852 13.8298 19.2799 14.8022 19.2799 15.9432C19.2799 17.0841 18.8839 18.0502 18.0919 18.8414C17.2999 19.6327 16.334 20.0283 15.1942 20.0283ZM2.38411 19.5075C2.10773 19.5075 1.87891 19.4169 1.69766 19.2356C1.51641 19.0544 1.42578 18.8255 1.42578 18.5492V13.195C1.42578 12.9283 1.51641 12.6985 1.69766 12.5054C1.87891 12.3123 2.10773 12.2158 2.38411 12.2158H7.75911C8.02161 12.2158 8.24349 12.3123 8.42474 12.5054C8.60599 12.6985 8.69661 12.9283 8.69661 13.195V18.5492C8.69661 18.8255 8.60599 19.0544 8.42474 19.2356C8.24349 19.4169 8.02161 19.5075 7.75911 19.5075H2.38411ZM15.2031 18.1117C15.8238 18.1117 16.339 17.9042 16.7487 17.4892C17.1584 17.0741 17.3633 16.5568 17.3633 15.9371C17.3633 15.3173 17.1578 14.7957 16.7469 14.3721C16.336 13.9485 15.8202 13.7367 15.1996 13.7367C14.5789 13.7367 14.0586 13.9488 13.6388 14.3732C13.219 14.7975 13.0091 15.3212 13.0091 15.9444C13.0091 16.5617 13.2196 17.0773 13.6406 17.491C14.0615 17.9048 14.5824 18.1117 15.2031 18.1117ZM3.34245 17.5908H6.80078V14.1325H3.34245V17.5908ZM8.07161 8.46582H12.0716L10.0924 5.21582L8.07161 8.46582Z'
          fill='#FAFAFA'
        />
      </g>
      <defs>
        <clipPath id='clip0_10501_64714'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0.0722656 0.84082)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconsIcon
