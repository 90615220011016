import { ReactSVGIcon } from 'components'
import { useDarkSide } from 'hooks'
import useDailyEvent from 'hooks/useDailyEvent'
import React from 'react'
import { betweenDates } from 'utils'

const LongEventsW = ({
  eventHours,
  event,
  moment,
  week,
  eventEdgePosition,
  longEventSecondFromEdgePosition,
  eventHeightLocal,
}) => {
  const days = betweenDates(event) - 1
  let testArrayOfDiv = []
  for (let i = 0; i < days; ++i) {
    testArrayOfDiv.push(
      <div key={i} className={`w-full grid grid-cols-6  overflow-hidden   `}>
        <div
          style={{
            opacity: 100,
            backgroundColor: event.group.color,
            gridColumnStart: 1,
            gridColumnEnd: 7,
          }}
          className='w-full  h-1.5'
        ></div>
      </div>
    )
  }

  const eventStartTime = new Date(moment(event.startTime).format('YYYY-MM-DD'))
  const eventEndTime = new Date(moment(event.endTime).format('YYYY-MM-DD'))
  const firstDayOfWeek = new Date(week[0]?.fullDate)
  const lastDayOfWeek = new Date(week[6]?.fullDate)

  const { startPosition, endPosition } = useDailyEvent(event)
  const [colorTheme] = useDarkSide()

  return (
    <div className={` w-full h-full flex group`}>
      {/* Event duration line */}
      <div className='flex w-full absolute'>
        <div
          className={`w-full grid grid-cols-6  overflow-hidden ${
            eventStartTime < firstDayOfWeek ? '' : 'rounded-tl-md '
          }  `}
        >
          <div
            style={{
              opacity: 100,
              backgroundColor: event.group.color,
              gridColumnStart:
                eventStartTime < firstDayOfWeek ? 1 : startPosition,
              gridColumnEnd: 7,
            }}
            className='w-full  h-1.5'
          ></div>
        </div>
        {testArrayOfDiv}
        <div
          className={`w-full grid grid-cols-6   overflow-hidden ${
            eventEndTime > lastDayOfWeek ? '' : 'rounded-tr-md '
          }`}
        >
          <div
            style={{
              opacity: 100,
              backgroundColor: event.group.color,
              gridColumnStart: 1,
              gridColumnEnd: eventEndTime > lastDayOfWeek ? 7 : endPosition,
            }}
            className='w-full  h-1.5'
          ></div>
        </div>
      </div>
      {/* Background line */}
      <span
        style={{
          backgroundColor: event.group.color,
        }}
        className='w-full h-1.5 top-0 absolute opacity-40  rounded-tl-md rounded-tr-md   '
      ></span>
      {/* Event background color */}
      <div
        style={{ backgroundColor: event.group.color }}
        className={`w-full h-full opacity-50 absolute group-hover:opacity-70 ${
          eventStartTime < firstDayOfWeek && eventEndTime > lastDayOfWeek
            ? ''
            : eventStartTime < firstDayOfWeek
            ? 'rounded-tr-md rounded-br-md'
            : eventEndTime > lastDayOfWeek
            ? 'rounded-tl-md rounded-bl-md'
            : 'rounded-md'
        } `}
      ></div>
      {/* Content */}
      <div
        className={`flex relative z-50 items-center   sm:justify-start  w-full h-full overflow-hidden ${
          eventEdgePosition ? 'sm:justify-start justify-center' : ''
        }`}
      >
        <ReactSVGIcon name={event?.icon?.name} />

        <div
          className={` flex flex-col justify-center px-1  sm:pt-1 pt-1.5 truncate ${
            colorTheme === 'light' ? 'text-black' : 'text-white'
          } ${eventEdgePosition ? 'sm:flex hidden' : ''}  `}
        >
          <p className=' text-left font-light truncate '>{event.title}</p>

          <p className=' font-semibold truncate '>
            {moment(event.startTime).format('MMM DD HH:mm')} -{' '}
            {moment(event.endTime).format('MMM DD HH:mm')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default LongEventsW
