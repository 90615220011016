/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const MoreVertIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      viewBox='0 0 50 50'
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M24.05 41.7q-1.25 0-2.125-.875t-.875-2.075q0-1.2.875-2.1.875-.9 2.075-.9 1.25 0 2.1.9.85.9.85 2.1 0 1.2-.85 2.075-.85.875-2.05.875Zm0-14.75q-1.25 0-2.125-.875T21.05 24q0-1.25.875-2.1.875-.85 2.075-.85 1.25 0 2.1.85.85.85.85 2.05 0 1.25-.85 2.125t-2.05.875Zm0-14.7q-1.25 0-2.125-.875T21.05 9.25q0-1.25.875-2.125T24 6.25q1.25 0 2.1.875.85.875.85 2.125t-.85 2.125q-.85.875-2.05.875Z' />
    </svg>
  )
}

export default MoreVertIcon
