/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import { AuthLayout, Input } from 'components'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FormLayout, UnumSection } from '..'

const Login = () => {
  /* If user logged in, navigate to calendar */
  const navigate = useNavigate()
  useEffect(() => {
    var token = localStorage.getItem('token')
    if (token) {
      navigate('/calendar')
    }
  }, [navigate])

  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  })

  const loginHandler = async (e) => {
    e.preventDefault()
    const results = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/auth/login`,
      { email: loginData.email, password: loginData.password }
    )
    localStorage.setItem('token', results.data.token)
    if (results.status === 200) {
      window.location.href = '/calendar'
    }
  }

  const textHandler = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value })
  }

  return (
    <AuthLayout>
      <div className='flex flex-col md:flex-row justify-between items-center'>
        <UnumSection />
        <FormLayout>
          <Input
            onChange={textHandler}
            name='email'
            type='email'
            placeholder='Email'
            autoComplete='email'
            required={true}
          />

          <Input
            onChange={textHandler}
            name='password'
            type='password'
            placeholder='Password'
            autoComplete='password'
            required={true}
          />

          <button
            onClick={loginHandler}
            type='button'
            className='flex w-[330px] h-[44px] justify-center items-center rounded-xl bg-BlueDarkColorMain px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-BlueDarkColorMainHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            Log in
          </button>

          <p className='mt-10 text-center text-sm text-white'>
            Don't have an account yet?
            <Link
              to={'/sign-up'}
              className='font-semibold leading-6 text-BlueDarkColorMain  hover:text-BlueDarkColorMainHover'
            >
              Signup
            </Link>
          </p>
        </FormLayout>
      </div>
    </AuthLayout>
  )
}

export default Login
