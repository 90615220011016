import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  theme: localStorage.theme || 'light',
  defaultTextIconColor:
    localStorage.theme === 'light'
      ? 'text-DMMainLightTextIcon'
      : 'text-LMMainDarkTextIcon',
  primarySvgColor:
    localStorage.theme === 'light'
      ? 'fill-DMMainLightTextIcon'
      : 'fill-LMMainDarkTextIcon',
  secondarySvgColor:
    localStorage.theme === 'light'
      ? 'fill-DM2ndLighterTextIcon'
      : 'fill-LM2ndLighterTextIcon',
  nightMode: localStorage.theme === 'light' ? true : false,
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload
      state.defaultTextIconColor =
        action.payload === 'light'
          ? 'text-DMMainLightTextIcon'
          : 'text-LMMainDarkTextIcon'
      state.primarySvgColor =
        action.payload === 'light'
          ? 'fill-DMMainLightTextIcon'
          : 'fill-LMMainDarkTextIcon'
      state.secondarySvgColor =
        action.payload === 'light'
          ? 'fill-DM2ndLighterTextIcon'
          : 'fill-LM2ndLighterTextIcon'
      state.nightMode = action.payload === 'light' ? true : false
    },
  },
})

export const { setTheme } = themeSlice.actions

export default themeSlice.reducer
