import React, { useRef } from 'react'
import Lottie from 'lottie-react'
import groovyWalkAnimation2 from '../../assets/animations/logo-to-text.json'

const LogoToText = ({ isLeftBarOpened }) => {
  const lottieRef = useRef(null)

  if (isLeftBarOpened) {
    setTimeout(() => {
      lottieRef.current?.play()
    }, 100)
  } else {
    lottieRef.current?.stop()
  }

  return (
    <Lottie
      className='h-[6.25rem]'
      lottieRef={lottieRef}
      autoplay={false}
      loop={false}
      animationData={groovyWalkAnimation2}
    />
  )
}

export default LogoToText
