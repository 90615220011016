/* eslint-disable no-unused-vars */
import { GroupDescription, InnerDrawer } from 'components'
import EventsRequest from 'components/Connections/components/EventsRequest'
import { searchRequest, setSearchIsOpen } from 'features'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useDebounce } from 'use-debounce'
import { SearchGroup } from './components'
import { GroupSettings } from 'components/Groups/components'
import {
  ChevronLeftIcon,
  ClearIcon,
  MoreVertIcon,
  SearchIcon,
} from 'components/Icons'

const buttonArr = [
  {
    id: 1,
    name: 'All',
    value: 'all',
  },
  {
    id: 2,
    name: 'Events',
    value: 'event',
  },
  {
    id: 3,
    name: 'Groups',
    value: 'group',
  },
]

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('')

  const [searchResults, setSearchResults] = useState([])
  const [activeBtn, setActiveBtn] = useState(buttonArr[0].id)
  const [searchType, setSearchType] = useState('all')

  const { defaultTextIconColor, nightMode } = useSelector(
    (state) => state.theme
  )

  const { groupDescriptionIsOpen, isLeftBarOpened, groupSettingsIsOpen } =
    useSelector((state) => state.sideView)

  const [debouncedValue] = useDebounce(searchTerm, 500)

  const clearSearchInputHandler = () => {
    setSearchResults([])
    setSearchTerm('')
  }

  useEffect(() => {
    const searchHandler = async () => {
      if (debouncedValue.trim() !== '') {
        try {
          const data = await searchRequest(debouncedValue, searchType)
          setSearchResults(data)
        } catch (error) {
          console.log(error)
        }
      }
    }
    searchHandler()
  }, [debouncedValue, searchType])

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
  }

  const eventsArray = searchResults.filter((event) => event.title)
  const groupsArray = searchResults.filter((group) => group.name)

  const handleButtonClick = (button) => {
    setActiveBtn(button.id)
    setSearchType(button.value)
  }

  const dispatch = useDispatch()

  /* Focus on the input element when the component mounts */
  // Create a ref for the first input element
  const firstInputRef = useRef(null)

  useEffect(() => {
    // Focus on the input element when the component mounts
    if (firstInputRef.current) {
      firstInputRef.current.focus()
    }
  }, [])
  return (
    <>
      <div
        className={`h-screen w-full backdrop-blur-md p-3 text-sm ${defaultTextIconColor}`}
      >
        {/* Header */}
        <div className='pb-6 font-bold text-sm flex justify-between items-center relative '>
          <ChevronLeftIcon
            size={30}
            className={'cursor-pointer'}
            onClick={() => {
              dispatch(setSearchIsOpen(false))
            }}
          />

          <p className={`text-sm  `}>Search</p>

          <MoreVertIcon className={'!invisible '} />
        </div>
        {/* Content */}
        {/* search input */}
        <div
          className={` rounded-xl items-center flex group overflow-hidden ${
            nightMode
              ? 'bg-DMDarkBlueForeground'
              : 'bg-LM2ndLighterTextIcon bg-opacity-10'
          }  `}
        >
          <SearchIcon
            size={28}
            className={`mx-2 fill-DM2ndLighterTextIcon  ${
              nightMode
                ? 'group-hover:!fill-DMMainLightTextIcon'
                : 'group-hover:!fill-LMMainDarkTextIcon'
            }`}
          />
          <input
            onChange={handleSearch}
            ref={firstInputRef}
            className={`w-full h-10  placeholder-DM2ndLighterTextIcon  outline-none bg-opacity-0   text-DM2ndLighterTextIcon text-sm ${
              nightMode
                ? 'bg-DMDarkBlueBackground group-hover:placeholder-DMMainLightTextIcon '
                : ' bg-GrayMediumBGColor bg-opacity-20 group-hover:placeholder-LMMainDarkTextIcon'
            }`}
            placeholder='Search UNUM...'
            value={searchTerm}
            name='search'
            type='text'
          />

          <ClearIcon
            onClick={clearSearchInputHandler}
            size={20}
            className={'cursor-pointer mx-3'}
          />
        </div>

        {/* Buttons */}
        <div className='flex items-center space-x-3 my-3'>
          {buttonArr.map((btn) => {
            return (
              <div
                key={btn.id}
                onClick={() => handleButtonClick(btn)}
                className={`w-20 h-9 flex cursor-pointer justify-center items-center rounded-xl text-DMMainLightTextIcon ${
                  activeBtn === btn.id
                    ? 'bg-BlueDarkColorMain hover:bg-BlueLightColor '
                    : nightMode
                    ? 'bg-DMDarkBlueForeground  hover:bg-DMLightBlueForeground2'
                    : 'bg-GrayMediumBGColor bg-opacity-20  text-LMMainDarkTextIcon2 hover:bg-opacity-30'
                }`}
              >
                {btn.name}
              </div>
            )
          })}
        </div>
        {/* End Buttons */}

        <div className='my-5 overflow-y-scroll h-full pb-16'>
          {eventsArray.length > 0 && (
            <>
              <h1 className='text-14 font-bold leading-14 mb-4'>Events</h1>
              {eventsArray.map((event) => (
                <EventsRequest key={event._id} event={event} />
              ))}
            </>
          )}

          {groupsArray.length > 0 && (
            <>
              <h1 className='text-14 font-bold leading-14 mb-4'>Groups</h1>
              {groupsArray.map((group) => (
                <SearchGroup key={group._id} item={group} />
              ))}
            </>
          )}
        </div>
      </div>
      <InnerDrawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={groupDescriptionIsOpen}
        position='left'
        className='bg-opacity-10'
      >
        <GroupDescription
          groupDescriptionIsOpen={groupDescriptionIsOpen}
          defaultTextIconColor={defaultTextIconColor}
        />
      </InnerDrawer>
      <InnerDrawer
        isLeftBarOpened={isLeftBarOpened}
        isOpen={groupSettingsIsOpen}
        position='left'
        className=''
      >
        <GroupSettings defaultTextIconColor={defaultTextIconColor} />
      </InnerDrawer>
    </>
  )
}

export default Search
