import { ChevronLeftIcon } from 'components/Icons'
import { setParticipantsIsOpen } from 'features'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'

const Participants = ({ participants }) => {
  const dispatch = useDispatch()
  const { defaultTextIconColor } = useSelector((state) => state.theme)

  return (
    <div className={`h-screen p-3 ${defaultTextIconColor}`}>
      {/* Header */}
      <div className='  pb-6 font-bold text-sm flex justify-between items-center relative'>
        <ChevronLeftIcon
          onClick={() => {
            dispatch(setParticipantsIsOpen(false))
          }}
          size={30}
          className={'cursor-pointer'}
        />
        <p className={`text-sm  `}>Participants</p>
        <ChevronLeftIcon
          onClick={() => {
            dispatch(setParticipantsIsOpen(false))
          }}
          size={30}
          className={'cursor-pointer invisible'}
        />
      </div>

      {/* Member List */}
      <div className='overflow-scroll pb-10 h-full'>
        {participants?.map((user) => {
          return (
            <div
              key={uuid()}
              className={`relative rounded-xl h-[3.75rem] overflow-hidden bg-DMDarkBlueForeground items-center w-full !px-0  py-6  flex justify-between mb-3 `}
            >
              <div className='flex items-center'>
                <div
                  className={`flex  bg-DM2ndLighterTextIcon justify-center items-center rounded-xl  w-[3.75rem] h-[3.75rem] `}
                >
                  <img
                    alt='test'
                    src={'http://source.unsplash.com/100x100/?girl'}
                    className='cursor-pointer rounded-xl'
                  />
                </div>
                <p className={`truncate pl-3 `}>{user.email}</p>
              </div>
              <span className='bg-DM2ndLighterTextIcon w-[4.688rem] bg-opacity-30 cursor-pointer h-[1.875rem] rounded-md justify-center flex items-center text-sm mr-4 '>
                Invite
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Participants
