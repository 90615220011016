/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const EditIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      onClick={onClick}
      viewBox='0 0 50 50'
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='m39.85 15.1-6.6-6.6 1.9-1.95q1-1 2.45-1 1.45 0 2.6 1.05l1.65 1.6q1.15 1.1 1.025 2.55-.125 1.45-1.125 2.45ZM8.1 42.6q-1 0-1.675-.675T5.75 40.25v-3.3q0-.45.175-.875t.525-.775l24.7-24.7 6.65 6.55L13.1 41.9q-.4.35-.825.525-.425.175-.875.175Z' />
    </svg>
  )
}

export default EditIcon
