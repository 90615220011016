/* eslint-disable no-unused-vars */
import axios from 'axios'
import { ChevronLeftIcon } from 'components/Icons'
import {
  cancelInvite,
  deleteNotification,
  fetchGroups,
  fetchSingleGroup,
  fetchUserInfo,
  resetSendInvitationStatus,
  sendInviteToMember,
  setCancelSocketData,
  setGroupAddMemberIsOpen,
  setSocketData,
} from 'features'
import { useSocket } from 'hooks'

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { io } from 'socket.io-client'
import { userId } from 'utils'

const AddMember = ({ defaultTextIconColor }) => {
  const { fetchedUserInfo } = useSelector((state) => state.user)
  const { fetchedGroups, fetchedSingleGroup } = useSelector(
    (state) => state.group
  )

  const { sendInvitationStatus, cancelInviteStatus } = useSelector(
    (state) => state.connection
  )

  const { selectedGroup } = useSelector((state) => state.group)

  const dispatch = useDispatch()

  // /* Socket IO */
  const { socket } = useSocket()

  // connect to the socket server
  useEffect(() => {
    if (socket) {
      const handleGetInvite = (data) => {
        dispatch(setSocketData(data))
      }

      const handleCancelInvite = (data) => {
        dispatch(setCancelSocketData(data))
      }

      socket.on('cancelledInvite', handleCancelInvite)

      socket.on('getInvite', handleGetInvite)

      // Clean up the event listener when the component unmounts
      return () => {
        socket.off('getInvite', handleGetInvite)
        socket.off('cancelledInvite', handleCancelInvite)
      }
    }
  }, [dispatch, socket])

  /* Socket IO END */

  /*  const [objectArrayOfSelectedMembers, setObjectArrayOfSelectedMembers] =
    useState([])

  const selectMemberHandler = (user) => {
    const object = {
      id: user._id,
      email: user.email,
    }

    setObjectArrayOfSelectedMembers((prevArray) => [...prevArray, object])
  } */

  useEffect(() => {
    if (sendInvitationStatus === 'rejected') {
      toast.error('Invitation has already sent')
      dispatch(resetSendInvitationStatus())
      // setObjectArrayOfSelectedMembers([])
      return
    } else if (sendInvitationStatus === 'success') {
      toast.success('Invitation sent')
      dispatch(resetSendInvitationStatus())
      //  setObjectArrayOfSelectedMembers([])
    }
  }, [dispatch, sendInvitationStatus])

  // add notification function

  const addNotification = async (forUser, typeId) => {
    const results = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add-notification`,

      {
        notificationFromUser: userId(),
        notificationType: 'group',
        isRead: false,
        notificationForUser: forUser,
        typeId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )

    return results.data
  }

  const inviteMemberToGroupHandler = (user) => {
    let id = selectedGroup?._id
    const objectArrayOfSelectedMembers = [
      {
        id: user._id,
        email: user.email,
        color: selectedGroup?.color,
      },
    ]

    if (objectArrayOfSelectedMembers.length === 0) {
      return toast.warning('Please select at least one member')
    }

    let data = { id, objectArrayOfSelectedMembers, type: 'group' }
    dispatch(sendInviteToMember(data))

    if (socket) {
      setTimeout(() => {
        socket.emit('sendInvite', {
          senderId: userId(),
          receiverId: user._id,
        })
      }, 1000)
    }
    addNotification(user._id, selectedGroup?._id)
  }

  const cancelInviteHandler = (user) => {
    let data = {
      id: selectedGroup?._id,
      userId: user._id,
      type: 'group',
    }

    dispatch(cancelInvite(data))

    dispatch(deleteNotification(data))

    setTimeout(() => {
      socket.emit('cancelInvite', {
        senderId: userId(),
        receiverId: user._id,
      })
    }, 1000)
  }

  const backArrowHandler = () => {
    dispatch(setGroupAddMemberIsOpen(false))
  }

  useEffect(() => {
    if (selectedGroup?._id) {
      dispatch(fetchSingleGroup(selectedGroup?._id))
    }
  }, [dispatch, sendInvitationStatus, cancelInviteStatus, selectedGroup])

  const groupedUsers = fetchedUserInfo?.friends?.reduce((groups, user) => {
    const firstLetter = user.email.charAt(0).toUpperCase()
    if (!groups[firstLetter]) {
      groups[firstLetter] = []
    }
    groups[firstLetter].push(user)
    return groups
  }, {})

  // Create an array of letters to iterate through
  const letters = Object.keys(groupedUsers).sort()
  return (
    <div
      className={` h-screen w-full backdrop-blur-md bg-DMDarkBlueBackground  p-3 text-xs ${defaultTextIconColor}`}
    >
      {/* Header */}
      <div className='pb-6 font-bold  text-sm flex justify-between items-center relative'>
        <ChevronLeftIcon
          size={30}
          onClick={backArrowHandler}
          className=' cursor-pointer '
        />

        <p className='  absolute left-1/2 -translate-x-1/2 pr-5'>
          Invite Participants
        </p>

        {/*  <p
          onClick={inviteMemberToGroupHandler}
          className='text-green-300 cursor-pointer px-3'
        >
          Save
        </p> */}
      </div>
      {/* Members list */}

      <div className='overflow-scroll pb-10 h-full'>
        {letters?.map((letter) => (
          <div key={letter}>
            <h2>{letter}</h2> {/* Display the letter as a heading */}
            {groupedUsers[letter]
              ?.slice()
              .sort((a, b) => a.email.localeCompare(b.email))
              .map((user) => {
                let invitedUser = fetchedSingleGroup?.invitedUsers?.filter(
                  (item) => item.user === user._id
                )
                return (
                  <div key={user._id}>
                    <div
                      className={`relative rounded-xl h-[3.75rem] overflow-hidden bg-DMDarkBlueForeground items-center w-full !px-0  py-6  flex justify-between mb-3 `}
                    >
                      <div className='flex items-center'>
                        <div
                          className={`flex  bg-DM2ndLighterTextIcon justify-center items-center rounded-xl  w-[3.75rem] h-[3.75rem] `}
                        >
                          <img
                            alt='test'
                            src={'http://source.unsplash.com/100x100/?girl'}
                            className='cursor-pointer rounded-xl'
                          />
                        </div>
                        <p className={`truncate pl-3 `}>{user.email}</p>
                      </div>
                      {invitedUser[0]?.status === 'pending' ? (
                        <span
                          onClick={cancelInviteHandler.bind(null, user)}
                          className='bg-DM2ndLighterTextIcon w-[4.688rem] bg-opacity-30 cursor-pointer h-[1.875rem] rounded-md justify-center flex items-center text-sm mr-4 text-red-600 '
                        >
                          Cancel
                        </span>
                      ) : invitedUser[0]?.status === 'accepted' ? (
                        <span className='bg-DM2ndLighterTextIcon w-[4.688rem] bg-opacity-30  h-[1.875rem] rounded-md justify-center flex items-center text-sm mr-4 font-light'>
                          <p className='opacity-50'> Member</p>
                        </span>
                      ) : (
                        <span
                          onClick={inviteMemberToGroupHandler.bind(null, user)}
                          className='bg-DM2ndLighterTextIcon w-[4.688rem] bg-opacity-30 cursor-pointer h-[1.875rem] rounded-md justify-center flex items-center text-sm mr-4 '
                        >
                          Invite
                        </span>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        ))}
        {/* {selfExclusionFromAllUsers
          .slice()
          .sort((a, b) => a.email.localeCompare(b.email))
          .map((user) => {
            let invitedUser = selectedGroupUpdated.invitedUsers.filter(
              (item) => item.user === user._id
            )

            return (
              <div
                className={`relative rounded-2xl h-[3.75rem] overflow-hidden bg-DMDarkBlueForeground items-center w-full !px-0  py-6  flex justify-between mb-3 `}
              >
                <div className='flex items-center'>
                  <div
                    className={`flex  bg-DM2ndLighterTextIcon justify-center items-center rounded-2xl  w-[3.75rem] h-[3.75rem] `}
                  >
                    <img
                      alt='test'
                      src={'http://source.unsplash.com/100x100/?girl'}
                      className='cursor-pointer rounded-2xl'
                    />
                  </div>
                  <p className={`truncate pl-3 `}>{user.email}</p>
                </div>
                {invitedUser[0]?.status === 'pending' ? (
                  <span
                    onClick={cancelInviteHandler.bind(null, user)}
                    className='bg-DM2ndLighterTextIcon w-[4.688rem] bg-opacity-30 cursor-pointer h-[1.875rem] rounded-lg justify-center flex items-center text-sm mr-4 text-red-600 '
                  >
                    Cancel
                  </span>
                ) : invitedUser[0]?.status === 'accepted' ? (
                  <span className='bg-DM2ndLighterTextIcon w-[4.688rem] bg-opacity-30  h-[1.875rem] rounded-lg justify-center flex items-center text-sm mr-4 font-light'>
                    <p className='opacity-50'> Member</p>
                  </span>
                ) : (
                  <span
                    onClick={inviteMemberToGroupHandler.bind(null, user)}
                    className='bg-DM2ndLighterTextIcon w-[4.688rem] bg-opacity-30 cursor-pointer h-[1.875rem] rounded-lg justify-center flex items-center text-sm mr-4 '
                  >
                    Invite
                  </span>
                )}
              </div>
            )
          })} */}
        {/* {selfExclusionFromAllUsers.map((user) => (
          <div className='flex w-full justify-between'>
            <p
              onClick={selectMemberHandler.bind(null, user)}
              className='py-3 cursor-pointer'
            >
              {user.email}
            </p>
            
          </div>
        ))} */}
      </div>
    </div>
  )
}

export default AddMember
