/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const AddIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      viewBox='0 0 50 50'
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M24 38.85q-1 0-1.675-.675T21.65 36.5V26.35H11.5q-1 0-1.675-.675T9.15 24q0-1 .675-1.675t1.675-.675h10.15V11.5q0-1 .675-1.675T24 9.15q1 0 1.675.675t.675 1.675v10.15H36.5q1 0 1.675.675T38.85 24q0 1-.675 1.675t-1.675.675H26.35V36.5q0 1-.675 1.675T24 38.85Z' />
    </svg>
  )
}

export default AddIcon
