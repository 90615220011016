/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const ClockIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      viewBox='0 0 21 21'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M10.9466 10.4773V7.29932C10.9466 7.08126 10.869 6.89133 10.7137 6.72952C10.5584 6.56772 10.3674 6.48682 10.1407 6.48682C9.91399 6.48682 9.71733 6.56772 9.55072 6.72952C9.38409 6.89133 9.30078 7.08126 9.30078 7.29932V10.7785C9.30078 10.9077 9.32162 11.0298 9.36328 11.1448C9.40495 11.2597 9.46745 11.3598 9.55078 11.4452L12.3216 14.2993C12.496 14.466 12.7028 14.5493 12.942 14.5493C13.1812 14.5493 13.3876 14.466 13.5612 14.2993C13.707 14.1604 13.7834 13.9625 13.7904 13.7056C13.7973 13.4486 13.7174 13.2368 13.5508 13.0702L10.9466 10.4773ZM10.0802 19.6118C8.88504 19.6118 7.74776 19.3834 6.66839 18.9267C5.589 18.4699 4.65308 17.8456 3.86064 17.0536C3.06818 16.2617 2.44342 15.3264 1.98636 14.2477C1.52931 13.1691 1.30078 12.0323 1.30078 10.8375C1.30078 9.63918 1.53107 8.50683 1.99166 7.44048C2.45225 6.37412 3.07847 5.44588 3.8703 4.65575C4.66212 3.86563 5.59602 3.23369 6.67199 2.75994C7.74796 2.28619 8.88309 2.04932 10.0774 2.04932C11.2767 2.04932 12.4091 2.28607 13.4747 2.75959C14.5402 3.2331 15.4679 3.86482 16.2579 4.65473C17.0478 5.44465 17.6795 6.37257 18.153 7.4385C18.6265 8.50443 18.8633 9.63726 18.8633 10.837C18.8633 12.0367 18.6264 13.1729 18.1527 14.2456C17.6789 15.3182 17.047 16.2505 16.2568 17.0423C15.4667 17.8341 14.5394 18.4603 13.475 18.9209C12.4106 19.3815 11.279 19.6118 10.0802 19.6118ZM10.0561 17.716C11.9414 17.716 13.5612 17.0501 14.9154 15.7184C16.2695 14.3866 16.9466 12.766 16.9466 10.8564C16.9466 8.95722 16.2721 7.33404 14.9231 5.98682C13.5741 4.63959 11.9551 3.96598 10.0663 3.96598C8.16708 3.96598 6.54731 4.63708 5.20703 5.97927C3.86675 7.32147 3.19661 8.94386 3.19661 10.8464C3.19661 12.7595 3.86589 14.3827 5.20445 15.716C6.543 17.0493 8.1602 17.716 10.0561 17.716Z' />
    </svg>
  )
}

export default ClockIcon
