import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Toggle = ({
  enabled,
  item,
  groupVisibility,
  dispatch,
  toast,
  color,
  showGroup,
  setShowGroup,
  setEnabled,
}) => {
  const hideGroupHandler = () => {
    setShowGroup(!showGroup)

    let dataForSubmit = {
      groupId: item._id,
      isGroupHide: enabled,
    }
    setTimeout(() => {
      dispatch(groupVisibility(dataForSubmit))
    }, 300)
  }
  return (
    <Switch
      checked={enabled}
      onChange={hideGroupHandler}
      className={classNames(
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
      )}
      style={{
        backgroundColor: enabled ? color : '#D0DCE6',
      }}
    >
      <span className='sr-only'>Use setting</span>
      <span
        aria-hidden='true'
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  )
}

export default Toggle
