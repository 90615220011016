import React from 'react'

const FormLayout = ({ children }) => {
  return (
    <section className='space-y-3 md:bg-DMDarkBlueBackground h-screen flex flex-col items-center justify-center md:w-[526px] !overflow-y-hidden p-7'>
      {children}
    </section>
  )
}

export default FormLayout
