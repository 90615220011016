/* eslint-disable no-unused-vars */
import React from 'react'
import { useDarkSide } from 'hooks'
import {
  UnumButtonImgBlack,
  UnumButtonImgWhite,
  UnumCloseButtonImg,
} from 'assets'
import { useDispatch } from 'react-redux'
import {
  setConnectionsIsOpen,
  setGroupDescriptionIsOpen,
  setGroupsIsOpen,
  setHomeScreenIsOpen,
  setInnerEventDescIsOpen,
  setIsContactsOpen,
  setPerDayEventsIsOpen,
  setSearchIsOpen,
  setSingeEventDescIsOpen,
} from 'features'
import { useSelector } from 'react-redux'

const UnumButton = () => {
  const dispatch = useDispatch()
  const { homeScreenIsOpen } = useSelector((state) => state.sideView)

  const { nightMode } = useSelector((state) => state.theme)

  const openHomeScreenHandler = () => {
    dispatch(setHomeScreenIsOpen(!homeScreenIsOpen))

    /* Close other drawers */
    dispatch(setPerDayEventsIsOpen(false))
    dispatch(setInnerEventDescIsOpen(false))
    dispatch(setSingeEventDescIsOpen(false))
    dispatch(setIsContactsOpen(false))
    dispatch(setConnectionsIsOpen(false))
    dispatch(setGroupDescriptionIsOpen(false))
    dispatch(setGroupsIsOpen(false))
    dispatch(setSearchIsOpen(false))
  }
  return (
    <div
      className={`fixed bottom-0  w-[3rem] right-0 mb-8 sm:mr-0 mr-6  z-40  sm:translate-x-full transform h-10  duration-1000 ease-in-out transition-all    `}
    >
      <div
        onClick={openHomeScreenHandler}
        className={`w-[3.2rem] h-[3.2rem] p-[2px]   rounded-full ${
          nightMode ? 'bg-DMDarkBlueBackground' : 'bg-white'
        }`}
      >
        <div className='bg-BlueDarkColorMain h-full w-full justify-center flex items-center rounded-full'>
          {homeScreenIsOpen ? (
            <svg
              width='22'
              height='22'
              viewBox='0 0 22 22'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1.33348 1.33344C1.97785 0.689079 3.02257 0.689079 3.66693 1.33344L20.6669 18.3334C21.3113 18.9778 21.3113 20.0225 20.6669 20.6669C20.0226 21.3113 18.9778 21.3113 18.3335 20.6669L1.33348 3.6669C0.689115 3.02253 0.689115 1.97781 1.33348 1.33344Z'
                fill='white'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1.33337 20.6668C0.689006 20.0225 0.689006 18.9777 1.33337 18.3334L18.3334 1.33337C18.9777 0.689006 20.0225 0.689007 20.6668 1.33337C21.3112 1.97774 21.3112 3.02246 20.6668 3.66682L3.66682 20.6668C3.02246 21.3112 1.97774 21.3112 1.33337 20.6668Z'
                fill='white'
              />
            </svg>
          ) : (
            <svg
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.7648 18.1698C14.135 19.3224 12.1463 20 10.0003 20C4.48077 20 -4.76837e-06 15.5192 -4.76837e-06 9.99972V1.62755C-4.76837e-06 1.40708 0.044087 1.19666 0.123902 1.00466C0.203158 0.812659 0.320931 0.632938 0.476653 0.476658C0.632933 0.320935 0.812654 0.203169 1.00466 0.123355C1.19666 0.0440981 1.40707 0 1.6281 0C1.84857 0 2.05899 0.0440981 2.251 0.123355C2.443 0.203169 2.62328 0.320935 2.779 0.476658L10.0003 7.69793L17.221 0.476658C17.3773 0.320935 17.557 0.203169 17.749 0.123355C17.941 0.0440981 18.1514 0 18.3724 0C18.5929 0 18.8033 0.0440981 18.9953 0.123355C19.1873 0.203169 19.3676 0.320935 19.5233 0.476658C19.6796 0.632938 19.7974 0.812659 19.8766 1.00466C19.9565 1.19666 20.0006 1.40708 20.0006 1.62755V9.99972C20.0006 12.1988 19.2889 14.2332 18.0839 15.8848L19.4089 17.2294C20.0396 17.8696 20.0324 18.901 19.3922 19.5317C18.752 20.1624 17.7205 20.1546 17.0898 19.515L15.7648 18.1698ZM15.7531 13.5194C16.3821 12.4946 16.7443 11.289 16.7443 9.99972V5.55801L12.2937 10.0087L15.7531 13.5194ZM8.84046 11.1428L3.25622 5.55801V9.99972C3.25622 13.722 6.278 16.7443 10.0003 16.7443C11.2539 16.7443 12.4282 16.4016 13.434 15.8044L8.84882 11.1512L8.84046 11.1428Z'
                fill='white'
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  )
}

export default UnumButton
