/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const ArrowRightIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      viewBox='0 0 11 10'
      width={size}
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='M3.65212 9.53435C2.92017 9.94598 2.14247 10.0448 1.31903 9.83078C0.495585 9.61679 0.0838623 9.20925 0.0838623 8.60817V1.45133C0.0838623 0.868959 0.495585 0.470777 1.31903 0.256782C2.14247 0.0427694 2.92017 0.141581 3.65212 0.553216L9.46198 4.14567C9.61447 4.26093 9.75934 4.40039 9.89658 4.56405C10.0338 4.72773 10.1024 4.88839 10.1024 5.04603C10.1024 5.20367 10.0338 5.36432 9.89658 5.52801C9.75934 5.69167 9.61447 5.82028 9.46198 5.91383L3.65212 9.53435Z' />
    </svg>
  )
}

export default ArrowRightIcon
