/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'

const ChevronLeftIcon = ({ size = 24, className, onClick, secondaryColor }) => {
  const { primarySvgColor, secondarySvgColor } = useSelector(
    (state) => state.theme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      width={size}
      viewBox='0 0 50 50'
      className={`${className} ${
        !secondaryColor ? primarySvgColor : secondarySvgColor
      }`}
    >
      <path d='m26.35 35.5-9.9-9.9q-.35-.35-.525-.775-.175-.425-.175-.875t.175-.875q.175-.425.525-.775l9.95-9.95q.7-.7 1.675-.7.975 0 1.675.7.7.7.675 1.7-.025 1-.725 1.7l-8.2 8.2 8.25 8.25q.7.7.7 1.65t-.7 1.65q-.7.7-1.7.7t-1.7-.7Z' />
    </svg>
  )
}

export default ChevronLeftIcon
