import { setMonthView } from 'features'
import { useEffect, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'
import { useDispatch } from 'react-redux'

const useMonthView = (monthCount, itemWeeks) => {
  const myRef = useRef()
  const { inViewport } = useInViewport(myRef)
  const dispatch = useDispatch()
  useEffect(() => {
    if (inViewport) {
      dispatch(
        setMonthView({
          monthCount,
          itemWeeks,
        })
      )
    }
  }, [monthCount, inViewport, dispatch, itemWeeks])

  return { myRef }
}

export default useMonthView
