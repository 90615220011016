import { ClearIcon } from 'components/Icons'
import React, { useState } from 'react'

const Input = ({
  type,
  onChange,
  name,
  placeholder,
  autoComplete,
  required,
  className,
  error,
  register,
  dirtyFields,
  defaultValue,
}) => {
  const [inputValue, setInputValue] = useState('')

  const clearInput = () => {
    setInputValue('')
  }

  const handleChange = (event) => {
    setInputValue(event.target.value)
    // If onChange prop is passed, call it to propagate the input value change
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <div className='relative'>
      <input
        className={`rounded-xl bg-GrayMediumBGColor bg-opacity-20 focus:outline-0 placeholder:opacity-30 text-DMMainLightTextIcon placeholder-DMMainLightTextIcon w-[330px] h-[44px] px-4 ${className} ${
          error ? 'border-NegativeRed border-2 ' : ''
        }`}
        defaultValue={defaultValue}
        type={type}
        name={name}
        placeholder={placeholder}
        // value={inputValue}
        onChange={handleChange}
        autoComplete={autoComplete}
        required={required}
        {...(register ? register(name) : {})} // Conditionally include register
      />
      {inputValue && (
        <ClearIcon
          onClick={clearInput}
          size={15}
          className={'cursor-pointer mx-3 absolute right-1 top-4'}
        />
      )}
      {error && (
        <div className='w-[330px] text-center text-NegativeRed pt-2 '>
          {' '}
          <p>{error}</p>
        </div>
      )}
    </div>
  )
}

export default Input
