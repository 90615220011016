import React, { useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Drawer = ({
  children,
  isOpen,
  setIsOpen,
  position,
  isLeftBarOpened,
  className,
  closeOnCLickOutside,
}) => {
  const {
    innerEventDescIsOpen,
    groupDescriptionIsOpen,
    appearanceIsOpen,
    personalInformationIsOpen,
    subscriptionIsOpen,
    privacyAndSecurityIsOpen,
    preferenceIsOpen,
    notificationIsOpen,
  } = useSelector((state) => state.sideView)

  /* // disable scroll when drawer is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen]) */
  const { nightMode } = useSelector((state) => state.theme)
  const [width, setWidth] = useState('')

  useLayoutEffect(() => {
    if (
      (isLeftBarOpened && innerEventDescIsOpen) ||
      (isLeftBarOpened && groupDescriptionIsOpen) ||
      (isLeftBarOpened && appearanceIsOpen) ||
      (isLeftBarOpened && personalInformationIsOpen) ||
      (isLeftBarOpened && subscriptionIsOpen) ||
      (isLeftBarOpened && privacyAndSecurityIsOpen) ||
      (isLeftBarOpened && preferenceIsOpen) ||
      (isLeftBarOpened && notificationIsOpen)
    ) {
      setWidth('md:w-[64.9rem]')
    } else if (
      (isLeftBarOpened && !innerEventDescIsOpen) ||
      (isLeftBarOpened && !groupDescriptionIsOpen) ||
      (isLeftBarOpened && appearanceIsOpen) ||
      (isLeftBarOpened && personalInformationIsOpen) ||
      (isLeftBarOpened && subscriptionIsOpen) ||
      (isLeftBarOpened && privacyAndSecurityIsOpen) ||
      (isLeftBarOpened && preferenceIsOpen) ||
      (isLeftBarOpened && notificationIsOpen)
    ) {
      /*    setTimeout(() => {
        setWidth('md:w-[37.1rem]')
      }, 500) */

      setWidth('md:w-[41.5rem]')
    } else if (
      (!isLeftBarOpened && innerEventDescIsOpen) ||
      (!isLeftBarOpened && groupDescriptionIsOpen) ||
      (!isLeftBarOpened && appearanceIsOpen) ||
      (!isLeftBarOpened && personalInformationIsOpen) ||
      (!isLeftBarOpened && subscriptionIsOpen) ||
      (!isLeftBarOpened && privacyAndSecurityIsOpen) ||
      (!isLeftBarOpened && preferenceIsOpen) ||
      (!isLeftBarOpened && notificationIsOpen)
    ) {
      setWidth('md:w-[51.7rem]')
    } else {
      setTimeout(() => {
        setWidth('md:w-[27.9rem] sm:w-[24.2rem]')
      }, 500)
    }
  }, [
    groupDescriptionIsOpen,
    innerEventDescIsOpen,
    isLeftBarOpened,
    appearanceIsOpen,
    personalInformationIsOpen,
    subscriptionIsOpen,
    privacyAndSecurityIsOpen,
    preferenceIsOpen,
    notificationIsOpen,
  ])

  return (
    <main
      className={`fixed overflow-hidden z-40 w-full  ${className} inset-0 transform ease-in-out ${
        isOpen
          ? ` transition-opacity opacity-100 ${width} `
          : 'delay-500 opacity-0 translate-x-full'
      }`}
    >
      <section
        className={`w-[100%] sm:w-[24rem] ${
          position === 'left'
            ? `left-0 ${
                isLeftBarOpened ? 'md:left-[16.875rem]' : 'md:left-[3.5rem]'
              }`
            : 'right-0'
        } absolute ${
          nightMode ? 'bg-DMDarkBlueBackground' : 'bg-white'
        } ${className}  h-full  duration-500 ease-in-out transition-all ${
          nightMode
            ? 'shadow-[0_10px_40px_-15px_rgba(0,0,0,1)]'
            : 'shadow-[0_10px_40px_-15px_rgba(128,128,128,1)]'
        } ${
          isOpen
            ? ''
            : `${
                position === 'left' ? '-translate-x-full' : 'translate-x-full'
              }`
        }`}
      >
        <article className=''>{children}</article>
      </section>
      {closeOnCLickOutside && (
        <section
          className=' w-screen h-full cursor-pointer '
          onClick={() => {
            setIsOpen(false)
          }}
        ></section>
      )}
    </main>
  )
}

export default Drawer
